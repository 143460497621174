import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";
import {addRaspberry, updateRaspberry} from "../../redux/actions/raspberries";
import {listActiveComerciales} from "../../redux/actions/comerciales"
import {defaultIfEmpty} from "../../helpers/helpers";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";


class NewRaspberryModal extends Component {
  state = {
    modal: false,
    forceModalClose: false,
    raspberry: {}
  };

  componentDidMount() {
    this.setState({raspberry: this.props.raspberry})
    if (!this.props.comerciales && !this.props.isLoadingActiveComerciales) {
      this.props.listActiveComerciales();
    }
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChange = e => {
    e.preventDefault();
    this.setState(
      { raspberry: {
        ...this.state.raspberry,
        [e.target.name]: e.target.value
      }
    });
  };

  onChangeComercial = e => {
    e.preventDefault();
    this.setState(
      { raspberry: {
        ...this.state.raspberry,
        comercial: this.props.comerciales.find(c => c.id === parseInt(e.target.value))
      }
    });
  }

  onChangeCliente = e => {
    e.preventDefault();
    this.setState(
      { raspberry: {
        ...this.state.raspberry,
        cliente: this.props.clientes.find(c => c.id === parseInt(e.target.value))
      }
    });
  }

  createRaspberry = e => {
    e.preventDefault();
    this.props.addRaspberry(this.state.raspberry);
    this.setState({modal: false});
  }

  editRaspberry = e => {
    e.preventDefault();
    const raspberry = this.state.raspberry;
    if (!raspberry.comercial){
      delete raspberry.comercial;
    }

    this.props.updateRaspberry(raspberry.id, raspberry);
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({raspberry: {}})
    }
  };


  render() {
    const create = this.props.create;
    const raspberry = this.state.raspberry || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;
    const { t } = this.props;
    
    return (
    <>
    {(this.props.isLoadingActiveComerciales  || this.props.isLoadingActiveClientes) ? null :
    <Fragment>
      { create ?
        <Button color="primary" size="sm" onClick={() => this.onClick()}> {t("add.label")} </Button> :
        <Button style={{marginLeft: "5px"}} color="warning" size="sm" onClick={() => this.onClick()}> {t("edit.label")}  </Button>
      }
      <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === raspberry.id || this.props.isLoading)} toggle={this.toggle} size='lg ' backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t('add.label')} Raspberry`: `${t('edit.label')} Raspberry`}
        </ModalHeader>
        <ModalBody>
        <LoadingOverlay active={this.props.isLoading} spinner={true}>
          <Row>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="canper_id">Id:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="canper_id"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.canper_id)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="mac">Mac:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="mac"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.mac)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="rb">Rb:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="rb"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.rb)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                  <Row>
                    <Col md="4">
                      <Label for="estado">{t('raspberries:state.label')}:</Label>
                    </Col>
                    <Col md="8">
                      <Input type="select" name="estado" onChange={this.onChange} value={defaultIfEmpty(raspberry.estado)}>
                        <option value="">{t("option.label")}</option>
                        <option value="ACTIVO">{t("raspberries:ACTIVO.estado")}</option>
                        <option value="INACTIVO">{t("raspberries:INACTIVO.estado")}</option>
                        <option value="RESERVADA">{t("raspberries:RESERVADA.estado")}</option>
                        <option value="PROGRAMADA">{t("raspberries:PROGRAMADA.estado")}</option>
                        <option value="ENTREGADA">{t("raspberries:ENTREGADA.estado")}</option>
                      </Input>
                    </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="anydesk_id">Anydesk id:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="anydesk_id"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.anydesk_id)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="anydesk_contraseña">{t('raspberries:anydesk_key.label')}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="anydesk_contraseña"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.anydesk_contraseña)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="ovpn_contraseña">{t('raspberries:ovpn_key.label')}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="ovpn_contraseña"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.ovpn_contraseña)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="fecha_expiracion">{t("raspberries:expiry_date.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="date"
                      name="fecha_expiracion"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.fecha_expiracion)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="hora_expiracion">{t("raspberries:expiry_time.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="time"
                      name="hora_expiracion"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.hora_expiracion)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="raspberry_contraseña">{t('raspberries:raspberry_key.label')}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="raspberry_contraseña"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.raspberry_contraseña)}
                    />
                  </Col>
                </Row>
              </FormGroup>              
            </Col>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="isp">Isp:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="isp"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.isp)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="router_usuario">{t('raspberries:router_user.label')}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="router_usuario"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.router_usuario)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="router_contraseña">{t('raspberries:router_key.label')}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="router_contraseña"
                      onChange={this.onChange}
                      value={defaultIfEmpty(raspberry.router_contraseña)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="fecha_rb_entrega">{t('raspberries:date_rb_delivery.label')}</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="date"
                    name="fecha_rb_entrega"
                    onChange={this.onChange}
                    value={defaultIfEmpty(raspberry.fecha_rb_entrega)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="fecha_rb_recogida">{t('raspberries:date_rb_collection.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="date"
                    name="fecha_rb_recogida"
                    onChange={this.onChange}
                    value={defaultIfEmpty(raspberry.fecha_rb_recogida)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="fecha_servicio_inicio">{t('raspberries:date_service_start.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="date"
                    name="fecha_servicio_inicio"
                    onChange={this.onChange}
                    value={defaultIfEmpty(raspberry.fecha_servicio_inicio)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="fecha_servicio_fin">{t('raspberries:date_service_end.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="date"
                    name="fecha_servicio_fin"
                    onChange={this.onChange}
                    value={defaultIfEmpty(raspberry.fecha_servicio_fin)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="comercial">{t("comerciales:comercial.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="comercial" onChange={this.onChangeComercial} value={raspberry.comercial ? defaultIfEmpty(raspberry.comercial.id) : null}>
                      <option value="">{t("common:option.label")}</option>
                      {(this.props.comerciales || []).map((n, index) => (<option key={index} value={n.id}>{n.nombre} {n.apellido_1} {n.apellido_2}</option>))}
                    </Input>
                  </Col>
              </Row>
            </FormGroup>            
          </Col>
        </Row>
        </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>
              {t("cancel.label")}
            </Button>
          { create ? 
            (<Button type="button" color="primary" onClick={this.createRaspberry}>
              {t("add.label")}
            </Button>) :
            (<Button type="button" color="warning" onClick={this.editRaspberry}>
              {t("edit.label")}
            </Button>)
          }
        </ModalFooter>
      </Modal>
    </Fragment>
    }
    </>);
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'RASPBERRIES',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'RASPBERRIES' && state.errores.status !== 200 ? state.errores.context.id : false,
  comerciales: state.comerciales.activeComerciales,
  isLoadingActiveComerciales: state.comerciales.isLoadingActiveComerciales,
});

export default connect(mapStateToProps, {addRaspberry, updateRaspberry, listActiveComerciales})(withTranslation('common', 'raspberries', 'comerciales')(NewRaspberryModal));