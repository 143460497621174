import axios from "axios";
import { tokenConfig } from "./cuentas";
import { API_BANCO_OPCIONES_URL, API_BOOKMAKERS_URL, API_MONEDEROS_OPCIONES_URL } from "../../constants";
import { GET_BANCO_OPCIONES } from "../types/banco";
import { GET_BOOKMAKERS } from "../types/clientes_bookmakers";
import { GET_MONEDEROS_OPCIONES } from "../types/monederos";

export const getOpciones = () => (dispatch, getState) => {

    // opciones para banco
    axios
        .get(`${API_BANCO_OPCIONES_URL}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_BANCO_OPCIONES,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err);
        });

    // opciones para bookmakers
    axios
        .get(`${API_BOOKMAKERS_URL}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_BOOKMAKERS,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err);
        });

    // opciones para monederos
    axios
        .get(`${API_MONEDEROS_OPCIONES_URL}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_MONEDEROS_OPCIONES,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err);
        });
};