import React from "react";
import { connect } from "react-redux";
import { listClientesActivosWithBookmakers } from "../../../redux/actions/clientes"
import {listUsersByGroup} from "../../../redux/actions/cuentas"
import Header from "../../common/Header";
import BreadCrumbs from "../../common/BreadCrumbs";
import { listEstrategiasBookmakersEnProgreso, listAsignaciones } from "../../../redux/actions/estrategias"
import { withTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import ListEstrategiasAccordions from "./ListEstrategiasAccordions";
import { listHistoricEstrategias } from "../../../redux/actions/estrategias"


class EstrategiasView extends React.Component {

    componentDidMount() {
        if (!this.props.estrategias) {
            this.props.listEstrategiasBookmakersEnProgreso()
        }
        if (!this.props.enlacesAsignados) {
            this.props.listAsignaciones()
        }
        if (!this.props.clientes) {
            this.props.listClientesActivosWithBookmakers();
        }
        if (!this.props.traders) {
            this.props.listUsersByGroup('trader');
        }
        if (!this.props.historicEstrategias) {
            this.props.listHistoricEstrategias()
        }
    }


    render() {
        const { t } = this.props;
        return (
            <>
            <Header />
            <Container fluid>
                <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'name': t('estrategias:strategy.label'), 'active': true},
                {'name': t('estrategias:assignation.label'), 'active': true},
                ]}/>
                { this.props.isClientesLoading === false && this.props.isEstrategiasLoading === false && this.props.isTradersLoading === false && this.props.isEnlacesAsignadosLoading === false && !this.props.AsignacionesOrEstrategiasLoading && !this.props.historicEstrategiasLoading ?
                <ListEstrategiasAccordions traders={this.props.traders} clientes={this.props.clientes} estrategias={this.props.estrategias} enlacesAsignados={this.props.enlacesAsignados} historicEstrategias={this.props.historicEstrategias} permissions={this.props.permissions}/> :
                <Spinner animation="border" variant="primary"/>}
            </Container>
            </>
        );
    }
}


const mapStateToProps = state => ({
    clientes: state.clientes.activeClientes,
    isClientesLoading: state.clientes.isLoadingActiveClientes,
    estrategias: state.estrategias.estrategias,
    isEstrategiasLoading: state.estrategias.isLoadingEstrategias,
    historicEstrategias: state.estrategias.estrategias_historico,
    historicEstrategiasLoading: state.estrategias.isLoadingHistoric,
    permissions: state.cuentas.permissions.estrategia,
    traders: state.usuarios.trader,
    isTradersLoading: state.usuarios.isLoading,
    enlacesAsignados: state.estrategias.asignaciones,
    isEnlacesAsignadosLoading: state.estrategias.isLoadingAsignaciones,
    AsignacionesOrEstrategiasLoading: state.estrategias.isSaveLoading && Object.values(state.estrategias.isSaveLoading).find(v => v)
});

export default connect(mapStateToProps, {listAsignaciones, listEstrategiasBookmakersEnProgreso, listClientesActivosWithBookmakers, listUsersByGroup, listHistoricEstrategias})(withTranslation('estrategias')(EstrategiasView));