import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Row, Col, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';


class DropZoneFicheros extends Component {

    render () {
        const {t} = this.props;
        const maxSize = 20971520; // 20 MB
        return (
            <Dropzone
                onDrop={acceptedFiles => console.log(acceptedFiles)}
                // Types: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
                accept="application/pdf, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, video/x-msvideo, video/mp4"
                minSize={0}
                maxSize={maxSize}
                maxFiles={50}
                multiple
                onDropAccepted={(f) => this.props.addFicheros(f)}
            >
                {({getRootProps, getInputProps, acceptedFiles, isDragActive, isDragReject, rejectedFiles}) => {
                    const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;            
                    
                    return (
                        <>
                        <div>
                            <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            
                            <Row style={{borderStyle: "dotted", paddingTop: "50px", paddingBottom: "50px", marginLeft: "25px", marginRight: "25px"}}>
                                <Col md="4"></Col>
                                <Col md="4">
                                    <Row style={{justifyContent: "center"}}><i className="fa fa-cloud-upload fa-2x"></i></Row>
                                    <Row />
                                    {!isDragActive &&
                                        <Row style={{justifyContent: "center"}}>{t("ficheros:drag.label")}</Row>}
                                    {isDragActive && !isDragReject && 
                                        <Row style={{justifyContent: "center"}}>{t("ficheros:drop.label")}</Row>}
                                    {isDragReject && 
                                        <Row className="text-danger" style={{justifyContent: "center"}}>{t("ficheros:not_allowed.label")}</Row>}
                                    {isFileTooLarge && 
                                        <Row className="text-danger" style={{justifyContent: "center"}}>{t("ficheros:too_big.label")}</Row>}
                                </Col>
                                <Col md="4"></Col>
                            </Row>    
                            </div>
                        </div>
                        <ul className="list-group mt-4">
                            {this.props.files.length > 0 && this.props.files.map((file, i) => (
                            <li key={i} className="list-group-item list-group-item-primary">
                                <Row>
                                    <Col>
                                        {file.name}
                                    </Col>   
                                    <Col className="col-auto">
                                    <Button size="sm" color="danger" onClick={() => this.props.deleteFichero(file.name)}>
                                        {t("cancel.label")}
                                    </Button>
                                    </Col>  
                                </Row>
                            </li>
                            ))}
                        </ul>
                        </>
                    )}
                    }
            </Dropzone>
        );
    }
}

export default (withTranslation(['common', 'ficheros'])(DropZoneFicheros));