import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, DropdownItem } from "reactstrap";
import {removeFichero} from '../../../redux/actions/ficheros'
import { withTranslation } from "react-i18next";


class DeleteFicheroModal extends Component {
    state = {
      modal: false
    };
  
    toggle = () => {
      this.setState(previous => ({
        modal: !previous.modal
      }));
    };
  
    deleteFichero = () => {
      this.props.fileIds.map((id) => this.props.removeFichero(id))
      this.toggle();
      this.props.clearSelectedFicheros()
    };
  
    render() {
      const {t} = this.props;

      return (   
        <Fragment>
          {/* <Button size="sm" color="danger" onClick={() => this.toggle()}> */}
          <DropdownItem onClick={() => this.toggle()}>
          {t("remove.label")}
          </DropdownItem>
            {/* </Button> */}
          <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
            <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} {t("ficheros:file.label")}
            </ModalHeader>
            <ModalBody>
              {t("ficheros:remove_confirmation.label")}:
              <ul className="list-group mt-2">
                  {this.props.fileNames.map((name, i) => (
                  <li key={i} className="list-group-item list-group-item-primary">
                      {name}
                  </li>
                  ))}
              </ul>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="outline-secondary" onClick={() => this.toggle()}>
              {t("cancel.label")}
              </Button>
              <Button
                type="button"
                color="danger"
                onClick={() => this.deleteFichero()}
              >
                {t("remove.label")}
              </Button>
            </ModalFooter>
          </Modal>
        </Fragment>
      );
    }
  }
  
  
  export default connect(null, {removeFichero})(withTranslation(['common', 'ficheros'])(DeleteFicheroModal));