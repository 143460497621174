import { API_CLIENTES_URL } from "../../constants";
import axios from "axios";

import { createMessage } from "../actions/mensajes";
import { setErrores } from "../actions/errores";
import {LIST_FICHEROS_BY_CLIENTE_ID, ADD_FICHERO, REMOVE_FICHERO} from "../types/ficheros"
import { tokenConfig } from './cuentas';
import i18n from '../../translations/i18n';

const FICHEROS = 'FICHEROS';

// Add fichero
export const addFichero = (clienteId, fichero) => (dispatch, getState) => {
    const config = tokenConfig(getState);

    axios
        .post(`${API_CLIENTES_URL}ficheros/`, {"cliente": clienteId}, config)
        .then(res => {
            const file_id = res.data['id'];
            config.headers['Content-Type'] = 'application/octet-stream';
            config.headers['Content-Disposition'] = `attachment; filename=${fichero.name}`;
            axios
            .put(`${API_CLIENTES_URL}ficheros/${file_id}/add/`, fichero, config)
            .then(res => {
                dispatch({
                    type: ADD_FICHERO,
                    payload: res.data
                });
                dispatch(createMessage(`${i18n.t("ficheros:added_file.message")}: ${fichero.name}`))
            })
            .catch(err => {
                dispatch(setErrores(err.response, FICHEROS));
            })
        })
        .catch(err => {
            dispatch(setErrores(err.response, FICHEROS));
        })
};


// Remove fichero
export const removeFichero = id => (dispatch, getState) => {
    axios
        .delete(`${API_CLIENTES_URL}ficheros/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_FICHERO,
                payload: id
            });
            dispatch(createMessage(i18n.t("ficheros:remove_file.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, FICHEROS));
        })
};


// List all ficheros
export const listFicherosByClienteId = (clienteId) => (dispatch, getState) => {
    axios
        .get(`${API_CLIENTES_URL}${clienteId}/ficheros/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_FICHEROS_BY_CLIENTE_ID,
                payload: res.data
            });
        })
        .catch(err => {
            if (err.response.status === 403) {
                dispatch({
                    type: LIST_FICHEROS_BY_CLIENTE_ID,
                    payload: 'unauthorized'
                });
            }
            console.log(err);
        });
};


export const downloadFichero = url => (dispatch, getState) => {
    const url_split = url.split('/');
    const fileName = url_split[url_split.length-1]
    const config = tokenConfig(getState)
    config.headers['Content-Type'] = 'application/octet-stream';
    config.headers['Content-Disposition'] = `attachment; filename=${fileName}`;
    config.responseType = 'blob';
    axios
        .get(url, config)
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            dispatch(createMessage(`${i18n.t("ficheros:downloaded_file.message")} ${fileName}`));
        })
        .catch(err => {
            dispatch(setErrores(err.response, FICHEROS));
        })
};