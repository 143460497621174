import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PDFViewer from './viewers/PDFViewer';
import Paper from '@material-ui/core/Paper';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { addTooltipToObservaciones } from '../../../helpers/helpers';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}));


const PDF = 'pdf';
const EXCEL = ['xls', 'xlsx', 'csv'];
const DOC = ['doc', 'docx'];
const TXT = 'txt';
const IMAGE = ['png', 'jpg', 'jpeg'];
const VIDEO = ['mp4', 'avi', 'mpeg'];
const ZIP = 'zip'


export default function DisplayFicheros(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        
        {props.ficheros.map((file, i) => (
            <Grid item md={2} key={i}>
            <Paper className={classes.paper}>
                
                <Grid container spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                      <span style={{cursor: "pointer"}} onClick={() => window.open(file.fichero, "_blank")}>
                        {
                        props.getExtension(file.fichero).toLowerCase() === PDF ?
                        <PDFViewer style={{textAlign: "center"}} fichero={file.fichero}/> :

                        IMAGE.includes(props.getExtension(file.fichero).toLowerCase()) ?
                        <div style={{height: "100px", width: "100%"}}>
                          <img style={{maxWidth: "100%", maxHeight: "100%"}} alt="alt" src={file.fichero} />
                        </div> :

                        props.getExtension(file.fichero).toLowerCase() === TXT ?
                        <i className="fa fa-file-alt-o" style={{fontSize: "7em"}}></i> :
                        
                        props.getExtension(file.fichero).toLowerCase() === ZIP ?
                        <i className="fa fa-file-archive-o" style={{fontSize: "7em"}}></i> :

                        VIDEO.includes(props.getExtension(file.fichero).toLowerCase()) ?
                        <i className="fa fa-file-video-o" style={{fontSize: "7em"}}></i> :

                        DOC.includes(props.getExtension(file.fichero).toLowerCase()) ?
                        <i className="fa fa-file-word-o" style={{fontSize: "7em"}}></i> :

                        EXCEL.includes(props.getExtension(file.fichero).toLowerCase()) ?
                        <i className="fa fa-file-excel-o" style={{fontSize: "7em"}}></i> :

                        <i className="fa fa-file" style={{fontSize: "7em"}}></i>
                        }
                      </span>
                        
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={props.checkedFicheros.hasOwnProperty(file.id) && props.checkedFicheros[file.id]}
                              onChange={(e) => props.onChangeCheckBox(e.target.checked, e.target.value)}
                              name="checkedB"
                              color="primary"
                              value={i}
                            />
                          }
                          label={<Typography className="fichero-name" style={{fontSize: '0.7rem', fontWeight: "bold"}}>{addTooltipToObservaciones(props.getShortName(file.fichero), `fichero-${i}`)}</Typography>}
                        />
                    </Grid>
                </Grid>

            </Paper>
            </Grid>
        ))}
        </Grid>
    </div>
  );
}