import React from "react";
import { Container } from "reactstrap";

import Header from "./common/Header";
import BreadCrumbs from "./common/BreadCrumbs";


export default function Home() {

  return (
    <>
      <Header />
      <Container fluid>
        <BreadCrumbs items={[
          {'href': '/#', 'name': 'Home', 'active': true},
        ]}/>
      </Container>
    </>
  );
}
