import { API_RASPBERRIES_URL, API_CLIENTES_URL } from "../../constants";
import {  ADD_CONEXION, REMOVE_CONEXION, UPDATE_CONEXION, LIST_CONEXIONES, LIST_CONEXION_BY_CLIENTE_ID, LIST_CONEXIONES_BY_RASPBERRY_ID, UPDATE_CONEXIONES} from "../types/conexiones";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores } from "./errores";
import i18n from '../../translations/i18n';


const CONEXIONES = 'CONEXIONES';

// Add Conexion
export const addConexion = (conexion) => (dispatch, getState) => {
    const partialConexion = {...conexion}
    delete partialConexion.fichero_ovpn

    const config = tokenConfig(getState);
    // Create record in DB
    return axios
        .post(`${API_RASPBERRIES_URL}conexiones/`, partialConexion, config)
        .then(res => {
            const conexion_id = res.data['id'];
            dispatch({
                type: ADD_CONEXION,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("conexiones:added_conexion.message")));
            config.headers['Content-Type'] = 'application/octet-stream';
            config.headers['Content-Disposition'] = `attachment; filename=${conexion.fichero_ovpn.name}`;

            // Fichero ovpn
            axios
            .patch(`${API_RASPBERRIES_URL}conexiones/${conexion_id}/add_ovpn/`, conexion.fichero_ovpn, config)
            .then(res => {
                dispatch({
                    type: UPDATE_CONEXION,
                    payload: res.data
                });
                dispatch(createMessage(i18n.t("conexiones:added_conexion_ovpn.message")));

                // Fichero exe
                axios
                .patch(`${API_RASPBERRIES_URL}conexiones/${conexion_id}/add_exe/`, null, config)
                .then(res => {
                    dispatch({
                        type: UPDATE_CONEXION,
                        payload: res.data
                    });
                    dispatch(createMessage(i18n.t("conexiones:added_conexion_exe.message")));
                })
                .catch(err => {
                    dispatch(setErrores(err.response, CONEXIONES));
                })
            })
            .catch(err => {
                dispatch(setErrores(err.response, CONEXIONES));
            })
        })
        .catch(err => {
            dispatch(setErrores(err.response, CONEXIONES));
        })
};


// Remove Conexion
export const removeConexion = id => (dispatch, getState) => {
    axios
        .delete(`${API_RASPBERRIES_URL}conexiones/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_CONEXION,
                payload: id
            });
            dispatch(createMessage(i18n.t("conexiones:remove_conexion.message")));
        })
        .catch(err => console.log(err))
};


// List Conexiones of a cliente
export const listConexiones = () => (dispatch, getState) => {
    axios
    .get(`${API_RASPBERRIES_URL}conexiones/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONEXIONES,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_CONEXIONES,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listConexionesByRaspberryId = (raspberryId) => (dispatch, getState) => {
    axios
    .get(`${API_RASPBERRIES_URL}${raspberryId}/conexiones/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONEXIONES_BY_RASPBERRY_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_CONEXIONES_BY_RASPBERRY_ID,
                payload: []
            });
        }
        console.log(err)
    })
};


export const downloadFichero = url => (dispatch, getState) => {
    if (!url.includes('/')) {return}

    const url_split = url.split('/');
    const fileName = url_split[url_split.length-1]
    const config = tokenConfig(getState)
    config.headers['Content-Type'] = 'application/octet-stream';
    config.headers['Content-Disposition'] = `attachment; filename=${fileName}`;
    config.responseType = 'blob';
    axios
        .get(url, config)
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            dispatch(createMessage(`${i18n.t("ficheros:downloaded_file.message")} ${fileName}`));
        })
        .catch(err => {
            dispatch(setErrores(err.response, CONEXIONES));
        })
};


export const checkConexiones = (conexiones) => (dispatch, getState) => {

    // const parsedConexiones = conexiones.map(c => 
    //     ({id: c.id, exe_contraseña: c.exe_contraseña}))
    axios
        .post(`${API_RASPBERRIES_URL}conexiones/check/`, conexiones ,tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: UPDATE_CONEXIONES,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("conexiones:check_connecions.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, CONEXIONES));
        })

}


export const listConexionByClienteId = (clienteId) => (dispatch, getState) => {
    axios
    .get(`${API_CLIENTES_URL}${clienteId}/conexion/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONEXION_BY_CLIENTE_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_CONEXION_BY_CLIENTE_ID,
                payload: []
            });
        }
        console.log(err)
    })
};
