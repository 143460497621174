import React from 'react';
import { useTranslation } from 'react-i18next';
import filterFactory, { selectFilter, textFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import { getOpciones, paginationOptions} from '../../common/BootstrapTableHelper';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { getBalanceSumary } from '../../contabilidades/helper';
import DisplayPagos from '../../pagos/DisplayPagos';


export default function ListResumenTraderPayment(props) {

    const filters = React.useState({
        trader: localStorage.getItem('filters.resumenAdministradorTraderPayment.trader'),
        balance: localStorage.getItem('filters.resumenAdministradorTraderPayment.balance'),
        estimacion: localStorage.getItem('filters.resumenAdministradorTraderPayment.estimacion'),
        pagado: localStorage.getItem('filters.resumenAdministradorTraderPayment.pagado'),
        pendiente: localStorage.getItem('filters.resumenAdministradorTraderPayment.pendiente'),
        diferencia: localStorage.getItem('filters.resumenAdministradorTraderPayment.diferencia'),
    });
    const { t } = useTranslation();

    let traderFilter = undefined;
    let balanceFilter = undefined;
    let estimacionFilter = undefined;
    let pagadoFilter = undefined;
    let pendienteFilter = undefined;
    let diferenciaFilter = undefined;

    const clearFilters = () => {
      traderFilter('');
      balanceFilter('');
      estimacionFilter('');
      pagadoFilter('');
      pendienteFilter('');
      diferenciaFilter('');
    }

    const addBalance = estrategias => {
      return (estrategias || []).map(e => ({...e, balance: getBalanceSumary(e)}));
    }

    const transformEstrategiasByTrader = estrategias => {
      return estrategias.reduce((obj, e) => (
        
        obj.hasOwnProperty(`${e.asignacion.id}`) ?
          {...obj, [`${e.asignacion.id}`]: [...obj[`${e.asignacion.id}`], e]}  :
          {...obj, [`${e.asignacion.id}`]: [e]})
        
          , {});
    }

    const transformEstrategiasIntoTable = (estrategias) => {
      const transformedEstrategias = [];
      estrategias.forEach(e => {
        let pagos = (props.pagos || []).filter(p => p.user === e.bookmaker.creado_por.id)
        if (props.canperId !== 'ALL') {
          pagos = pagos.filter(p => p.canper_id === e.canper_id)
        }
        const pagado = pagos.reduce((sum, p) => p.pago ? sum + parseFloat(p.pago) : sum, 0);

        transformedEstrategias.push({
          'trader': e.asignacion.trader.username,
          'pagado_value': pagado,
          'pagado': <>
            {pagado}
            <DisplayPagos pagos={pagos}/>
            </>,
          'pendiente': parseFloat(e.balance) + parseFloat(e.estimacion),
          ...e
        })
      })

      return transformedEstrategias.map(e => ({
        'diferencia': (e.pendiente - e.pagado_value).toFixed(2),
        ...e
      }))
    }

    const estrategiasIntoGrouping = estrategiasByGestor => {
      const balances = Object.values(estrategiasByGestor).map(e => e.reduce((sum, b) => b.saldo_final && b.fecha_fin && b.balance ? sum + parseFloat(b.balance) : sum, 0)).map(b => b.toFixed(2));
      const estimaciones = Object.values(estrategiasByGestor).map(e => e.reduce((sum, b) => !b.saldo_final && !b.fecha_fin && b.estimaciones && b.estimaciones.length > 0 ? sum + parseFloat(b.balance)  : sum, 0)).map(b => b.toFixed(2));

      return Object.keys(estrategiasByGestor).map((c, i) => ({...estrategiasByGestor[c][0], 'balance': balances[i], 'estimacion': estimaciones[i], 'total': parseFloat(parseFloat(balances[i]) + parseFloat(estimaciones[i])).toFixed(2)}))
    }

    const estrategias = addBalance(props.estrategias);
    const estrategiasByTrader = transformEstrategiasByTrader(estrategias);
    const estrategiasGrouping = estrategiasIntoGrouping(estrategiasByTrader);
    const rows = transformEstrategiasIntoTable(estrategiasGrouping);
    
    const headers = [
      {
        text: 'Trader', 
        dataField: 'trader',
        filter: selectFilter({
          defaultValue: filters.trader,
          options: getOpciones('trader', rows),
          getFilter: (filter) => {
            traderFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTraderPayment.trader', value);
          },
        }),
        sort: true
      },      
      
      
      {
        text: 'Balance', 
        dataField: 'balance',
        filter: textFilter({
          defaultValue: filters.balance,
          getFilter: (filter) => {
            balanceFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTraderPayment.balance', value);
          },
        }),
        sort: true
      },
      {
        text: 'Estimacion', 
        dataField: 'estimacion',
        filter: textFilter({
          defaultValue: filters.estimacion,
          getFilter: (filter) => {
            estimacionFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTraderPayment.estimacion', value);
          },
        }),
        sort: true
      },
      {
        text: t("common:pending.label"), 
        dataField: 'pendiente',
        filter: numberFilter({
          defaultValue: {
            number: filters.pendiente,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            pendienteFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTraderPayment.pendiente', value);
          },
        }),
        sort: true
      },
      {
        text: t('common:paid.label'), 
        dataField: 'pagado',
        filter: numberFilter({
          defaultValue: {
            number: filters.pagado,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            pagadoFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTraderPayment.pagado', value);
          },
        }),
        sort: true
      },
      {
        text: t('common:difference.label'), 
        dataField: 'diferencia',
        filter: numberFilter({
          defaultValue: {
            number: filters.diferencia,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            diferenciaFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTraderPayment.diferencia', value);
          },
        }),
        style: (cell, row, rowIndex, colIndex) => {
          return cell === '0.00' ? {backgroundColor: '#c8e6c9'} : {backgroundColor: '#e69aa6'};
        },
        sort: true
      },   
    ]

    return <PaginationProvider  pagination={ paginationFactory(paginationOptions(rows.length)) }>
    {
      ({
        paginationProps,
        paginationTableProps
      }) => (
        <>
        <div className="pagination-table">
          <Row >
            <Col>
              <Button color="secondary" size="sm" onClick={clearFilters}>{t('common:remove_filters.label')}</Button>
            </Col>
            <Col className="col-auto">
              <PaginationListStandalone
                { ...paginationProps }
              />
            </Col>
            <Col className="col-auto">
              <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
            </Col>
          </Row>
        </div>
        <BootstrapTable
            bootstrap4
            hover
            condensed
            bordered={ false }
            keyField='id'
            data={rows}
            columns={ headers }
            filter={ filterFactory() }
            defaultSorted={ [{dataField: 'id', order: 'asc'}] }
            wrapperClasses="resumen-gestores-datatable"
            { ...paginationTableProps }
            />
        </>
        )}
    </PaginationProvider>
}
