import {LIST_CONEXIONESPROV, LIST_CONEXIONPROVCLIENTE,REMOVE_CONEXIONPROV, UPDATE_CONEXIONPROV, ADD_CONEXIONPROV, IMPORT_CONEXION_FICHERO,BULK_DELETE_CONEXIONPROV} from "../types/conexionesprov";

const initialState = {
    conexionesprov: null};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_CONEXIONESPROV:
            return {
                ...state,
                conexionesprov: action.payload,
            }
        case LIST_CONEXIONPROVCLIENTE:
            return {
                ...state,
                conexionesprovByCliente: {
                    ...state.conexionesprovByCliente, 
                    [action.payload.clienteId]: action.payload.conexiones
                }
            }
        case REMOVE_CONEXIONPROV:
            return {
                ...state,
                conexionesprov: state.conexionesprov.filter(conexionprov => conexionprov.id !== action.payload)
            }
        case ADD_CONEXIONPROV:
            return {
                ...state,
                conexionesprov: Array.isArray(action.payload) ? [...state.conexionesprov, ...action.payload] : [...state.conexionesprov, action.payload]
            }

        case UPDATE_CONEXIONPROV:
            return {
                ...state,
                conexionesprov: (state.conexionesprov || []).map(conexionprov => conexionprov.id !== action.payload.id ? conexionprov : action.payload),
                conexionprov: action.payload
            }

        case IMPORT_CONEXION_FICHERO:
            return {
                ...state,
                conexionesprov: [state.conexionesprov, ...action.payload],
                importationIsLoading: false
            }

        default:
            return state;
    }
}
