import React from "react";
import { connect } from "react-redux";
import Header from "../../common/Header";
import BreadCrumbs from "../../common/BreadCrumbs";
import { listHistoricEstrategias } from "../../../redux/actions/estrategias"
import { withTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import ListHistoricEstrategias from "./ListHistoricEstrategias";


class EstrategiasHistoricView extends React.Component {

    componentDidMount() {
        if (!this.props.estrategias) {
            this.props.listHistoricEstrategias()
        }
    }


    render() {
        const { t } = this.props;
        return (
            <>
            <Header />
            <Container fluid>
                <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'name': t('estrategias:strategy.label'), 'active': true},
                {'name': t('contabilidades:historic.label'), 'active': true},
                ]}/>
                { this.props.isLoading !== false ? <Spinner animation="border" variant="primary"/> :
                <ListHistoricEstrategias estrategias={this.props.estrategias} permissions={this.props.permissions}/> }
            </Container>
            </>
        );
    }
}


const mapStateToProps = state => ({
    estrategias: state.estrategias.estrategias_historico,
    isLoading: state.estrategias.isLoadingHistoric,
    permissions: state.cuentas.permissions.estrategia
});

export default connect(mapStateToProps, {listHistoricEstrategias})(withTranslation('estrategias')(EstrategiasHistoricView));