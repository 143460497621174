import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, DropdownItem } from "reactstrap";
import { exportToXLSX } from "../../../utilities/Export";
import { createMessage } from "../../../redux/actions/mensajes";
import {exportApuestas} from "./ExportApuestas"
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { dateFromUStoEU } from "../../../helpers/helpers";


class ExportApuestasModal extends Component {
    state = {
        modal: false
    }

    toggle = () => {
        this.setState(previous => ({
          modal: !previous.modal
        }));
    };

    export = () => {
        const {t} = this.props;
        const data = exportApuestas(this.props.apuestas, this.props.selectedApuestas);
        const filename = `export_${t("apuestas:bet.label")}_${dateFromUStoEU(new Date().toJSON().slice(0,10))}`;
        exportToXLSX(data, filename)
        this.toggle()
        this.props.createMessage(`${t("ficheros:export.message")}: ${filename}`);
    }

    render() {
        const {t} = this.props;

        return (
        <Fragment>
            <DropdownItem onClick={() => {this.toggle()}} key="apuestas-export">
                {t("export.label")}
            </DropdownItem>
            <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
            <ModalHeader toggle={this.toggle}>
                {t("export.label")} {t("apuestas:bet.label")}s
            </ModalHeader>
            <ModalBody>
                {t("apuestas:export.label")}
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="outline-secondary" onClick={() => this.toggle()}>
                {t("cancel.label")}
                </Button>
                <Button
                type="button"
                color="info"
                onClick={this.export}
                >
                {t("export.label")}
                </Button>
                </ModalFooter>
            </Modal> 
        </Fragment>
        );
    }
}


export default connect(null, {createMessage})(withTranslation(['common', 'apuestas'])(ExportApuestasModal));