import React from 'react';

import DisplayBookmakers from "./DisplayBookmakers"
import NewBookmakersModal from "./NewBookmakersModal";
import { addBookmaker, updateBookmaker, listBookmakersByClienteId } from "../../../redux/actions/clientes_bookmakers";
import { updateSaldoInicialContabilidad } from "../../../redux/actions/contabilidades"
import { listIngresosByClienteId } from "../../../redux/actions/ingresos"
import { listRetiradasByClienteId } from "../../../redux/actions/retiradas"
import { listAsignacionesByCliente } from "../../../redux/actions/estrategias"
import { connect } from 'react-redux';
import { Row, Col, CardBody, Card, CardHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class Bookmakers extends React.Component {

    componentDidMount() {
        this.props.listBookmakersByClienteId(this.props.clienteId)
        this.props.listIngresosByClienteId(this.props.clienteId)
        this.props.listRetiradasByClienteId(this.props.clienteId)
        this.props.listAsignacionesByCliente(this.props.clienteId)
    }
   
    createBookmaker = bookmaker => {
        this.props.addBookmaker(bookmaker, bookmaker.estado === 'SOLICITADA', bookmaker.estado === 'EN PROGRESO' && this.props.asignaciones.find(r => !r.fecha_fin));
    };
    
    editBookmaker = bookmaker => {
        const prev_bookmaker = this.props.bookmakers.find(b => b.id === bookmaker.id);
        const isEstadoDifferent = bookmaker.estado !== prev_bookmaker.estado;
        this.props.updateBookmaker(bookmaker.id, bookmaker, bookmaker.estado === 'SOLICITADA' && isEstadoDifferent, bookmaker.estado === 'EN PROGRESO' && isEstadoDifferent ? this.props.asignaciones.find(r => !r.fecha_fin) : false);

        if (prev_bookmaker.saldo_inicial !== bookmaker.saldo_inicial) {
            this.props.updateSaldoInicialContabilidad(bookmaker);
        }
    };

    getExistingBookmakers = () => {
        return this.props.bookmakers.map((b) => b.bookmaker);
    };
  

    render() {
        const { t } = this.props;
        const permissions = this.props.permissions || [];
        return (
            <>
            {!this.props.bookmakers || !this.props.ingresos || !this.props.retiradas ?
            null :
            !permissions.includes('view') ? 
            null :
            <Card style={{ marginTop: "10px" }}>
                <CardHeader>
                <Row>                    
                    <Col>
                        <h5>Bookmakers</h5>
                    </Col>
                    <Col className="col-auto">
                        {permissions.includes('add') &&
                        <NewBookmakersModal existingBookmakers={this.getExistingBookmakers} createBookmaker={this.createBookmaker} create={true} clienteId={this.props.clienteId} />}
                    </Col>
                </Row>
                </CardHeader>
                <CardBody>
                    {this.props.bookmakers.length > 0 ?
                    <DisplayBookmakers editBookmaker={this.editBookmaker} bookmakers={this.props.bookmakers} ingresos={this.props.ingresos} retiradas={this.props.retiradas}/> :
                    <span>{t("dont_exist.label")}</span>}
                </CardBody>
            </Card>
                }
            </>
        );
    }
}


const mapStateToProps = state => ({
    bookmakers: state.clientes.bookmakers,
    bookmaker_opciones: state.opciones.bookmaker,
    ingresos: state.clientes.bookmaker_ingresos,
    retiradas: state.clientes.bookmaker_retiradas,
    permissions: state.cuentas.permissions.bookmaker,
    asignaciones: state.clientes.asignaciones,
});


export default connect(mapStateToProps, {updateSaldoInicialContabilidad, addBookmaker, updateBookmaker, listBookmakersByClienteId, listIngresosByClienteId, listRetiradasByClienteId, listAsignacionesByCliente})(withTranslation('bookmakers')(Bookmakers));