import React from 'react';
import { addBanco, removeBanco, updateBanco, listBancoByClienteId } from "../../../redux/actions/banco";
import { connect } from 'react-redux';
import { Row, Col, CardHeader, CardBody, Card } from 'reactstrap';

import NewBancoModal from "./NewBancoModal";
import DeleteBancoModal from './DeleteBancoModal';
import CopyToClipboard from "../../common/CopyToClipboard";
import {addTooltipToObservaciones} from "../../../helpers/helpers";
import { withTranslation } from 'react-i18next';


class Banco extends React.Component {

    componentDidMount() {
        this.props.listBancoByClienteId(this.props.clienteId)
    }
    
    createBanco = bookmaker => {
        this.props.addBanco(bookmaker);
    };
    
    editBanco = bookmaker => {
        this.props.updateBanco(bookmaker.id, bookmaker);
    };

    getMonthYear = date => {
        if (!date || !date.includes('-')) {
            return date
        }
        const d = date.split("-")
        return `${d[1]}/${d[0]}`
    };


    render() {
        const { t } = this.props;
        const banco = this.props.banco || {};
        const permissions = this.props.permissions || [];

        return (
        <>
            {!permissions.includes('view') ?
            null :
            <Card style={{ marginTop: "10px" }}>
                <CardHeader>
                <Row>                    
                    <Col>
                        <h5>{t('bank.label')}</h5>
                    </Col>                    

                    {!banco.id ?
                        <Col className="col-auto">
                            {permissions.includes('add') &&
                            <NewBancoModal create={true} banco={banco} createBanco={this.createBanco} clienteId={this.props.clienteId}/>}
                        </Col> : <>
                        
                            <Col className="col-auto">
                                {permissions.includes('change') &&
                                <NewBancoModal create={false} banco={banco} editBanco={this.editBanco}/>}
                            </Col>
                            <Col className="col-auto">
                                {permissions.includes('delete') &&
                                <DeleteBancoModal id={banco.id}/>}
                            </Col>
                        </>
                    }
                </Row>
                </CardHeader>
                <CardBody>

                    {!banco.id ? <Row><Col><span>{t("dont_exist.label")}</span></Col></Row> :
                    
                    <Row>
                        <Col sm="12" md="4" className="border-right">
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("bank.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{banco.banco}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">Iban</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{banco.iban}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">SWIFT</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{banco.bic_o_swift}</CopyToClipboard></Col>
                            </Row>
                            
                        </Col>
                        <Col sm="12" md="4" className="border-right">                            
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("card.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{banco.tarjeta}</CopyToClipboard></Col>
                            </Row>                            
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("expired.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{this.getMonthYear(banco.fecha_caducidad)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">PIN</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{banco.pin}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">CVC</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{banco.cvc}</CopyToClipboard></Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="4" >
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("user.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{banco.usuario}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("key.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{banco.clave_acceso}</CopyToClipboard></Col>
                            </Row>                            
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("state.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{t(`${banco.estado}.estado`)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("notes.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9">{addTooltipToObservaciones(banco.observaciones, 'banco')}</Col>
                            </Row>
                        </Col>
                    </Row>
                    }
                </CardBody>
            </Card>}
        </>
        );
    }
}


const mapStateToProps = state => ({
    banco: state.clientes.banco,
    banco_opciones: state.opciones.banco,
    permissions: state.cuentas.permissions.banco
  });

export default connect(mapStateToProps, {addBanco, removeBanco, updateBanco, listBancoByClienteId})(withTranslation('banco')(Banco));