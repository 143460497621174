import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, FormGroup, Label, Input } from "reactstrap";
import moment from 'moment';

import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { withTranslation } from "react-i18next";
import { defaultIfEmpty } from "../../../../helpers/helpers";
import { updateIngreso, addIngreso } from "../../../../redux/actions/ingresos"
import { addIngreso as addIngresoFromContabilidad } from "../../../../redux/actions/contabilidades"
import {EditButton,AddButton,CloseButton}  from "../../../common/buttons/Buttons";


class NewIngresoModal extends Component {

  state = {
    modal: false
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
    if (this.props.ingreso) {
      this.setState({ingreso: this.props.ingreso})
    }    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ingreso && prevState.ingreso && prevState.ingreso.id !== prevProps.ingreso.id) {
      this.setState({ingreso: prevProps.ingreso})
    }
  }

  onChange = e => {
    e.preventDefault();
    this.setState(
      { ingreso: {
        ...this.state.ingreso,
        [e.target.name]: e.target.value
      }
    });
  };

  createIngreso = e => {
    e.preventDefault();
    const ingreso = {...this.state.ingreso, bookmaker: this.props.bookmakerId}
    this.props.fromContabilidad ?
    this.props.addIngresoFromContabilidad({...ingreso, pertenece_a: this.props.perteneceAId}) :
    this.props.addIngreso(ingreso);
    this.setState({modal: false});
  }
  
  editIngreso = e => {
    e.preventDefault();
    this.props.updateIngreso(this.state.ingreso.id, this.state.ingreso);
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({ingreso: {}})
    }
  }

  render() {
    const { t } = this.props;
    const create = this.props.create;
    const ingreso = this.state.ingreso || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;  

    return (
    <Fragment>
      { create ? <AddButton onClick={this.onClick} style={{margin: "1px"}}  /> : <EditButton onClick={this.onClick} label= {t("common:edit.label")} style={{margin: "1px"}}/>}
        <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === ingreso.id || this.props.isLoading)} toggle={this.toggle} backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t("common:add.label")} ${t("ingresos:entry.label")}` : `${t("common:edit.label")} ${t("ingresos:entry.label")}`}
        </ModalHeader>
        <ModalBody>                   
          <LoadingOverlay active={this.props.isLoading} spinner={true}>
          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="transferencia">{t("ingresos:entry.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="number"
                  step="0.01"
                  name="transferencia"
                  onChange={this.onChange}
                  value={defaultIfEmpty(ingreso.transferencia)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="fecha">{t("ingresos:date.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="datetime-local"
                  name="fecha"
                  onChange={this.onChange}
                  value={ingreso.fecha}
                  max={moment().format("YYYY-MM-DDThh:mm")}
                />
              </Col>
            </Row>
          </FormGroup>

          </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <CloseButton onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}} label={t("common:cancel.label")} style={{margin: "1px"}}/>
          { create ? <AddButton onClick={this.createIngreso} label={t("common:add.label")} style={{margin: "1px"}}/> : <EditButton onClick={this.editIngreso} label={t("common:edit.label")} style={{margin: "1px"}}/> }
        </ModalFooter>
        </Modal>
    </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'INGRESOS',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'INGRESOS' && state.errores.status !== 200 ? state.errores.context.id : false,
});


export default connect(mapStateToProps, {updateIngreso, addIngreso, addIngresoFromContabilidad})(withTranslation('ingresos', 'common')(NewIngresoModal));