export const ADD_BOOKMAKER = 'ADD_BOOKMAKER';
export const REMOVE_BOOKMAKER = 'REMOVE_BOOKMAKER';
export const UPDATE_BOOKMAKER = 'UPDATE_BOOKMAKER';
export const LIST_BOOKMAKERS = 'LIST_BOOKMAKERS';
export const LIST_BOOKMAKER = 'LIST_BOOKMAKER';
export const LIST_BOOKMAKERS_LOADING = 'LIST_BOOKMAKERS_LOADING';
export const REMOVE_PRIORIDADES = 'REMOVE_PRIORIDADES';
export const ADD_PRIORIDADES = 'ADD_PRIORIDADES';
export const LIST_PRIORIDADES_BY_TRADER = 'LIST_PRIORIDADES_BY_TRADER';
export const LIST_PRIORIDADES_BY_TRADER_LOADING = 'LIST_PRIORIDADES_BY_TRADER_LOADING';
export const LIST_PRIORIDADES_BY_CLIENTE_LOADING = 'LIST_PRIORIDADES_BY_CLIENTE_LOADING';
export const LIST_PRIORIDADES_BY_CLIENTE = 'LIST_PRIORIDADES_BY_CLIENTE';