import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";
import {updateHistoricContabilidad} from "../../../redux/actions/contabilidades";
import {defaultIfEmpty} from "../../../helpers/helpers";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import { CloseButton,EditButton } from "../../common/buttons/Buttons";


class EditContabilidadModal extends Component {
  state = {
    saldoFinal: null,
    saldoInicial: null
  };

  componentDidMount() {
    this.setState({
      saldoFinal: this.props.contabilidad.saldo_final,
      saldoInicial: this.props.contabilidad.saldo_inicial
      })
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChangeSaldoFinal = e => {
    e.preventDefault();
    this.setState(
      {
        saldoFinal:  e.target.value
      });
  };

  onChangeSaldoInicial = e => {
    e.preventDefault();
    this.setState(
      {
        saldoInicial:  e.target.value
      });
  };

  editContabilidad = e => {
    e.preventDefault();
    this.props.updateHistoricContabilidad(
      this.props.contabilidad.id,
      {
        ...this.props.contabilidad,
        saldo_final: this.state.saldoFinal,
        saldo_inicial: this.state.saldoInicial,
      }
      );

    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
  };


  render() {
    const saldo_final = this.state.saldoFinal
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;
    const { t } = this.props;
    
    return (
    <Fragment>
        <EditButton onClick={this.onClick} style={{margin: "1px"}}/>
      <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === this.props.contabilidad.id || this.props.isLoading)} toggle={this.toggle} size='md' backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {`${t('contabilidades:link.label')}`} {this.props.contabilidad.bookmaker && this.props.contabilidad.bookmaker.cliente.enlace} - {this.props.contabilidad.pertenece_a && this.props.contabilidad.pertenece_a.username}
        </ModalHeader>
        <ModalBody>
        <LoadingOverlay active={this.props.isLoading} spinner={true}>
          <Row>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="canper_id">Id:</Label>
                  </Col>
                  <Col md="8" style={{alignSelf: "center"}}>
                    {this.props.contabilidad.canper_id}
                  </Col>
                  <Col md="4">
                    <Label className="col-form-label" for="canper_id">Bookmaker:</Label>
                  </Col>
                  <Col md="8" style={{alignSelf: "center"}}>
                    {this.props.contabilidad.bookmaker && this.props.contabilidad.bookmaker.bookmaker}
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="fecha_inicio">{t("contabilidades:date_start.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="datetime-local"
                      value={this.props.contabilidad.fecha_inicio.replace('Z', '')}
                      readOnly
                    />
                  </Col>
                </Row>
              </FormGroup>

               <> 
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="fecha_fin">{t("contabilidades:date_end.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="datetime-local"
                      value={this.props.contabilidad.fecha_fin.replace('Z', '')}
                      readOnly
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="saldo_inicial">{t("bookmakers:minimum_balance.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      onChange={this.onChangeSaldoInicial}
                      value={defaultIfEmpty(this.state.saldoInicial)}
                    />
                  </Col>
                </Row>
            </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="saldo_final">{t("contabilidades:final_balance.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      name="saldo_final"
                      onChange={(e) => {this.onChangeSaldoFinal(e)}}
                      value={defaultIfEmpty(saldo_final)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              </>
          </Col>
        </Row>
        </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <CloseButton color={"outline-secondary"} onClick={() => {this.setState({'forceModalClose': true}); this.toggle()}} label={t("cancel.label")} style={{margin: "1px"}}/>
            <EditButton onClick={this.editContabilidad} label={t("edit.label")} style={{margin: "1px"}}/>
      
        </ModalFooter>
      </Modal>
    </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'CONTABILIDADES',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'CONTABILIDADES' && state.errores.status !== 200 ? state.errores.context.id : false,
});

export default connect(mapStateToProps, {updateHistoricContabilidad})(withTranslation('common', 'contabilidades','clientes')(EditContabilidadModal));