import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";
import {addConexionprov, updateConexionprov} from "../../redux/actions/conexionesprov";
import {defaultIfEmpty} from "../../helpers/helpers";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import {EditButton,AddButton,CloseButton} from "../common/buttons/Buttons";


class NewConexionModal extends Component {
  state = {
    modal: false,
    forceModalClose: false,
    conexionprov: {},
  };

  componentDidMount() {
    this.setState({
      conexionprov: this.props.conexionprov,
    })
   
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

 
  onChange = (e, checkbox=false) => {
    // e.preventDefault();
    
    const value = !checkbox ? e.target.value : e.target.checked
    this.setState(
      { conexionprov: {
        ...this.state.conexionprov,
        [e.target.name]: value,
      }
    });
  };
  onChangeCliente = (e) =>{
    const clienteId = [e.target.value];
    const cliente = this.props.clientes.find(c => c.id === parseInt(clienteId));

    this.setState(
      { conexionprov: {
        ...this.state.conexionprov,
        cliente
      }
    });
  };
  createConexion = e => {
    e.preventDefault();
    const cliente = this.state.conexionprov.cliente ? this.state.conexionprov.cliente : undefined;
    this.props.addConexionprov({
      ...this.state.conexionprov,
      cliente
    });
    this.setState({modal: false});
  }

  editConexion = e => {
    e.preventDefault();
    const cliente = this.state.conexionprov.cliente ? this.state.conexionprov.cliente : undefined;
    this.props.updateConexionprov(this.state.conexionprov.id, {...this.state.conexionprov, cliente});
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({conexionprov: {}})
    }
  };


  render() {
    const create = this.props.create;
    const conexionprov = this.state.conexionprov || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;
    const { t } = this.props;
    
    return (
      <>
      { this.props.isLoadingBookmakers || this.props.isLoadingTraders ? null :
      <Fragment>
      
      {create ? <AddButton onClick={this.onClick} style={{margin: "1px"}} /> :< EditButton onClick={this.onClick} style={{margin: "1px"}} /> }

      <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === conexionprov.id|| this.props.isLoading)} toggle={this.toggle} size='lg' backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t('add.label')} ${t("conexionesprov:connection.label")}`: `${t('edit.label')} ${t("conexionesprov:connection.label")}`}
        </ModalHeader>
        <ModalBody>
        <LoadingOverlay active={this.props.isLoading} spinner={true}>
          <Row>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="anydesk_id">Anydesk:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="anydesk_id"
                      onChange={this.onChange}
                      value={defaultIfEmpty(conexionprov.anydesk_id)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="anydesk_contraseña">{t("conexionesprov:anydesk_password.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="anydesk_contraseña"
                      onChange={this.onChange}
                      value={defaultIfEmpty(conexionprov.anydesk_contraseña)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="ip_id">Ip id:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="ip_id"
                      onChange={this.onChange}
                      value={defaultIfEmpty(conexionprov.ip_id)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="clave_ip_id">{t("conexionesprov:key_ip.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="clave_ip_id"
                      onChange={this.onChange}
                      value={defaultIfEmpty(conexionprov.clave_ip_id)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="ip">Ip:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="ip"
                      onChange={this.onChange}
                      value={defaultIfEmpty(conexionprov.ip)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="ip">{t("conexionesprov:client_connection.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="select"
                      name="cliente"
                      onChange={this.onChangeCliente}
                      value={defaultIfEmpty(conexionprov.cliente ? conexionprov.cliente.id : "")}
                    >
                      <option value={''}>{t("estrategias:no_assign.label")}</option>
                      {this.props.clientes && this.props.clientes.map((t,i)=>(<option key={i} value={t.id}>{`${t.entidad} - ${t.enlace}`}</option>))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="pertenece_trader">{t("conexionesprov:trader_connection.label")}</Label>
                  </Col>
                  <Col>
                    <Input
                      type="checkbox"
                      name="pertenece_trader"
                      onChange={(e) => this.onChange(e, true)}
                      checked={conexionprov.pertenece_trader? true : false}
                    />
                  </Col>
                  <Col md="4">
                    <Label className="col-form-label" for="pertenece_gestor"> {t("conexionesprov:manager_connection.label")}</Label>
                  </Col>
                  <Col>
                    <Input
                      type="checkbox"
                      name="pertenece_gestor"
                      onChange={(e) => this.onChange(e, true)}
                      checked={conexionprov.pertenece_gestor? true : false}
                    />
                  </Col>
                </Row>
              </FormGroup>
          </Col>
        </Row>
        </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>
              {t("cancel.label")}
            </Button>
          { create ? 
            (<Button type="button" color="primary" onClick={this.createConexion}>
              {t("add.label")}
            </Button>) :
            (<Button type="button" color="warning" onClick={this.editConexion}>
              {t("edit.label")}
            </Button>)
          }
        </ModalFooter>
      </Modal>
    </Fragment> }
    </>
    );
  }
}


const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'CONEXIONESPROV',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'CONEXIONESPROV' && state.errores.status !== 200 ? state.errores.context.id : false,
});

export default connect(mapStateToProps, {addConexionprov, updateConexionprov})(withTranslation(['common','conexionesprov','estrategias'])(NewConexionModal));