import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";

import { defaultIfEmpty } from "../../../helpers/helpers";
import { knownMonederos, vip, estado, email_o_usuario, observaciones, identificador, secure_o_pin, clave_acceso, pregunta, respuesta } from "./attributeForMonederos";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { withTranslation } from "react-i18next";
import { AddButton, EditButton,CloseButton } from "../../common/buttons/Buttons";


class NewMonederoModal extends Component {
  state = {
    modal: false,
    forceModalClose: false,
    monedero: {}
  };

  componentDidMount() {
    this.setState({monedero: this.props.monedero})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.monedero && prevState.monedero && prevState.monedero.id !== prevProps.monedero.id) {
      this.setState({monedero: prevProps.monedero})
    }
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChange = (e, checkbox=false) => {
    // e.preventDefault();
    const value = !checkbox ? e.target.value : e.target.checked
    this.setState(
      { monedero: {
        ...this.state.monedero,
        [e.target.name]: value
      }
    });
  };

  createMonedero = e => {
    e.preventDefault();
    const monedero = {...this.state.monedero, cliente: this.props.clienteId}
    this.props.createMonedero(monedero);
    this.setState({modal: false});
  }

  editMonedero = e => {
    e.preventDefault();
    this.props.editMonedero(this.state.monedero);
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({monedero: {}})
    }
  }

  render() {
    const { t } = this.props;
    const create = this.props.create;
    const monedero = this.state.monedero || {};
    const monederoOpciones = this.props.monedero_opciones;
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;

    return (
    <Fragment>
      { create ?
        <AddButton onClick={ this.onClick} style={{margin: "1px"}}/>:
        <EditButton onClick={ this.onClick} style={{margin: "1px"}}/>
      }

    <LoadingOverlay active={this.props.isLoading} spinner={true}>
        <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === monedero.id || this.props.isLoading)} toggle={this.toggle} backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t("add.label")} ${t("monederos:wallet.label")}` : `${t("edit.label")} ${t("monederos:wallet.label")}`}
        </ModalHeader>
        <ModalBody>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="monedero">{t("monederos:wallet.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input type="select" name="monedero" onChange={this.onChange} value={defaultIfEmpty(monedero.monedero)}>
                    <option value="">{t("common:option.label")}</option>
                    {
                      // Filtro los monederos ya existentes
                      monederoOpciones.filter((m) => m.monedero === monedero.monedero ||  !this.props.existingMonederos().includes(m.monedero))
                      .map((f, i) => (<option key={i}>{f.monedero}</option>))
                    }
                  </Input>
                </Col>
              </Row>
            </FormGroup>


            { email_o_usuario.includes(monedero.monedero) || (!knownMonederos.includes(monedero.monedero) && monedero.monedero) ?
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="email_o_usuario">{t("monederos:user.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="email_o_usuario"
                      onChange={this.onChange}
                      value={defaultIfEmpty(monedero.email_o_usuario)}
                    />
                  </Col>
                </Row>
              </FormGroup> : null
            }

            { secure_o_pin.includes(monedero.monedero) || (!knownMonederos.includes(monedero.monedero) && monedero.monedero) ?
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="secure_o_pin">Secure/PIN:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="secure_o_pin"
                      onChange={this.onChange}
                      value={defaultIfEmpty(monedero.secure_o_pin)}
                    />
                  </Col>
                </Row>
              </FormGroup> : null
            }

            { identificador.includes(monedero.monedero) || (!knownMonederos.includes(monedero.monedero) && monedero.monedero) ?
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="identificador">{t("monederos:identificator.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="identificador"
                      onChange={this.onChange}
                      value={defaultIfEmpty(monedero.identificador)}
                    />
                  </Col>
                </Row>
              </FormGroup> : null
            }        

            { clave_acceso.includes(monedero.monedero) || (!knownMonederos.includes(monedero.monedero) && monedero.monedero) ?
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="clave_acceso">{t("monederos:key.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="clave_acceso"
                      onChange={this.onChange}
                      value={defaultIfEmpty(monedero.clave_acceso)}
                    />
                  </Col>
                </Row>
              </FormGroup> : null
            }

            { pregunta.includes(monedero.monedero) || (!knownMonederos.includes(monedero.monedero) && monedero.monedero) ?
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="pregunta">{t("monederos:question.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="pregunta"
                      onChange={this.onChange}
                      value={defaultIfEmpty(monedero.pregunta)}
                    />
                  </Col>
                </Row>
              </FormGroup> : null
            }

            { respuesta.includes(monedero.monedero) || (!knownMonederos.includes(monedero.monedero) && monedero.monedero) ?
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="respuesta">{t("monederos:answer.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="respuesta"
                      onChange={this.onChange}
                      value={defaultIfEmpty(monedero.respuesta)}
                    />
                  </Col>
                </Row>
              </FormGroup> : null
            }

            { estado.includes(monedero.monedero) || (!knownMonederos.includes(monedero.monedero) && monedero.monedero) ?
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="estado">{t("monederos:state.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="estado" onChange={this.onChange} value={defaultIfEmpty(monedero.estado)}>
                      <option value="">{t("common:option.label")}</option>
                      <option value="DISPONIBLE">{t("monederos:DISPONIBLE.estado")}</option>
                      <option value="DISPONIBLE NO VERIFICADO">{t("monederos:DISPONIBLE NO VERIFICADO.estado")}</option>
                      <option value="NO DISPONIBLE">{t("monederos:NO DISPONIBLE.estado")}</option>
                    </Input>
                  </Col>
                </Row>
              </FormGroup> : null
            }

            { (vip.includes(monedero.monedero) || (!knownMonederos.includes(monedero.monedero) && monedero.monedero)) &&
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="vip">Vip:</Label>
                  </Col>
                  <Col md="7">
                      <input 
                      type="checkbox"
                      name="vip"
                      checked={!monedero.vip ? false : true}
                      onChange={(e) => this.onChange(e, true)}
                      />
                  </Col>
                </Row>
              </FormGroup>
            }

            { observaciones.includes(monedero.monedero) || (!knownMonederos.includes(monedero.monedero) && monedero.monedero) ?
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="observaciones">{t("monederos:notes.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="textarea"
                      name="observaciones"
                      onChange={this.onChange}
                      value={defaultIfEmpty(monedero.observaciones)}
                    />
                  </Col>
                </Row>
              </FormGroup> : null
            }    

        </ModalBody>
        <ModalFooter>
            <CloseButton onClick={() => {this.setState({'forceModalClose': true}); this.toggle()}} label={t("cancel.label")} style={{margin: "1px"}} />
          { create ? <AddButton onClick={this.createMonedero} label={t("add.label")} /> : <EditButton onClick={this.editMonedero} label={t("edit.label")} style={{margin: "1px"}}/> }
        </ModalFooter>
        </Modal>
      </LoadingOverlay>
    </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'MONEDEROS',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'MONEDEROS' && state.errores.status !== 200 ? state.errores.context.id : false,
  monedero_opciones: state.opciones.monedero
});


export default connect(mapStateToProps)(withTranslation('common', 'monederos')(NewMonederoModal));