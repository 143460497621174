import React from 'react';
import { connect } from "react-redux";
import {addConexionCliente, updateConexionCliente} from "../../../redux/actions/conexion_cliente"
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";
import { withTranslation } from "react-i18next";
import DeleteConexionClienteModal from './DeleteConexionClienteModal';


class ConexionClienteModal extends React.Component {

    state = {
        modal: false,
        selectedClienteId: null
    };

    componentDidMount() {
        this.setState({selectedClienteId: this.props.conexion_cliente && this.props.conexion_cliente.cliente ? this.props.conexion_cliente.cliente.id : '' })
    }

    toggle = () => {
        this.setState(previous => ({
          modal: !previous.modal
        }));
    };

    onChange = e => {
        e.preventDefault();
        this.setState(
          { selectedClienteId: e.target.value
          
        });
    };

    addConexionCliente = () => {
        const cliente = this.props.clientes.find(c => c.id === parseInt(this.state.selectedClienteId))
        const payload = {'conexion': this.props.conexionId, cliente}
        this.props.addConexionCliente(payload)
        this.toggle();
    }

    editConexionCliente = c => {
        const cliente = this.props.clientes.find(c => c.id === parseInt(this.state.selectedClienteId))
        const payload = {'conexion': this.props.conexionId, cliente}
        this.props.updateConexionCliente(this.props.conexion_cliente.id, payload)
        this.toggle();
    }

    
    render () {
        const {t} = this.props;
        return (
            <>
                {this.props.conexion_cliente ? 
                <>{`${this.props.conexion_cliente.cliente.nombre} ${this.props.conexion_cliente.cliente.apellido_1}`}<Button style={{marginLeft: "5px"}} color="warning" size="sm" onClick={() => this.toggle()}> {t("common:edit.label")} </Button>
                <DeleteConexionClienteModal conexionClienteId={this.props.conexion_cliente.id}/>
                </> :
                <Button color="primary" size="sm" onClick={() => this.toggle()}> {t("common:add.label")} </Button>                
                }
                <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
                    <ModalHeader>
                    </ModalHeader>
                    <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col md="4">
                                <Label for="cliente">{t("clientes:client.label")}:</Label>
                            </Col>
                            <Col md="8">
                                <Input type="select" name="cliente" onChange={this.onChange} value={this.state.selectedClienteId}>
                                    <option value="">{t("common:option.label")}</option>
                                    {(this.props.clientes || []).map((n, index) => (<option key={index} value={n.id}>{n.nombre} {n.apellido_1} {n.apellido_2}</option>))}
                                </Input>
                            </Col>
                        </Row>
                    </FormGroup>

                    </ModalBody>

                    <ModalFooter>
                    <Button type="button" color="outline-secondary" onClick={this.toggle}>
                        {t("common:cancel.label")}
                    </Button>
                    {!this.props.conexion_cliente ? 
                        <Button type="button" color="primary" onClick={this.addConexionCliente}>{t("common:add.label")}
                        </Button> :
                        <Button type="button" color="warning" onClick={this.editConexionCliente}>{t("common:edit.label")}
                        </Button>
                    }

                    </ModalFooter>
                </Modal>
            </>
        );
    }
}


export default connect(null, {addConexionCliente, updateConexionCliente})(withTranslation('conexiones', 'common')(ConexionClienteModal))