import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import ListEstimaciones from './ListEstimaciones';
import PrioridadesModal from './PrioridadesModal';
import DisplayConexionprov from "./DisplayConexionprov";


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    details: {
      display: 'grid',
    }
  }));



export default function ListEstimacionesAccordions(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const estimacionesByEstrategia = (estimaciones) => {
      return estimaciones.reduce((obj, e) => (
        obj.hasOwnProperty(e.asignacion.id) ?
          {...obj, [e.asignacion.id]: [...obj[e.asignacion.id], e]} :
          {...obj, [e.asignacion.id]: [e]}
        ), {});
    };

    const estimaciones = estimacionesByEstrategia(props.estimaciones);

    return <>
    {Object.keys(estimaciones).map(estrategia => {
    const clienteId = estimaciones[estrategia][0].bookmaker.cliente.id;
    const clientePrioridades = props.prioridades.filter(p => p.cliente === clienteId);
      return <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{t("estrategias:strategy.label")} {estrategia} </Typography> 
        </AccordionSummary>
        <AccordionSummary> 
          <PrioridadesModal clienteId={clienteId} prioridades={clientePrioridades}/>
        </AccordionSummary>        
        <AccordionSummary> 
          <DisplayConexionprov clienteId={clienteId}/>
        </AccordionSummary>        
        <AccordionDetails>
          <ListEstimaciones estimaciones={estimaciones[estrategia]} prioridades={clientePrioridades}/>
        </AccordionDetails>
      </Accordion>
    })}
    </>;
}