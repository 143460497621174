import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, numberFilter, Comparator  } from 'react-bootstrap-table2-filter';
import { Row } from "reactstrap";
import { addToCopyFormatter} from '../../common/BootstrapTableHelper';
import CloseEstrategiasModal from "./CloseEstrategiasModal";
import NewObjetivoModal from "./NewObjetivoModal"
import Col from "reactstrap/lib/Col";
import { getBalance } from "../../contabilidades/helper";
import { datetimeFromUStoEU } from "../../../helpers/helpers";



class ListEstrategiasAsignadas extends React.Component {

    state = {
        filters: {
          id: localStorage.getItem('filters.estrategias.id'),
          enlace: localStorage.getItem('filters.estrategias.enlace'),
          entidad: localStorage.getItem('filters.estrategias.entidad'),
          bookmaker: localStorage.getItem('filters.estrategias.bookmaker'),
          nBookmakerProgreso: localStorage.getItem('filters.estrategias.nBookmakerProgreso'),
          trader: localStorage.getItem('filters.estrategias.trader'),
          objetivo: localStorage.getItem('filters.estrategias.objetivo'),
          }
    }
    idFilter;
    entidadFilter;
    enlaceFilter;
    bookmakerFilter;
    nBookmakerProgreso;
    traderFilter;
    objetivoFilter;

    clearFilters = () => {
      this.idFilter('');
      this.entidadFilter('');
      this.enlaceFilter('');
      this.nBookmakerProgreso('');
      this.traderFilter('');
      this.objetivoFilter('');
    }

    transformEstrategiasIntoTable = (estrategias) => {
        return estrategias.map((estrategia) => ({
          'asignacion_id': estrategia.records[0].asignacion.id,
          'entidad': estrategia.records[0].entidad,
          'n_bookmaker_progreso': estrategia.records.map(e => e.bookmaker).reduce((acc, e) => e.estado === 'EN PROGRESO' ? 1 + acc : acc, 0)|| 0,
          'objetivo_value': estrategia.records[0].asignacion.objetivo.objetivo,
          'acciones': <>
             <Row>
               {this.props.permissions.includes('change') && !estrategia.fecha_fin && <CloseEstrategiasModal estrategias={estrategia.records.filter(r => !r.saldo_final)} objetivo={estrategia.objetivo.objetivo} trader={estrategia.trader} traders={this.props.traders} />}
               {this.props.permissions.includes('change') &&  <NewObjetivoModal asignacion={estrategia.records[0]}/>}
             </Row>
           </>,
        ...estrategia
        }));
    };

    transformEstrategiasByEnlace = estrategias => {
      const estrategiasByEnlace = estrategias.reduce((obj, e) => (
        obj.hasOwnProperty(e['bookmaker']['cliente']['id']) ?
          {...obj, [e.bookmaker.cliente.id]: {trader: e['asignacion']['trader'], enlace: e['bookmaker']['cliente']['enlace'], objetivo: e['asignacion']['objetivo'], records: obj[e.bookmaker.cliente.id].records.concat([e])}} :
          {...obj, [e.bookmaker.cliente.id]: {trader: e['asignacion']['trader'], enlace: e['bookmaker']['cliente']['enlace'], objetivo: e['asignacion']['objetivo'], records: [e]}})
        , {});
      return Object.values(estrategiasByEnlace);
    }


    render() {
        const { t } = this.props;
        const estrategias = this.props.estrategias;
        const estrategiasByEnlace = this.transformEstrategiasByEnlace(estrategias);
        const rows = this.transformEstrategiasIntoTable(estrategiasByEnlace);
        const headers = [
          this.props.permissions.includes('view') &&
          {
            text: t("estrategias:strategy.label"), 
            dataField: 'asignacion_id',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.id,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.idFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.id', value);
              },
            }),
            sort: true
          },
          {
            text: t("contabilidades:entity.label"), 
            dataField: 'entidad',
            formatter: addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.entidad,
              getFilter: (filter) => {
                this.entidadFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.entidad', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') &&
          {
            text: t("contabilidades:link.label"), 
            dataField: 'enlace',
            formatter: addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.enlace,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.enlaceFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.enlace', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: `Bookmakers ${t("bookmakers:EN PROGRESO.estado").toLowerCase()}`, 
            dataField: 'n_bookmaker_progreso',
            formatter: addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.nBookmakerProgreso,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.nBookmakerProgresoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.nBookmakerProgreso', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: 'Trader', 
            dataField: 'trader.username',
            formatter: addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.trader,
              getFilter: (filter) => {
                this.traderFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.trader', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("estrategias:target.label"), 
            dataField: 'objetivo_value',
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.objetivo,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.objetivoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.objetivo', value);
              },
            }),
            sort: true
          },    
          this.props.permissions.includes('view') && {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]

        const expandRow = {
          renderer: row =>
          (
            <>
              <Row>
                <Col>Bookmaker</Col>
                <Col>{t("contabilidades:date_start.label")}</Col>
                <Col>{t("contabilidades:date_end.label")}</Col>
                <Col>Trader</Col>
                <Col>{t("contabilidades:initial_balance.label")}</Col>
                <Col>{t("contabilidades:final_balance.label")}</Col>
                <Col>Balance</Col>
              </Row>
              {row.records.map(e =>
                <Row>
                  <Col>{e.bookmaker.bookmaker}</Col>
                  <Col>{datetimeFromUStoEU(e.fecha_inicio)}</Col>
                  <Col>{datetimeFromUStoEU(e.fecha_fin)}</Col>
                  <Col>{row.trader.username}</Col>
                  <Col>{e.saldo_inicial}</Col>
                  <Col>{e.saldo_final}</Col>
                  <Col>{getBalance(e)}</Col>
                </Row>
              )}
            </>
          ),
          showExpandColumn: true,
          expandByColumnOnly: true
        };

        return (

            <BootstrapTable
              bootstrap4
              hover
              striped
              condensed
              bordered={ false }
              keyField='records.id'
              data={rows}
              columns={ headers }
              filter={ filterFactory() }
              defaultSorted={ [{dataField: 'estrategia', order: 'asc'}] }
              wrapperClasses="estrategias-datatable"
              expandRow={ expandRow }
            />

        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.estrategia,
  });

export default connect(mapStateToProps, {})(withTranslation('estrategias', 'common')(ListEstrategiasAsignadas));