import React from "react";
import { connect } from "react-redux";
import Header from "../common/Header";
import BreadCrumbs from "../common/BreadCrumbs";
import { listApuestas } from "../../redux/actions/apuestas"
import { withTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import ListApuestas from "./ListApuestas";
import {listBookmakers} from "../../redux/actions/bookmakers";
import {listUsersByGroup} from "../../redux/actions/cuentas"


class ApuestasView extends React.Component {

    componentDidMount() {
        if (!this.props.apuestas) {
            this.props.listApuestas()
        }
        if (!this.props.bookmakers && !this.props.isLoadingBookmakers) {
            this.props.listBookmakers();
        }
        if (!this.props.traders && !this.props.isLoadingTraders) {
            this.props.listUsersByGroup('trader');
        }
    }


    render() {
        const { t } = this.props;
        return (
            <>
            <Header />
            <Container fluid>
                <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'href': '/#/apuestas', 'name': `${t('bet.label')}s`, 'active': true},
                ]}/>
                {/* { this.props.isLoading ? <Spinner animation="border" variant="primary"/> : */}
                { !this.props.apuestas ? <Spinner animation="border" variant="primary"/> : 
                <ListApuestas apuestas={this.props.apuestas}/>}
            </Container>
            </>
        );
    }
}


const mapStateToProps = state => ({
    bookmakers: state.bookmakers.bookmakers,
    apuestas: state.apuestas.apuestas,
    isLoadingBookmakers: state.bookmakers.isLoading,
    isLoadingTraders: state.usuarios.isLoading,
    traders: state.usuarios.trader,
});

export default connect(mapStateToProps, {listApuestas, listBookmakers, listUsersByGroup})(withTranslation('apuestas')(ApuestasView));