import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dateFromUStoEU } from '../../../helpers/helpers'
import {addToCopyFormatter} from '../../common/BootstrapTableHelper';
import ConexionClienteModal from './ConexionClienteModal';


class DisplayConexiones extends React.Component {

    transformConexionesIntoTable = (conexiones, t) => {
        return conexiones.map(b => ({
            'fecha_expiracion_eu': dateFromUStoEU(b.fecha_expiracion),
            'estado': t(`${b.estado}.estado`),
            'cliente_conexion': 
            <Row>
                {<ConexionClienteModal conexionId={b.id} conexion_cliente={this.props.conexiones_clientes.find(c => c.conexion === b.id)} clientes={this.props.clientes}/>}
            </Row>,
            ...b
        }));
    }

    handleOnSelect = (row, isSelect) => {
        isSelect ?
        this.props.addFicheroToselectedRows(row) :
        this.props.deleteFicheroFromselectedRows(row)
          
    }
    
    handleOnSelectAll = (isSelect, rows) => {
    isSelect ?
    rows.map(r => this.props.addFicheroToselectedRows(r)) :
    this.props.clearselectedRows();
    }

    render() {
        const {t} = this.props;
        const conexiones = this.props.conexiones;
        const rows = this.transformConexionesIntoTable(conexiones, t)
        const headers = [
            {text: '', dataField: 'selection'},
            {text: 'Id', dataField: 'canper_id', sort: true, formatter: addToCopyFormatter},
            {text: t("exe_key.label"), dataField: 'exe_contraseña', sort: true, formatter: addToCopyFormatter},
            {text: t("state.label"), dataField: 'estado'},
            {text: t("clientes:client.label"), dataField: 'cliente_conexion'}
        ];

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            bgColor: '#D5F2F7',
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
          };


        return (
            !this.props.permissions.includes('view') ? null :
            <div className="horizontal-scroll">
                <BootstrapTable
                    bootstrap4
                    hover
                    condensed
                    bordered={ false }
                    selectRow={ selectRow }
                    defaultSorted={ [{dataField: 'canper_id', order: 'asc'}] }
                    wrapperClasses="conexiones-datatable"
                    keyField='canper_id'
                    data={ rows }
                    columns={ headers }
                />
            </div>
        );
    }
}


const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.conexion
});

export default connect(mapStateToProps)(withTranslation('conexiones', 'clientes')(DisplayConexiones));