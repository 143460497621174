import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";
import { addAsignacionObjetivoAndEstrategias } from "../../../redux/actions/estrategias";
import {defaultIfEmpty} from "../../../helpers/helpers";
import {setManualErrores} from "../../../redux/actions/errores"
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from 'moment';
import { AddButton, CloseButton } from "../../common/buttons/Buttons";


class NewAsignacionModal extends Component {
  state = {
    modal: false,
    trader: null,
    objetivo: undefined,
    canperId: moment().format("YYYY-MM")
  };

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChangeTrader = e => {
    e.preventDefault();
    this.setState(
      { trader: e.target.value ? this.props.traders.find(c => c.id === parseInt(e.target.value)) : null}
    );
  }
  onChangeCanperId = e =>{
    e.preventDefault();    
    this.setState({ canperId: e.target.value });
  }
  onChangeObjetivo = e => {
    this.setState({ objetivo: e.target.value});
  };

  isValid = () => {
    const errs = {}
    if (!this.state.trader) {
      errs['TRADER'] = ['EMPTY VALUE']
    }
    if (!this.state.objetivo) {
      errs['OBJETIVO'] = ['EMPTY VALUE']
    }
    if (Object.entries(errs).length === 0) {
      return true;
    } else {
      this.props.setManualErrores(errs, {'type': 'ESTRATEGIAS'});
      return false;
    }

  }

  createAsignacionAndEstrategias = (e) => {
    const cliente = this.props.estrategia;
    const trader = this.state.trader;
    const objetivo = this.state.objetivo;
    const canper_id = new Date(this.state.canperId).toLocaleString("en-GB", {month: "short", year: "numeric"}).toUpperCase().replace(" ", "");
    
    const estrategias = this.props.estrategia.bookmakers.filter(b => b.estado === 'EN PROGRESO').map(b => {
      const existingEstrategia = this.props.historicEstrategias.sort((a, b) => a.id > b.id ? -1 : 1).find(e => e.bookmaker.id === b.id);
      return {
        bookmaker: {...b, cliente},
        fecha_inicio: moment().format().slice(0,19),
        saldo_inicial: existingEstrategia && existingEstrategia.saldo_final || b.saldo_inicial,
        canper_id
      }
    });
    this.props.addAsignacionObjetivoAndEstrategias({cliente, trader}, objetivo, estrategias);
  }

  asignarEstrategia = e => {
    e.preventDefault();

    if (this.isValid()) {      
      this.createAsignacionAndEstrategias();
      this.setState({modal: false});
    }
  }

  onClick = () => {
    this.toggle();
    if (this.props.create) {
      this.setState({estrategia: {}})
    }
  };

  
  render() {
    const traders = this.props.traders;
    const closeBtn = <button className="close" onClick={() => this.toggle()}>&times;</button>;
    const { t } = this.props;

    return (
    <Fragment>
        <AddButton onClick={ this.onClick} style={{margin: "1px"}}/>
      <Modal isOpen={this.state.modal} toggle={this.toggle} size='md' backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {`${t('estrategias:assign_trader.label')}`} {this.props.estrategia.bookmaker && this.props.estrategia.bookmaker.cliente.enlace}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="canper_id">Id:</Label>
                  </Col>
                  <Col md="8">   
                  <Input
                      type="month"
                      name="canper_id"
                      value={this.state.canperId}
                      onChange={(e) => this.onChangeCanperId(e)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                  <Row>
                    <Col md="4">
                      <Label for="trader">Trader:</Label>
                    </Col>
                    <Col md="8">
                      <Input type="select" name="trader" onChange={this.onChangeTrader} value={this.state.trader ? defaultIfEmpty(this.state.trader.id) : null}>
                        <option value="">{t("common:option.label")}</option>
                        {traders.map((n, index) => (<option key={index} value={n.id}>{n.username}</option>))}
                      </Input>
                    </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="fecha_inicio">{t("contabilidades:date_start.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="datetime-local"
                      name="fecha_inicio"
                      value={moment().format().slice(0,19)}
                      readOnly                    
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="objetivo">{t("estrategias:target.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      min="0"
                      name="objetivo"
                      onChange={this.onChangeObjetivo}
                      value={defaultIfEmpty(this.state.objetivo)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
            <CloseButton color={"outline-secondary"} onClick={() => {this.toggle()}} label={t("cancel.label")} style={{margin: "1px"}}/>
            <AddButton onClick={this.asignarEstrategia} label={t("common:add.label")} style={{margin: "1px"}} />
        </ModalFooter>
      </Modal>
    </Fragment>
    );
  }
}


export default connect(null, {setManualErrores, addAsignacionObjetivoAndEstrategias})(withTranslation('common', 'estrategias')(NewAsignacionModal));