import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import LoadingOverlay from 'react-loading-overlay'

import NewClienteForm from "./NewClienteForm";
import { addCliente, updateCliente } from "../../redux/actions/clientes";
import { listActiveComerciales } from "../../redux/actions/comerciales";
import { withTranslation } from "react-i18next";
import {EditButton,AddButton,CloseButton} from "../common/buttons/Buttons";


class NewClienteModal extends Component {
  state = {
    modal: false,
    forceModalClose: false,
    cliente: {}
  };

  componentDidMount() {
    if (this.props.cliente) {
      this.setState({ cliente: this.props.cliente });
    }
    if (!this.props.comerciales && !this.props.isLoadingActiveComerciales) {
      this.props.listActiveComerciales()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cliente && prevState.cliente && prevState.cliente.id !== prevProps.cliente.id) {
      this.setState({cliente: prevProps.cliente})
    }
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChange = e => {
    e.preventDefault();
    this.setState(
      { cliente: {
        ...this.state.cliente,
        [e.target.name]: e.target.value
      }
    });
  };

  onChangeComercial = e => {
    e.preventDefault();
    this.setState(
      { cliente: {
        ...this.state.cliente,
        comercial: this.props.comerciales.find(c => c.id === parseInt(e.target.value))
      }
    });
  }

  createCliente = () => {
    this.props.addCliente(this.state.cliente)
  };

  editCliente = () => {
    const cliente = this.state.cliente;
    if (!cliente.comercial){
      delete cliente.comercial;
    }
    // Remove attached entities
    delete cliente.bookmakers;
    delete cliente.monederos;
    delete cliente.banco;
    this.props.updateCliente(cliente.id, cliente);
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.create ? this.createCliente() : this.editCliente() 
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({cliente: {}})
    }
  }

  render() {
    const { t } = this.props;
    const create = this.props.create;
    const cliente = this.state.cliente || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;

    return (
      <>
      { this.props.isLoadingActiveComerciales ? null :
      <Fragment>
          {create ? <AddButton onClick={this.onClick} style={{margin: "1px"}} /> :< EditButton onClick={this.onClick} style={{margin: "1px"}} /> }
          <LoadingOverlay active={this.props.isLoading} spinner={true}>
            <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === cliente.id || this.props.isLoading)} toggle={this.toggle} backdrop="static" size='lg'>            
              <ModalHeader close={closeBtn}>{create ? t("add.label") : t('edit.label')}</ModalHeader>            
                <ModalBody>
                  <NewClienteForm
                    toggle={this.toggle}
                    cliente={cliente}
                    onChange={this.onChange}
                    onChangeComercial={this.onChangeComercial}
                    onChangeSim={this.onChangeSim}
                    comerciales={this.props.comerciales}
                    create={create}
                    bookmakersEstados={(this.props.bookmakers || []).map(b => b.estado)}
                  />
                </ModalBody>
              <ModalFooter>
                <CloseButton onClick={() => {this.setState({'forceModalClose': true}); this.toggle(); }} label={t("common:cancel.label")} style={{margin: "1px"}}/>
                {create ? <AddButton onClick={this.onSubmit} label={t("common:add.label")} style={{margin: "1px"}} /> :< EditButton onClick={this.onSubmit} label={t("common:edit.label")} style={{margin: "1px"}} /> }

              </ModalFooter>
            </Modal>
          </LoadingOverlay>
        </Fragment>
      }
      </>
    );
  }
}


const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'CLIENTES',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'CLIENTES' && state.errores.status !== 200 ? state.errores.context.id : false,
  comerciales: state.comerciales.activeComerciales,
  isLoadingActiveComerciales: state.comerciales.isLoadingActiveComerciales,
  bookmakers: state.clientes.bookmakers
});


export default connect(mapStateToProps, { addCliente, updateCliente, listActiveComerciales })(withTranslation(['clientes', 'common'])(NewClienteModal));


