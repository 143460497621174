import React from "react";
import { connect } from "react-redux";
import Header from "../../common/Header";
import BreadCrumbs from "../../common/BreadCrumbs";
import { listActivasContabilidades } from "../../../redux/actions/contabilidades"
import { withTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import ListContabilidadesAccordions from "./ListContabilidadesAccordions";
import {listEstrategias, listAsignacionesActivas} from "../../../redux/actions/estrategias"


class ContabilidadesView extends React.Component {

    componentDidMount() {
        if (!this.props.contabilidades) {
            this.props.listActivasContabilidades()
        }
        if (!this.props.estrategias) {
            this.props.listEstrategias();
        }
        if (!this.props.asignaciones) {
            this.props.listAsignacionesActivas();
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
            <Header />
            <Container fluid>
                <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'name': t('accounting.label'), 'active': true},
                {'name': t('active.label'), 'active': true},
                ]}/>
                { (this.props.isLoading !== false || this.props.isEstrategiasLoading !== false || this.props.isAsignacionesLoading !== false) ? <Spinner animation="border" variant="primary"/> :
                <ListContabilidadesAccordions contabilidades={this.props.contabilidades} permissions={this.props.permissions} estrategias={this.props.estrategias} asignaciones={this.props.asignaciones}/> }
            </Container>
            </>
        );
    }
}


const mapStateToProps = state => ({
    contabilidades: state.contabilidades.contabilidades,
    isLoading: state.contabilidades.isLoadingActiveContabilidades,
    permissions: state.cuentas.permissions.contabilidad,
    estrategias: state.estrategias.estrategias,
    isEstrategiasLoading: state.estrategias.isLoadingEstrategias,
    asignaciones: state.estrategias.activeAsignaciones,
    isAsignacionesLoading: state.estrategias.isLoadingActiveAsignaciones
});

export default connect(mapStateToProps, {listActivasContabilidades, listEstrategias, listAsignacionesActivas})(withTranslation('contabilidades')(ContabilidadesView));