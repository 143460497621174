import React, { useState, useEffect }  from 'react';
import Datatable from "react-bs-datatable";
import { useTranslation } from 'react-i18next';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import DeleteRetiradaModal from './DeleteRetiradaModal'
import {CustomButton} from "../../../common/buttons/Buttons";
import moment from 'moment';
import { datetimeFromUStoEU } from '../../../../helpers/helpers';


function DisplayRetiradas(props) {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        props.modal && setModal(props.modal)        
    },[]);

    const header = [
        {title: t("ingresos:entry.label"), prop: 'transferencia', sortable: true},
        {title: t("ingresos:date.label"), prop: 'fecha_eu', sortable: true},
        {title: t("common:actions.label"), prop: 'action'}
    ];

    const permissions = props.permissions || [];
    const customLabels = {
        noResults: 'No existen retiradas'
    };

    const retiradas = props.retiradas.map(i => ({
        ...i,
        'fecha_eu': datetimeFromUStoEU(i.fecha),
        'action': <>
            {/* {permissions.includes('change') && <NewRetiradaModal create={false} retirada={i}/>}            */}
            {permissions.includes('delete') && !props.fromContabilidadHistoric && <DeleteRetiradaModal id={i.id} fromContabilidad={props.fromContabilidad || false}/>}
        </>
    }))

    return <>
        <CustomButton color="info" onClick={toggle} style={{margin: "1px"}}/>
        <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle}>{t("ingresos:history.label")}</ModalHeader>
            <ModalBody>
                <div className="horizontal-scroll">
                <Datatable
                    tableHeaders={header}
                    tableBody={retiradas}
                    tableClass="striped responsive"
                    labels={customLabels}
                />
                </div>
            </ModalBody>
            <ModalFooter>
                <CustomButton color="outline-secondary" onClick={toggle} label={t("common:close.label")} style={{margin: "1px"}} />
            </ModalFooter>
        </Modal>
    </>
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.retirada
});


export default connect(mapStateToProps)(DisplayRetiradas);
