import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import { removeBookmaker } from "../../../redux/actions/clientes_bookmakers";
import { withTranslation } from "react-i18next";

import {CloseButton,DeleteButton} from "../../common/buttons/Buttons";

class DeleteBookmakerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.initialModalState
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  deleteBookmaker = e => {
    e.preventDefault();
    this.props.removeBookmaker(this.props.id);
    this.toggle();
  };

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <DeleteButton onClick={this.toggle} style={{margin: "1px"}}/>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} Bookmaker
          </ModalHeader>
          <ModalBody>
            {t("bookmakers:remove_confirmation.label")}
          </ModalBody>
          <ModalFooter>
            <CloseButton onClick={this.toggle} label={t("cancel.label")} style={{margin: "1px"}}/>
            <DeleteButton onClick={this.deleteBookmaker} label={t("remove.label")} style={{margin: "1px"}}/>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeBookmaker})(withTranslation(['common', 'bookmakers'])(DeleteBookmakerModal));