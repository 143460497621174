import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Col, Row, Button } from "reactstrap";
import NewRaspberryModal from "./NewRaspberryModal";
import DeleteRaspberryModal from "./DeleteRaspberryModal";
import {dateFromUStoEU} from "../../helpers/helpers";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {paginationOptions, CustomToggleList, getOpciones, addToCopyFormatter} from '../common/BootstrapTableHelper';
import {CustomButton} from "../common/buttons/Buttons";


class ListRaspberries extends React.Component {

    state = {
        filters: {
            canper_id: localStorage.getItem('filters.raspberries.canper_id'),
            mac: localStorage.getItem('filters.raspberries.mac'),
            rb: localStorage.getItem('filters.raspberries.rb'),
            estado: localStorage.getItem('filters.raspberries.estado'),
            ovpn_contraseña: localStorage.getItem('filters.raspberries.ovpn_contraseña'),
            anydesk_id: localStorage.getItem('filters.raspberries.anydesk_id'),
            anydesk_contraseña: localStorage.getItem('filters.raspberries.anydesk_contraseña'),
            fecha_expiracion: localStorage.getItem('filters.raspberries.fecha_expiracion'),
            hora_expiracion: localStorage.getItem('filters.raspberries.hora_expiracion'),
            raspberry_contraseña: localStorage.getItem('filters.raspberries.raspberry_contraseña'),
            isp: localStorage.getItem('filters.raspberries.isp'),
            router_usuario: localStorage.getItem('filters.raspberries.router_usuario'),
            router_contraseña: localStorage.getItem('filters.raspberries.router_contraseña'),
            fecha_rb_entrega: localStorage.getItem('filters.raspberries.fecha_rb_entrega'),
            fecha_rb_recogida: localStorage.getItem('filters.raspberries.fecha_rb_recogida'),
            fecha_servicio_inicio: localStorage.getItem('filters.raspberries.fecha_servicio_inicio'),
            fecha_servicio_fin: localStorage.getItem('filters.raspberries.fecha_servicio_fin'),
          }
    }

    canperIdFilter;
    macFilter;
    rbFilter;
    ovpn_contraseñaFilter;
    anydesk_idFilter;
    anydesk_contraseñaFilter;
    raspberry_contraseñaFilter;
    ispFilter;
    router_usuarioFilter;
    router_contraseñaFilter;
    fecha_rb_entregaFilter;
    fecha_rb_recogidaFilter;
    fecha_servicio_inicioFilter;
    fecha_servicio_finFilter;
    estadoFilter;
    fecha_expiracionFilter;
    hora_expiracionFilter;

    clearFilters = () => {
        this.canperIdFilter('');
        this.macFilter('');
        this.rbFilter('');
        this.ovpn_contraseñaFilter('');
        this.anydesk_idFilter('');
        this.anydesk_contraseñaFilter('');
        this.raspberry_contraseñaFilter('');
        this.ispFilter('');
        this.router_usuarioFilter('');
        this.router_contraseñaFilter('');
        this.fecha_rb_entregaFilter('');
        this.fecha_rb_recogidaFilter('');
        this.fecha_servicio_inicioFilter('');
        this.fecha_servicio_finFilter('');
        this.estadoFilter('');
        this.fecha_expiracionFilter('');
        this.hora_expiracionFilter('');
    }

    transformRaspberriesIntoTable = (raspberries, t) => {
        return raspberries.map((raspberry) => ({
        'fecha_expiracion_date': raspberry.fecha_expiracion ? dateFromUStoEU(raspberry.fecha_expiracion) : "",
        'fecha_rb_entrega_date': raspberry.fecha_rb_entrega ? dateFromUStoEU(raspberry.fecha_rb_entrega) : "",
        'fecha_rb_recogida_date': raspberry.fecha_rb_recogida ? dateFromUStoEU(raspberry.fecha_rb_recogida) : "",
        'fecha_servicio_inicio_date': raspberry.fecha_servicio_inicio ? dateFromUStoEU(raspberry.fecha_servicio_inicio) : "",
        'fecha_servicio_fin_date': raspberry.fecha_servicio_fin ? dateFromUStoEU(raspberry.fecha_servicio_fin) : "",
        'fecha_rb_entrega_filter': raspberry.fecha_rb_entrega ? raspberry.fecha_rb_entrega : "",
        'fecha_rb_recogida_filter': raspberry.fecha_rb_recogida ? raspberry.fecha_rb_recogida : "",
        'fecha_servicio_inicio_filter': raspberry.fecha_servicio_inicio ? raspberry.fecha_servicio_inicio : "",
        'fecha_servicio_fin_filter': raspberry.fecha_servicio_fin ? raspberry.fecha_servicio_fin : "",
        'acciones': <>
        {this.props.permissions.raspberry.includes("view") && <Button color="primary" size="sm" href={`#/raspberries/${raspberry.id}`}>{t('common:view.label')}</Button>}
        {this.props.permissions.raspberry.includes("change") && <NewRaspberryModal create={false} raspberry={raspberry} />}
        {this.props.permissions.raspberry.includes("delete") && <DeleteRaspberryModal id={raspberry.id}/>}
        </>,
        ...raspberry
        }));
    };


    render() {
        const { t } = this.props;
        const raspberries = this.props.raspberries;
        const rows = this.transformRaspberriesIntoTable(raspberries, t);
        const headers = [{
            text: 'Id',
            dataField: 'canper_id',
            formatter: addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.canper_id,
              getFilter: (filter) => {
                this.canperIdFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.canper_id', value);
              },
            }),
            sort: true
          },
          {
            text: 'Mac', 
            dataField: 'mac',
            filter: textFilter({
              defaultValue: this.state.filters.mac,
              getFilter: (filter) => {
                this.macFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.mac', value);
              },
            }),
            sort: true
          },
          {
            text: 'Rb', 
            dataField: 'rb',
            filter: textFilter({
              defaultValue: this.state.filters.rb,
              getFilter: (filter) => {
                this.rbFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.rb', value);
              },
            }),
            sort: true
          },
          {
            text: 'Anydesk id', 
            dataField: 'anydesk_id',
            filter: textFilter({
              defaultValue: this.state.filters.anydesk_id,
              getFilter: (filter) => {
                this.anydesk_idFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.anydesk_id', value);
              },
            }),
            sort: true
          },
          {
            text: t("anydesk_key.label"), 
            dataField: 'anydesk_contraseña',
            filter: textFilter({
              defaultValue: this.state.filters.anydesk_contraseña,
              getFilter: (filter) => {
                this.anydesk_contraseñaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.anydesk_contraseña', value);
              },
            }),
            sort: true
          },
          {
            text: t("ovpn_key.label"), 
            dataField: 'ovpn_contraseña',
            filter: textFilter({
              defaultValue: this.state.filters.ovpn_contraseña,
              getFilter: (filter) => {
                this.ovpn_contraseñaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.ovpn_contraseña', value);
              },
            }),
            sort: true
          },
          {
            text: t("expiry_date.label"), 
            dataField: 'fecha_expiracion_date',
            filter: textFilter({
              defaultValue: this.state.filters.fecha_expiracion,
              getFilter: (filter) => {
                this.fecha_expiracionFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.fecha_expiracion', value);
              },
            }),
            sort: true
          },
          {
            text: t("expiry_time.label"), 
            dataField: 'hora_expiracion',
            filter: textFilter({
              defaultValue: this.state.filters.hora_expiracion,
              getFilter: (filter) => {
                this.hora_expiracionFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.hora_expiracion', value);
              },
            }),
            sort: true
          },
          {
            text: t("raspberry_key.label"), 
            dataField: 'raspberry_contraseña',
            filter: textFilter({
              defaultValue: this.state.filters.raspberry_contraseña,
              getFilter: (filter) => {
                this.raspberry_contraseñaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.raspberry_contraseña', value);
              },
            }),
            sort: true
          },
          {
            text: 'Isp', 
            dataField: 'isp',
            filter: textFilter({
              defaultValue: this.state.filters.isp,
              getFilter: (filter) => {
                this.ispFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.isp', value);
              },
            }),
            sort: true
          },
          {
            text: t("router_user.label"), 
            dataField: 'router_usuario',
            filter: textFilter({
              defaultValue: this.state.filters.router_usuario,
              getFilter: (filter) => {
                this.router_usuarioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.router_usuario', value);
              },
            }),
            sort: true
          },
          {
            text: t("router_key.label"), 
            dataField: 'router_contraseña',
            filter: textFilter({
              defaultValue: this.state.filters.router_contraseña,
              getFilter: (filter) => {
                this.router_contraseñaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.router_contraseña', value);
              },
            }),
            sort: true
          },
          {
            text: t("date_rb_delivery.label"), 
            dataField: 'fecha_rb_entrega_filter',
            formatter: (cell, row) => row.fecha_rb_entrega_date,               
            filter: textFilter({
              defaultValue: this.state.filters.fecha_rb_entrega_date,
              getFilter: (filter) => {
                this.fecha_rb_entregaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.fecha_rb_entrega', value);
              },
            }),
            sort: true
          },
          {
            text: t("date_rb_collection.label"), 
            dataField: 'fecha_rb_recogida_filter',
            formatter: (cell, row) => row.fecha_rb_recogida_date,               
            filter: textFilter({
              defaultValue: this.state.filters.fecha_rb_recogida_date,
              getFilter: (filter) => {
                this.fecha_rb_recogidaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.fecha_rb_recogida', value);
              },
            }),
            sort: true
          },
          {
            text: t("date_service_start.label"), 
            dataField: 'fecha_servicio_inicio_filter',
            formatter: (cell, row) => row.fecha_servicio_inicio_date,               
            filter: textFilter({
              defaultValue: this.state.filters.fecha_servicio_inicio_date,
              getFilter: (filter) => {
                this.fecha_servicio_inicioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.fecha_servicio_inicio', value);
              },
            }),
            sort: true
          },
          {
            text: t("date_service_end.label"), 
            dataField: 'fecha_servicio_fin_filter',
            formatter: (cell, row) => row.fecha_servicio_fin_date,               
            filter: textFilter({
              defaultValue: this.state.filters.fecha_servicio_fin_date,
              getFilter: (filter) => {
                this.fecha_servicio_finFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.fecha_servicio_fin', value);
              },
            }),
            sort: true
          },
          {
            text: t("state.label"), 
            dataField: 'estado',
            align: 'center',
            filter: selectFilter({
              defaultValue: this.state.filters.estado,
              options: getOpciones('estado', rows),
              getFilter: (filter) => {
                this.estadoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.raspberries.estado', value);
              },
            }),
            sort: true
          },
          {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]


        return (
        <PaginationProvider  pagination={ paginationFactory(paginationOptions(raspberries.length)) }>
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
            <ToolkitProvider keyField='id' data={ rows } columns={ headers } columnToggle>
              {
                ({
                  columnToggleProps,
                  baseProps
              }) => (
            <>
            <div className="pagination-table">
              <Row >
                <Col>
                  <CustomButton color="secondary" onClick={this.clearFilters} label={t('common:remove_filters.label')} style={{margin: "1px"}}/>
                </Col>
                <Col className="col-auto">
                    <CustomToggleList { ...columnToggleProps} label={t("common:columns.label")} entity='raspberries' />
                </Col>
                <Col className="col-auto">
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </Col>
                <Col className="col-auto">
                  <SizePerPageDropdownStandalone
                    { ...paginationProps }
                  />
                </Col>                
                <Col className="col-auto">
                  { this.props.permissions.raspberry.includes("add") && <NewRaspberryModal create={true} raspberry={{}}/>}
                </Col>
              </Row>
            </div>
              <BootstrapTable
                bootstrap4
                hover
                condensed
                bordered={ false }
                filter={ filterFactory() }
                defaultSorted={ [{dataField: 'canper_id', order: 'asc'}] }
                wrapperClasses="raspberries-datatable"
                { ...paginationTableProps }
                { ...baseProps }
              />
            </>
            )}
            </ToolkitProvider>
            )}
        </PaginationProvider>
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions,
  });

export default connect(mapStateToProps, {})(withTranslation('raspberries', 'common')(ListRaspberries));