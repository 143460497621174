import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {dateFromUStoEU, datetimeFromUStoEU} from "../../../helpers/helpers";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Row, Col } from "reactstrap";
import { addToCopyFormatter} from '../../common/BootstrapTableHelper';
import NewAsignacionModal from './NewAsignacionModal'
import { EN_PROGRESO } from "../../../constants";

class ListEstrategiasNoAsignadas extends React.Component {

    state = {
        filters: {
            enlace: localStorage.getItem('filters.estrategias.enlace'),
            gestor: localStorage.getItem('filters.estrategias.gestor'),
            nBookmakerProgreso: localStorage.getItem('filters.estrategias.nBookmakerProgreso'),
            nBookmakerSolicitado: localStorage.getItem('filters.estrategias.nBookmakerSolicitado'),
          }
    }
    gestorFilter;
    enlaceFilter;
    nBookmakerProgreso;
    nBookmakerSolicitado;

    clearFilters = () => {
      this.gestorFilter('');
      this.enlaceFilter('');
      this.nBookmakerProgreso('');
      this.nBookmakerSolicitado('');
    }

    transformEstrategiasIntoTable = (estrategias) => {
      return estrategias.map((estrategia) => ({
        'gestor': estrategia.creado_por.username,
        'n_bookmaker_progreso': estrategia.bookmakers.filter(b => b.estado === EN_PROGRESO).length,
        'acciones': <>
            <Row>
              {this.props.permissions.includes('change') && <NewAsignacionModal traders={this.props.traders} estrategia={estrategia} historicEstrategias={this.props.historicEstrategias}/>}
            </Row>
          </>,
      ...estrategia
      }));
    };


    render() {
        const { t } = this.props;
        const estrategias = this.props.estrategias;
        const rows = this.transformEstrategiasIntoTable(estrategias);
        const headers = [
          this.props.permissions.includes('view') &&
          {
            text: 'Gestor', 
            dataField: 'gestor',
            formatter: addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.gestor,
              getFilter: (filter) => {
                this.gestorFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.gestor', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') &&
          {
            text: t("contabilidades:link.label"), 
            dataField: 'enlace',
            formatter: addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.enlace,
              getFilter: (filter) => {
                this.enlaceFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.enlace', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: `Bookmakers ${t("bookmakers:EN PROGRESO.estado").toLowerCase()}`, 
            dataField: 'n_bookmaker_progreso',
            formatter: addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.nBookmakerProgreso,
              getFilter: (filter) => {
                this.nBookmakerProgresoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.nBookmakerProgreso', value);
              },
            }),
            sort: true
          },               
          this.props.permissions.includes('view') && {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]

        const expandRow = {
          renderer: row =>
          (
            <>
              <Row>
                <Col>Bookmaker</Col>
                <Col>{t("contabilidades:initial_balance.label")}</Col>
                <Col>{t("bookmakers:state.label")}</Col>
              </Row>
              {row.bookmakers.filter(b => b.estado === EN_PROGRESO).map(e =>
                <Row>
                  <Col>{e.bookmaker}</Col>
                  <Col>{e.saldo_inicial}</Col>
                  <Col>{e.estado}</Col>
                </Row>
              )}
            </>
          ),
          showExpandColumn: true,
          expandByColumnOnly: true
        };

        return (
            <BootstrapTable
              bootstrap4
              hover
              striped
              condensed
              bordered={ false }
              keyField='id'
              data={rows}
              columns={ headers }
              filter={ filterFactory() }
              defaultSorted={ [{dataField: 'estrategia', order: 'asc'}] }
              wrapperClasses="estrategias-datatable"
              expandRow={ expandRow }
            />

        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.estrategia,
  });

export default connect(mapStateToProps, {})(withTranslation('estrategias', 'common')(ListEstrategiasNoAsignadas));