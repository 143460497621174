import {LIST_APUESTAS, REMOVE_APUESTA, UPDATE_APUESTA, ADD_APUESTA, IMPORT_APUESTA_FICHERO} from "../types/apuestas";

const initialState = {
    apuestas: null
};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_APUESTAS:
            return {
                ...state,
                apuestas: action.payload,
            }
        case REMOVE_APUESTA:
            return {
                ...state,
                // Devuelve todos los apuestas excepto el borrado
                apuestas: state.apuestas.filter(apuesta => apuesta.id !== action.payload)
            }
        case ADD_APUESTA:
            return {
                ...state,
                apuestas: Array.isArray(action.payload) ? [...state.apuestas, ...action.payload] : [...state.apuestas, action.payload]
            }

        case UPDATE_APUESTA:
            return {
                ...state,
                apuestas: (state.apuestas || []).map(apuesta => apuesta.id !== action.payload.id ? apuesta : action.payload),
                apuesta: action.payload
            }

        case IMPORT_APUESTA_FICHERO:
            return {
                ...state,
                apuestas: [state.apuestas, ...action.payload],
                importationIsLoading: false
            }

        default:
            return state;
    }
}
