import React from "react";
import { connect } from "react-redux";
import Header from "../common/Header";
import BreadCrumbs from "../common/BreadCrumbs";
import { listRaspberries } from "../../redux/actions/raspberries"
import { withTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import ListRaspberries from "./ListRaspberries";
import {listActiveComerciales} from "../../redux/actions/comerciales"


class RaspberriesView extends React.Component {

    componentDidMount() {
        if (!this.props.raspberries && !this.props.isLoading) {
            this.props.listRaspberries();
        }
        if (!this.props.comerciales && !this.props.isLoadingActiveComerciales) {
            this.props.listActiveComerciales();
        }
    }


    render() {
        return (
            <>
            <Header />
            <Container fluid>
                <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'href': '/#/raspberries', 'name': 'Raspberries', 'active': true},
                ]}/>
                { this.props.isLoading !== false ? <Spinner animation="border" variant="primary"/> :
                <ListRaspberries raspberries={this.props.raspberries}/>}
            </Container>
            </>
        );
    }
}


const mapStateToProps = state => ({
    raspberries: state.raspberries.raspberries,
    isLoading: state.raspberries.isLoading,
    comerciales: state.comerciales.activeComerciales,
    isLoadingActiveComerciales: state.comerciales.isLoadingActiveComerciales,
});

export default connect(mapStateToProps, {listRaspberries, listActiveComerciales})(withTranslation('raspberries')(RaspberriesView));