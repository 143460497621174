import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { listClienteByUser } from "../../redux/actions/clientes"
import DisplayProfile from './DisplayProfile';
import { Spinner } from 'reactstrap';


class ProfileView extends React.Component {    
    componentDidMount() {
        !this.props.cliente && this.props.listClienteByUser()
    }    

    render() {
        return <>
        {
            this.props.isLoading === true ?
            <Spinner animation="border" color="primary"/> :
            <DisplayProfile {...this.props} />
        }
        </>;
    }
}

const mapStateToProps = state => ({
    cliente: state.clientes.cliente,
    permissions: state.cuentas.permissions.cliente,
    isLoading: state.clientes.isLoading
  });


export default connect(mapStateToProps, { listClienteByUser })(withTranslation('clientes')(ProfileView));