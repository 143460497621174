import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListContabilidades from './ListContabilidades';
import { useTranslation } from 'react-i18next';
import {urlParams} from '../../../helpers/helpers'
import CloseMultipleContabilidadesModal from "./CloseMultipleContabilidadesModal";
import ArchiveEnlaceModal from "./ArchiveEnlaceModal";
import { useHistory } from 'react-router-dom';
import DisplayConexionprov from './DisplayConexionprov'


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    details: {
      display: 'grid',
    }
  }));

export default function ListContabilidadesAccordions(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const url = urlParams();
    let history = useHistory();

    // Usado para dirigir al usuario a contabilidad historica o contabilidad
    useEffect(() => {
      if (url.get('enlace') && url.get('entidad')) {
        const isCurrentContabilidad = props.contabilidades.find(c => c.bookmaker.id === parseInt(url.get('bookmaker')))
        if (!isCurrentContabilidad && url.get('bookmaker') && url.get('context')) {
          history.push(`/contabilidades_historico?bookmaker=${url.get('bookmaker')}&context=${url.get('context')}`);
        }
      }
    },[]);


    function listContabilidades(clientes) {

        return Object.entries(clientes).map(([cliente_enlace, payloads]) => 
        <Accordion defaultExpanded={url.get('enlace') === cliente_enlace}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{t("contabilidades:link.label")} : {cliente_enlace}</Typography>
          </AccordionSummary>

          <AccordionDetails>
          { payloads.find(c => c.bookmaker.estado === 'EN PROGRESO') &&
              <CloseMultipleContabilidadesModal contabilidades={payloads.filter(c => c.bookmaker.estado === 'EN PROGRESO' && !c.fecha_fin)}/>}
          { !payloads.find(c => c.bookmaker.estado !== 'COMPLETADA') &&
              <ArchiveEnlaceModal clienteId={payloads[0].bookmaker.cliente.id}/>}
              <DisplayConexionprov clienteId={payloads[0].bookmaker.cliente.id} />
          </AccordionDetails>
          
          <AccordionDetails>
            <ListContabilidades contabilidades={payloads} estrategias={props.estrategias} asignaciones={props.asignaciones} urlParams={url}/>
          </AccordionDetails>
        </Accordion>
      )
    }

    const contabilidadesByEntidad = props.contabilidades.reduce((obj, c) => (
      obj.hasOwnProperty(c['entidad']) ? {...obj, [c.entidad]: [...obj[c.entidad], c]} : {...obj, [c.entidad]: [c]})
    , {});

    let contabilidadesByEntidadByCliente = {}
    
    Object.entries(contabilidadesByEntidad).forEach(([entidad, payloads]) => {
      const contabilidadesByClient = payloads.reduce((obj, c) => (
          obj.hasOwnProperty(c['bookmaker']['cliente']['enlace']) ? {...obj, [c.bookmaker.cliente.enlace]: [...obj[c.bookmaker.cliente.enlace], c]} : {...obj, [c.bookmaker.cliente.enlace]: [c]})
        , {})

      contabilidadesByEntidadByCliente[entidad] = contabilidadesByClient;
    });

    return Object.entries(contabilidadesByEntidadByCliente).map(([entidad, clientes]) =>
      
      props.permissions.includes("change") ?
        <Accordion defaultExpanded={url.get('entidad') === entidad}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{entidad}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {listContabilidades(clientes)}
          </AccordionDetails>
        </Accordion> :
        listContabilidades(clientes)
    )
}