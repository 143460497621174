import React from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Row, Col, Button, ButtonDropdown, DropdownToggle, DropdownMenu, Spinner } from "reactstrap";
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ExportClientesModal from "./exportacion/ExportClientesModal";
import { listSummaryClientes } from "../../redux/actions/clientes"
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import i18n from '../../translations/i18n';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {paginationOptions, CustomToggleList, getOpciones, helperFormatter, addToCopyFormatter} from '../common/BootstrapTableHelper';


const DISPONIBLE = 'DISPONIBLE';
const DISPONIBLE_NV = "DISPONIBLE NO VERIFICADO"
const NO_DISPONIBLE = "NO DISPONIBLE"
const NO_EXISTE = "NO EXISTE"
const OBSERVACIONES_STRING_LIMIT = 20;


class List extends React.Component {

  state = {
    dropdownOpen: false,
    selectedRows: [],
    filters: {
      canperID: localStorage.getItem('filters.clientes.canperID'),
      cliente: localStorage.getItem('filters.clientes.cliente'),
      estado: localStorage.getItem('filters.clientes.estado'),
      paysafecard: localStorage.getItem('filters.clientes.paysafecard'),
      paypal: localStorage.getItem('filters.clientes.paypal'),
      skrill: localStorage.getItem('filters.clientes.skrill'),
      neteller: localStorage.getItem('filters.clientes.neteller'),
      banco: localStorage.getItem('filters.clientes.banco'),
      observaciones: localStorage.getItem('filters.clientes.observaciones'),
    }
  }

  canperIDFilter;
  clienteFilter;
  estadoFilter;
  paysafecardFilter;
  paypalFilter;
  skrillFilter;
  netellerFilter;
  bancoFilter;
  observacionesFilter;

  // Translate constans
  getConstant = (constant) => {
    return this.props.t(`banco:${constant}.estado`);
  }

  selectEstadoOptions = {
    ...(this.props.permissions.cliente.includes("estado_activo") && {[i18n.t("clientes:ACTIVO.estado")]: i18n.t("clientes:ACTIVO.estado")}),
    ...(this.props.permissions.cliente.includes("estado_archivado") && {[i18n.t("clientes:ARCHIVADO.estado")]: i18n.t("clientes:ARCHIVADO.estado")}),
    ...(this.props.permissions.cliente.includes("estado_en_proceso") && {[i18n.t("clientes:EN PROCESO.estado")]: i18n.t("clientes:EN PROCESO.estado")}),
    ...(this.props.permissions.cliente.includes("estado_activo") && this.props.permissions.cliente.includes('estado_archivado') && this.props.permissions.cliente.includes('estado_en_proceso') && {'': 'MOSTRAR TODOS'}),
  };
  

  toggle = () => {
    this.setState(previous => ({
        dropdownOpen: !previous.dropdownOpen
    }));
  };

  transformMonederosIntoTable = (monederos, monedero, t) => {
    for (let i in monederos) {
      if (monederos[i].monedero === monedero) {
        return monederos[i].estado === 'DISPONIBLE' ? this.getConstant(DISPONIBLE) : monederos[i].estado === 'NO DISPONIBLE' ? this.getConstant(NO_DISPONIBLE, t) : this.getConstant(DISPONIBLE_NV, t);
      }
    }

    return this.getConstant(NO_EXISTE);
  }

  transformObservacionesMonederosIntoTable = (monederos, monedero) => {
    for (let i in monederos) {
      if (monederos[i].monedero === monedero) {
        return monederos[i].observaciones ? monederos[i].observaciones : '';
      }
    }
    return '';
  }


  transformClientesIntoTable = (clientes, t) => {
    return clientes.map((cliente) => ({
      'id': cliente.id,
      'canper_id_cli': cliente.canper_id_cli,
      'cliente':`${cliente.nombre} ${cliente.apellido_1} ${cliente.apellido_2}`,
      'estado': cliente.estado ? t(`${cliente.estado}.estado`) : this.getConstant(NO_EXISTE),
      'banco': !cliente.banco ? this.getConstant(NO_EXISTE) : cliente.banco.estado === 'NO DISPONIBLE' ? this.getConstant(NO_DISPONIBLE) : cliente.banco.estado === 'DISPONIBLE' ? this.getConstant(DISPONIBLE, t) : this.getConstant(DISPONIBLE_NV, t),
      'banco_observaciones': cliente.banco ? cliente.banco.observaciones : '',
      'neteller': this.transformMonederosIntoTable(cliente.monederos, 'Neteller', t),
      'neteller_observaciones': this.transformObservacionesMonederosIntoTable(cliente.monederos, 'Neteller'),
      'skrill': this.transformMonederosIntoTable(cliente.monederos, 'Skrill', t),
      'skrill_observaciones': this.transformObservacionesMonederosIntoTable(cliente.monederos, 'Skrill'),
      'paypal': this.transformMonederosIntoTable(cliente.monederos, 'Paypal', t),
      'paypal_observaciones': this.transformObservacionesMonederosIntoTable(cliente.monederos, 'Paypal'),
      'paysafecard': this.transformMonederosIntoTable(cliente.monederos, 'Paysafecard', t),
      'paysafecard_observaciones': this.transformObservacionesMonederosIntoTable(cliente.monederos, 'Paysafecard'),
      'observaciones_short': cliente.observaciones,
      'observaciones_extended': cliente.observaciones.length > OBSERVACIONES_STRING_LIMIT ? cliente.observaciones : '',
      'acciones': <Button color="primary" size="sm" href={`#/clientes/${cliente.id}`}><i className="fa fa-eye"></i></Button>
    }));
  };


  rowEvents =  {
    // Abre en una pestaÃ±a vista detallada
    onAuxClick: (e, row,rowIndex) => {
      window.open(`#/clientes/${row.id}`, "_blank");
    }
  };


  clearFilters = () => {
    if (this.props.permissions.cliente && this.props.permissions.cliente.includes('view')) {
      this.canperIDFilter('');
      this.clienteFilter('');
      this.estadoFilter('');
      this.observacionesFilter('');
    }
    if (this.props.permissions.monedero && this.props.permissions.monedero.includes('view')) {
      this.paysafecardFilter && this.paysafecardFilter('');
      this.paypalFilter && this.paypalFilter('');
      this.skrillFilter && this.skrillFilter('');
      this.netellerFilter && this.netellerFilter('');
    }
    if (this.props.permissions.banco && this.props.permissions.banco.includes('view')) {
      this.bancoFilter && this.bancoFilter('');
    }
  }


  Formatter = (cell) => {
    return cell === this.getConstant(NO_EXISTE) ? <i className="fa fa-minus"></i> : 
    cell === this.getConstant(DISPONIBLE) ? <i className="fa fa-check"></i> : 
    cell === this.getConstant(DISPONIBLE_NV) ? <i className="fa fa-exclamation"></i> :
    cell === this.getConstant(NO_DISPONIBLE) ? <i className="fa fa-times"></i> :
    cell;
  }

  BancoFormatter = (cell, row) => {
    const icon = this.Formatter(cell);
    return helperFormatter(icon, row.banco_observaciones);
  }

  NetellerFormatter = (cell, row) => {
    const icon = this.Formatter(cell);
    return helperFormatter(icon, row.neteller_observaciones);
  }

  SkrillFormatter = (cell, row) => {
    const icon = this.Formatter(cell);
    return helperFormatter(icon, row.skrill_observaciones);
  }

  PaypalFormatter = (cell, row) => {
    const icon = this.Formatter(cell);
    return helperFormatter(icon, row.paypal_observaciones);
  }

  PaysafecardFormatter = (cell, row) => {
    const icon = this.Formatter(cell);
    return helperFormatter(icon, row.paysafecard_observaciones);
  }

  ObservacionesFormatter = (cell, row) => {
    return cell.length > OBSERVACIONES_STRING_LIMIT ?
    helperFormatter(cell.substring(0, OBSERVACIONES_STRING_LIMIT), true) :
    cell
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedRows: [...this.state.selectedRows, row.id]
      }));
    } else {
      this.setState(() => ({
        selectedRows: this.state.selectedRows.filter(x => x !== row.id)
      }));
    }
  }

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    isSelect ?
    this.setState(() => ({selectedRows: ids})) :
    this.setState(() => ({selectedRows: []}));
  }


  render() {
    const { t } = this.props;
    const permissions = this.props.permissions || [];
    const clientes = this.props.clientes || [];
    const rows = this.transformClientesIntoTable(clientes, t);

    const headers = [
      this.props.permissions.cliente && this.props.permissions.cliente.includes('view') &&
      {
        text: 'ID', 
        dataField: 'canper_id_cli',
        formatter: addToCopyFormatter,
        filter: textFilter({
          defaultValue: this.state.filters.canperID,
          getFilter: (filter) => {
            this.canperIDFilter = filter
          },
          onFilter: (value) => {
            localStorage.setItem('filters.clientes.canperID', value);
          },
        }),
        sortFunc: (a, b, order, dataField) => {
          if (order === 'asc') {
            return a - b;
          }
          return b - a; // desc
        },
        sort: true
      },
      this.props.permissions.cliente && this.props.permissions.cliente.includes('view') &&
      {
        text: t("client.label"), 
        dataField: 'cliente',
        formatter: addToCopyFormatter,
        filter: textFilter({
          defaultValue: this.state.filters.cliente,
          getFilter: (filter) => {
            this.clienteFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.clientes.cliente', value);
          },
        }),
        title: (cell, row, rowIndex, colIndex) => row.cliente,
        sort: true
      },
      this.props.permissions.cliente && this.props.permissions.cliente.includes('view') &&
      {
        text: t("state.label"), 
        dataField: 'estado',
        formatter: this.Formatter,
        align: 'center',
        filter: selectFilter({
          defaultValue: this.state.filters.estado,
          withoutEmptyOption: true,
          options: this.selectEstadoOptions,
          getFilter: (filter) => {
            this.estadoFilter = filter;
          },
          onFilter: (value) => {
            if (localStorage.getItem('filters.clientes.estado') !== value) {
              localStorage.setItem('filters.clientes.estado', value);
              if (!this.props.listClientesisLoading) {
                let estado = value
                // If show all estado has been selected
                if (estado === '') {
                  estado = 'all'
                } else {
                  // Translate to spanish in the case is in english
                  estado = t(`clientes:${estado}.estado`)
                }
                estado = estado.replace(' ', '_')
                this.props.listSummaryClientes(estado)
              }
            }
          },
        }),
        sort: true
      },
      this.props.permissions.banco && this.props.permissions.banco.includes('view') &&
      {
        text: t("banco:bank.label"), 
        dataField: 'banco',
        formatter: this.BancoFormatter,
        align: 'center',
        filter: selectFilter({
          defaultValue: this.state.filters.banco,
          options: getOpciones('banco', rows),
          getFilter: (filter) => {
            this.bancoFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.clientes.banco', value);
          },
        }),
        title: (cell, row, rowIndex, colIndex) => row.banco_observaciones,
        sort: true
      },
      this.props.permissions.monedero && this.props.permissions.monedero.includes('view') &&
      {
        text: 'Neteller', 
        dataField: 'neteller',
        formatter: this.NetellerFormatter,
        align: 'center',
        filter: selectFilter({
          defaultValue: this.state.filters.neteller,
          options: getOpciones('neteller', rows),
          getFilter: (filter) => {
            this.netellerFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.clientes.neteller', value);
          },
        }),
        title: (cell, row, rowIndex, colIndex) => row.neteller_observaciones,
        sort: true
      },
      this.props.permissions.monedero && this.props.permissions.monedero.includes('view') &&
      {
        text: 'Skrill', 
        dataField: 'skrill',
        formatter: this.SkrillFormatter,
        align: 'center',
        filter: selectFilter({
          defaultValue: this.state.filters.skrill,
          options: getOpciones('skrill', rows),
          getFilter: (filter) => {
            this.skrillFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.clientes.skrill', value);
          },
        }),
        title: (cell, row, rowIndex, colIndex) => row.skrill_observaciones,
        sort: true
      },
      this.props.permissions.monedero && this.props.permissions.monedero.includes('view') &&
      {
        text: 'Paypal', 
        dataField: 'paypal',
        formatter: this.PaypalFormatter,
        align: 'center',
        filter: selectFilter({
          defaultValue: this.state.filters.paypal,
          options: getOpciones('paypal', rows),
          getFilter: (filter) => {
            this.paypalFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.clientes.paypal', value);
          },
        }),
        title: (cell, row, rowIndex, colIndex) => row.paypal_observaciones,
        sort: true
      },
      this.props.permissions.monedero && this.props.permissions.monedero.includes('view') &&
      {
        text: 'Paysafecard', 
        dataField: 'paysafecard',
        formatter: this.PaysafecardFormatter,
        align: 'center',
        filter: selectFilter({
          defaultValue: this.state.filters.paysafecard,
          options: getOpciones('paysafecard', rows),
          getFilter: (filter) => {
            this.paysafecardFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.clientes.paysafecard', value);
          },
        }),
        title: (cell, row, rowIndex, colIndex) => row.paysafecard_observaciones,
        sort: true
      },
      this.props.permissions.cliente && this.props.permissions.cliente.includes('view') &&
      {
        text: t("notes.label"), 
        dataField: 'observaciones_short',
        formatter: this.ObservacionesFormatter,
        filter: textFilter({
          defaultValue: this.state.filters.observaciones,
          getFilter: (filter) => {
            this.observacionesFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.clientes.observaciones', value);
          },
        }),
        title: (cell, row, rowIndex, colIndex) => row.observaciones_extended,
        sort: true
      },
      this.props.permissions.cliente && this.props.permissions.cliente.includes('view') &&
      {
        text: "",
        dataField: 'acciones',
        sort: false
      }

    ].map(h => ({ ...h }));

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      bgColor: '#D5F2F7',
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };


    return (
      <div>
        { this.props.listClientesisLoading ?
         <Spinner animation="border" variant="primary"/> :
        <PaginationProvider  pagination={ paginationFactory(paginationOptions(clientes.length)) }>
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
            <ToolkitProvider keyField='id' data={ rows } columns={ headers } columnToggle>
              {
                ({
                  columnToggleProps,
                  baseProps
              }) => (
            <>
            <div className="pagination-table">
              <Row >
                <Col>
                  <Button color="secondary" size="sm" onClick={this.clearFilters}>{t('remove_filters.label')}</Button>
                </Col>
                <Col className="col-auto">
                    <CustomToggleList { ...columnToggleProps} label={t("common:columns.label")} entity='clientes   ' />
                </Col>
                <Col className="col-auto">
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </Col>
                <Col className="col-auto">
                  <SizePerPageDropdownStandalone
                    { ...paginationProps }
                  />
                </Col>
                <Col className="col-auto">
                  {this.state.selectedRows.length ?
                    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret size="sm" color="info">
                            {t('common:actions.label')}
                        </DropdownToggle>
                        <DropdownMenu persist={true}>
                            <ExportClientesModal clientes={this.props.clientes} selectedClientes={this.state.selectedRows} />
                        </DropdownMenu>
                    </ButtonDropdown> : null}
                </Col>
              </Row>
            </div>
              <BootstrapTable
                bootstrap4
                hover
                condensed
                bordered={ false }
                keyField='id'
                data={rows }
                columns={ headers }
                filter={ filterFactory() }
                rowEvents={ this.rowEvents }
                defaultSorted={ [{dataField: 'canper_id_cli', order: 'asc'}] }
                selectRow={ selectRow }
                wrapperClasses="clientes-datatable"
                { ...paginationTableProps }
                { ...baseProps }
              />
            </>
            )}
            </ToolkitProvider>
            )}
          </PaginationProvider>
          }
      </div>
      );
  }
}


const mapStateToProps = state => ({
  permissions: state.cuentas.permissions,
  listClientesisLoading: state.clientes.isLoading,
  // clientes: state.clientes.clientes
});


export default connect(mapStateToProps, {listSummaryClientes})(withRouter(withTranslation('clientes', 'common', 'banco')(List)));