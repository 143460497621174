import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import SimCardIcon from '@material-ui/icons/SimCard';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ListItemText from '@material-ui/core/ListItemText';
import AppsSharpIcon from '@material-ui/icons/AppsSharp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import { connect } from "react-redux";
import { logout } from "../../redux/actions/cuentas";
import { useHistory } from 'react-router-dom';
import LanguageSelector from '../../translations/LanguageSelector'
import { withTranslation } from "react-i18next";
import { Badge, Menu, MenuItem } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import HistoryIcon from '@material-ui/icons/History';
import TimelineIcon from '@material-ui/icons/Timeline';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AvisoLegalModal from "./AvisoLegalModal";
import PoliticaPrivacidadModal from "./PoliticaPrivacidadModal";
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NotificacionesCampanaView from '../notificaciones/NotificacionesCampanaView';
import {GROUPS_HEADER, HOME, ESTRATEGIAS, ESTRATEGIASACTIVO, ESTRATEGIASHISTORICO, ESTRATEGIASASIGNACION, CUENTAS, CUENTASCLIENTES, CONTABILIDAD, CONTABILIDADACTIVO, CONTABILIDADHISTORICO, APUESTAS, CONEXIONP, RESUMENGESTOR, RESUMENTRADER, RESUMENADMINISTRADOR, CUENTASPERFIL} from '../../../src/constants/index'
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IncidenceModal from './IncidenceModal';
import WarningIcon from '@material-ui/icons/Warning';
import Tronadvisor from '../../assets/images/Tronadvisor.png';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsModal from './settings/SettingsModal';


const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: "1000"
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  welcome: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
    justifyContent: 'center',
    display: 'flex'
  },
  logo: {
    width: 50,
    height: 45,
    marginRight: 90
  },
}));




function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationEl, setnotificationEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const openNotificationMenu = Boolean(notificationEl);
  const { t } = props;
  let history = useHistory();
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifiactionClose = () => {
    setnotificationEl(null);
  };

  const handleNotifiactionMenu = (event) => {
    setnotificationEl(event.currentTarget);
  };

  const renderAccountMenu = (
  <>
    <IconButton
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleMenu}
      color="inherit"
    >
      <AccountCircle />
      <ArrowDropDownIcon />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={openMenu}
      onClose={handleClose}
    >
      <PoliticaPrivacidadModal/>
      <AvisoLegalModal/>
      {/* Ajustes */}
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <SettingsModal />      
      </MenuItem>
      {/* Incidencia */}
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <WarningIcon />
        </ListItemIcon>
        <IncidenceModal />      
      </MenuItem>
      <Divider />
      {/* Logout */}
      <MenuItem onClick={() => {props.logout(); history.push(`/`)}}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />      
      </MenuItem>
    </Menu>
  </>);

  const renderNotificationMenu = (
  <>
    <IconButton
      aria-controls="menu-notificationbar"
      aria-haspopup="true"
      onClick={handleNotifiactionMenu}
      color="inherit"
    >
      {props.notificaciones ?
        <>
        <Badge badgeContent={props.notificaciones.length} color="secondary">
          <NotificationsIcon />
        </Badge>
        <ArrowDropDownIcon /> 
        </> :
        <NotificationsIcon />
      }
    </IconButton>
    <Menu
      id="menu-notificationbar"
      anchorEl={notificationEl}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={openNotificationMenu}
      onClose={handleNotifiactionClose}
    >
      <NotificacionesCampanaView onClose={handleNotifiactionClose}/>
    </Menu>
  </>);

  const userName = props.user ? props.user.username : "";
  const headerPermission = props.userGroup ? GROUPS_HEADER[props.userGroup] : [];

  return (
    <header className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography id="header-title" variant="h6" className={classes.title}>
            Gestion Manager
          </Typography>
          <span className="navbar-text text-light" style={{marginRight: "10px"}}>
            <LanguageSelector />
          </span>
          <Typography className={classes.welcome}>
            {userName ? `${t("welcome.label")} ${userName}` : ''}
          </Typography> 
          {renderNotificationMenu}
          {renderAccountMenu}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        onBackdropClick={handleDrawerClose}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <img alt="Tronadvisor" src={Tronadvisor} className={classes.logo}/>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List component="nav" >
            {headerPermission.includes(HOME) && <ListItem button>
                <ListItemIcon><AppsSharpIcon /></ListItemIcon>
                <ListItemText primary="Home" onClick={() => history.push(`/`)}/>
            </ListItem>}
            { headerPermission.includes(CUENTAS) &&
            <ListItem>
              <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                <ListItemText primary={`${t("account.label")}s`} />
            </ListItem>}
                <List component="div" >
                { headerPermission.includes(CUENTASCLIENTES) &&
                <ListItem button style={{ paddingLeft: "40px" }} onClick={() => history.push(`/clientes`)}>
                    <ListItemIcon><PeopleAltIcon /></ListItemIcon>
                    <ListItemText primary={`${t("clientes:client.label")}s`} />
                </ListItem>}   
                { headerPermission.includes(CUENTASPERFIL) &&
                <ListItem button style={{ paddingLeft: "40px" }} onClick={() => history.push(`/perfil`)}>
                    <ListItemIcon><PeopleAltIcon /></ListItemIcon>
                    <ListItemText primary={`${t("clientes:profile.label")}`} />
                </ListItem>} 
            </List>
            
            <List component="div" >
            {headerPermission.includes(CONTABILIDAD) && <ListItem>
                  <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
                  <ListItemText primary={t('contabilidades:accounting.label')} />
              </ListItem>}
            {headerPermission.includes(CONTABILIDADACTIVO) && <ListItem button style={{ paddingLeft: "40px" }} onClick={() => history.push(`/contabilidades`)}>
                      <ListItemIcon><TimelineIcon /></ListItemIcon>
                      <ListItemText primary={t("contabilidades:active.label")} />
              </ListItem>}
            {headerPermission.includes(CONTABILIDADHISTORICO) && <ListItem button style={{ paddingLeft: "40px" }} onClick={() => history.push(`/contabilidades_historico`)}>
                        <ListItemIcon><HistoryIcon /></ListItemIcon>
                        <ListItemText primary={t("contabilidades:historic.label")} />
              </ListItem>}
            </List>
            <List>
              {headerPermission.includes(ESTRATEGIAS) && <ListItem>
                <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
                <ListItemText primary={t('estrategias:strategy.label')} />
              </ListItem>}
              {headerPermission.includes(ESTRATEGIASACTIVO)  && <ListItem button style={{ paddingLeft: "40px" }} onClick={() => history.push(`/estrategias`)}>
                <ListItemIcon><TimelineIcon /></ListItemIcon>
                <ListItemText primary={t("contabilidades:active.label")} />
              </ListItem>}
              {headerPermission.includes(ESTRATEGIASHISTORICO) && <ListItem button style={{ paddingLeft: "40px" }} onClick={() => history.push(`/estrategias_historico`)}>
                <ListItemIcon><HistoryIcon /></ListItemIcon>
                <ListItemText primary={t("contabilidades:historic.label")} />
              </ListItem>}
              {headerPermission.includes(ESTRATEGIASASIGNACION) && <ListItem button style={{ paddingLeft: "40px" }} onClick={() => history.push(`/asignaciones`)}>
                <ListItemIcon><AssessmentIcon /></ListItemIcon>
                <ListItemText primary={t("estrategias:assignation.label")} />
              </ListItem>}
            </List>

            {/* { props.permissions.bookmakeropciones && 
            <ListItem button onClick={() => history.push(`/bookmakers`)}>
                <ListItemIcon><LocalAtmIcon /></ListItemIcon>
                <ListItemText primary='Bookmakers' />
            </ListItem> }
            { props.permissions.raspberry && <ListItem button onClick={() => history.push(`/raspberries`)}>
                <ListItemIcon>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-hdd" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M14 9H2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zM2 8a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H2z"/>
                  <path d="M5 10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                  <path fillRule="evenodd" d="M4.094 4a.5.5 0 0 0-.44.26l-2.47 4.532A1.5 1.5 0 0 0 1 9.51v.99H0v-.99c0-.418.105-.83.305-1.197l2.472-4.531A1.5 1.5 0 0 1 4.094 3h7.812a1.5 1.5 0 0 1 1.317.782l2.472 4.53c.2.368.305.78.305 1.198v.99h-1v-.99a1.5 1.5 0 0 0-.183-.718L12.345 4.26a.5.5 0 0 0-.439-.26H4.094z"/>
                </svg>
                </ListItemIcon>      
                <ListItemText primary="Raspberries" />
            </ListItem> } */}

            { headerPermission.includes(APUESTAS) && <ListItem button onClick={() => history.push(`/apuestas`)}>
                <ListItemIcon>< MultilineChartIcon/></ListItemIcon>
                <ListItemText primary={`${t('apuestas:bet.label')}s`} />
            </ListItem> }
            { headerPermission.includes(RESUMENGESTOR) && <ListItem button onClick={() => history.push(`/resumen_g`)}>
                <ListItemIcon><FormatAlignJustifyIcon /></ListItemIcon>
                <ListItemText primary={t('estrategias:summary.label')} />
            </ListItem> }
            { headerPermission.includes(RESUMENTRADER) && <ListItem button onClick={() => history.push(`/resumen_t`)}>
                <ListItemIcon><FormatAlignJustifyIcon /></ListItemIcon>
                <ListItemText primary={t('estrategias:summary.label')} />
            </ListItem> }
            { headerPermission.includes(RESUMENADMINISTRADOR) && <ListItem button onClick={() => history.push(`/resumen_a`)}>
                <ListItemIcon><FormatAlignJustifyIcon /></ListItemIcon>
                <ListItemText primary={t('estrategias:summary.label')} />
            </ListItem> }
            { headerPermission.includes(CONEXIONP) && <ListItem button onClick={() => history.push(`/conexionesprov`)}>
                <ListItemIcon><SettingsInputComponentIcon /></ListItemIcon>
                <ListItemText primary="ConexionesP" />
            </ListItem> }
            
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </header>
  );
}


const mapStateToProps = state => ({
  permissions: state.cuentas.permissions,
  user: state.cuentas.user,
  userGroup: state.cuentas.permissions_group,
  notificaciones: state.notificaciones.recievedUncompletedNotificaciones
});

export default connect(mapStateToProps, { logout })(withTranslation(['common', 'clientes'])(Header));