import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "../actions/mensajes";
import i18n from '../../translations/i18n';
import { setErrores } from "../actions/errores";
import { API_AJUSTES_URL, API_USERS_URL } from "../../constants";
import { ADD_AJUSTES, UPDATE_AJUSTES, LIST_USER_AJUSTES, LIST_USER_AJUSTES_LOADING, LIST_USER_PROFILE_LOADING, LIST_USER_PROFILE } from "../types/ajustes";

const AJUSTES = 'AJUSTES';


export const addAjustes = (ajustes) => (dispatch, getState) => {
    return axios
    .post(`${API_AJUSTES_URL}`, ajustes, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: ADD_AJUSTES,
            payload: res.data
        });
    })
    .catch(err => {
        dispatch(setErrores(err.response, AJUSTES));
    });
};


export const updateAjustes = (id, ajustes) => (dispatch, getState) => {
    axios
    .put(`${API_AJUSTES_URL}${id}/`, ajustes, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: UPDATE_AJUSTES,
            payload: res.data
        });
        dispatch(createMessage(i18n.t("common:updated_language.message")));
    })
    .catch(err => {
        dispatch(setErrores(err.response, AJUSTES));
    });
};


export const listClienteAjustes = (userId) => (dispatch, getState) => {
    dispatch({ type: LIST_USER_AJUSTES_LOADING });
    axios
    .get(`${API_USERS_URL}${userId}/ajustes/`, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: LIST_USER_AJUSTES,
            payload: res.data
        });
    })
    .catch(err => {
        dispatch(setErrores(err.response, AJUSTES));
    });
};


export const listClienteProfile = (userId) => (dispatch, getState) => {
    dispatch({ type: LIST_USER_PROFILE_LOADING });
    axios
    .get(`${API_USERS_URL}${userId}/profile/`, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: LIST_USER_PROFILE,
            payload: res.data
        });
    })
    .catch(err => {
        dispatch(setErrores(err.response, AJUSTES));
    });
};
