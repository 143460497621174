import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CopyToClipboard from "../../common/CopyToClipboard";
import {datetimeFromUStoEU} from "../../../helpers/helpers";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, numberFilter, Comparator  } from 'react-bootstrap-table2-filter';
import { Button, Col, Row } from "reactstrap";
import { paginationOptions} from '../../common/BootstrapTableHelper';
import DisplayIngresos from "../../clientes/bookmakers/ingresos/DisplayIngresos";
import DisplayRetiradas from "../../clientes/bookmakers/retiradas/DisplayRetiradas";
import { getBalance } from "../../contabilidades/helper";
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import EditEstrategiaModal from "./EditEstrategiaModal";


class ListHistoricEstrategias extends React.Component {

    state = {
        filters: {
            estrategia: localStorage.getItem('filters.estrategias.estrategia'),
            user: localStorage.getItem('filters.estrategias.user'),
            cliente: localStorage.getItem('filters.estrategias.cliente'),
            canperId: localStorage.getItem('filters.contabilidades.canperId'),
            trader: localStorage.getItem('filters.estrategias.trader'),
            bookmaker: localStorage.getItem('filters.estrategias.bookmaker'),
            usuario: localStorage.getItem('filters.estrategias.usuario'),
            clave: localStorage.getItem('filters.estrategias.clave'),
            saldoMaximo: localStorage.getItem('filters.estrategias.saldoMaximo'),
            saldoInicial: localStorage.getItem('filters.estrategias.saldoInicial'),
            saldoBono: localStorage.getItem('filters.estrategias.saldoBono'),
            saldoFinal: localStorage.getItem('filters.estrategias.saldoFinal'),
            ingresos: localStorage.getItem('filters.estrategias.ingresos'),
            retiradas: localStorage.getItem('filters.estrategias.retiradas'),
            fechaInicio: localStorage.getItem('filters.estrategias.fechaInicio'),
            fechaFin: localStorage.getItem('filters.estrategias.fechaFin'),
            balance: localStorage.getItem('filters.estrategias.balance')
          }
    }

    userFilter;
    clienteFilter;
    canperIdFilter;
    traderFilter;
    bookmakerFilter;
    usuarioFilter;
    claveFilter;
    saldoMaximoFilter;
    saldoInicialFilter;
    saldoBonoFilter;
    saldoFinalFilter;
    ingresosFilter;
    retiradasFilter;
    fechaInicioFilter;
    fechaFinFilter;
    balanceFilter;
    estadoFilter;
    estrategiaFilter;

    clearFilters = () => {
      this.userFilter('');
      this.clienteFilter('');
      this.canperIdFilter('');
      this.traderFilter('');
      this.bookmakerFilter('');
      this.usuarioFilter('');
      this.claveFilter('');
      this.saldoMaximoFilter('');
      this.saldoInicialFilter('');
      this.saldoBonoFilter('');
      this.saldoFinalFilter('');
      this.ingresosFilter('');
      this.retiradasFilter('');
      this.fechaInicioFilter('');
      this.fechaFinFilter('');
      this.balanceFilter('');
      this.estrategiaFilter('');
    }

    transformEstrategiasIntoTable = (estrategias) => {
      const transformedEstrategias = [];
      estrategias.forEach((estrategia) => {

        const ingresos_value = estrategia.bookmaker.ingresos.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia).toFixed(2)), 0);
        const retiradas_value = estrategia.bookmaker.retiradas.reduce((acc, r) => acc + parseFloat(parseFloat(r.transferencia).toFixed(2)), 0);
        transformedEstrategias.push({
          'ingresos_value': ingresos_value,
          'retiradas_value': retiradas_value,
          'asignacion_id': estrategia.asignacion.id,
          'ingresos': <>
          {ingresos_value}
          {estrategia.bookmaker.ingresos && estrategia.bookmaker.ingresos.length > 0 && <DisplayIngresos ingresos={estrategia.bookmaker.ingresos} fromEstrategiaHistoric={true}/>}
          </>,
          'retiradas': <>
          {retiradas_value}
          {estrategia.bookmaker.retiradas && estrategia.bookmaker.retiradas.length > 0 && <DisplayRetiradas retiradas={estrategia.bookmaker.retiradas} fromEstrategiaHistoric={true} />}
          </>,
          'fecha_inicio_date': estrategia.fecha_inicio ? datetimeFromUStoEU(estrategia.fecha_inicio) : "",
          'fecha_inicio_format' : new Date(estrategia.fecha_inicio).toLocaleDateString("sv-SE"),
          'fecha_fin_format': estrategia.fecha_fin ? new Date(estrategia.fecha_fin).toLocaleDateString("sv-SE"): "",
          'fecha_fin_date': estrategia.fecha_fin ? datetimeFromUStoEU(estrategia.fecha_fin) : "",
          // Hago esto para que funcionen las opciones del filtro
          'estado': estrategia.bookmaker.estado,
          'balance': getBalance(estrategia),
          'acciones': <>
          {this.props.permissions.includes('change') && <EditEstrategiaModal estrategia={estrategia} />}
          </>,
        ...estrategia
        })
      });
      return transformedEstrategias;
    };

    // Esta funcion transforma el array de estrategias. El output sera un objecto que tiene como keys
    // los diferentes nombres de bookmakers, y como values tendran todos los registros de estrategias para ese bookmaker
    transformEstrategiasArrayIntoObject = (estrategias) => {
      return estrategias.reduce((obj, c) => (
        obj.hasOwnProperty(c['bookmaker']['bookmaker']) ? {...obj, [c.bookmaker.bookmaker]: [...obj[c.bookmaker.bookmaker], c]} : {...obj, [c.bookmaker.bookmaker]: [c]})
      , {})
    }

    splitEstrategias = (estrategias) => {
      const historyEstrategias = {};
      const newEstrategias = [];
      Object.entries(estrategias).forEach(([key, value]) => {
        newEstrategias.push(value.pop())
        historyEstrategias[key] = value;
      });

      return [newEstrategias, historyEstrategias];
    }

    addToCopyFormatter = (cell) => {
        return <CopyToClipboard>{cell}</CopyToClipboard>
    }


    handleOnSelect = (row, isSelect) => {
      if (isSelect) {
        this.setState(() => ({
          selectedRows: [...this.state.selectedRows, row.id]
        }));
      } else {
        this.setState(() => ({
          selectedRows: this.state.selectedRows.filter(x => x !== row.id)
        }));
      }
    }
  
    handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      isSelect ?
      this.setState(() => ({selectedRows: ids})) :
      this.setState(() => ({selectedRows: []}));
    }

    render() {
        const { t } = this.props;
        const estrategias = this.props.estrategias;

        const rows = this.transformEstrategiasIntoTable(estrategias);
        const headers = [
          this.props.permissions.includes('view') &&
          {
            text: t("estrategias:strategy.label"), 
            dataField: 'asignacion_id',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.estrategia,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.estrategiaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.estrategia', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && this.props.userGroup !== 'TRADER' &&
          {
            text: 'Entidad', 
            dataField: 'entidad',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.user,
              getFilter: (filter) => {
                this.userFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.user', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && this.props.userGroup !== 'TRADER' &&
          {
            text: t("clientes:client.label"), 
            dataField: 'bookmaker.cliente.enlace',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.cliente,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.clienteFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.cliente', value);
              },
            }),
            sort: true
          },          
          this.props.permissions.includes('view') && this.props.userGroup !== 'TRADER' &&
          {
            text: 'Trader', 
            dataField: 'asignacion.trader.username',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.trader,
              getFilter: (filter) => {
                this.traderFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.trader', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') &&
          {
            text: 'Id', 
            dataField: 'canper_id',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.canperId,
              getFilter: (filter) => {
                this.canperIdFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.canperId', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: 'Bookmaker', 
            dataField: 'bookmaker.bookmaker',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.bookmaker,
              getFilter: (filter) => {
                this.bookmakerFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.bookmaker', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:user.label"), 
            dataField: 'bookmaker.usuario',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.usuario,
              getFilter: (filter) => {
                this.usuarioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.usuario', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:key.label"), 
            dataField: 'bookmaker.clave_acceso',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.clave,
              getFilter: (filter) => {
                this.claveFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.clave', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:maximum_balance.label"), 
            dataField: 'bookmaker.saldo_maximo',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoMaximo,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoMaximoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.saldoMaximo', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("contabilidades:initial_balance.label"), 
            dataField: 'saldo_inicial',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoInicial,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoInicialFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.saldoInicial', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("ingresos:entries.label"), 
            dataField: 'ingresos_value',
            formatter: (cell, row) => row.ingresos,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.ingresos,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.ingresosFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.ingresos', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("ingresos:withdrawals.label"), 
            dataField: 'retiradas_value',
            formatter: (cell, row) => row.retiradas,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.retiradas,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.retiradasFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.retiradas', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("contabilidades:date_start.label"), 
            dataField: 'fecha_inicio_format',
            formatter: (cell, row) => row.fecha_inicio_date,         
            filter: textFilter({
              defaultValue: this.state.filters.fechaInicio,
              getFilter: (filter) => {
                this.fechaInicioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.fechaInicio', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("contabilidades:date_end.label"), 
            dataField: 'fecha_fin_format',
            formatter: (cell, row) => row.fecha_fin_date,
            filter: textFilter({     
              defaultValue: this.state.filters.fechaFin,
              getFilter: (filter) => {
                this.fechaFinFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.fechaFin', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("contabilidades:final_balance.label"), 
            dataField: 'saldo_final',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoFinal,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoFinalFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.saldoFinal', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: 'Balance', 
            dataField: 'balance',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.balance,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.balanceFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estrategias.balance', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]

        return (
          <PaginationProvider  pagination={ paginationFactory(paginationOptions(estrategias.length)) }>
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
              <>
              <div className="pagination-table">
                <Row >
                  <Col>
                    <Button color="secondary" size="sm" onClick={this.clearFilters}>{t('common:remove_filters.label')}</Button>
                  </Col>
                  <Col className="col-auto">
                    <PaginationListStandalone
                      { ...paginationProps }
                    />
                  </Col>
                  <Col className="col-auto">
                    <SizePerPageDropdownStandalone
                      { ...paginationProps }
                    />
                  </Col>
                </Row>
              </div>
            <BootstrapTable
              bootstrap4
              hover
              condensed
              bordered={ false }
              keyField='id'
              data={rows}
              columns={ headers }
              filter={ filterFactory() }
              defaultSorted={ [{dataField: 'asignacion_id', order: 'asc'}] }
              wrapperClasses="historico-estrategias-datatable"
              { ...paginationTableProps }
              />
            </>
            )}
        </PaginationProvider>
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.estrategia,
    userGroup: state.cuentas.permissions_group
  });

export default connect(mapStateToProps, {})(withTranslation('estrategias', 'common')(ListHistoricEstrategias));