import React from "react";
import CustomTooltip from "./CustomToolTip";
import { useLocation } from "react-router-dom";


export const defaultIfEmpty = value => {
    return (value === "" ) ? "" : value;
  };

  
export const dateFromUStoEU = (date) => {
  if (!date) {return date}
  const toEU = date.split('-');
  return `${toEU[2]}/${toEU[1]}/${toEU[0]}`;
}


// Input date format "2020-05-08T15:18:00Z"
// Output date format "08/05/2020 15:18:00"
export const datetimeFromUStoEU = (datetime) => {
  if (!datetime) {return datetime}
  const date = datetime.substring(0, 10);
  const time = datetime.substring(11, 19);
  const d = date.split('-');
  return `${d[2]}/${d[1]}/${d[0]} ${time}`;
}


export const addTooltipToObservaciones = (text, toTarget, stringLimit=15) => {
  const toolTip = text.length > stringLimit ? <CustomTooltip toTarget={toTarget} text={text} stringLimit={stringLimit}/> : text;
  return toolTip
}

export const urlParams = () => {
  return new URLSearchParams(useLocation().search);
}