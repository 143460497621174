import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';

 
class PDFViewer extends Component {

  render() {
 
    return (
      <div>
        <Document
          file={this.props.fichero}
        >
          <Page pageNumber={1} height={100} rotate={270} renderMode="svg"/>
        </Document>
      </div>
    );
  }
}

export default PDFViewer;