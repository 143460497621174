import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CopyToClipboard from "../../common/CopyToClipboard";
import {datetimeFromUStoEU} from "../../../helpers/helpers";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import { Button, Col, Row } from "reactstrap";
import { paginationOptions} from '../../common/BootstrapTableHelper';
import DisplayIngresos from "../../clientes/bookmakers/ingresos/DisplayIngresos";
import DisplayRetiradas from "../../clientes/bookmakers/retiradas/DisplayRetiradas";
import { getBalance } from "../helper";
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import EditContabilidadModal from "./EditContabilidadModal";

class ListHistoricContabilidades extends React.Component {

    state = {
        filters: {
            entidad: localStorage.getItem('filters.contabilidades.entidad'),
            user: localStorage.getItem('filters.contabilidades.user'),
            cliente: localStorage.getItem('filters.contabilidades.cliente'),
            canperId: localStorage.getItem('filters.contabilidades.canperId'),
            bookmaker: localStorage.getItem('filters.contabilidades.bookmaker'),
            usuario: localStorage.getItem('filters.contabilidades.usuario'),
            clave: localStorage.getItem('filters.contabilidades.clave'),
            saldoMaximo: localStorage.getItem('filters.contabilidades.saldoMaximo'),
            saldoInicial: localStorage.getItem('filters.contabilidades.saldoInicial'),
            saldoBono: localStorage.getItem('filters.contabilidades.saldoBono'),
            saldoFinal: localStorage.getItem('filters.contabilidades.saldoFinal'),
            ingresos: localStorage.getItem('filters.contabilidades.ingresos'),
            retiradas: localStorage.getItem('filters.contabilidades.retiradas'),
            fechaInicio: localStorage.getItem('filters.contabilidades.fechaInicio'),
            fechaFin: localStorage.getItem('filters.contabilidades.fechaFin'),
            balance: localStorage.getItem('filters.contabilidades.balance')
          }
    }

    entidadFilter;
    userFilter;
    clienteFilter;
    canperIdFilter;
    bookmakerFilter;
    usuarioFilter;
    claveFilter;
    saldoMaximoFilter;
    saldoInicialFilter;
    saldoBonoFilter;
    saldoFinalFilter;
    ingresosFilter;
    retiradasFilter;
    fechaInicioFilter;
    fechaFinFilter;
    balanceFilter;
    estadoFilter;

    clearFilters = () => {
      this.entidadFilter('');
      this.userFilter('');
      this.clienteFilter('');
      this.canperIdFilter('');
      this.bookmakerFilter('');
      this.usuarioFilter('');
      this.claveFilter('');
      this.saldoMaximoFilter('');
      this.saldoInicialFilter('');
      this.saldoBonoFilter('');
      this.saldoFinalFilter('');
      this.ingresosFilter('');
      this.retiradasFilter('');
      this.fechaInicioFilter('');
      this.fechaFinFilter('');
      this.balanceFilter('');
    }

    transformContabilidadesIntoTable = (contabilidades) => {
      const transformedContabilidades = [];
      contabilidades.forEach((contabilidad) => {

        const ingresos_value = contabilidad.bookmaker.ingresos.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia).toFixed(2)), 0.00);
        const retiradas_value = contabilidad.bookmaker.retiradas.reduce((acc, r) => acc + parseFloat(parseFloat(r.transferencia).toFixed(2)), 0.00);

        transformedContabilidades.push({
        'ingresos_value': ingresos_value,
        'retiradas_value': retiradas_value,
        'ingresos': <>
        {ingresos_value}
        {contabilidad.bookmaker.ingresos && contabilidad.bookmaker.ingresos.length > 0 && <DisplayIngresos ingresos={contabilidad.bookmaker.ingresos} fromContabilidadHistoric={true} modal={this.props.urlParams.get('context') === 'ingresos' && parseInt(this.props.urlParams.get('bookmaker')) === contabilidad.bookmaker.id}/>}
        </>,
        'retiradas': <>
        {retiradas_value}
        {contabilidad.bookmaker.retiradas && contabilidad.bookmaker.retiradas.length > 0 && <DisplayRetiradas retiradas={contabilidad.bookmaker.retiradas} fromContabilidadHistoric={true} modal={this.props.urlParams.get('context') === 'retiradas' && parseInt(this.props.urlParams.get('bookmaker')) === contabilidad.bookmaker.id}/>}
        </>,
        'fecha_inicio_date': contabilidad.fecha_inicio ? datetimeFromUStoEU(contabilidad.fecha_inicio) : "",
        'fecha_inicio_format' : new Date(contabilidad.fecha_inicio).toLocaleDateString("sv-SE"),
        'fecha_fin_format': contabilidad.fecha_fin ? new Date(contabilidad.fecha_fin).toLocaleDateString("sv-SE"): "",
        'fecha_fin_date': contabilidad.fecha_fin ? datetimeFromUStoEU(contabilidad.fecha_fin) : "",
        // Hago esto para que funcionen las opciones del filtro
        'estado': contabilidad.bookmaker.estado,
        'balance': getBalance(contabilidad),
        'acciones': <>
        {this.props.permissions.includes('change') && <EditContabilidadModal contabilidad={contabilidad}/>}
        </>,
      ...contabilidad
        })
      });

      return transformedContabilidades;
    };

    // Esta funcion transforma el array de contabilidades. El output sera un objecto que tiene como keys
    // los diferentes nombres de bookmakers, y como values tendran todos los registros de contabilidades para ese bookmaker
    transformContabilidadesArrayIntoObject = (contabilidades) => {
      return contabilidades.reduce((obj, c) => (
        obj.hasOwnProperty(c['bookmaker']['bookmaker']) ? {...obj, [c.bookmaker.bookmaker]: [...obj[c.bookmaker.bookmaker], c]} : {...obj, [c.bookmaker.bookmaker]: [c]})
      , {})
    }

    splitContabilidades = (contabilidades) => {
      const historyContabilidades = {};
      const newContabilidades = [];
      Object.entries(contabilidades).forEach(([key, value]) => {
        newContabilidades.push(value.pop())
        historyContabilidades[key] = value;
      });

      return [newContabilidades, historyContabilidades];
    }

    addToCopyFormatter = (cell) => {
        return <CopyToClipboard>{cell}</CopyToClipboard>
    }


    handleOnSelect = (row, isSelect) => {
      if (isSelect) {
        this.setState(() => ({
          selectedRows: [...this.state.selectedRows, row.id]
        }));
      } else {
        this.setState(() => ({
          selectedRows: this.state.selectedRows.filter(x => x !== row.id)
        }));
      }
    }
  
    handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      isSelect ?
      this.setState(() => ({selectedRows: ids})) :
      this.setState(() => ({selectedRows: []}));
    }

    render() {
        const { t } = this.props;
        const contabilidades = this.props.contabilidades;

        const rows = this.transformContabilidadesIntoTable(contabilidades);
        const headers = [
          this.props.permissions.includes('view') &&
          {
            text: t('cuentas:entity.label'), 
            dataField: 'entidad',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.entidad,
              getFilter: (filter) => {
                this.entidadFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.entidad', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') &&
          {
            text: t("clientes:link.label"), 
            dataField: 'bookmaker.cliente.enlace',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.cliente,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.clienteFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.cliente', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') &&
          {
            text: 'Id', 
            dataField: 'canper_id',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.canperId,
              getFilter: (filter) => {
                this.canperIdFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.canperId', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: 'Bookmaker', 
            dataField: 'bookmaker.bookmaker',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.bookmaker,
              getFilter: (filter) => {
                this.bookmakerFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.bookmaker', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:user.label"), 
            dataField: 'bookmaker.usuario',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.usuario,
              getFilter: (filter) => {
                this.userFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.usuario', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:key.label"), 
            dataField: 'bookmaker.clave_acceso',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.clave,
              getFilter: (filter) => {
                this.claveFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.clave', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:maximum_balance.label"), 
            dataField: 'bookmaker.saldo_maximo',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoMaximo,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoMaximoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoMaximo', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("initial_balance.label"), 
            dataField: 'saldo_inicial',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoInicial,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoInicialFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoInicial', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("ingresos:entries.label"), 
            dataField: 'ingresos_value',
            formatter: (cell, row) => row.ingresos,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.ingresos,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.ingresosFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.ingresos', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("ingresos:withdrawals.label"), 
            dataField: 'retiradas_value',
            formatter: (cell, row) => row.retiradas,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.retiradas,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.retiradasFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.retiradas', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("date_start.label"), 
            dataField: 'fecha_inicio_format',
            formatter: (cell, row) => row.fecha_inicio_date,         
            filter: textFilter({
              defaultValue: this.state.filters.fechaInicio,
              getFilter: (filter) => {
                this.fechaInicioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.fechaInicio', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("date_end.label"), 
            dataField: 'fecha_fin_format',
            formatter: (cell, row) => row.fecha_fin_date,
            filter: textFilter({     
              defaultValue: this.state.filters.fechaFin,
              getFilter: (filter) => {
                this.fechaFinFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.fechaFin', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("final_balance.label"), 
            dataField: 'saldo_final',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoFinal,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoFinalFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoFinal', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: 'Balance', 
            dataField: 'balance',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.balance,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.balanceFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.balance', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('change') && {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]

        return (
          <PaginationProvider  pagination={ paginationFactory(paginationOptions(contabilidades.length)) }>
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
              <>
              <div className="pagination-table">
                <Row >
                  <Col>
                    <Button color="secondary" size="sm" onClick={this.clearFilters}>{t('common:remove_filters.label')}</Button>
                  </Col>
                  <Col className="col-auto">
                    <PaginationListStandalone
                      { ...paginationProps }
                    />
                  </Col>
                  <Col className="col-auto">
                    <SizePerPageDropdownStandalone
                      { ...paginationProps }
                    />
                  </Col>
                </Row>
              </div>
            <BootstrapTable
              bootstrap4
              hover
              condensed
              bordered={ false }
              keyField='id'
              data={rows}
              columns={ headers }
              filter={ filterFactory() }
              defaultSorted={ [{dataField: 'contabilidad', order: 'asc'}] }
              wrapperClasses="historico-contabilidades-datatable"
              { ...paginationTableProps }
              />
            </>
            )}
        </PaginationProvider>
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.contabilidad,
  });

export default connect(mapStateToProps, {})(withTranslation('contabilidades', 'common')(ListHistoricContabilidades));