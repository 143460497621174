import React from 'react';

import DisplayRangos from "./DisplayRangos"
import NewRangoModal from "./NewRangoModal";

import { addRango, updateRango, listRangosByBookmakerId } from "../../redux/actions/rangos";
import { connect } from 'react-redux';
import { Row, Col, CardBody, Card, CardHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class Rangos extends React.Component {

    componentDidMount() {
        this.props.listRangosByBookmakerId(this.props.bookmakerId)
    }

    createRango = rango => {
        this.props.addRango(rango);
    };
    
    editRango = rango => {
        this.props.updateRango(rango.id, rango);
    };



    render() {
        const { t } = this.props;
        const permissions = this.props.permissions || [];
        return (
            <>
            {!this.props.rangos ?
            null :
            !permissions.includes('view') ? 
            null :
            <Card style={{ marginTop: "10px" }}>
                <CardHeader>
                <Row>                    
                    <Col>
                        <h5>{t("ranks.label")}</h5>
                    </Col>
                    <Col className="col-auto">
                        {permissions.includes('add') &&
                        <NewRangoModal createRango={this.createRango} create={true} bookmakerId={this.props.bookmakerId} />}
                    </Col>
                </Row>
                </CardHeader>
                <CardBody>
                    {this.props.rangos.length > 0 ?
                    <DisplayRangos editRango={this.editRango} rangos={this.props.rangos}/> :
                    <span>{t("dont_exist.label")}</span>}
                </CardBody>
            </Card>
                }
            </>
        );
    }
}


const mapStateToProps = state => ({
    rangos: state.bookmakers.rangos,
    permissions: state.cuentas.permissions.rango
});


export default connect(mapStateToProps, {addRango, updateRango, listRangosByBookmakerId})(withTranslation('rangos')(Rangos));