import { API_RASPBERRIES_URL } from "../../constants";
import {  LIST_RASPBERRY, ADD_RASPBERRY, REMOVE_RASPBERRY, UPDATE_RASPBERRY, LIST_RASPBERRIES, LIST_RASPBERRIES_LOADING} from "../types/raspberries";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';


const RASPBERRIES = 'RASPBERRIES';

// Add Raspberry
export const addRaspberry = (raspberry) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, RASPBERRIES));
    return axios
        .post(`${API_RASPBERRIES_URL}`, raspberry, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_RASPBERRY,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("raspberries:added_raspberry.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': RASPBERRIES, 'id': raspberry.id}));
        })
};


// List Raspberry
export const listRaspberry = id => (dispatch, getState) => {    
    axios
        .get(`${API_RASPBERRIES_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_RASPBERRY,
                payload: res.data
            });
        })
        .catch(err => console.log(err))
};


// Remove Raspberry
export const removeRaspberry = id => (dispatch, getState) => {
    axios
        .delete(`${API_RASPBERRIES_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_RASPBERRY,
                payload: id
            });
            dispatch(createMessage(i18n.t("raspberries:remove_raspberry.message")));
        })
        .catch(err => console.log(err))
};


// Update Raspberry
export const updateRaspberry = (id, Raspberry) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, RASPBERRIES));
    axios
        .put(`${API_RASPBERRIES_URL}${id}/`, Raspberry, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_RASPBERRY,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("raspberries:updated_raspberry.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': RASPBERRIES, 'id': id}));
        })
};


// List Raspberries of a cliente
export const listRaspberries = () => (dispatch, getState) => {
    dispatch({ type: LIST_RASPBERRIES_LOADING });
    axios
    .get(`${API_RASPBERRIES_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_RASPBERRIES,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_RASPBERRIES,
                payload: []
            });
        }
        console.log(err)
    })
};
