import React from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import CopyToClipboard from "../common/CopyToClipboard";


export function paginationOptions(size) {
   return {
       custom: true,
        sizePerPage: size,
        totalSize: size,
        sizePerPageList: [
        {text: 'Mostrar todo', value: size},
        {text: '10', value: 10},
        {text: '20', value: 20},
        {text: '50', value: 50},
        {text: '100', value: 100},
        {text: '500', value: 500},
    ]
    }
};


function columnsOnChange(column, onColumnToggle, entity) {
    localStorage.setItem(`selectedColumns.${entity}.${column.dataField}`, column.toggle)
    onColumnToggle(column.dataField)
}

export const CustomToggleList = ({
    columns,
    onColumnToggle,
    toggles,
    label,
    entity
  }) => (
    <UncontrolledDropdown>
     <DropdownToggle caret size="sm" color="info">
         {label}
     </DropdownToggle>
      <DropdownMenu persist={true}>
      {
        columns
          .map(column => ({
            ...column,
            toggle: toggles[column.dataField]
          }))
          .map((column, index) => (
            <DropdownItem
              key={index}
              active={column.toggle ? true : false}
              onClick={ () => columnsOnChange(column, onColumnToggle, entity) }
              toggle={false}>
            {column.text}          
            </DropdownItem>

          ))
      }
      </DropdownMenu>
    </UncontrolledDropdown>
    
  );


export function getOpciones(attr, rows) {
    const estados_unicos = [...new Set(rows.map((r) => r[attr]))]
    return estados_unicos.map((e) => ({ value: e, label: e }))
}


export function addToCopyFormatter(cell) {
  return <CopyToClipboard>{cell}</CopyToClipboard>
}


export function helperFormatter(icon, extra_data) {
  return extra_data ?
  <>
  {icon}
    <svg style={{marginLeft: "5px"}} className="bi bi-chat-square-dots" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M14 1H2a1 1 0 00-1 1v8a1 1 0 001 1h2.5a2 2 0 011.6.8L8 14.333 9.9 11.8a2 2 0 011.6-.8H14a1 1 0 001-1V2a1 1 0 00-1-1zM2 0a2 2 0 00-2 2v8a2 2 0 002 2h2.5a1 1 0 01.8.4l1.9 2.533a1 1 0 001.6 0l1.9-2.533a1 1 0 01.8-.4H14a2 2 0 002-2V2a2 2 0 00-2-2H2z" clipRule="evenodd"/>
      <path d="M5 6a1 1 0 11-2 0 1 1 0 012 0zm4 0a1 1 0 11-2 0 1 1 0 012 0zm4 0a1 1 0 11-2 0 1 1 0 012 0z"/>
    </svg>
  </> :
  icon;
}
