import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListEstrategiasAsignadas from './ListEstrategiasAsignadas';
import { useTranslation } from 'react-i18next';
import ListEstrategiasNoAsignadas from './ListEstrategiasNoAsignadas';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    details: {
      display: 'grid',
    }
  }));

export default function ListEstrategiasAccordions(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const enlaces = props.clientes;
    const enlacesAsignados = props.enlacesAsignados.filter(e => e.trader && !e.fecha_fin);
    
    const enlacesClientesAsignadosIds = enlacesAsignados.map(e => e.cliente.id);
    const enlacesAsignadosIds = enlacesAsignados.map(e => e.id);
    const estrategias = props.estrategias.filter(e => enlacesAsignadosIds.includes(e.asignacion.id));
    const enlacesNoAsignados = enlaces.filter(e => !enlacesClientesAsignadosIds.includes(e.id))
    
    return <>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{t("estrategias:pending_links.label")} {enlacesNoAsignados.length} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ListEstrategiasNoAsignadas enlacesAsignados estrategias={enlacesNoAsignados} traders={props.traders} historicEstrategias={props.historicEstrategias}/>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{t("estrategias:asigned_links.label")} {enlacesAsignadosIds.length}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ListEstrategiasAsignadas estrategias={estrategias} traders={props.traders}/>
      </AccordionDetails>
    </Accordion>
    </>;
}