import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { FormGroup, Input, Modal, ModalHeader, ModalFooter, ModalBody, Col, Label, Form } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { withTranslation } from "react-i18next";
import { addAsignacionObjetivoAndEstrategias, addAsignacion, closeEstrategia, addEstrategia, listEstrategias, updateAsignacion, patchAsignacion, addObjetivo} from "../../../redux/actions/estrategias"
import {setManualErrores} from "../../../redux/actions/errores"
import { defaultIfEmpty } from "../../../helpers/helpers";
import moment from 'moment';
import { getBalance } from "../../contabilidades/helper";
import {CloseButton} from "../../common/buttons/Buttons";


class CloseEstrategiasModal extends Component {
    state = {
        modal: false,
        estrategias: [],
        trader: {},
        objetivo: undefined
    }

    componentDidMount() {
      this.setState({
        estrategias: this.props.estrategias,
        trader_id: this.props.trader.id,
        objetivo: this.props.objetivo
      })
    }

    componentDidUpdate(prevProps) {
      if (this.props.estrategias !== prevProps.estrategias) {
        this.setState({
          estrategias: this.props.estrategias,
          trader_id: this.props.trader.id
        })
      }
    }

    onChange = (index, e) => {
      const attr = e.target.name;
      const value = e.target.value;

      this.setState({
        estrategias: [
           ...this.state.estrategias.slice(0, index),
           Object.assign({}, this.state.estrategias[index], {[attr]: value}),
           ...this.state.estrategias.slice(index + 1)
        ]
      });
    };

    onChangeObjetivo = e => {
      this.setState({ objetivo: e.target.value});
    };

    onChangeTrader = e => {
      this.setState({ trader_id: e.target.value});
    };

    toggle = () => {
        this.setState(previous => ({
          modal: !previous.modal
        }));
    };

    isValid = (keys=['saldo_final']) => {
      const errs = this.state.estrategias.find(e => keys.find(k => !e[k]));
      if (errs || this.state.objetivo === '') {
        this.props.setManualErrores({"": ["Empty value(s)"]}, {'type': 'ESTRATEGIAS'});
        return false;
      }
      return true;
    };

    close = () => {
        const fecha_fin_default = moment();

        if (this.isValid()) {
          
          const asignacion = this.props.estrategias[0].asignacion;
          const canper_id = this.props.estrategias[0].canper_id;
          const objetivo = this.state.objetivo;
          
          // En el caso de cambio de trader
          if (this.props.trader.id !== this.state.trader_id) {
            const asignacionToUpdate = {...asignacion, fecha_fin: fecha_fin_default.format().slice(0, 19).replace('T', ' ')};
            const asignacionToAdd = {...asignacion, trader: this.state.trader_id ? this.props.traders.find(t => t.id === parseInt(this.state.trader_id)) : null}
            const estrategiasToAdd = this.state.estrategias.map(e => ({...e , fecha_inicio: fecha_fin_default.format().slice(0, 19).replace('T', ' '), saldo_inicial: e.saldo_final, saldo_final: null, canper_id}));

            this.props.updateAsignacion(asignacionToUpdate.id, asignacionToUpdate);
            this.state.trader_id !== "No asignar" && this.props.addAsignacionObjetivoAndEstrategias(asignacionToAdd, objetivo, estrategiasToAdd);
            // Actualizo fecha fin de estrategias existentes
            this.state.estrategias.forEach(estrategia => {
              this.props.closeEstrategia(estrategia.id, {saldo_final: estrategia.saldo_final, fecha_fin: fecha_fin_default.format().slice(0, 19).replace('T', ' ')});
            });
          }
          else {
            this.state.estrategias.forEach(estrategia => {
              this.props.closeEstrategia(estrategia.id, {saldo_final: estrategia.saldo_final, fecha_fin: fecha_fin_default.format().slice(0, 19).replace('T', ' ')});
              this.props.addEstrategia({
                fecha_inicio: fecha_fin_default.format().slice(0, 19).replace('T', ' '),
                bookmaker: estrategia.bookmaker,
                saldo_inicial: estrategia.saldo_final,
                asignacion,
                canper_id 
              });
            });
            this.props.addObjetivo({'objetivo': objetivo, 'asignacion': asignacion.id})
          }

          // Re-initialize data
          this.setState({
            estrategias: this.props.estrategias,
            trader_id: this.props.trader.id
          })
          this.toggle();
        }
    };

    render() {
        const {t} = this.props;
        const estrategias = this.state.estrategias;
        const estrategiasWithBalance = estrategias.reduce((obj, e) => ([...obj, {
          ...e, balance: getBalance({
            ...e,
            saldo_inicial: e.saldo_inicial,
            fecha_fin: e.fecha_fin || moment().endOf('month').format('YYYY-MM-DD'),
            bookmaker: {...e.bookmaker, ingresos: e.bookmaker.ingresos, retiradas: e.bookmaker.retiradas},
          })
        }]), []);

        return (
            <Fragment>
              { estrategias.length > 0 && <CloseButton onClick={() => {this.toggle()}} style={{margin: "1px"}} color={"primary"}  />}

                <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static" size='xl'>
                  <ModalHeader toggle={this.toggle}>
                    {t("contabilidades:closure.label")} {t("estrategias:strategy.label")}
                  </ModalHeader>
                    
                  <ModalBody>
                  <LoadingOverlay active={this.props.isLoading} spinner={true}>
                  <FormGroup row>
                    <Label md={1} for="bookmaker">Trader:</Label>
                    <Col md={4}>
                      <Input type="select" name="trader" onChange={this.onChangeTrader} value={this.state.trader_id}>
                        {
                          this.props.traders.map((t, i) => (<option key={i} value={t.id}>{t.username}</option>))
                        }
                        <option value={null}>{t("estrategias:no_assign.label")}</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                      <Label md={1} for="objetivo">{t("estrategias:target.label")}:</Label>
                      <Col md={4}>
                        <Input
                          type="number"
                          step="0.01"
                          name="objetivo"
                          onChange={this.onChangeObjetivo}
                          value={defaultIfEmpty(this.state.objetivo)}
                        />
                      </Col>
                  </FormGroup>
                    {estrategiasWithBalance.map((e, index) =>
                    <Form inline>                      
                      <FormGroup className="mb-4 mr-sm-2">
                        <Label className="mr-sm-2" for="fecha_fin">{t("contabilidades:date_end.label")}</Label>
                        <Input
                          type="datetime-local"
                          name="fecha_fin"
                          readOnly
                          value={moment().format().slice(0,19)}
                        />
                      </FormGroup>
                      <FormGroup className="mb-2 mr-sm-2">
                        <Label className="mr-sm-2" for="saldo_final">{t("contabilidades:final_balance.label")}</Label>
                        <Input
                          type="number"
                          step="0.01"
                          min="0"
                          name="saldo_final"
                          onChange={(e) => {this.onChange(index, e)}}
                          value={defaultIfEmpty(e.saldo_final)}
                        />
                      </FormGroup>
                      <FormGroup className="mb-2 mr-sm-5">
                        <Label className="mr-sm-2" for="balance">Balance</Label>
                        <Input
                          type="number"
                          name="balance"                    
                          value={defaultIfEmpty(e.balance)}
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <span style={{fontWeight: "bold"}}>{e.bookmaker.bookmaker}</span>
                      </FormGroup>
                    </Form>
                    )}
                  </LoadingOverlay>
                  </ModalBody>
                  
                  <ModalFooter>
                    <CloseButton onClick={() => this.toggle()} style={{margin: "1px"}} label={t("cancel.label")} />
                    <CloseButton color={"primary"} onClick={() => this.close()} label= {t("contabilidades:closure.label")} style={{margin: "1px"}} /> 
                  </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'ESTRATEGIAS',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'ESTRATEGIAS' && state.errores.status !== 200 ? state.errores.context.id : false,
});

export default connect(mapStateToProps, {setManualErrores, addAsignacionObjetivoAndEstrategias, addAsignacion, updateAsignacion, listEstrategias, addEstrategia, closeEstrategia, patchAsignacion, addObjetivo})(withTranslation(['common', 'estrategias'])(CloseEstrategiasModal));