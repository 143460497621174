import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CopyToClipboard from "../../common/CopyToClipboard";
import {datetimeFromUStoEU} from "../../../helpers/helpers";
import CloseContabilidadesModal from "./CloseContabilidadesModal";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import { getOpciones} from '../../common/BootstrapTableHelper';
import DisplayHistoryContabilidades from "./DisplayHistoryContabilidades";
import NewContabilidadModal from "./NewContabilidadModal";
import DisplayIngresos from "../../clientes/bookmakers/ingresos/DisplayIngresos";
import DisplayRetiradas from "../../clientes/bookmakers/retiradas/DisplayRetiradas";
import NewIngresoModal from "../../clientes/bookmakers/ingresos/NewIngresoModal";
import NewRetiradaModal from "../../clientes/bookmakers/retiradas/NewRetiradaModal";
import { getBalance } from "../helper";
import {EN_PREPARACION, SOLICITADA, EN_PROGRESO, EN_REVISION, COMPLETADA} from "../../../constants/index";
import moment from 'moment';


class ListContabilidades extends React.Component {

    state = {
        filters: {
            canperId: localStorage.getItem('filters.contabilidades.canperId'),
            bookmaker: localStorage.getItem('filters.contabilidades.bookmaker'),
            usuario: localStorage.getItem('filters.contabilidades.usuario'),
            clave: localStorage.getItem('filters.contabilidades.clave'),
            saldoMaximo: localStorage.getItem('filters.contabilidades.saldoMaximo'),
            saldoInicial: localStorage.getItem('filters.contabilidades.saldoInicial'),
            saldoBono: localStorage.getItem('filters.contabilidades.saldoBono'),
            saldoFinal: localStorage.getItem('filters.contabilidades.saldoFinal'),
            ingresos: localStorage.getItem('filters.contabilidades.ingresos'),
            retiradas: localStorage.getItem('filters.contabilidades.retiradas'),
            fechaInicio: localStorage.getItem('filters.contabilidades.fechaInicio'),
            fechaFin: localStorage.getItem('filters.contabilidades.fechaFin'),
            balance: localStorage.getItem('filters.contabilidades.balance'),
            saldoEstimado: localStorage.getItem('filters.contabilidades.saldoEstimado'),
            estado: localStorage.getItem('filters.contabilidades.estado'),
          },
    }

    canperIdFilter;
    bookmakerFilter;
    usuarioFilter;
    claveFilter;
    saldoMaximoFilter;
    saldoInicialFilter;
    saldoBonoFilter;
    saldoFinalFilter;
    ingresosFilter;
    retiradasFilter;
    fechaInicioFilter;
    fechaFinFilter;
    balanceFilter;
    estadoFilter;
    saldoEstimadoFilter;

    clearFilters = () => {
      this.canperIdFilter('');
      this.bookmakerFilter('');
      this.usuarioFilter('');
      this.claveFilter('');
      this.saldoMaximoFilter('');
      this.saldoInicialFilter('');
      this.saldoBonoFilter('');
      this.saldoFinalFilter('');
      this.ingresosFilter('');
      this.retiradasFilter('');
      this.fechaInicioFilter('');
      this.fechaFinFilter('');
      this.balanceFilter('');
      this.saldoEstimadoFilter('');
      this.estadoFilter('');
    }

    transformContabilidadesIntoTable = (contabilidades, historyContabilidades) => {
      const transformedContabilidades = [];
      contabilidades.forEach((contabilidad) => {

        const ingresos_value = contabilidad.bookmaker.ingresos.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia).toFixed(2)), 0.00);
        const retiradas_value = contabilidad.bookmaker.retiradas.reduce((acc, r) => acc + parseFloat(parseFloat(r.transferencia).toFixed(2)), 0.00);
        transformedContabilidades.push({
          'ingresos': <>
          {ingresos_value}
          {this.props.ingresosPermissions.includes('add')&& <NewIngresoModal create={true} bookmakerId={contabilidad.bookmaker.id} fromContabilidad={true} perteneceAId={contabilidad.bookmaker.creado_por.id}/>}
          {contabilidad.bookmaker.ingresos && contabilidad.bookmaker.ingresos.length > 0 && <DisplayIngresos ingresos={contabilidad.bookmaker.ingresos} fromContabilidad={true} modal={this.props.urlParams.get('context') === 'ingresos' && parseInt(this.props.urlParams.get('bookmaker')) === contabilidad.bookmaker.id}/>}
          </>,
          'ingresos_value': ingresos_value,
          'retiradas': <>
          {retiradas_value}
          {this.props.retiradasPermissions.includes('add') && <NewRetiradaModal create={true} bookmakerId={contabilidad.bookmaker.id} fromContabilidad={true} perteneceAId={contabilidad.bookmaker.creado_por.id}/>}
          {contabilidad.bookmaker.retiradas && contabilidad.bookmaker.retiradas.length > 0 && <DisplayRetiradas retiradas={contabilidad.bookmaker.retiradas} fromContabilidad={true} modal={this.props.urlParams.get('context') === 'retiradas' && parseInt(this.props.urlParams.get('bookmaker')) === contabilidad.bookmaker.id}/>}
          </>,
          'retiradas_value': retiradas_value,
          'fecha_inicio_filter': contabilidad.fecha_inicio ? new Date(contabilidad.fecha_inicio).toLocaleDateString("sv-SE") : "",
          'fecha_inicio_date': contabilidad.fecha_inicio ? datetimeFromUStoEU(contabilidad.fecha_inicio) : "",
          'fecha_inicio_format' : new Date(contabilidad.fecha_inicio).toLocaleDateString("sv-SE"),
          'fecha_fin_format': contabilidad.fecha_fin ? new Date(contabilidad.fecha_fin).toLocaleDateString("sv-SE"): "",
          'fecha_fin_date': contabilidad.fecha_fin ? datetimeFromUStoEU(contabilidad.fecha_fin) : "",
          'estado': contabilidad.bookmaker.estado,
          'balance': getBalance(contabilidad),
          'saldo_estimado': contabilidad.estimaciones && contabilidad.estimaciones.length > 0 ?
            contabilidad.estimaciones[contabilidad.estimaciones.length-1].estimacion : null, 
          'acciones': <>
              {historyContabilidades[contabilidad.bookmaker.bookmaker].length > 0 && <DisplayHistoryContabilidades contabilidades={historyContabilidades[contabilidad.bookmaker.bookmaker]}/>}
              {this.props.permissions.includes('change') && contabilidad.bookmaker.estado === 'SOLICITADA' &&  <NewContabilidadModal contabilidad={contabilidad} asignaciones={this.props.asignaciones}/>}
              {this.props.permissions.includes('change') && (contabilidad.bookmaker.estado === 'EN PROGRESO') && (!contabilidad.tipo_cierre || contabilidad.tipo_cierre !== 'CIERRE') && <CloseContabilidadesModal contabilidad={contabilidad} estrategias={this.props.estrategias}/>}
          </>,
        ...contabilidad
        });
      });
      return transformedContabilidades;
    };

    // Esta funcion transforma el array de contabilidades. El output sera un objecto que tiene como keys
    // los diferentes nombres de bookmakers, y como values tendran todos los registros de contabilidades para ese bookmaker
    transformContabilidadesArrayIntoObject = (contabilidades) => {
      return contabilidades.reduce((obj, c) => (
        obj.hasOwnProperty(c['bookmaker']['bookmaker']) ? {...obj, [c.bookmaker.bookmaker]: [...obj[c.bookmaker.bookmaker], c]} : {...obj, [c.bookmaker.bookmaker]: [c]})
      , {})
    }

    // Ordenamos contabilidades por estado de bookmaker, para que los bookmaker
    // que necesitan acción aparezcan como nuevos y no en el historial
    // Esto es bastante ñaposo 
    prioritiesStates = {
      [EN_PREPARACION]: 2,
      [SOLICITADA]: 4,
      [EN_PROGRESO]: 5,
      [EN_REVISION]: 3,
      [COMPLETADA]: 1
    }
    sortContabilidadesByBookmakerState = contabilidades => {
      return Object.entries(contabilidades).reduce((obj, c) => ({...obj, [c[0]]: c[1].sort((a,b) => (this.prioritiesStates[a.bookmaker.estado] > this.prioritiesStates[b.bookmaker.estado]) ? 1 : ((this.prioritiesStates[b.bookmaker.estado] > this.prioritiesStates[a.bookmaker.estado]) ? -1 : 0))}), {})
    }
    ////////////////////

    splitContabilidades = (contabilidades) => {
      const historyContabilidades = {};
      const newContabilidades = [];
      Object.entries(contabilidades).forEach(([key, value]) => {
        newContabilidades.push(value.pop())
        historyContabilidades[key] = value;
      });

      return [newContabilidades, historyContabilidades];
    }

    addToCopyFormatter = (cell) => {
        return <CopyToClipboard>{cell}</CopyToClipboard>
    }


    render() {
        const { t } = this.props;
        const contabilidades = this.props.contabilidades;
        const contabilidadesByBookmakers = this.transformContabilidadesArrayIntoObject(contabilidades);
        const conbilidadesSortedByBookmakers = this.sortContabilidadesByBookmakerState(contabilidadesByBookmakers);
        const [newContabilidades, historyContabilidades] = this.splitContabilidades(conbilidadesSortedByBookmakers);        

        const rows = this.transformContabilidadesIntoTable(
          // Elimino contabilidades que son compeltadas de meses anteriores
          newContabilidades.filter(c => !(c.bookmaker.estado === COMPLETADA && c.fecha_fin && c.fecha_fin < moment().format("YYYY-MM-01Thh:mm"))),
          historyContabilidades);
        const headers = [
          this.props.permissions.includes('view') &&
          {
            text: 'Id', 
            dataField: 'canper_id',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.canperId,
              getFilter: (filter) => {
                this.canperIdFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.canperId', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: 'Bookmaker', 
            dataField: 'bookmaker.bookmaker',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.bookmaker,
              getFilter: (filter) => {
                this.bookmakerFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.bookmaker', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:user.label"), 
            dataField: 'bookmaker.usuario',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.usuario,
              getFilter: (filter) => {
                this.usuarioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.usuario', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:key.label"), 
            dataField: 'bookmaker.clave_acceso',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.clave,
              getFilter: (filter) => {
                this.claveFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.clave', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:maximum_balance.label"), 
            dataField: 'bookmaker.saldo_maximo',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoMaximo,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoMaximoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoMaximo', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("initial_balance.label"), 
            dataField: 'saldo_inicial',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoInicial,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoInicialFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoInicial', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("ingresos:entries.label"), 
            dataField: 'ingresos_value',
            formatter: (cell, row) => row.ingresos,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.ingresos,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.ingresosFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.ingresos', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("ingresos:withdrawals.label"), 
            dataField: 'retiradas_value',
            formatter: (cell, row) => row.retiradas,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.retiradas,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.retiradasFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.retiradas', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("date_start.label"), 
            dataField: 'fecha_inicio_format',
            formatter: (cell, row) => row.fecha_inicio_date,         
            filter: textFilter({
              defaultValue: this.state.filters.fechaInicio,
              getFilter: (filter) => {
                this.fechaInicioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.fechaInicio', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("date_end.label"), 
            dataField: 'fecha_fin_format',
            formatter: (cell, row) => row.fecha_fin_date,
            filter: textFilter({     
              defaultValue: this.state.filters.fechaFin,
              getFilter: (filter) => {
                this.fechaFinFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.fechaFin', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("final_balance.label"), 
            dataField: 'saldo_final',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoFinal,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoFinalFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoFinal', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: 'Balance', 
            dataField: 'balance',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.balance,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.balanceFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.balance', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t('contabilidades:estimated_balance.label'), 
            dataField: 'saldo_estimado',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.saldoEstimado,
              getFilter: (filter) => {
                this.saldoEstimadoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoEstimado', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:state.label"), 
            dataField: 'estado',
            align: 'center',
            filter: selectFilter({
              defaultValue: this.state.filters.estado,
              options: getOpciones('estado', rows),
              getFilter: (filter) => {
                this.estadoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.estado', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]
        
        return (            
            <BootstrapTable
              bootstrap4
              hover
              striped
              condensed
              bordered={ false }
              keyField='id'
              data={rows}
              columns={ headers }
              filter={ filterFactory() }
              defaultSorted={ [{dataField: 'contabilidad', order: 'asc'}] }
              wrapperClasses="contabilidades-datatable"
            />
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.contabilidad,
    ingresosPermissions: state.cuentas.permissions.ingreso,
    retiradasPermissions: state.cuentas.permissions.retirada,
  });

export default connect(mapStateToProps, {})(withTranslation('contabilidades', 'common')(ListContabilidades));
