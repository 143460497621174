import React from "react";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next'
import { Redirect} from "react-router-dom";
import { register, isCodeValid } from "../../redux/actions/cuentas";
import { Spinner } from 'reactstrap';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { setManualErrores } from '../../redux/actions/errores'
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import Tronadvisor from '../../assets/images/Tronadvisor.png';
import Copyright from './Copyright'
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


const styles = makeStyles((theme) => ({
  paper: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginTop: 50,
    marginBottom: 10,
    width: 70,
    height: 80,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 1,
  },
  submit: {
    margin: (3, 0, 2),
  },
}));


function SignUp(props) {

  const [signUp, setSignUp] = React.useState({});
  const classes = styles();
  const { t } = useTranslation();

  const onSubmit = e => {
    e.preventDefault();
    props.codeValid && props.codeValid.valid ? checkPasswords() && props.register({...signUp, email: props.email}, props.codeValid.id) : props.isCodeValid(signUp.code);
  }

  const checkPasswords = () => {
    const {password, password2} = signUp;
    if (password === password2) {
      return true;
    }
    
    const errs = {'PASSWORDS': ['PASSWORDS ARE DIFFERENT']}
    props.setManualErrores(errs, {'type': 'CUENTAS'})
    return false
  }

  const state = props.location.state;

  return <>
    
    {props.isAuthenticated ?
    <Redirect to={state ? state.originalRoute : "/#"} /> :
    props.registration ?
    <Redirect to="/#/login" /> :
    <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <img alt="Tronadvisor" src={Tronadvisor} className={classes.avatar} />
      <Typography component="h1" variant="h4">
        Gestion Manager
      </Typography>
      <form className={classes.form} onSubmit={onSubmit} noValidate>
        {(!props.codeValid || !props.codeValid.valid) && <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="code"
          label="Code"
          name="code"
          autoFocus
          onChange={e => setSignUp({...signUp, code: e.target.value})}
        />}
        {signUp.code && props.codeValid && props.codeValid.valid &&
        <>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="email"
          label="Email"
          type="email"
          id="email"
          value={props.email}
          disabled={true}
          autoComplete="current-password"
          
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label={t('cuentas:user.label')}
          name="username"
          autoComplete="username"
          autoFocus
          onChange={e => setSignUp({...signUp, username: e.target.value})}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('cuentas:pass.label')}
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={e => setSignUp({...signUp, password: e.target.value})}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password2"
          label={`${t('common:repeat.label')} ${t('cuentas:pass.label')}`}
          type="password"
          id="password2"
          autoComplete="current-password"
          onChange={e => setSignUp({...signUp, password2: e.target.value})}
          onBlur={checkPasswords}
        /> </>}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}            
        >
          {props.codeValid ? 'Sign Up' : 'Validate Code'}
        </Button>
      </form>
    </div>
    <Box mt={2}>
      <Copyright />
    </Box>
  </Container>
  }
  </>
}

const mapStateToProps = state => ({
    isAuthenticated: state.cuentas.isAuthenticated,
    user: state.cuentas.user,
    codeValid: state.cuentas.user && state.cuentas.user.codeValidation,
    registration: state.cuentas.user && state.cuentas.user.registration,
    email: state.cuentas.user && state.cuentas.user.email,
});


export default connect(mapStateToProps, { setManualErrores, register, isCodeValid })(withTranslation('cuentas')(SignUp));