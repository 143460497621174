import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";
import { updateContabilidadAndEstadoAndSaldoInicial} from "../../../redux/actions/contabilidades";
import {defaultIfEmpty} from "../../../helpers/helpers";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import { CloseButton,EditButton } from "../../common/buttons/Buttons";


class NewContabilidadModal extends Component {
  state = {
    modal: false,
    forceModalClose: false,
    contabilidad: {},
    estado: null,
    saldoInicial: null
  };

  componentDidMount() {
    this.setState({
      contabilidad: this.props.contabilidad,
      estado: this.props.contabilidad.bookmaker.estado,
      saldoInicial: this.props.contabilidad.bookmaker.saldo_inicial})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contabilidad.bookmaker.estado !== prevState.estado) {
      this.setState({
        estado: this.props.contabilidad.bookmaker.estado,
      })
    }
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChange = e => {
    e.preventDefault();
    this.setState(
      { contabilidad: {
        ...this.state.contabilidad,
        [e.target.name]: e.target.value
      }
    });
  };

  onChangeEstado = e => {
    e.preventDefault();
    this.setState({ estado: e.target.value});
  }

  onChangeSaldoInicial = e => {
    e.preventDefault();
    this.setState({ saldoInicial: e.target.value});
  }
  onChangeCanperId = e =>{
    e.preventDefault();
    const fecha = new Date(e.target.value).toLocaleString("en-GB", {month: "short", year: "numeric"}).toUpperCase().replace(" ", "");
    this.setState(
      { contabilidad: {
        ...this.state.contabilidad,
        canper_id: fecha
      }
    });
  }
  editContabilidad = e => {
    e.preventDefault();
    
    this.props.updateContabilidadAndEstadoAndSaldoInicial(
      this.state.contabilidad.id,
      {...this.state.contabilidad},
      this.state.estado,
      this.state.saldoInicial,
      // En el caso de que se actualiza a EN PROGRESO, y el cliente tenga una asignacion, se crea una estrategia
      this.state.estado === 'EN PROGRESO' ? this.props.asignaciones.find(a => a.cliente.id === this.state.contabilidad.bookmaker.cliente.id) : false
      );

    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
  };


  render() {
    const contabilidad = this.state.contabilidad || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;
    const fecha_inicio = contabilidad && contabilidad.fecha_inicio;
    const canper_id = contabilidad.canper_id ? moment(contabilidad.canper_id).format().slice(0, 7) : '';
    const { t } = this.props;
    
    return (
    <Fragment>
        <EditButton onClick={this.onClick} style={{margin: "1px"}}/>
      <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === contabilidad.id || this.props.isLoading)} toggle={this.toggle} size='md' backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {`${t('contabilidades:link.label')}`} {contabilidad.bookmaker && contabilidad.bookmaker.cliente.enlace} - {contabilidad.pertenece_a && contabilidad.pertenece_a.username}
        </ModalHeader>
        <ModalBody>
        <LoadingOverlay active={this.props.isLoading} spinner={true}>
          <Row>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="canper_id">Bookmaker:</Label>
                  </Col>
                  <Col md="8" style={{alignSelf: "center"}}>
                    {contabilidad.bookmaker && contabilidad.bookmaker.bookmaker}
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="canper_id">Id:</Label>
                  </Col>
                  <Col md="8">   
                  <Input
                      type="month"
                      name="canper_id"
                      value={canper_id ? canper_id : ""}
                      onChange={(e) => this.onChangeCanperId(e)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="fecha_inicio">{t("contabilidades:date_start.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="datetime-local"
                      name="fecha_inicio"
                      onChange={(e) => this.onChange(e)}
                      value={fecha_inicio ? fecha_inicio.slice(0,19) : ''}
                      max={moment().format("YYYY-MM-DDThh:mm")}
                    />
                  </Col>
                </Row>
              </FormGroup>

              {!!contabilidad.tipo_cierre && <> 
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="fecha_fin">{t("contabilidades:date_end.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="date"
                      name="fecha_fin"
                      onChange={(e) => this.onChange(e)}
                      max={moment().format('YYYY-MM-DD')}
                      value={defaultIfEmpty(contabilidad.fecha_fin)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="saldo_final">{t("contabilidades:final_balance.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      name="saldo_final"
                      onChange={(e) => {this.onChange(e)}}
                      value={defaultIfEmpty(contabilidad.saldo_final)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              </>}

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="saldo_inicial">{t("bookmakers:minimum_balance.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      name="saldo_inicial"
                      onChange={e => {this.onChange(e); this.onChangeSaldoInicial(e)}}
                      value={defaultIfEmpty(contabilidad.saldo_inicial)}
                    />
                  </Col>
                </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="estado">{t("bookmakers:state.label")}:</Label>
                </Col>
                <Col md="8">              
                  <Input
                    type="select"
                    name="bookmaker.estado"
                    onChange={this.onChangeEstado}
                    value={this.state.estado}
                    disabled={this.state.estado === 'COMPLETADA'}>
                    {this.state.estado === 'SOLICITADA' && <option value='SOLICITADA'>{t('bookmakers:SOLICITADA.estado')}</option>}
                    <option value='EN PROGRESO'>{t('bookmakers:EN PROGRESO.estado')}</option>
                    <option value='EN REVISION'>{t('bookmakers:EN REVISION.estado')}</option>
                    {this.state.estado === 'COMPLETADA' && <option value='COMPLETADA'>{t('bookmakers:COMPLETADA.estado')}</option>}
                  </Input>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <CloseButton color={"outline-secondary"} onClick={() => {this.setState({'forceModalClose': true}); this.toggle()}} label={t("cancel.label")} style={{margin: "1px"}}/>
            <EditButton onClick={this.editContabilidad} label={t("edit.label")} style={{margin: "1px"}}/>
      
        </ModalFooter>
      </Modal>
    </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'COMERCIALES',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'COMERCIALES' && state.errores.status !== 200 ? state.errores.context.id : false,
});

export default connect(mapStateToProps, {updateContabilidadAndEstadoAndSaldoInicial})(withTranslation('common', 'contabilidades')(NewContabilidadModal));