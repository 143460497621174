import {ADD_PAGO, REMOVE_PAGO, UPDATE_PAGO, LIST_PAGOS, LIST_PAGOS_LOADING, LIST_PAGOS_BY_USER, LIST_PAGOS_BY_USER_LOADING} from "../types/pagos";

const initialState = {
    pagos: null
};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_PAGOS:
            return {
                ...state,
                pagos: action.payload,
            }
        case LIST_PAGOS_LOADING:
            return {
                ...state,
                isLoadingPagos: true
            }
        case REMOVE_PAGO:
            return {
                ...state,
                pagos: state.pagos.filter(pago => pago.id !== action.payload)
            }
        case ADD_PAGO:
            return {
                ...state,
                pagos: [...state.pagos, action.payload]
            }

        case UPDATE_PAGO:
            return {
                ...state,
                pagos: (state.pagos || []).map(pago => pago.id !== action.payload.id ? pago : action.payload),
                pago: action.payload
            }

        default:
            return state;
    }
}
