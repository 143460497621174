
const buildDataHelper = (payload, payloadCliente) => {
    let entidadPayload = {}
    entidadPayload.cliente_nombre = `${payloadCliente['nombre']} ${payloadCliente['apellido_1']} ${payloadCliente['apellido_2']}`
    entidadPayload = {...entidadPayload, ...payload}
    delete entidadPayload.cliente
    delete entidadPayload.id

    return entidadPayload;
}


const buildData = (entidad, payloads) => {
    let generatedPayloads = [];
    // eslint-disable-next-line
    payloads.map(function(payload) {
        
        if (payload.hasOwnProperty(entidad)) {
            const generatedPayload = Array.isArray(payload[entidad]) ?
            payload[entidad].map((p) => buildDataHelper(p, payload)) :
            [buildDataHelper(payload[entidad], payload)]
            
            generatedPayloads.push(...generatedPayload)
        }
    })
    return generatedPayloads;
}


const buildClienteData = (payloads) => {
    let newPayloads = []
    // eslint-disable-next-line
    payloads.map(function(payload) {
        let newPayload = {}
        if (payload.hasOwnProperty('banco')) {
            delete payload.banco
        }
        if (payload.hasOwnProperty('monederos')) {
            delete payload.monederos
        }
        if (payload.hasOwnProperty('bookmakers')) {
            delete payload.bookmakers
        }
        delete payload.id
        newPayload = {
            nombre_completo: `${payload['nombre']} ${payload['apellido_1']} ${payload['apellido_2']}`,
            ...payload
        }
        newPayloads.push(newPayload);
    })
    return newPayloads
}


const existMonedero = (monederos, monedero) => {
    return monederos.find(m => monedero === m.monedero)
}


const buildSummary = (payloads, permissions) => {
    let summaries = [];
    // eslint-disable-next-line
    payloads.map(function(payload) {
        let summary = {}
        if (permissions.cliente && permissions.cliente.includes('view')) {
            summary.cliente_nombre = `${payload['nombre']} ${payload['apellido_1']} ${payload['apellido_2']}`
            summary.ESTADO = payload.estado
        }
        if (permissions.banco && permissions.banco.includes('view')) {
            summary.BANCO = payload.banco ? 'DISPONIBLE' : 'NO DISPONIBLE'
        }
        if (permissions.monedero && permissions.monedero.includes('view')) {
            summary.NETELLER = existMonedero(payload.monederos, 'Neteller') ? 'DISPONIBLE' : 'NO DISPONIBLE'
            summary.SKRILL = existMonedero(payload.monederos, 'Skrill') ? 'DISPONIBLE' : 'NO DISPONIBLE'
            summary.PAYPAL = existMonedero(payload.monederos, 'Paypal')  ? 'DISPONIBLE' : 'NO DISPONIBLE'
            summary.PAYSAFECARD = existMonedero(payload.monederos, 'Paysafecard')  ? 'DISPONIBLE' : 'NO DISPONIBLE'
        }
        if (permissions.cliente && permissions.cliente.includes('view')) {
            summary.OBSERVACIONES = payload.observaciones
        }
        summaries.push(summary);
    })
    return summaries
}


export const exportClientes = (clientes, selectedClientes, selectedEntidades, bookmakers, permissions) => {

    // Fetch payload from state for selected clientes
    const payloads = clientes.filter((c) => selectedClientes.includes(c.id));

    // Add bookmakers to payload if it has been selected
    if (selectedEntidades.includes('bookmakers')) {
        // eslint-disable-next-line 
        payloads.map(function(payload) {
            payload = {...payload, bookmakers: bookmakers[payload.id]}
        });
    }


    let data = {};
    data['resumen'] = buildSummary(payloads, permissions);
    // eslint-disable-next-line
    selectedEntidades.map(function(entidad) {
        data[entidad] = buildData(entidad, payloads)
    });

    if (selectedEntidades.includes('cliente')) {
        data['cliente'] = buildClienteData(payloads)
    }

    return data
}
