import { API_PAGOS_URL } from "../../constants";
import { ADD_PAGO, REMOVE_PAGO, UPDATE_PAGO, LIST_PAGOS, LIST_PAGOS_LOADING } from "../types/pagos"
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';


const PAGOS = 'PAGOS';

// Add Pago
export const addPago = (pago) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, PAGOS));
    return axios
        .post(`${API_PAGOS_URL}`, pago, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_PAGO,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("pagos:added_pago.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': PAGOS, 'id': pago.id}));
        })
};


// Remove Pago
export const removePago = id => (dispatch, getState) => {
    axios
        .delete(`${API_PAGOS_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_PAGO,
                payload: id
            });
            dispatch(createMessage(i18n.t("pagos:remove_pago.message")));
        })
        .catch(err => console.log(err))
};


// Update Pago
export const updatePago = (id, Pago) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, PAGOS));
    axios
        .put(`${API_PAGOS_URL}${id}/`, Pago, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_PAGO,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("pagos:updated_pago.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': PAGOS, 'id': id}));
        })
};


// List Pagos of a cliente
export const listPagos = () => (dispatch, getState) => {
    dispatch({ type: LIST_PAGOS_LOADING });
    axios
    .get(`${API_PAGOS_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_PAGOS,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_PAGOS,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listPagosByCanperId = (canperId) => (dispatch, getState) => {
    dispatch({ type: LIST_PAGOS_LOADING });
    axios
    .get(`${API_PAGOS_URL}?canper_id=${canperId}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_PAGOS,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_PAGOS,
                payload: []
            });
        }
        console.log(err)
    })
};