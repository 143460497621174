import { API_BOOKMAKERS_URL, API_CLIENTES_URL } from "../../constants";
import {  ADD_INGRESO, REMOVE_INGRESO, UPDATE_INGRESO, LIST_INGRESOS, LIST_INGRESOS_BY_BOOKMAKER_ID, LIST_INGRESOS_BY_CLIENTE_ID} from "../types/ingresos";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';


const INGRESOS = 'INGRESOS';

// Add Ingreso
export const addIngreso = (ingreso) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, INGRESOS));
    return axios
        .post(`${API_BOOKMAKERS_URL}ingresos/`, ingreso, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_INGRESO,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("ingresos:added_ingreso.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': INGRESOS, 'id': ingreso.id}));
        })
};


// Remove Ingreso
export const removeIngreso = id => (dispatch, getState) => {
    axios
        .delete(`${API_BOOKMAKERS_URL}ingresos/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_INGRESO,
                payload: id
            });
            dispatch(createMessage(i18n.t("ingresos:remove_ingreso.message")));
        })
        .catch(err => console.log(err))
};


// Update Ingreso
export const updateIngreso = (id, ingreso) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, INGRESOS));
    axios
        .put(`${API_BOOKMAKERS_URL}ingresos/${id}/`, ingreso, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_INGRESO,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("ingresos:updated_ingreso.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': INGRESOS, 'id': id}));
        })
};


// List Ingresos of a cliente
export const listIngresos = () => (dispatch, getState) => {
    axios
    .get(`${API_BOOKMAKERS_URL}ingresos/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_INGRESOS,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_INGRESOS,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listIngresosByBookmakerId = (bookmakerId) => (dispatch, getState) => {
    axios
    .get(`${API_BOOKMAKERS_URL}${bookmakerId}/ingresos/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_INGRESOS_BY_BOOKMAKER_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_INGRESOS_BY_BOOKMAKER_ID,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listIngresosByClienteId = (clienteId) => (dispatch, getState) => {
    axios
    .get(`${API_CLIENTES_URL}${clienteId}/bookmakers/ingresos/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_INGRESOS_BY_CLIENTE_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_INGRESOS_BY_CLIENTE_ID,
                payload: []
            });
        }
        console.log(err)
    })
};