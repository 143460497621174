import { API_CLIENTES_BOOKMAKERS_URL, API_CLIENTES_URL, nowDatetime } from "../../constants";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { ADD_CLIENTE_BOOKMAKER, UPDATE_CLIENTE_BOOKMAKER, REMOVE_CLIENTE_BOOKMAKER, LIST_CLIENTE_BOOKMAKERS_BY_CLIENTE_IDS, LIST_CLIENTE_BOOKMAKERS_BY_CLIENTE_ID } from "../types/clientes_bookmakers"
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import {addContabilidad} from "./contabilidades";
import {addEstrategia} from "./estrategias";
import i18n from '../../translations/i18n';
import moment from 'moment';
const BOOKMAKERS = 'CLIENTES_BOOKMAKERS';

// Add bookmaker
export const addBookmaker = (bookmaker, addContabilidadRecord=false, asignacion=false) => (dispatch, getState) => {

    dispatch(setErroresLoading(true, BOOKMAKERS));
    return axios
        .post(`${API_CLIENTES_BOOKMAKERS_URL}`, bookmaker, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_CLIENTE_BOOKMAKER,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("bookmakers:added_bookmaker.message")));
            
            const cliente = getState().clientes.cliente 
            const cliente_payload = {...cliente, comercial: cliente.comercial ? cliente.comercial.id : null};
            const fecha_inicio = nowDatetime;
            const canper_id = new Date().toLocaleString("en-GB", {month: "short", year: "numeric"}).toUpperCase().replace(" ", "");
            // Añado registro en contabilidad
            //TODO: FECHA INICIO CAMBIADA A DATETIME
            if (addContabilidadRecord) {
                dispatch(addContabilidad({
                    canper_id,
                    fecha_inicio,
                    saldo_inicial: res.data.saldo_inicial,
                    bookmaker: {...res.data, cliente: cliente_payload, creado_por: {id: res.data.creado_por, username: 'to_be_overwritten'}}}));
            }  
            /**
             *Por operativa nunca va a entrar en este if creando la estrategia puesto que solo se debería crear cuando el administrador la pasa a el bkm a "En progreso"
             *y nunca se genera desde la propia creación del bookmaker que crea el gestor.
             *Revisar para ver si se quita este apartado de asignación y por si no estoy en lo cierto.          
             */
            
            if (asignacion) {
                dispatch(addEstrategia({
                    canper_id,
                    fecha_inicio,
                    saldo_inicial: res.data.saldo_inicial,
                    asignacion: asignacion,
                    bookmaker: {...res.data, cliente: cliente_payload, creado_por: {id: res.data.creado_por, username: 'to_be_overwritten'}}
                }))
            }
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': BOOKMAKERS, 'id': bookmaker.id}));
        })
};


// Remove bookmaker
export const removeBookmaker = id => (dispatch, getState) => {
    axios
        .delete(`${API_CLIENTES_BOOKMAKERS_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_CLIENTE_BOOKMAKER,
                payload: id
            });
            dispatch(createMessage(i18n.t("bookmakers:remove_bookmaker.message")));
        })
        .catch(err => console.log(err))
};


// Update bookmaker
export const updateBookmaker = (id, bookmaker, addContabilidadRecord=false, asignacion=false ) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, BOOKMAKERS));
    axios
        .put(`${API_CLIENTES_BOOKMAKERS_URL}${id}/`, bookmaker, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CLIENTE_BOOKMAKER,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("bookmakers:updated_bookmaker.message")));

            const fecha_inicio = nowDatetime;
            const canper_id = new Date().toLocaleString("en-GB", {month: "short", year: "numeric"}).toUpperCase().replace(" ", "");
            const cliente = getState().clientes.cliente;
            const cliente_payload = {...cliente, comercial: cliente.comercial ? cliente.comercial.id : null};
            // Añado registro en contabilidad
            if (addContabilidadRecord) {
                dispatch(addContabilidad({
                    canper_id,
                    fecha_inicio, 
                    bookmaker: {...res.data, cliente: cliente_payload, creado_por: {id: res.data.creado_por, username: 'to_be_overwritten'}}}));
            }
            //Creo que esta creación de estrategia debe eliminarse puesto que en la edición/creación de bookmkaer esto no se utiliza
            if (asignacion) {
                dispatch(addEstrategia({
                    canper_id,
                    fecha_inicio,
                    saldo_inicial: res.data.saldo_inicial,
                    asignacion: asignacion,
                    bookmaker: {...res.data, cliente: cliente_payload, creado_por: {id: res.data.creado_por, username: 'to_be_overwritten'}}
                }))
            }
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': BOOKMAKERS, 'id': id}));
        })
};


// List bookmakers of a cliente
export const listBookmakersByClienteIds = (clienteIds) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, BOOKMAKERS));
    const calls = clienteIds.map((clienteId) => 
        axios.get(`${API_CLIENTES_URL}${clienteId}/bookmakers/`, tokenConfig(getState))
    );
    axios.all(calls)
    .then(resArr => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CLIENTE_BOOKMAKERS_BY_CLIENTE_IDS,
            payloads: resArr
        });
        dispatch(setErroresLoading(false));
    })
    .catch(err => {
        console.log(err)
        dispatch(setErroresLoading(false))
    })
};


// List bookmakers of a cliente
export const listBookmakersByClienteId = (clienteId) => (dispatch, getState) => {
    axios
    .get(`${API_CLIENTES_URL}${clienteId}/bookmakers/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CLIENTE_BOOKMAKERS_BY_CLIENTE_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_CLIENTE_BOOKMAKERS_BY_CLIENTE_ID,
                payload: 'unauthorized'
            });
        }
        console.log(err)
    })
};
