export const ADD_NOTIFICACION = 'ADD_NOTIFICACION'
export const REMOVE_NOTIFICACION = 'REMOVE_NOTIFICACION'
export const UPDATE_NOTIFICACION = 'UPDATE_NOTIFICACION'
export const LIST_NOTIFICACIONES = 'LIST_NOTIFICACIONES'
export const LIST_RECEIVED_NOTIFICACIONES = 'LIST_RECEIVED_NOTIFICACIONES'
export const LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES = 'LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES'
export const LIST_SENT_NOTIFICACIONES = 'LIST_SENT_NOTIFICACIONES'
export const LIST_NOTIFICACIONES_LOADING = 'LIST_NOTIFICACIONES_LOADING'
export const LIST_RECEIVED_NOTIFICACIONES_LOADING = 'LIST_RECEIVED_NOTIFICACIONES_LOADING'
export const LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES_LOADING = 'LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES_LOADING'
export const LIST_SENT_NOTIFICACIONES_LOADING = 'LIST_SENT_NOTIFICACIONES_LOADING'
export const LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS = 'LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS';
export const LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS_LOADING = 'LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS_LOADING';
export const LIST_FOLLOWED_NOTIFICACIONES = 'LIST_FOLLOWED_NOTIFICACIONES';
export const LIST_FOLLOWED_NOTIFICACIONES_LOADING = 'LIST_FOLLOWED_NOTIFICACIONES_LOADING';
export const LIST_NOTIFICACIONES_CHANNELS_LOADING = 'LIST_NOTIFICACIONES_CHANNELS_LOADING';
export const LIST_NOTIFICACIONES_CHANNELS = 'LIST_NOTIFICACIONES_CHANNELS';
export const UPDATE_NOTIFICACION_CHANNEL = 'UPDATE_NOTIFICACION_CHANNEL';
export const CREATE_NOTIFICACION_CHANNEL = 'CREATE_NOTIFICACION_CHANNEL';