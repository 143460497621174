import React, {useEffect} from 'react';
import {addObjetivo, addObjetivoFromResumen} from "../../../redux/actions/estrategias";
import {setManualErrores} from "../../../redux/actions/errores"
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { EditButton } from '../../common/buttons/Buttons';
import { Input } from 'reactstrap/lib';
import { defaultIfEmpty } from '../../../helpers/helpers';


function NewObjetivoModal(props) {
    const [modal, setModal] = React.useState(false);
    const [objetivo, setObjetivo] = React.useState(null);
    const toggle = () => setModal(!modal);
    const { t } = useTranslation();

    useEffect(() => {
      setObjetivo(props.asignacion.asignacion.objetivo ? props.asignacion.asignacion.objetivo.objetivo : null);
    },[]);

    const isValid = () => {
        if (!objetivo && objetivo !== 0) {
          props.setManualErrores({[t('estrategias:target.label')]: ['EMPTY VALUE']});
          return false
        }

        return true
    }

    const createObjetivo = () => {
      const payload = {
        'objetivo': objetivo,
        'asignacion': props.asignacion.asignacion.id
      };
      if (isValid()) {
        props.fromResumen ? props.addObjetivoFromResumen(payload) : props.addObjetivo(payload);
        toggle();
      }
    }

    return <>
    <EditButton onClick={toggle} style={{margin: "1px"}}/>
    <Modal isOpen={modal} toggle={toggle} backdrop="static" >
        <ModalHeader toggle={toggle}>
            {t('common:edit.label')} {t('estrategias:target.label')} 
        </ModalHeader>
        <ModalBody>
            <FormGroup>
              <Row>
                <Col md="4">
                    <Label for="estrategia">{t("estrategias:strategy.label")}:</Label>
                </Col>
                <Col md="8">
                  {props.asignacion.asignacion.id}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="4">
                    <Label for="entidad">{t("cuentas:entity.label")}:</Label>
                </Col>
                <Col md="8">
                  {props.asignacion.entidad}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="4">
                    <Label for="estrategia">{t("clientes:link.label")}:</Label>
                </Col>
                <Col md="8">
                  {props.asignacion.bookmaker.cliente.enlace}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="objetivo">{t("estrategias:target.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      name="objetivo"
                      min="0"
                      onChange={(e) => setObjetivo(e.target.value)}
                      value={defaultIfEmpty(objetivo)}
                    />
                  </Col>
                </Row>
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={toggle}>
              {t("common:cancel.label")}
            </Button>
            <Button type="button" color="primary" onClick={createObjetivo}>
              {t("common:add.label")}
            </Button>
        </ModalFooter>
    </Modal>
    </>;
}


export default connect(null, {addObjetivo, setManualErrores, addObjetivoFromResumen})(NewObjetivoModal);