import { API_BOOKMAKERS_URL } from "../../constants";
import {  ADD_RANGO, REMOVE_RANGO, UPDATE_RANGO, LIST_RANGOS, LIST_RANGOS_BY_BOOKMAKER_ID} from "../types/rangos";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores } from "./errores";
import i18n from '../../translations/i18n';


const RANGOS = 'RANGOS';

// Add Rango
export const addRango = (rango) => (dispatch, getState) => {

    return axios
        .post(`${API_BOOKMAKERS_URL}rangos/`, rango, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_RANGO,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("rangos:added_rango.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': RANGOS, 'id': rango.id}));
        })
};


// Remove Rango
export const removeRango = id => (dispatch, getState) => {
    axios
        .delete(`${API_BOOKMAKERS_URL}rangos/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_RANGO,
                payload: id
            });
            dispatch(createMessage(i18n.t("rangos:remove_rango.message")));
        })
        .catch(err => console.log(err))
};


// Update Rango
export const updateRango = (id, Rango) => (dispatch, getState) => {
    axios
        .put(`${API_BOOKMAKERS_URL}rangos/${id}/`, Rango, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_RANGO,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("rangos:updated_rango.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': RANGOS, 'id': id}));
        })
};


// List Rangos of a cliente
export const listRangos = () => (dispatch, getState) => {
    axios
    .get(`${API_BOOKMAKERS_URL}rangos/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_RANGOS,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_RANGOS,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listRangosByBookmakerId = (bookmakerId) => (dispatch, getState) => {
    axios
    .get(`${API_BOOKMAKERS_URL}${bookmakerId}/rangos/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_RANGOS_BY_BOOKMAKER_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_RANGOS_BY_BOOKMAKER_ID,
                payload: []
            });
        }
        console.log(err)
    })
};