import React, {useEffect } from "react";
import { connect } from 'react-redux';
import {listConexionprovByCliente} from '../../../redux/actions/conexionesprov';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CloseButton, CustomButton } from '../../common/buttons/Buttons';
import {  Row, Col } from "reactstrap";
import CopyToClipboard from "../../common/CopyToClipboard";


function DisplayConexionprov(props) {
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);

    const { t } = useTranslation();
    const conexion = props.conexiones && props.conexiones.hasOwnProperty(props.clienteId) ? props.conexiones[props.clienteId].find(c => c.pertenece_gestor) : {};

    useEffect(() => {
        (!props.conexiones || !props.conexiones.hasOwnProperty(props.clienteId)) && props.listConexionprovByCliente(props.clienteId)
    },[])

    return <>
    {props.permissions.includes('view') && conexion && <CustomButton onClick={toggle} style={{margin: "1px"}} color={"secondary"} label={t("conexionesprov:connection.label")} />}
    <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>
            {t("conexionesprov:connection.label")}
        </ModalHeader>
        <ModalBody>
            <Row>
            <Col sm="12" md="4" className="border-right">
                <Row>
                    <Col className="col-info" xs="6" md="6" >Anydesk id</Col>
                    <Col className="col-info-result" xs="6" md="6" ><CopyToClipboard>{conexion && conexion.anydesk_id}</CopyToClipboard></Col>
                </Row>
                <Row>
                    <Col className="col-info" xs="6" md="6" >Anydesk contraseña</Col>
                    <Col className="col-info-result" xs="6" md="6" ><CopyToClipboard>{conexion && conexion.anydesk_contraseña}</CopyToClipboard></Col>
                </Row>
            </Col>
            <Col sm="12" md="4" className="border-right">                            
                <Row>
                    <Col className="col-info" xs="6" md="5" >Ip id</Col>
                    <Col className="col-info-result" xs="6" md="7" ><CopyToClipboard>{conexion && conexion.ip_id}</CopyToClipboard></Col>
                </Row>                            
                <Row>
                    <Col className="col-info" xs="6" md="5">Clave ip id</Col>
                    <Col className="col-info-result" xs="6" md="7" ><CopyToClipboard>{conexion && conexion.clave_ip_id}</CopyToClipboard></Col>
                </Row>
            </Col>
            <Col sm="12" md="4" >
                <Row>
                    <Col className="col-info" xs="4" md="3">Ip</Col>
                    <Col className="col-info-result" xs="8" md="9" ><CopyToClipboard>{conexion && conexion.ip}</CopyToClipboard></Col>
                </Row>
            </Col>
            </Row>
        </ModalBody>

        <ModalFooter>
            <CloseButton onClick={toggle} style={{margin: "1px"}} label={t("common:cancel.label")} />
        </ModalFooter>
    </Modal>
    </>
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.contabilidad,
    conexiones: state.conexionesprov.conexionesprovByCliente,
  });

export default connect (mapStateToProps, {listConexionprovByCliente})(DisplayConexionprov);