import React  from 'react';
import { useTranslation } from 'react-i18next';
import Datatable from "react-bs-datatable";


export default function Prioridades(props) {
    const { t } = useTranslation();
    const prioridades = props.prioridades.length > 0 ? props.prioridades.map(p => ({...p.bookmaker, prioridad: p.prioridad})) : props.all_bookmakers;
    const existingBookmakersNames = props.existingBookmakers.map(b => b.bookmaker);
    const body = prioridades.map(p => ({
        ...p,
        'bookmakerName': p.bookmaker
    }));


    const header = [
        {title: "Bookmaker", prop: 'bookmakerName', sortable: true, cellProps: {style: row => existingBookmakersNames.includes(row.bookmaker) ? { background: 'LightGrey' } : undefined}},
        {title: t("bookmakers:priority.label"), prop: 'prioridad', sortable: true, cellProps: {style: row => existingBookmakersNames.includes(row.bookmaker) ? { background: 'LightGrey' } : undefined}}
    ];

    
    return <div className="horizontal-scroll vertical-scroll-medium">
        <Datatable
            tableHeaders={header}
            tableBody={body}
            tableClass="striped hover responsive"
        />
    </div>

}