import React from "react";
import { connect } from "react-redux";
import { addMonedero, updateMonedero, listMonderosByClienteId } from "../../../redux/actions/monederos";
import NewMonederosModal from "./NewMonederosModal";
import { Row, Col, CardHeader, CardBody, Card } from "reactstrap";
import DisplayMonederos from "./DisplayMonederos";
import { withTranslation } from "react-i18next";


class Monederos extends React.Component {

    componentDidMount() {
        this.props.listMonderosByClienteId(this.props.clienteId)
    }

    createMonedero = monedero => {
        this.props.addMonedero(monedero);
    };
    
    editMonedero = monedero => {
        this.props.updateMonedero(monedero.id, monedero);
    };

    getExistingMonederos = () => {
        return this.props.monederos.map((m) => m.monedero);
    };


    render() {
        const { t } = this.props;
        const permissions = this.props.permissions || [];

        return (
            <>
            {!this.props.monederos ?
            null :
            !permissions.includes('view') ? 
            null :
            <Card style={{ marginTop: "10px" }}>
                <CardHeader>
                <Row>
                    <Col>
                        <h5>{t("wallet.label")}s</h5>
                    </Col>
                    <Col className="col-auto">
                        {permissions.includes('add') &&
                        <NewMonederosModal create={true} clienteId={this.props.clienteId} createMonedero={this.createMonedero} existingMonederos={this.getExistingMonederos}/>}
                    </Col>
                </Row>
                </CardHeader>
                <CardBody>
                    {this.props.monederos.length > 0 ?
                    <DisplayMonederos editMonedero={this.editMonedero} monederos={this.props.monederos} /> :
                    <span>{t("dont_exist.label")}</span>}
                </CardBody>
            </Card>
            }
            </>
        );
    }
}


const mapStateToProps = state => ({
    monederos: state.clientes.monederos,
    monedero_opciones: state.opciones.monedero,
    permissions: state.cuentas.permissions.monedero
  });

export default connect(mapStateToProps, {addMonedero, updateMonedero, listMonderosByClienteId})(withTranslation('monederos')(Monederos));