import { API_COMERCIALES_URL } from "../../constants";
import { ADD_COMERCIAL, REMOVE_COMERCIAL, UPDATE_COMERCIAL, LIST_COMERCIALES, LIST_ACTIVE_COMERCIALES, LIST_ACTIVE_COMERCIALES_LOADING, LIST_COMERCIALES_LOADING } from "../types/comerciales"
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "../actions/mensajes";
import { setErrores, setErroresLoading } from "../actions/errores";
import i18n from '../../translations/i18n';


const COMERCIALES = 'COMERCIALES';

// Add Comercial
export const addComercial = (comercial) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, COMERCIALES));
    return axios
        .post(`${API_COMERCIALES_URL}`, comercial, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_COMERCIAL,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("comerciales:added_comercial.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': COMERCIALES, 'id': comercial.id}));
        })
};


// Remove Comercial
export const removeComercial = id => (dispatch, getState) => {
    axios
        .delete(`${API_COMERCIALES_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_COMERCIAL,
                payload: id
            });
            dispatch(createMessage(i18n.t("comerciales:remove_comercial.message")));
        })
        .catch(err => console.log(err))
};


// Update Comercial
export const updateComercial = (id, comercial) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, COMERCIALES));
    axios
        .put(`${API_COMERCIALES_URL}${id}/`, comercial, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_COMERCIAL,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("comerciales:updated_comercial.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': COMERCIALES, 'id': id}));
        })
};


// List Comercials of a cliente
export const listComerciales = () => (dispatch, getState) => {
    dispatch({ type: LIST_COMERCIALES_LOADING });
    axios
    .get(`${API_COMERCIALES_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_COMERCIALES,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_COMERCIALES,
                payload: []
            });
        }
        console.log(err)
    })
};


// List Comercials of a cliente
export const listActiveComerciales = () => (dispatch, getState) => {
    dispatch({ type: LIST_ACTIVE_COMERCIALES_LOADING });
    axios
    .get(`${API_COMERCIALES_URL}?estado=activo`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ACTIVE_COMERCIALES,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_ACTIVE_COMERCIALES,
                payload: []
            });
        }
        console.log(err)
    })
};