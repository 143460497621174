import React from 'react';
import { connect } from "react-redux";

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Spinner from 'reactstrap/lib/Spinner';

import { listCanperIdsByEntidad, listContabilidadesByEntidadAndCanperId } from "../../../redux/actions/contabilidades";
import { listEstrategiasByEntidadAndCanperId} from "../../../redux/actions/estrategias";
import ListResumenContabilidadesVsEstrategias from './ListResumenContabilidadesVsEstrategias';
import { Row } from 'reactstrap';
import { Checkbox } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));


function ListResumenComparison(props) {
    const classes = useStyles();
    const [entidad, setEntidad] = React.useState(null);
    const [canperId, setCanperId] = React.useState(null);
    const [discrepancies, setDiscrepances] = React.useState(false);
    
    
    const onChangeDiscrepancies = (checked) => {
        setDiscrepances(checked);
    };

    const onChangeEntidad = (event) => {
        const entidad = event.target.value;
        setEntidad(entidad);
        setCanperId(null);
        props.listCanperIdsByEntidad(entidad);
    };

    const onChangeCanperId = (event) => {
        const canperId = event.target.value;
        setCanperId(canperId);
        props.listContabilidadesByEntidadAndCanperId(entidad, canperId)
        props.listEstrategiasByEntidadAndCanperId(entidad, canperId)
    };

    const { t } = useTranslation();

    return <>
        {/* Entidad select */}
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>{t('cuentas:entity.label')}</InputLabel>
            <Select
                labelId="entidad-label"
                id="entidad-id"
                value={entidad}
                onChange={onChangeEntidad}
                label={t('cuentas:entity.label')}
            >
                {props.entidades.map(e => 
                    <MenuItem value={e.nombre}>{e.nombre}</MenuItem>)
                }
            </Select>
        </FormControl>

        {/* Canper Ids select */}
        {props.canperIdsByEntidadIsLoading  ? <Spinner style={{marginTop: '10px', marginLeft: '20px'}} animation="border" color="primary"/> :
        !entidad ? null :
        props.canperIdsByEntidad && props.canperIdsByEntidad.hasOwnProperty(entidad) ? 
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Id</InputLabel>
                <Select
                    labelId="canper-label"
                    id="canper-id"
                    value={canperId}
                    onChange={onChangeCanperId}
                    label="Id"
                >
                    {props.canperIdsByEntidad[entidad].map(e => 
                        <MenuItem value={e}>{e}</MenuItem>)
                    }
                </Select>
            </FormControl> : null }


        {/* Discrepancies checkbox */}
        {!canperId ? null :
        <FormControlLabel
            control={
            <Checkbox
                checked={discrepancies}
                onChange={e => onChangeDiscrepancies(e.target.checked)}
                name="discrepances"
                color="primary"
                size='bg'
            />
            }
            label={t('common:discrepancies.label')}
            labelPlacement="top"
        /> }

        {/* Contabilidades vs Estrategias */}
        {!entidad || !canperId ? null : 
        props.contabilidadesByEntidadAndCanperIdIsLoading || props.estrategiasByEntidadAndCanperIdIsLoading ? <Row><Spinner style={{marginTop: '30px', marginLeft: '100px'}} animation="border" color="primary"/></Row> :
        <ListResumenContabilidadesVsEstrategias contabilidades={props.contabilidades} estrategias={props.estrategias} entidad canperId discrepancies={discrepancies}/>
        }
    </>
}


const mapStateToProps = state => ({
    canperIdsByEntidad: state.contabilidades.canperIdsByEntidad,
    canperIdsByEntidadIsLoading: state.contabilidades.canperIdsByEntidadIsLoading,
    contabilidades: state.contabilidades.contabilidadesByEntidadAndCanperId,
    contabilidadesByEntidadAndCanperIdIsLoading: state.contabilidades.contabilidadesByEntidadAndCanperIdIsLoading,
    estrategiasByEntidadAndCanperIdIsLoading: state.estrategias.estrategiasByEntidadAndCanperIdIsLoading,
    estrategias: state.estrategias.estrategiasByEntidadAndCanperId,
});


export default connect(mapStateToProps, {listCanperIdsByEntidad, listContabilidadesByEntidadAndCanperId, listEstrategiasByEntidadAndCanperId})(ListResumenComparison);