import { API_CLIENTES_URL, API_ESTRATEGIAS_URL, API_ASIGNACIONES_URL, API_ESTIMACIONES_URL, API_OBJETIVO_URL } from "../../constants";
import { LOADING_ASIGNACION_OR_ESTRATEGIA, ADD_ESTRATEGIA, UPDATE_HISTORIC_ESTRATEGIA, UPDATE_ESTRATEGIA, LIST_ESTRATEGIAS, LIST_ESTRATEGIAS_LOADING, LIST_ESTRATEGIAS_BY_CLIENTE, LIST_ASIGNACIONES_LOADING, LIST_ASIGNACIONES, ADD_ASIGNACION, UPDATE_ASIGNACION, LIST_HISTORIC_ESTRATEGIAS, LIST_HISTORIC_ESTRATEGIAS_LOADING, LIST_ASIGNACIONES_BY_CLIENTE, UPDATE_ESTIMACION, ADD_ESTIMACION, LIST_ESTRATEGIAS_ESTIMACIONES_LOADING, LIST_ESTRATEGIAS_ESTIMACIONES, LIST_ACTIVE_ASIGNACIONES_LOADING, LIST_ACTIVE_ASIGNACIONES, LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES_LOADING, LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES, LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES_LOADING, LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES, CLOSE_ESTRATEGIA, LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID_LOADING, LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID, ADD_OBJETIVO, ADD_OBJETIVO_RESUMEN, LIST_ESTRATEGIAS_CANPER_IDS_LOADING, LIST_ESTRATEGIAS_CANPER_IDS} from "../types/estrategias"
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';


const ESTRATEGIAS = 'ESTRATEGIAS';
const ASIGNACIONES = 'ASIGNACIONES';
const ESTIMACIONES = 'ESTIMACIONES';

// Esta accion es utilizada para asegurar que las creaciones/actuliazacion para
// asignaciones y estrategias son realizadas, antes de mostrar la info
const setEstrategiaOrAsignacionLoading = loading => dispatch => {
    dispatch({
        type: LOADING_ASIGNACION_OR_ESTRATEGIA,
        payload: loading
    });
}


// Add Estrategia
export const addEstrategia = (estrategia) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, ESTRATEGIAS));
    dispatch(setEstrategiaOrAsignacionLoading(ADD_ESTRATEGIA));
    axios
        .post(`${API_ESTRATEGIAS_URL}`, estrategia, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_ESTRATEGIA,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:added_estrategia.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ESTRATEGIAS, 'id': estrategia.id}));
        })
};


// Update Estrategia
export const updateEstrategia = (id, estrategia) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, ESTRATEGIAS));
    dispatch(setEstrategiaOrAsignacionLoading(UPDATE_ESTRATEGIA));
    axios
        .put(`${API_ESTRATEGIAS_URL}${id}/`, estrategia, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_ESTRATEGIA,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:updated_estrategia.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ESTRATEGIAS, 'id': id}));
        })
};


// Update Estrategia
export const patchEstrategia = (id, estrategia) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, ESTRATEGIAS));
    dispatch(setEstrategiaOrAsignacionLoading(UPDATE_ESTRATEGIA));
    axios
        .patch(`${API_ESTRATEGIAS_URL}${id}/`, estrategia, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_ESTRATEGIA,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:updated_estrategia.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ESTRATEGIAS, 'id': id}));
        })
};


export const closeEstrategia = (id, estrategia) => (dispatch, getState) => {
    dispatch(patchEstrategia(id, estrategia));
    dispatch({
        type: CLOSE_ESTRATEGIA,
        payload: {
            id,
            ...estrategia
        }
    });
};


export const listEstrategias = () => (dispatch, getState) => {
    dispatch({ type: LIST_ESTRATEGIAS_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ESTRATEGIAS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};

export const listHistoricEstrategias = () => (dispatch, getState) => {
    dispatch({ type: LIST_HISTORIC_ESTRATEGIAS_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}historicos/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_HISTORIC_ESTRATEGIAS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listHistoricEstrategiasWithEstimaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}historicos/with_estimaciones/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
}


export const listHistoricEstrategiasWithEstimacionesByCanperId = (canperId) => (dispatch, getState) => {
    dispatch({ type: LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}historicos/with_estimaciones/?canper_id=${canperId}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
}


export const updateHistoricEstrategia = (id, estrategia) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, ESTRATEGIAS));
    dispatch(setEstrategiaOrAsignacionLoading(UPDATE_ESTRATEGIA));
    axios
        .put(`${API_ESTRATEGIAS_URL}${id}/`, estrategia, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_HISTORIC_ESTRATEGIA,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:updated_estrategia.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ESTRATEGIAS, 'id': id}));
        })
};

export const listEstrategiasActuales = () => (dispatch, getState) => {
    dispatch({ type: LIST_ESTRATEGIAS_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}actuales/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ESTRATEGIAS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};

export const listEstrategiasBookmakersEnProgreso = () => (dispatch, getState) => {
    dispatch({ type: LIST_ESTRATEGIAS_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}?estado=EN PROGRESO`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ESTRATEGIAS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};

// List Estrategias of a cliente
export const listEstrategiasByCliente = (cliente_id) => (dispatch, getState) => {
    axios
    .get(`${API_CLIENTES_URL}${cliente_id}/estrategias`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ESTRATEGIAS_BY_CLIENTE,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


// Add Asignacion
export const addAsignacion = (asignacion) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, ASIGNACIONES));
    dispatch(setEstrategiaOrAsignacionLoading(ADD_ASIGNACION));
    axios
        .post(`${API_ASIGNACIONES_URL}`, asignacion, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_ASIGNACION,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:added_asignacion.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ASIGNACIONES, 'id': asignacion.id}));
        })
};


// Update Asignacion
export const updateAsignacion = (id, asignacion) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, ASIGNACIONES));
    dispatch(setEstrategiaOrAsignacionLoading(UPDATE_ASIGNACION));
    axios
        .put(`${API_ASIGNACIONES_URL}${id}/`, asignacion, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_ASIGNACION,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:updated_asignacion.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ASIGNACIONES, 'id': id}));
        })
};


export const patchAsignacion = (id, asignacion) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, ASIGNACIONES));
    dispatch(setEstrategiaOrAsignacionLoading(UPDATE_ASIGNACION));
    axios
        .patch(`${API_ASIGNACIONES_URL}${id}/`, asignacion, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_ASIGNACION,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:updated_asignacion.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ASIGNACIONES, 'id': id}));
        })
};


export const listAsignaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_ASIGNACIONES_LOADING });
    axios
    .get(`${API_ASIGNACIONES_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ASIGNACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listAsignacionesActivas = () => (dispatch, getState) => {
    dispatch({ type: LIST_ACTIVE_ASIGNACIONES_LOADING });
    axios
    .get(`${API_ASIGNACIONES_URL}activas/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ACTIVE_ASIGNACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listAsignacionesByCliente = (cliente_id) => (dispatch, getState) => {
    axios
    .get(`${API_CLIENTES_URL}${cliente_id}/asignaciones/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ASIGNACIONES_BY_CLIENTE,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const addAsignacionObjetivoAndEstrategias = (asignacion, objetivo, estrategias) => (dispatch, getState) => {
    
    dispatch(setErroresLoading(true, ASIGNACIONES));
    dispatch(setEstrategiaOrAsignacionLoading(ADD_ASIGNACION));
    axios
        .post(`${API_ASIGNACIONES_URL}`, asignacion, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_ASIGNACION,
                payload: res.data
            });
            dispatch(addObjetivo({objetivo: objetivo, asignacion: res.data.id}))
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:added_asignacion.message")));
            estrategias.map(e => dispatch(addEstrategia({...e, asignacion: res.data})));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ASIGNACIONES, 'id': asignacion.id}));
        })
    
}


export const updateEstimacion = (id, estimacion) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, ESTIMACIONES));
    axios
        .put(`${API_ESTIMACIONES_URL}${id}/`, estimacion, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_ESTIMACION,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:updated_estimacion.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ESTIMACIONES, 'id': id}));
        })
};


export const addEstimacion = (estimacion) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, ESTIMACIONES));
    axios
        .post(`${API_ESTIMACIONES_URL}`, estimacion, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_ESTIMACION,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("estrategias:added_estimacion.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': ESTIMACIONES, 'id': estimacion.id}));
        })
};


export const addObjetivo = (objetivo) => (dispatch, getState) => {
    axios
        .post(`${API_OBJETIVO_URL}`, objetivo, tokenConfig(getState))
        .then(res => {objetivo
            dispatch({
                type: ADD_OBJETIVO,
                payload: res.data
            });
        })
};

export const addObjetivoFromResumen = (objetivo) => (dispatch, getState) => {
    axios
        .post(`${API_OBJETIVO_URL}`, objetivo, tokenConfig(getState))
        .then(res => {objetivo
            dispatch({
                type: ADD_OBJETIVO_RESUMEN,
                payload: res.data
            });
        })
};

export const listEstrategiasWithEstimaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_ESTRATEGIAS_ESTIMACIONES_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}with_estimaciones/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ESTRATEGIAS_ESTIMACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listEstrategiasActualesWithEstimaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}actuales/with_estimaciones/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listEstrategiasByEntidadAndCanperId = (entidad, canper_id) => (dispatch, getState) => {
    dispatch({ type: LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}?entidad=${entidad}&canper_id=${canper_id}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID,
            payload: res.data,
        });
    })
}


export const listEstrategiasWithEstimacionesByCanperId = (canperId) => (dispatch, getState) => {
    dispatch({ type: LIST_ESTRATEGIAS_ESTIMACIONES_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}with_estimaciones/?canper_id=${canperId}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ESTRATEGIAS_ESTIMACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listCanperIds = () => (dispatch, getState) => {
    dispatch({ type: LIST_ESTRATEGIAS_CANPER_IDS_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}canper_ids/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ESTRATEGIAS_CANPER_IDS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listCanperIdsByTrader = (trader) => (dispatch, getState) => {
    dispatch({ type: LIST_ESTRATEGIAS_CANPER_IDS_LOADING });
    axios
    .get(`${API_ESTRATEGIAS_URL}canper_ids/?trader=${trader}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ESTRATEGIAS_CANPER_IDS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};