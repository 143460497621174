import React from "react";
import { Row, Col, Input, Label, FormGroup } from "reactstrap";

import {defaultIfEmpty} from "../../../helpers/helpers";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class NewBookmakersForm extends React.Component {
    
    render() {
        const {t} = this.props;
        const bookmaker = this.props.bookmaker;
        const bookmakerOpciones = this.props.bookmaker_opciones;
        const blockChangeEstado = !(this.props.permission_contabiliad || []).includes('change') && bookmaker.estado && bookmaker.estado !== 'EN PREPARACION' && !this.props.create;
        
        return <>
          <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="bookmaker">Bookmaker:</Label>
                </Col>
                <Col md="8">
                  <Input type="select" name="bookmaker" onChange={this.props.onChange} value={defaultIfEmpty(bookmaker.bookmaker)}>
                    <option value="">{t("common:option.label")}</option>
                    {
                      // Filtro los bookmakers ya existentes
                      //Object.values(bookmakerOpciones).filter((b) => b.bookmaker === bookmaker.bookmaker || !this.props.existingBookmakers().includes(b.bookmaker)).map((f, i) => (<option key={i}>{f.bookmaker}</option>))
                      Object.values(bookmakerOpciones).map((f, i) => (<option key={i}>{f.bookmaker}</option>))
                    }
                  </Input>
                </Col>
            </Row>
          </FormGroup>


          <FormGroup>
            <Row>
              <Col md="4">
                <Label className="col-form-label" for="usuario">{t("user.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="usuario"
                  onChange={this.props.onChange}
                  value={defaultIfEmpty(bookmaker.usuario)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label className="col-form-label" for="clave_acceso">{t("key.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="clave_acceso"
                  onChange={this.props.onChange}
                  value={defaultIfEmpty(bookmaker.clave_acceso)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label className="col-form-label" for="pregunta_o_pin">{t("question.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="pregunta_o_pin"
                  onChange={this.props.onChange}
                  value={defaultIfEmpty(bookmaker.pregunta_o_pin)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label className="col-form-label" for="respuesta">{t("answer.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="respuesta"
                  onChange={this.props.onChange}
                  value={defaultIfEmpty(bookmaker.respuesta)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="saldo_inicial">{t("bookmakers:minimum_balance.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="number"
                  step="0.01"
                  name="saldo_inicial"
                  onChange={this.props.onChange}
                  value={defaultIfEmpty(bookmaker.saldo_inicial)}
                  readOnly={blockChangeEstado}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="saldo_maximo">{t("bookmakers:maximum_balance.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="number"
                  step="0.01"
                  name="saldo_maximo"
                  onChange={this.props.onChange}
                  value={defaultIfEmpty(bookmaker.saldo_maximo)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="estado">{t("state.label")}:</Label>
              </Col>
              <Col md="8">
              {blockChangeEstado ?
                <Input type="text" name="estado" value={defaultIfEmpty(bookmaker.estado)} readOnly /> :
                <Input type="select" name="estado" onChange={this.props.onChange} value={defaultIfEmpty(bookmaker.estado)}>
                  <option value="">{t("common:option.label")}</option>
                  <option value='EN PREPARACION'>{t('EN PREPARACION.estado')}</option>
                  <option value='SOLICITADA'>{t('SOLICITADA.estado')}</option>
                  {(this.props.permission_contabiliad || []).includes('change') && <>
                  <option value='EN PROGRESO'>{t('EN PROGRESO.estado')}</option>
                  <option value='EN REVISION'>{t('EN REVISION.estado')}</option>
                  <option value='COMPLETADA'>{t('COMPLETADA.estado')}</option>
                  </>}
                </Input>
              }
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label className="col-form-label" for="observaciones">{t("notes.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="textarea"
                  name="observaciones"
                  onChange={this.props.onChange}
                  value={defaultIfEmpty(bookmaker.observaciones)}
                />
              </Col>
            </Row>
          </FormGroup>     
        </>;
    }
}

const mapStateToProps = state => ({
  bookmaker_opciones: state.opciones.bookmaker,
  permission_contabiliad : state.cuentas.permissions.contabilidad
});


export default connect(mapStateToProps)(withTranslation('bookmakers', 'common')(NewBookmakersForm));
