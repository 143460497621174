import React, {useState} from "react";

const Footer = () => {

    const [showScroll, setShowScroll] = useState(false)

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
     };

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 300){
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 300){
            setShowScroll(false)
        }
    };

    window.addEventListener('scroll', checkScrollTop)
    
    return (
        <footer
        style={{height:"60px", marginTop: "20px", backgroundColor: "#3f51b5", display: "flex", alignItems: "center", justifyContent: "center"}}
        >
            <span onClick={() => scrollTop()} style={{display: showScroll ? 'flex' : 'none', color: "white"}}><i className="fa fa-chevron-circle-up fa-3x" aria-hidden="true"></i></span>
        </footer>
    );
}

export default Footer;