import {
    USUARIO_LOADING,
    USUARIO_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    UPDATE_PROFILE,
    LIST_ENTIDADES_LOADING,
    LIST_ENTIDADES,
    CODE_VALIDATION,
    REGISTER
  } from '../types/cuentas';
import { UPDATE_AJUSTES, ADD_AJUSTES, LIST_USER_AJUSTES, LIST_USER_AJUSTES_LOADING, LIST_USER_PROFILE_LOADING, LIST_USER_PROFILE  } from '../types/ajustes';
import { LIST_USER_PERMISSIONS, LIST_USER_PERMISSIONS_GROUP} from '../types/permissions';
  
  const initialState = {
    isLoading: false,
    isAuthenticated: null,
    user: null,
    token: localStorage.getItem('token')
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case USUARIO_LOADING:
        return {
          ...state,
          isLoading: true
        };
      case USUARIO_LOADED:
        return {
          ...state,
          isLoading: false,
          isAuthenticated: true,
          user: action.payload
        };
      case LOGIN_SUCCESS:
        localStorage.setItem('token', action.payload.token);
        return {
          ...state,
          isLoading: false,
          isAuthenticated: true,
          ...action.payload
        };
      case AUTH_ERROR:
      case LOGIN_FAIL:
      case LOGOUT_SUCCESS:
        localStorage.removeItem('token');
        return {
          isLoading: false,
          isAuthenticated: false,
          user: null,
          token: null,
          permissions: null
        };
      // Ajustes reducers
      case LIST_USER_AJUSTES_LOADING:
        return {
          ...state,
          ajustes: {isLoading: true}
        }
      case LIST_USER_AJUSTES:
      case ADD_AJUSTES:
      case UPDATE_AJUSTES:
        return {
          ...state,
          ajustes: {
            ...action.payload,
            isLoading: false
          }
        }
      // Permissions reducers
      case LIST_USER_PERMISSIONS:
        return {
          ...state,
          permissions: action.payload
        }
      case LIST_USER_PERMISSIONS_GROUP:
        return {
          ...state,
          permissions_group: action.payload
        }

      // PROFILE
      case LIST_USER_PROFILE_LOADING:
        return {
          ...state,
          profileIsLoading: true
        }
      case LIST_USER_PROFILE:
        return {
          ...state,
          profile: action.payload,
          profileIsLoading: true
        }
      case UPDATE_PROFILE:
        return {
          ...state,
          profile: {...state.profile, ...action.payload}
        }

      case LIST_ENTIDADES:
        return {
          ...state,
          entidades: action.payload,
          isLoadingEntidades: false
        }
      case LIST_ENTIDADES_LOADING:
        return {
          ...state,
          isLoadingEntidades: true
        }
      case CODE_VALIDATION:
        return {
          ...state,
          user: {...state.user, codeValidation: action.payload, email: action.payload.email}
        }
      case REGISTER:
        return {
          ...state,
          user: {...state.user, registration: true}
        }

      default:
        return state;
    }
  }