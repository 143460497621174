import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { paginationOptions, getOpciones, addToCopyFormatter } from '../common/BootstrapTableHelper';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { dateFromUStoEU, datetimeFromUStoEU } from '../../helpers/helpers';
import { getBalance } from '../contabilidades/helper';


export default function ListResumenTrader(props) {

    const filters = React.useState({
      canperId: localStorage.getItem('filters.resumenGestor.canperId'),
      fechaInicio: localStorage.getItem('filters.resumenGestor.fechaInicio'),
      fechaFin: localStorage.getItem('filters.resumenGestor.fechaFin'),
      balance: localStorage.getItem('filters.resumenGestor.balance'),
    });
    const { t } = useTranslation();

    let canperIdFilter = undefined;
    let fechaInicioFilter = undefined;
    let fechaFinFilter = undefined;
    let balanceFilter = undefined;

    const clearFilters = () => {
      canperIdFilter('');
      fechaInicioFilter('');
      fechaFinFilter('');
      balanceFilter('');
    }

    const addBalance = contabilidades => {
      return (contabilidades || []).map(e => ({...e, balance: getBalance(e)}));
    }

    const transformContabilidadesByCanperId = contabilidades => {
      return contabilidades.reduce((obj, e) => (
        obj.hasOwnProperty(e['canper_id']) ?
          {...obj, [e.canper_id]: [...obj[e.canper_id], e]} :
          {...obj, [e.canper_id]: [e]})
        , {});
    }

    const transformContabilidadesByCliente = contabilidades => {
      return contabilidades.reduce((obj, e) => (
        obj.hasOwnProperty(e['bookmaker']['cliente']['id']) ?
          {...obj, [e.bookmaker.cliente.id]: [...obj[e.bookmaker.cliente.id], e]} :
          {...obj, [e.bookmaker.cliente.id]: [e]})
        , {});
    }

    const transformContabilidadesIntoTable = (contabilidades) => {
        return contabilidades.map(e => ({
          'fecha_inicio_filter': e.fecha_inicio ? e.fecha_inicio : "",
          'fecha_inicio_date': e.fecha_inicio ? dateFromUStoEU(e.fecha_inicio) : "",
          'fecha_fin_filter': e.fecha_fin ? e.fecha_fin : "",
          'fecha_fin_date': e.fecha_fin ? dateFromUStoEU(e.fecha_fin) : "",
            ...e
        }))
    }

    const estrategiasIntoGrouping = contabilidadesByCanperId => {
      const balances = Object.values(contabilidadesByCanperId).map(e => e.reduce((sum, b) => b.saldo_final && b.fecha_fin && b.balance ? sum + parseFloat(b.balance) : sum, 0)).map(b => b.toFixed(2));
      const fechas_inicios = Object.values(contabilidadesByCanperId).map(e => (new Date(Math.min(...e.map(f => new Date(f.fecha_inicio)))))).map(f => f.toLocaleDateString("sv-SE"))
      const fechas_finales = Object.values(contabilidadesByCanperId).map(e => (new Date(Math.max(...e.map(f => new Date(f.fecha_fin)))))).map(f => f.toLocaleDateString("sv-SE"))

      return Object.entries(contabilidadesByCanperId).map(([canperId, c], i) => ({...contabilidadesByCanperId[canperId][0], 'enlaces': transformContabilidadesByCliente(c), 'fecha_inicio': fechas_inicios[i], 'fecha_fin': fechas_finales[i], 'balance': balances[i]}))
    }

    const contabilidades = addBalance(props.contabilidades);
    const contabilidadesByCanperId = transformContabilidadesByCanperId(contabilidades);
    const contabilidadesGrouped = estrategiasIntoGrouping(contabilidadesByCanperId);

    const rows = transformContabilidadesIntoTable(contabilidadesGrouped);
    
    const headers = [      
      {
        text: 'Id', 
        dataField: 'canper_id',
        filter: textFilter({
          defaultValue: filters.canperId,
          getFilter: (filter) => {
            canperIdFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenGestor.canperId', value);
          },
        }),
        sort: true
      },
      {
        text: t("contabilidades:date_start.label"), 
        dataField: '',
        formatter: (cell, row) => row.fecha_inicio_date,         
        filter: textFilter({
          defaultValue: filters.fechaInicio,
          getFilter: (filter) => {
            fechaInicioFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenGestor.fechaInicio', value);
          },
        }),
        sort: true
      },
      {
        text: t("contabilidades:date_end.label"), 
        dataField: 'fecha_fin_filter',
        formatter: (cell, row) => row.fecha_fin_date,
        filter: textFilter({     
          defaultValue: filters.fechaFin,
          getFilter: (filter) => {
            fechaFinFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenGestor.fechaFin', value);
          },
        }),
        sort: true
      },
      {
        text: 'Balance', 
        dataField: 'balance',
        filter: textFilter({
          defaultValue: filters.balance,
          getFilter: (filter) => {
            balanceFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenGestor.balance', value);
          },
        }),
        sort: true
      },      
    ]

    const expandRow = {
      renderer: row =>
      (
        <>
          <Row>
            <Col>{t("clientes:link.label")}</Col>
            <Col></Col><Col></Col>
            <Col>Balance</Col>
          </Row>
          {Object.values(row.enlaces).map(c =>
            <Row>
              <Col>{c[0].bookmaker.cliente.enlace}</Col>
              <Col></Col><Col></Col>
              <Col>{c.reduce((sum, b) => parseFloat(b.balance) + sum, 0).toFixed(2)}</Col>
            </Row>
          )}
        </>
      ),
      showExpandColumn: true,
      expandByColumnOnly: true,
    };

    return <PaginationProvider  pagination={ paginationFactory(paginationOptions(rows.length)) }>
    {
      ({
        paginationProps,
        paginationTableProps
      }) => (
        <>
        <div className="pagination-table">
          <Row >
            <Col>
              <Button color="secondary" size="sm" onClick={clearFilters}>{t('common:remove_filters.label')}</Button>
            </Col>
            <Col className="col-auto">
              <PaginationListStandalone
                { ...paginationProps }
              />
            </Col>
            <Col className="col-auto">
              <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
            </Col>
          </Row>
        </div>
        <BootstrapTable
            bootstrap4
            hover
            condensed
            bordered={ false }
            keyField='id'
            data={rows}
            columns={ headers }
            filter={ filterFactory() }
            defaultSorted={ [{dataField: 'id', order: 'asc'}] }
            wrapperClasses="resumen-gestores-datatable"
            expandRow={ expandRow }
            { ...paginationTableProps }
            />
        </>
        )}
    </PaginationProvider>
}
