import React, {useEffect} from 'react';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ListNotificaciones from './ListNotificaciones';
import NewNotificacionModal from './NewNotificacionModal';
import { listRecivedUncompletedNotificaciones, listNotificacionesPossibleReceivers, markNotificacionAsCompleted } from '../../redux/actions/notificaciones';
import { listEstrategiasActuales } from "../../redux/actions/estrategias"
import { SUPERUSER, TRADER } from '../../constants';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    addNotificationIcon: {
        paddingRight: 2
    }
}));

const REFRESH_UNCOMPLETED_NOTIFICACIONES_MINUTES = 2;
const REFRESH_UNCOMPLETED_NOTIFICACIONES = REFRESH_UNCOMPLETED_NOTIFICACIONES_MINUTES * 60 * 1000;

function NotificacionesCampanaView(props) {
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        if (!props.possibleReceivers && props.possibleReceiversAreLoading === false) {
            props.listNotificacionesPossibleReceivers();
        }
    }, [props.possibleReceivers, props.possibleReceiversAreLoading]);

    useEffect(() => {
        if (!props.estrategias && props.isEstrategiasLoading === false && (props.userGroup === TRADER || props.userGroup === SUPERUSER)) {
            props.listEstrategiasActuales();
        }
    }, [props.estrategias, props.isEstrategiasLoading]);

    useEffect(() => {
        if (!props.notificaciones && props.isLoading === false) {
            props.listRecivedUncompletedNotificaciones();

            setInterval(()=> {
                props.listRecivedUncompletedNotificaciones();
            }, REFRESH_UNCOMPLETED_NOTIFICACIONES);
        }
    }, []);

    return <>
        <Grid container>
            <Grid item xs={8}>
                <a href='#/notificaciones'>
                    <Typography variant="h5" className={classes.text}>            
                        {t('notificaciones:notifications.label')}
                    </Typography>
                </a>
            </Grid>
            <Grid item xs={4}>
                <MenuItem onClick={props.onClose}>
                    <NewNotificacionModal userGroup={props.userGroup} sender={props.user} possibleReceivers={props.possibleReceivers} estrategias={props.estrategias}/>
                </MenuItem>
            </Grid>
        </Grid>
        <hr />
        {props.notificaciones && props.notificaciones.length > 0 ?
        <ListNotificaciones notificaciones={props.notificaciones} markNotificacionAsCompleted={props.markNotificacionAsCompleted} userGroup={props.userGroup} user={props.user} /> :
        <Typography variant="body2" color="textSecondary" className={classes.text}>{t('notificaciones:no_notifications.message')}</Typography>}
    </>;

}

const mapStateToProps = state => ({
    notificaciones: state.notificaciones.recievedUncompletedNotificaciones,
    isLoading: state.notificaciones.recievedUncompletedNotificacionesisLoading || false,
    possibleReceivers: state.notificaciones.possibleReceivers,
    possibleReceiversAreLoading: state.notificaciones.possibleReceiversAreLoading || false,
    userGroup: state.cuentas.permissions_group,
    user: state.cuentas.user,
    estrategias: state.estrategias.estrategias,
    isEstrategiasLoading: state.estrategias.isLoadingEstrategias || false,
});

export default connect(mapStateToProps, {listEstrategiasActuales, markNotificacionAsCompleted, listRecivedUncompletedNotificaciones, listNotificacionesPossibleReceivers})(NotificacionesCampanaView);