import {LIST_BOOKMAKERS, REMOVE_BOOKMAKER, UPDATE_BOOKMAKER, ADD_BOOKMAKER, LIST_BOOKMAKER, LIST_BOOKMAKERS_LOADING, ADD_PRIORIDADES, REMOVE_PRIORIDADES, LIST_PRIORIDADES_BY_TRADER, LIST_PRIORIDADES_BY_TRADER_LOADING } from "../types/bookmakers";
import {LIST_RANGOS, UPDATE_RANGO, ADD_RANGO, REMOVE_RANGO, LIST_RANGOS_BY_BOOKMAKER_ID } from "../types/rangos";


const initialState = {
    bookmakers: null
};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_BOOKMAKERS:
            return {
                ...state,
                bookmakers: action.payload,
                isLoading: false
            }
        case LIST_BOOKMAKERS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case REMOVE_BOOKMAKER:
            return {
                ...state,
                // Devuelve todos los bookmakers excepto el borrado
                bookmakers: state.bookmakers.filter(bookmaker => bookmaker.id !== action.payload)
            }
        case ADD_BOOKMAKER:
            return {
                ...state,
                bookmakers: [...state.bookmakers, action.payload]
            }
        case LIST_BOOKMAKER:
            return {
                ...state,
                bookmaker: action.payload,
            }

        case UPDATE_BOOKMAKER:
            return {
                ...state,
                bookmakers: (state.bookmakers || []).map(bookmaker => bookmaker.id !== action.payload.id ? bookmaker : action.payload),
                bookmaker: action.payload
            }

        // rangos reducers
        case UPDATE_RANGO:
            return {
                ...state,
                rangos: state.rangos.map(c => c.id === action.payload.id ? action.payload : c)
            }

        case LIST_RANGOS:
        case LIST_RANGOS_BY_BOOKMAKER_ID:
            return {
                ...state,
                rangos: action.payload
            }

        case ADD_RANGO:
            return {
                ...state,
                rangos: [...state.rangos, action.payload]
            }

        case REMOVE_RANGO:
            return {
                ...state,
                rangos: state.rangos.filter(c => c.id !== action.payload)
            }

        case REMOVE_PRIORIDADES:
            return {
                ...state,
                prioridades: state.prioridades.filter(p => !action.payload.includes(p.id))
            }

        case ADD_PRIORIDADES:
            return {
                ...state,
                prioridades: [...state.prioridades, action.payload]
            }

        case LIST_PRIORIDADES_BY_TRADER:
            return {
                prioridades: action.payload,
                isLoadingPrioridades: false
            }
        
        case LIST_PRIORIDADES_BY_TRADER_LOADING:
            return {
                isLoadingPrioridades: true
            }
        
        default:
            return state;
    }
}
