import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { Input } from 'reactstrap/lib';
import { CustomButton, CloseButton } from '../../common/buttons/Buttons';
import { defaultIfEmpty } from "../../../helpers/helpers";
import moment from 'moment';
import { getBalance } from "../helper";
import {setManualErrores} from "../../../redux/actions/errores"
import { connect } from "react-redux";
import {patchBulkCompleteBookmakers} from "../../../redux/actions/contabilidades"


function CloseMultipleContabilidadesModal(props) {
    const [modal, setModal] = React.useState(false);
    const [saldoFinales, setSaldoFinales] = React.useState([]);
    const toggle = () => setModal(!modal);
    const isLoading = props.isLoading === props.contabilidades[0].bookmaker.cliente.id;
    const { t } = useTranslation();

    const isValid = () => {
        if (props.contabilidades.length !== saldoFinales.length || saldoFinales.find(c => !c)) {
          props.setManualErrores({"SALDO FINAL": ["Empty value(s)"]}, {'type': 'CONTABILIDADES'});
          return false;
        }
        return true;
    };

    const onChangeSaldoFinales = (index, e) => {  
        setSaldoFinales([
             ...saldoFinales.slice(0, index),
             e.target.value,
             ...saldoFinales.slice(index + 1)
        ]);
    };

    const close = () => {
        if (isValid()) {            
            props.patchBulkCompleteBookmakers(props.contabilidades, props.estrategias, saldoFinales);
        }
        setModal(false);
    }

    return (
        <>
            {props.permissions.includes('change') && <CustomButton onClick={toggle} style={{margin: "1px"}} color={"primary"}  label={t("contabilidades:close.label")} />}
            <Modal isOpen={modal || isLoading} toggle={toggle} backdrop="static" size='lg'>
                <ModalHeader toggle={!isLoading && toggle}>
                    {t("contabilidades:closure.label")} {t("contabilidades:accountings.label")}
                </ModalHeader>
                <ModalBody>
                    {isLoading ? <Spinner animation="border" color="primary"/> :
                    <>
                    <FormGroup row>
                        <Label md={2} for="fecha_fin">{t("contabilidades:date_end.label")}</Label>
                        <Col md={5}>
                            <Input
                            type="date"
                            name="fecha_fin"
                            readOnly
                            value={moment().format('YYYY-MM-DD')}
                            />
                        </Col>
                    </FormGroup>
                    {props.contabilidades.map((c, index) =>
                    <Form inline>
                        <FormGroup className="mb-2 mr-sm-2">
                            <Label className="mr-sm-2" for="saldo_final">{t("contabilidades:final_balance.label")}</Label>
                            <Input
                            type="number"
                            step="0.01"
                            min="0"
                            max="999999"
                            name="saldo_final"
                            onChange={(e) => {onChangeSaldoFinales(index, e)}}
                            value={defaultIfEmpty(c.saldo_final)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-2 mr-sm-2">
                            <Label className="mr-sm-2" for="balance">Balance</Label>
                            <Input
                                type="number"
                                name="balance"                    
                                value={defaultIfEmpty(getBalance({
                                    ...c,
                                    fecha_fin: moment().format('YYYY-MM-DD'),
                                    saldo_final: saldoFinales[index]
                                    }))}
                                readOnly
                            />
                        </FormGroup>
                        <FormGroup>
                            <span style={{fontWeight: "bold"}}>{c.bookmaker.bookmaker}</span>
                    </FormGroup>
                    </Form>
                    )}
                    </>}
                </ModalBody>

                <ModalFooter>
                    <CloseButton onClick={toggle} style={{margin: "1px"}} label={t("common:cancel.label")} disabled={isLoading}/>
                    <CloseButton color={"primary"} onClick={close} label= {t("contabilidades:closure.label")} style={{margin: "1px"}} disabled={isLoading}/> 
                  </ModalFooter>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.contabilidad,    
    estrategias: state.estrategias.estrategias,
    isLoading: state.errores.isLoading && state.errores.isLoading.type === 'CIERRE_MULTIPLE_CONTABILIDADES' ? state.errores.isLoading.id  : false,
  });

export default connect (mapStateToProps, {setManualErrores, patchBulkCompleteBookmakers})(CloseMultipleContabilidadesModal);