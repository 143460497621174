import React, {useEffect} from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import LoadingOverlay from 'react-loading-overlay';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Modal from 'reactstrap/lib/Modal';
import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import { addNotificacion, markNotificacionAsCompleted } from '../../redux/actions/notificaciones';
import { fetchNotificacionesTemplates, allNotificaciones } from './notificacionesTemplates';
import { connect } from 'react-redux';
import {setManualErrores} from "../../redux/actions/errores"
import ForwardIcon from '@material-ui/icons/Forward';
import { ADMINISTRADOR, LARGE } from '../../constants';


const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    }
}));


function NewNotificacionModal(props) {
    const [modal, setModal] = React.useState(false);
    const [notificacion, setNotificacion] = React.useState({});

    const [estrategia, setEstrategia] = React.useState(null);
    const [notificacionTemplate, setNotificacionTemplate] = React.useState(null);
    const classes = useStyles();
    const toggle = () => setModal(!modal);
    const { t } = useTranslation();
    const notificacionesTemplates = fetchNotificacionesTemplates(props.userGroup)

    // Efecto para cuando creamos una notificacion de forma correcta
    useEffect(() => {
      if (modal && props.erroring === false && props.isLoading === false && !props.edit) {
        setModal(false);
        setEstrategia(null);
        setNotificacion({});
        setNotificacionTemplate(null);
      }
      // eslint-disable-next-line
    }, [props.erroring, props.isLoading]);

    // Efecto para cargar datos de notificacion en caso de modo edición
    useEffect(() => {
      if (props.edit) {
        setNotificacionTemplate({'priority': props.notificacion.priority});
        // setFollower(props.sender); // Administrador informado
        setNotificacion({
          ...props.notificacion,
          receiver: props.notificacion.forward,
          sender: props.notificacion.receiver,
          follower: props.notificacion.sender
        })
      }
    // eslint-disable-next-line
    }, [props.edit, props.notificacion]);

    const isValid = () => {
      const errs = {};
      if (notificacionTemplate.estrategia && !estrategia) {
        errs[t('estrategias:strategy.label')] = ['EMPTY VALUE'];
      }
      if (notificacionTemplate.follower && !notificacion.follower) {
        errs[t('estrategias:follower.label')] = ['EMPTY VALUE'];
      }
      props.setManualErrores(errs, {'type': 'NOTIFICACIONES'})
      return Object.entries(errs).length === 0 ? true : false;
    };

    const onChange = e => {
      const possibleReceivers = Object.values(props.possibleReceivers).flat()
      const attr = e.target.name;
      const id = parseInt(e.target.value);
      setNotificacion({
        ...notificacion,
        [attr]: possibleReceivers.find(s => s.id === id)
      });
    };

    const onChangeEstrategia = event => {
      const estrategias = props.estrategias;
      notificacionTemplate.estrategia === LARGE ? 
      setEstrategia(estrategias.find(e => e.id === parseInt(event.target.value))) :
      setEstrategia(estrategias.find(e => e.asignacion.id === parseInt(event.target.value)));
    };

    const onChangePayload = e => {
      setNotificacion({
        ...notificacion,
        payload: {
          ...notificacion.payload,
          [e.target.name]: e.target.value
        }
      });
    };

    const onChangeTemplate = e => {
      const id = e.target[e.target.selectedIndex].id;
      !id ?
      setNotificacionTemplate(null) :
      setNotificacionTemplate(
        allNotificaciones.find(n => id === n.id)
      );
    };

    const addNotificacion = () => {
      // Validacion de estrategia
      if (!notificacionTemplate || !isValid()) {
        return
      }

      let payload = notificacion.payload;
      let forward = notificacionTemplate.forward;
      let receiver = notificacion.receiver;
      let link = notificacionTemplate.link;

      if (estrategia) {
        // Añado informacion de estratregia al contexto
        payload.context = {
          'enlace': estrategia.asignacion.cliente.enlace,
          'estrategia': estrategia.asignacion.id,
          'bookmaker': estrategia.bookmaker.bookmaker
        } 

        // Actualizo forward en el caso de que se pueda reenviar la notificaion
        if (forward) {
          forward = estrategia.bookmaker.creado_por;
        }
        // Asigno receiver usando estrategia, si no se ha elegido
        if (notificacionTemplate.receiver !== ADMINISTRADOR) {
          receiver = estrategia.bookmaker.creado_por;
        }

        // Añado link
        if (link && estrategia) {
          payload.link = link + `entidad=${estrategia.entidad}&enlace=${estrategia.bookmaker.cliente.enlace}`;
        }
      }

      
      props.addNotificacion({
        payload: payload,
        sender: props.sender,
        ...notificacion,
        priority: notificacionTemplate && notificacionTemplate.priority ? notificacionTemplate.priority : undefined,
        forward,
        receiver,
        verified: notificacionTemplate.verified
      });
      
      if (props.edit) {
        props.markNotificacionAsCompleted(props.notificacion.id)
      }
    };

    return <>
    {!props.edit ?
      <Tooltip title="" className={classes.text} onClick={toggle}>
          <IconButton aria-label="add">
              <AddAlertIcon color="primary"/>
          </IconButton>
      </Tooltip> :
      <Tooltip title={t('notificaciones:forward.label')} onClick={toggle} >
        <IconButton aria-label="view">
          <ForwardIcon fontSize={props.fontSize || "small"} color="primary"/>
        </IconButton>
      </Tooltip>
    }
    <Modal isOpen={modal} toggle={toggle} backdrop="static" >
        <ModalHeader color="danger" toggle={toggle}>
            {t('common:add.label')} {t('notificaciones:notifications.label')} 
        </ModalHeader>
        <ModalBody>
        <LoadingOverlay active={props.isLoading} spinner={true}>
            <FormGroup >
              <Row>
                <Col md="4">
                  <Label for="header">{t("common:type.label")}:</Label>
                </Col>
                <Col md="8">
                  {!props.edit ? <Input type="select" name="header" onChange={(e) => {onChangePayload(e); onChangeTemplate(e)}}>
                    <option value="">{t("common:option.label")}</option>
                    {notificacionesTemplates.map((n, index) => <option key={index} id={n.id} value={n.header}>{t(`notificaciones:${n.header}.header`)}</option>)}
                  </Input>:
                  <span>{notificacion.payload && notificacion.payload.header}</span>}
                </Col>
              </Row>
            </FormGroup>
            {notificacionTemplate && <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="body">{t('notificaciones:body.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input type="textarea" maxLength="500" name="body" onChange={onChangePayload} value={notificacion.payload && notificacion.payload.body ? notificacion.payload.body : ""}/>
                </Col>
              </Row>
            </FormGroup>}

            {notificacionTemplate && notificacionTemplate.estrategia && <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="estrategia">{t('estrategias:strategy.label')}</Label>
                </Col>
                <Col md="8">
                  <Input type="select" name="estrategia" onChange={onChangeEstrategia}>
                    <option value="">{t("common:option.label")}</option>
                    {
                    notificacionTemplate.estrategia === LARGE ?
                    (props.estrategias || []).map((e, index) => <option key={index} value={e.id}>{`${t('estrategias:strategy.label')}: ${e.asignacion.id} Bookmaker: ${e.bookmaker.bookmaker}`}</option>) :
                    [...new Set((props.estrategias || []).map(e => e.asignacion.id))].map((id, index) => <option key={index} value={id}>{`${t('estrategias:strategy.label')}: ${id}`}</option>)
                    }
                  </Input>
                </Col>
              </Row>
            </FormGroup>}
            
            {notificacionTemplate && (!notificacionTemplate.estrategia ||  notificacionTemplate.receiver === ADMINISTRADOR) && <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="receiver">{t('notificaciones:receiver.label')}:</Label>
                </Col>
                <Col md="8">
                  {!props.edit ?
                  <Input type="select" name="receiver" onChange={onChange}>
                    <option value="">{t("common:option.label")}</option>
                    {notificacionTemplate.receiver in props.possibleReceivers ?
                    props.possibleReceivers[notificacionTemplate.receiver].map((n, index) => <option key={index} value={n.id}>{n.username}</option>) :
                    null}
                  </Input> :
                  <span>{notificacion.receiver.username}</span>}
                </Col>
              </Row>
            </FormGroup>}

            {notificacionTemplate && notificacionTemplate.follower && <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="receiver">{t('notificaciones:follower.label')}:</Label>
                </Col>
                <Col md="8">
                  {!props.edit ?
                  <Input type="select" name="follower" onChange={onChange}>
                    <option value="">{t("common:option.label")}</option>
                    {notificacionTemplate.follower in props.possibleReceivers ?
                    props.possibleReceivers[notificacionTemplate.follower].map((n, index) => <option key={index} value={n.id}>{n.username}</option>) :
                    null}
                  </Input> :
                  <span>{notificacion.follower.username}</span>}
                </Col>
              </Row>
            </FormGroup>}            

        </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={toggle}>
              {t("common:cancel.label")}
            </Button>
            <Button type="button" color="primary" onClick={addNotificacion}>
              {t("common:add.label")}
            </Button>
        </ModalFooter>
    </Modal>
    </>;
}


const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'NOTIFICACIONES',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'NOTIFICACIONES' && state.errores.status !== 200 ? true: false,
});


export default connect(mapStateToProps, {addNotificacion, setManualErrores, markNotificacionAsCompleted})(NewNotificacionModal);