import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

const PrivateRoute = ({component: ChildComponent, cuentas, ...rest}) => (
    <Route 
    {...rest}
    render={props => {
      if (cuentas.isLoading) {
        return <Spinner animation="border" variant="primary"/>;
      } else if (!cuentas.isAuthenticated) {
        return <Redirect to={{pathname: '/login', state: { originalRoute: `${props.location.pathname}${props.location.search}` }}} />;
      }
        else {
          return <ChildComponent {...props} />
        }
      }
    }
 />
)


  const mapStatetoProps = state => ({
      cuentas: state.cuentas
  });

  export default connect(mapStatetoProps, {})(PrivateRoute);