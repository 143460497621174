export const ADD_ESTRATEGIA = 'ADD_ESTRATEGIA';
export const UPDATE_ESTRATEGIA = 'UPDATE_ESTRATEGIA';
export const LIST_ESTRATEGIAS = 'LIST_ESTRATEGIAS';
export const LIST_ESTRATEGIAS_LOADING = 'LIST_ESTRATEGIAS_LOADING';
export const LIST_ESTRATEGIAS_BY_CLIENTE = 'LIST_ESTRATEGIAS_BY_CLIENTE';
export const LIST_ESTRATEGIAS_BY_CLIENTE_LOADING = 'LIST_ESTRATEGIAS_BY_CLIENTE_LOADING';
export const LIST_ASIGNACIONES = 'LIST_ASIGNACIONES';
export const LIST_ASIGNACIONES_LOADING = 'LIST_ASIGNACIONES_LOADING';
export const ADD_ASIGNACION = 'ADD_ASIGNACION';
export const UPDATE_ASIGNACION = 'UPDATE_ASIGNACION';
export const LOADING_ASIGNACION_OR_ESTRATEGIA = 'LOADING_ASIGNACION_OR_ESTRATEGIA';
export const LIST_HISTORIC_ESTRATEGIAS = 'LIST_HISTORIC_ESTRATEGIAS';
export const LIST_HISTORIC_ESTRATEGIAS_LOADING = 'LIST_HISTORIC_ESTRATEGIAS_LOADING';
export const LIST_ASIGNACIONES_BY_CLIENTE = 'LIST_ASIGNACIONES_BY_CLIENTE';
export const UPDATE_ESTIMACION = 'UPDATE_ESTIMACION';
export const ADD_ESTIMACION = 'ADD_ESTIMACION';
export const LIST_ESTRATEGIAS_ESTIMACIONES_LOADING = 'LIST_ESTRATEGIAS_ESTIMACIONES_LOADING';
export const LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES_LOADING = 'LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES_LOADING';
export const LIST_ESTRATEGIAS_ESTIMACIONES = 'LIST_ESTRATEGIAS_ESTIMACIONES';
export const LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES = 'LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES';
export const LIST_ACTIVE_ASIGNACIONES = 'LIST_ACTIVE_ASIGNACIONES';
export const LIST_ACTIVE_ASIGNACIONES_LOADING = 'LIST_ACTIVE_ASIGNACIONES_LOADING';
export const UPDATE_HISTORIC_ESTRATEGIA = 'UPDATE_HISTORIC_ESTRATEGIA';
export const LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES_LOADING = 'LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES_LOADING';
export const LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES = 'LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES';
export const CLOSE_ESTRATEGIA = 'CLOSE_ESTRATEGIA';
export const LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID_LOADING = 'LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID_LOADING';
export const LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID = 'LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID';
export const ADD_OBJETIVO = 'ADD_OBJETIVO';
export const ADD_OBJETIVO_RESUMEN = 'ADD_OBJETIVO_RESUMEN';
export const LIST_ESTRATEGIAS_CANPER_IDS_LOADING = 'LIST_ESTRATEGIAS_CANPER_IDS_LOADING';
export const LIST_ESTRATEGIAS_CANPER_IDS = 'LIST_ESTRATEGIAS_CANPER_IDS';
