import React, {Component, Fragment} from 'react';
import { Provider } from 'react-redux'
import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';

import store from './redux/store'
import { loadUser } from "./redux/actions/cuentas"
import Home from "./components/Home";
import SignIn from "./components/cuentas/SignIn";
import SignUp from "./components/cuentas/SignUp";
import PrivateRoute from "./components/common/PrivateRoute";
import NotFound from "./components/common/NotFound";
import Alerts from "./components/common/Alerts"
import ProfileView from './components/clientes/ProfileView';
import ComercialesView from './components/comerciales/ComercialesView'
import RaspberriesView from './components/raspberries/RaspberriesView'
import DisplayRaspberry from './components/raspberries/DisplayRaspberry';
import BookmakersView from './components/bookmakers/BookmakersView'
import ConexionesprovView from './components/conexionesprov/ConexionesprovView';
import DisplayBookmaker from './components/bookmakers/DisplayBookmaker';
import ApuestasView from './components/apuestas/ApuestasView';
import ContabilidadesView from './components/contabilidades/contabilidad/ContabilidadesView'
import ContabilidadesHistoricView from './components/contabilidades/historico/ContabilidadesHistoricView';
import EstrategiasView from './components/estrategias/asignacion/EstrategiasView'
import EstrategiasHistoricView from './components/estrategias/historico/EstrategiasHistoricView'
import EstimacionesView from './components/estrategias/estimacion/EstimacionesView';
import NotificacionesView from './components/notificaciones/NotificacionesView';
import ResumenTraderView from './components/resumen/ResumenTraderView'
import ResumenGestorView from './components/resumen/ResumenGestorView'
import ResumenAdministradorView from './components/resumen/administrador/ResumenAdministradorView'
import ErrorFallback from './components/common/ErrorFallBack';
import ClientesView from './components/clientes/ClientesView';
import DisplayCliente from './components/clientes/DisplayCliente';


// Alert Options
const alertOptions = {
  timeout: 5000,
  position: positions.BOTTOM_RIGHT,
  containerStyle:{
    zIndex: 3000
  }
};

class App extends Component {

  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (      
      <Provider store={store}>
      <Router>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <ErrorFallback>
          <Fragment>
            <Alerts />
              <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/bookmakers" component={BookmakersView} />
                <PrivateRoute exact path="/notificaciones" component={NotificacionesView} />
                <PrivateRoute exact path="/comerciales" component={ComercialesView} />
                <PrivateRoute exact path="/raspberries" component={RaspberriesView} />
                <PrivateRoute exact path="/conexionesprov" component={ConexionesprovView} />
                <PrivateRoute exact path="/contabilidades" component={ContabilidadesView} />
                <PrivateRoute exact path="/contabilidades_historico" component={ContabilidadesHistoricView} />
                <PrivateRoute exact path="/estrategias" component={EstimacionesView} />
                <PrivateRoute exact path="/estrategias_historico" component={EstrategiasHistoricView} />
                <PrivateRoute exact path="/asignaciones" component={EstrategiasView} />
                <PrivateRoute path="/raspberries/:id" component={DisplayRaspberry} />
                <PrivateRoute path="/bookmakers/:id" component={DisplayBookmaker} />
                <PrivateRoute exact path="/apuestas" component={ApuestasView} />
                <PrivateRoute exact path="/resumen_g" component={ResumenGestorView} />
                <PrivateRoute exact path="/resumen_t" component={ResumenTraderView} />
                <PrivateRoute exact path="/resumen_a" component={ResumenAdministradorView} />
                <PrivateRoute exact path="/perfil" component={ProfileView} />
                <PrivateRoute exact path="/clientes" component={ClientesView} />
                <PrivateRoute path="/clientes/:id" component={DisplayCliente} />
                <Route exact path="/login" component={SignIn} />
                <Route exact path="/register" component={SignUp} />
                <Route path="*" component={NotFound} />
              </Switch>
          </Fragment>
          </ErrorFallback>
        </AlertProvider>
      </Router>
      </Provider>
    );
  }
}

export default App;
