import { LIST_CLIENTES, LIST_CLIENTE, REMOVE_CLIENTE, UPDATE_CLIENTE, ADD_CLIENTE, LIST_SUMMARY_CLIENTES, LIST_SUMMARY_CLIENTES_LOADING, LIST_ACTIVE_CLIENTES, LIST_ACTIVE_CLIENTES_LOADING, LIST_CLIENTES_LOADING } from "../types/clientes";
import { ADD_CLIENTE_BOOKMAKER, REMOVE_CLIENTE_BOOKMAKER, UPDATE_CLIENTE_BOOKMAKER, LIST_CLIENTE_BOOKMAKERS_BY_CLIENTE_IDS, LIST_CLIENTE_BOOKMAKERS_BY_CLIENTE_ID } from "../types/clientes_bookmakers";
import { ADD_BANCO, UPDATE_BANCO, REMOVE_BANCO, LIST_BANCO_BY_CLIENTE_ID } from "../types/banco";
import { ADD_MONEDERO, UPDATE_MONEDERO, REMOVE_MONEDERO, LIST_MONEDEROS_BY_CLIENTE_ID } from "../types/monederos";
import {ADD_FICHERO, LIST_FICHEROS_BY_CLIENTE_ID, REMOVE_FICHERO} from "../types/ficheros"
import {LIST_RETIRADAS, UPDATE_RETIRADA, ADD_RETIRADA, REMOVE_RETIRADA, LIST_RETIRADAS_BY_BOOKMAKER_ID, LIST_RETIRADAS_BY_CLIENTE_ID } from "../types/retiradas";
import {LIST_INGRESOS, UPDATE_INGRESO, ADD_INGRESO, REMOVE_INGRESO, LIST_INGRESOS_BY_BOOKMAKER_ID, LIST_INGRESOS_BY_CLIENTE_ID } from "../types/ingresos";
import {LIST_CONEXION_BY_CLIENTE_ID} from "../types/conexiones"
import {LIST_RASPBERRY_BY_CLIENTE_ID} from "../types/raspberries"
import { LIST_ESTRATEGIAS_BY_CLIENTE, LIST_ASIGNACIONES_BY_CLIENTE } from "../types/estrategias";
import { LIST_PRIORIDADES_BOOKMAKERS_BY_CLIENTE_ID, LIST_PRIORIDADES_BY_CLIENTE } from "../types/bookmakers";
import { ARCHIVE_CLIENTE } from "../types/contabilidades";


const initialState = {
    clientes: null
};

export default function(state = initialState, action) {
    switch(action.type) {

        // Clientes reducers
        case REMOVE_CLIENTE:
            return {
                ...state,
                // Devuelve todos los clientes excepto el borrado
                clientes: state.clientes.filter(cliente => cliente.id !== action.payload)
            }
        case LIST_SUMMARY_CLIENTES:
        case LIST_CLIENTES:
            return {
                ...state,
                clientes: action.payload,
                isLoading: false
            }
        case LIST_SUMMARY_CLIENTES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case ADD_CLIENTE:
            return {
                ...state,
                clientes: [...(state.clientes || []), action.payload],
                cliente:  action.payload
            }
        case LIST_ACTIVE_CLIENTES:
            return {
                ...state,
                activeClientes: action.payload,
                isLoadingActiveClientes: false
            }
        case LIST_ACTIVE_CLIENTES_LOADING:
            return {
                ...state,
                isLoadingActiveClientes: true
            }

        case ARCHIVE_CLIENTE:
            return {
                ...state,
                activeClientes: (state.activeClientes || []).filter(c => c.id !== action.payload.id)
            }

        // Cliente reducers
        case UPDATE_CLIENTE:
            return {
                ...state,
                cliente: action.payload
            }
        case LIST_CLIENTE:
            return {
                ...state,
                cliente: action.payload,
                isLoading: false
            }

        case LIST_CLIENTES_LOADING:
            return {
                ...state,
                isLoading: true
            }

        // Bookmakers reducers
        case REMOVE_CLIENTE_BOOKMAKER:
            return {
                ...state,
                bookmakers: state.bookmakers.filter(b => b.id !== action.payload)
            }
        case ADD_CLIENTE_BOOKMAKER:
            return {
                ...state,
                bookmakers: [...state.bookmakers, action.payload]
            }
        case UPDATE_CLIENTE_BOOKMAKER:
            return {
                ...state,
                bookmakers: (state.bookmakers || []).map(b => b.id === action.payload.id ? action.payload : b)
            }
        case LIST_CLIENTE_BOOKMAKERS_BY_CLIENTE_ID:
            return {
                ...state,
                bookmakers: action.payload
            }
        case LIST_CLIENTE_BOOKMAKERS_BY_CLIENTE_IDS:
            let bookmakersByClientesIds = {};
            // eslint-disable-next-line
            action.payloads.map(function(b) {
                bookmakersByClientesIds = {
                    ...bookmakersByClientesIds,
                    [b.cliente]: b
                }
            })
            
            return {
                ...state,
                bookmakersByClientesIds: {
                    ...state.bookmakersByClientesIds,
                    ...bookmakersByClientesIds
                }
            }        

         // Ingresos
         case UPDATE_INGRESO:
            return {
                ...state,
                bookmaker_ingresos: state.bookmaker_ingresos.map(c => c.id === action.payload.id ? action.payload : c)
            }

        case LIST_INGRESOS:
        case LIST_INGRESOS_BY_BOOKMAKER_ID:
        case LIST_INGRESOS_BY_CLIENTE_ID:
            return {
                ...state,
                bookmaker_ingresos: action.payload
            }

        case ADD_INGRESO:
            return {
                ...state,
                bookmaker_ingresos: [...state.bookmaker_ingresos, action.payload]
            }

        case REMOVE_INGRESO:
            return {
                ...state,
                bookmaker_ingresos: state.bookmaker_ingresos.filter(c => c.id !== action.payload)
            }

        // retiradas
        case UPDATE_RETIRADA:
            return {
                ...state,
                bookmaker_retiradas: state.bookmaker_retiradas.map(c => c.id === action.payload.id ? action.payload : c)
            }

        case LIST_RETIRADAS:
        case LIST_RETIRADAS_BY_BOOKMAKER_ID:
        case LIST_RETIRADAS_BY_CLIENTE_ID:
            return {
                ...state,
                bookmaker_retiradas: action.payload
            }

        case ADD_RETIRADA:
            return {
                ...state,
                bookmaker_retiradas: [...state.bookmaker_retiradas, action.payload]
            }

        case REMOVE_RETIRADA:
            return {
                ...state,
                bookmaker_retiradas: state.bookmaker_retiradas.filter(c => c.id !== action.payload)
            }


        // Monederos reducers
        case REMOVE_MONEDERO:
            return {
                ...state,
                monederos: state.monederos.filter(b => b.id !== action.payload)
            }
        case ADD_MONEDERO:
            return {
                ...state,
                monederos: [...state.monederos, action.payload]
            }
        case UPDATE_MONEDERO:
            return {
                ...state,
                monederos: state.monederos.map(b => b.id === action.payload.id ? action.payload : b)
            }
        case LIST_MONEDEROS_BY_CLIENTE_ID:
            return {
                ...state,
                monederos: action.payload
            }

        // Banco reducers
        case REMOVE_BANCO:
            return {
                ...state,
                banco: null
            }
        case UPDATE_BANCO:
        case ADD_BANCO:
            return {
                ...state,
                banco: action.payload
            }
        case LIST_BANCO_BY_CLIENTE_ID:
            return {
                ...state,
                banco: action.payload
            }

        // Fichero reducers
        case LIST_FICHEROS_BY_CLIENTE_ID:
            return {
                ...state,
                ficheros: action.payload
            }
        case ADD_FICHERO:
            return {
                ...state,
                ficheros: [...state.ficheros, action.payload]
            }
        case REMOVE_FICHERO:
            return {
                ...state,
                ficheros: state.ficheros.filter(f => f.id !== action.payload)
            }

        case LIST_CONEXION_BY_CLIENTE_ID:
            return {
                ...state,
                conexion: action.payload
            }
        case LIST_RASPBERRY_BY_CLIENTE_ID:
            return {
                ...state,
                raspberry: action.payload
            }

        case LIST_ESTRATEGIAS_BY_CLIENTE:
            return {
                ...state,
                estrategias: action.payload
        }
        case LIST_ASIGNACIONES_BY_CLIENTE:
            return {
                ...state,
                asignaciones: action.payload
            }

        case LIST_PRIORIDADES_BY_CLIENTE:
            return {
                ...state,
                prioridades: action.payload
            }

        default:
            return state;
    }
}
