const COLS = {
    'canper_id': 'ID',
    'trader': 'OP',
    'bookmaker': 'BMKR',
    'usuario': 'USER',
    'p_l': 'P/L',
    's_m': 'S/M',
    'procedencia': 'SOURCE',
    'beneficio': 'PROFIT',
    'fecha': 'DATE',
    'apuesta': 'STAKE',
    'sobrante': 'ODD',
    'potencial': 'POTENTIAL',
    'devuelto': 'RETURN',
    'mercado': 'MARKET',
    'evento': 'EVENT',
    'estado': 'STATUS',
    'observaciones': 'COMMENTS'
}


const buildData = (apuestas) => {
    return apuestas.map(function(a) {
        let payload = {};
        Object.entries(a).forEach(([key, value]) => {
            if (COLS.hasOwnProperty(key)) {
                const col_name = COLS[key];
                const col_value = key === 'bookmaker' && value ? value.bookmaker : key === 'trader' && value ? value.nick : value
                payload[col_name] = col_value ? col_value : '-';
            }
        });
        return payload
    });
}


export const exportApuestas = (apuestas, selectedApuestas) => {
    let data = {};
    const apuestasSelection = apuestas.filter((c) => selectedApuestas.includes(c.id));
    data['RECORD'] = buildData(apuestasSelection)
    return data;
}