import { API_BANCO_URL, API_CLIENTES_URL } from "../../constants";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "../actions/mensajes";
import { setErrores, setErroresLoading } from "../actions/errores";
import i18n from '../../translations/i18n';
import { REMOVE_BANCO, ADD_BANCO, UPDATE_BANCO, LIST_BANCO_BY_CLIENTE_ID } from "../types/banco";

const BANCO = 'BANCO';


// Add banco
export const addBanco = (banco) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, BANCO));
    return axios
        .post(`${API_BANCO_URL}`, banco, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_BANCO,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("banco:added_bank.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, BANCO));
        });
};


// Remove banco
export const removeBanco = id => (dispatch, getState) => {
    axios
        .delete(`${API_BANCO_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_BANCO,
                payload: id
            });
            dispatch(createMessage(i18n.t("banco:remove_bank.message")));
        })
        .catch(err => console.log(err));
};

// Update banco
export const updateBanco = (id, banco) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, BANCO));
    axios
        .put(`${API_BANCO_URL}${id}/`, banco, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_BANCO,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("banco:updated_bank.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, BANCO));
        });
};


// List banco
export const listBancoByClienteId = (clienteId) => (dispatch, getState) => {
    axios
        .get(`${API_CLIENTES_URL}${clienteId}/banco/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_BANCO_BY_CLIENTE_ID,
                payload: res.data
            });
        })
        .catch(err => {
            if (err.response.status === 403) {
                dispatch({
                    type: LIST_BANCO_BY_CLIENTE_ID,
                    payload: 'unauthorized'
                });
            }
            console.log(err);
        });
};
