import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import PageNotFound from '../../assets/images/PageNotFound.jpg';


function NotFound() {
    
    let history = useHistory();

    return (
    <div>
        <img alt="Not Found" src={PageNotFound} style={{ width: 600, height: 500, display: 'block', margin: 'auto', position: 'relative' }} />
        <br/>
        <center>
            <Button color="primary" size="md" onClick={() => history.push('/')}> Home </Button>
        </center>
    </div>);
}

export default NotFound;