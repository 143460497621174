import React from "react";
import { connect } from "react-redux";
import Header from "../../common/Header";
import BreadCrumbs from "../../common/BreadCrumbs";
import { listEstrategiasActualesWithEstimaciones } from "../../../redux/actions/estrategias"
import { listPrioridadesByTrader } from "../../../redux/actions/bookmakers"
import { withTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import ListEstimacionesAccordions from "./ListEstimacionesAccordions";


class EstimacionesView extends React.Component {

    componentDidMount() {
        !this.props.estrategias && this.props.listEstrategiasActualesWithEstimaciones();        
        !this.props.prioridades && this.props.listPrioridadesByTrader();
    }


    render() {
        const { t } = this.props;
        return (
            <>
            <Header />
            <Container fluid>
                <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'name': t('estrategias:strategy.label'), 'active': true},
                {'name': t('contabilidades:active.label'), 'active': true},
                ]}/>
                { this.props.isLoadingEstimaciones === false && this.props.isLoadingPrioridades === false ?
                <ListEstimacionesAccordions  estimaciones={this.props.estimaciones} prioridades={this.props.prioridades}/> :
                <Spinner animation="border" variant="primary"/>}
            </Container>
            </>
        );
    }
}


const mapStateToProps = state => ({   
    estimaciones: state.estrategias.actualesEstimaciones,
    prioridades: state.bookmakers.prioridades,
    isLoadingPrioridades: state.bookmakers.isLoadingPrioridades,
    isLoadingEstimaciones: state.estrategias.isLoadingActualesEstimaciones
});

export default connect(mapStateToProps, {listEstrategiasActualesWithEstimaciones, listPrioridadesByTrader})(withTranslation('estimaciones')(EstimacionesView));