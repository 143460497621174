import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import i18n (needs to be bundled ;)) 
import i18n from './translations/i18n';
import {I18nextProvider} from 'react-i18next';
import "./helpers/ModalHotFix"

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const rootElement = document.getElementById('root')
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  rootElement
)
