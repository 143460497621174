import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CloseButton, CustomButton } from '../../common/buttons/Buttons';
import {setManualErrores} from "../../../redux/actions/errores"
import { connect } from "react-redux";
import {archiveCliente} from "../../../redux/actions/contabilidades";
import {patchAsignacion} from "../../../redux/actions/estrategias"
import { nowDatetime } from '../../../constants';


function ArchiveEnlaceModal(props) {
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);

    const { t } = useTranslation();

    const archive = () => {
        const asignacion = props.asignaciones.find(a => a.cliente.id ===  props.clienteId)
        if (asignacion) {
            const asignacionId = asignacion.id;
            props.patchAsignacion(asignacionId, {fecha_fin: nowDatetime})
        }
        props.archiveCliente(props.clienteId, {estado: 'ARCHIVADO'})
        toggle();
    }

    return (
        <>
            {props.permissions.includes('change') && <CustomButton onClick={toggle} style={{margin: "1px"}} color={"secondary"} label={t("contabilidades:archive.label")} />}
            <Modal isOpen={modal} toggle={toggle} backdrop="static">
                <ModalHeader toggle={toggle}>
                    {t("contabilidades:archive.label")} {t("contabilidades:link.label")}
                </ModalHeader>
                <ModalBody>
                    {t("contabilidades:archive.message")}
                </ModalBody>

                <ModalFooter>
                    <CloseButton onClick={toggle} style={{margin: "1px"}} color={"primary"} label={t("common:cancel.label")} />
                    <CloseButton color={"secondary"} onClick={archive} label= {t("contabilidades:archive.label")} style={{margin: "1px"}} /> 
                  </ModalFooter>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.contabilidad,
    asignaciones: state.estrategias.activeAsignaciones,
  });

export default connect (mapStateToProps, {setManualErrores, patchAsignacion, archiveCliente})(ArchiveEnlaceModal);