import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { paginationOptions, getOpciones } from '../common/BootstrapTableHelper';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { dateFromUStoEU, datetimeFromUStoEU } from '../../helpers/helpers';
import { GESTOR, TRADER } from '../../constants';


export default function ListHistoricNotificaciones(props) {

    const filters = React.useState({
        sender: localStorage.getItem('filters.notificaciones.sender'),
        receiver: localStorage.getItem('filters.notificaciones.receiver'),
        follower: localStorage.getItem('filters.notificaciones.follower'),
        completed: localStorage.getItem('filters.notificaciones.completed'),
        priority: localStorage.getItem('filters.notificaciones.priority'),
        header: localStorage.getItem('filters.notificaciones.header'),
        body: localStorage.getItem('filters.notificaciones.body'),
        context: localStorage.getItem('filters.notificaciones.context'),
        fecha: localStorage.getItem('filters.notificaciones.fecha')       
    });
    const { t } = useTranslation();
    
    let senderFilter = undefined;
    let receiverFilter = undefined;
    let followerFilter = undefined;
    let completedFilter = undefined;
    let priorityFilter = undefined;
    let headerFilter = undefined;
    let bodyFilter = undefined;
    let contextFilter = undefined;
    let fechaFilter = undefined;

    const clearFilters = () => {
        senderFilter('');
        receiverFilter('');
        followerFilter('');
        completedFilter('');
        priorityFilter('');
        headerFilter('');
        bodyFilter('');
        contextFilter('');
        fechaFilter('');
    }

    const dedup = notificaciones => {
        const seen = new Set();

        return notificaciones.filter(el => {
            const duplicate = seen.has(el.id);
            seen.add(el.id);
            return !duplicate;
          });
    }

    const extractContext = context => {
      const filteredContext = props.userGroup === TRADER ? Object.entries(context).filter(([k, v]) => k !== 'enlace') :
        props.userGroup === GESTOR ? Object.entries(context).filter(([k, v]) => k !== 'estrategia') : 
        Object.entries(context)
  
      return filteredContext.map(([key, value]) => `${t(`notificaciones:${key}.context`)}:${value}`).join(' / ')
    }

    const transformNotificacionesIntoTable = (notificaciones) => {
        return notificaciones.map(n => ({
            'header': t(`notificaciones:${n.payload.header}.header`),
            'priority_label': n.priority === 0 ? t("notificaciones:NORMAL.prioridad") : t("notificaciones:URGENT.prioridad"),
            'completed_label': n.completed ? t("notificaciones:completed.label") : t("notificaciones:incompleted.label"),
            'context': n.payload.context ? extractContext(n.payload.context) : "",
            ...n
        }))
    }

    const notificaciones = dedup(props.notificaciones);
    const rows = transformNotificacionesIntoTable(notificaciones);

    const headers = [
        {
          text: t('notificaciones:sender.label'), 
          dataField: 'sender.username',
          filter: textFilter({
            defaultValue: filters.sender,
            getFilter: (filter) => {
              senderFilter = filter;
            },
            onFilter: (value) => {
              localStorage.setItem('filters.notificaciones.sender', value);
            },
          }),
          sort: true
        },
        {
            text: t('notificaciones:receiver.label'), 
            dataField: 'receiver.username',
            filter: textFilter({
              defaultValue: filters.receiver,
              getFilter: (filter) => {
                receiverFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.notificaciones.receiver', value);
              },
            }),
            sort: true
          },
          {
            text: t('notificaciones:follower.label'), 
            dataField: 'follower.username',
            filter: textFilter({
              defaultValue: filters.follower,
              getFilter: (filter) => {
                followerFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.notificaciones.follower', value);
              },
            }),
            sort: true
          },
          {
            text: t('notificaciones:complete.label'), 
            dataField: 'completed_label',
            filter: selectFilter({
                defaultValue: filters.completed,
                options: getOpciones('completed_label', rows),
                getFilter: (filter) => {
                    completedFilter = filter;
                },
                onFilter: (value) => {
                  localStorage.setItem('filters.notificaciones.completed', value);
                },
            }),
            sort: true
          },
          {
            text: t('notificaciones:priority.label'), 
            dataField: 'priority_label',
            filter: selectFilter({
                defaultValue: filters.priority,
                options: getOpciones('priority_label', rows),
                getFilter: (filter) => {
                    priorityFilter = filter;
                },
                onFilter: (value) => {
                  localStorage.setItem('filters.notificaciones.priority', value);
                },
            }),
            sort: true
          },
          {
            text: t('notificaciones:header.label'), 
            dataField: 'header',
            filter: selectFilter({
                defaultValue: filters.header,
                options: getOpciones('header', rows),
                getFilter: (filter) => {
                  headerFilter = filter;
                },
                onFilter: (value) => {
                  localStorage.setItem('filters.notificaciones.header', value);
                },
            }),
            sort: true
          },
          {
            text: t('notificaciones:attached.label'), 
            dataField: 'context',
            filter: textFilter({
              defaultValue: filters.context,
              getFilter: (filter) => {
                contextFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.notificaciones.context', value);
              },
            }),
            sort: true
          },
          {
            text: t('notificaciones:body.label'), 
            dataField: 'payload.body',
            filter: textFilter({
              defaultValue: filters.body,
              getFilter: (filter) => {
                bodyFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.notificaciones.body', value);
              },
            }),
            sort: true
          },
          {
            text: t('common:date.label'), 
            dataField: 'actualizado_a',
            formatter: (cell, row) => datetimeFromUStoEU(row.actualizado_a),
            filter: dateFilter({
              defaultValue: filters.fecha,
              getFilter: (filter) => {
                fechaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.notificaciones.fecha', value);
              },
            }),
            sort: true
          },
    ]

    return <PaginationProvider  pagination={ paginationFactory(paginationOptions(notificaciones.length)) }>
    {
      ({
        paginationProps,
        paginationTableProps
      }) => (
        <>
        <div className="pagination-table">
          <Row >
            <Col>
              <Button color="secondary" size="sm" onClick={clearFilters}>{t('common:remove_filters.label')}</Button>
            </Col>
            <Col className="col-auto">
              <PaginationListStandalone
                { ...paginationProps }
              />
            </Col>
            <Col className="col-auto">
              <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
            </Col>
          </Row>
        </div>
        <BootstrapTable
            bootstrap4
            hover
            condensed
            bordered={ false }
            keyField='id'
            data={rows}
            columns={ headers }
            filter={ filterFactory() }
            defaultSorted={ [{dataField: 'id', order: 'asc'}] }
            wrapperClasses="historico-notificaciones-datatable"
            { ...paginationTableProps }
            />
        </>
        )}
    </PaginationProvider>
}
