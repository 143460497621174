import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {rootReducer} from './reducers';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync'
import { ADD_ASIGNACION, ADD_ESTRATEGIA } from './types/estrategias';

const initialState = {};
const config = {
    // Only this actions will be triggered in other tabs
    whitelist: [ADD_ASIGNACION, ADD_ESTRATEGIA],
}
const middlewares = [createStateSyncMiddleware(config)];
const middleware = [thunk];

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware), applyMiddleware(...middlewares))
);
initStateWithPrevTab(store)

export default store;