import React from "react";
import { connect } from "react-redux";
import Header from "../common/Header";
import BreadCrumbs from "../common/BreadCrumbs";
import { listComerciales } from "../../redux/actions/comerciales"
import { withTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import ListComerciales from "./ListComerciales";


class ComercialesView extends React.Component {

    componentDidMount() {
        if (!this.props.comerciales) {
            this.props.listComerciales()
        }
    }


    render() {
        return (
            <>
            <Header />
            <Container fluid>
                <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'href': '/#/comerciales', 'name': 'Comerciales', 'active': true},
                ]}/>
                { this.props.isLoading !== false ? <Spinner animation="border" variant="primary"/> :
                <ListComerciales comerciales={this.props.comerciales}/>}
            </Container>
            </>
        );
    }
}


const mapStateToProps = state => ({
    comerciales: state.comerciales.comerciales,
    isLoading: state.comerciales.isLoading
});

export default connect(mapStateToProps, {listComerciales})(withTranslation('comerciales')(ComercialesView));