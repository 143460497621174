
import React from "react";
import {Button} from "reactstrap";

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import HistoryIcon from '@material-ui/icons/History';


//Creo que todas las props pueden simplificarse en una deconstrucción de props 
export const DeleteButton = (props ,{color="danger"}) => {
    return <Button {...props}   color={props.color ? props.color : color} size={"sm"}> {  props.label ? props.label: <DeleteOutlineOutlinedIcon />}</Button>;
}

export const AddButton = (props, {color="primary"}) => {
    return <Button {...props}  color={props.color ? props.color : color} size={"sm"} >{ props.label ? props.label:<AddBoxOutlinedIcon />}</Button>;
}

export const EditButton = (props,{color="warning" }) => {
    return <Button {...props}  color={props.color ? props.color : color} size={"sm"} >{ props.label ? props.label:<EditOutlinedIcon />}</Button>;
}

export const CloseButton = (props,{color="outline-secondary"}) => {
    return <Button {...props}   color={props.color ? props.color : color} size={"sm"} >{ props.label ? props.label:<CancelOutlinedIcon />}</Button>;
}

export const CustomButton = (props, {color="outline-secondary"}) => {
    return <Button {...props}  color={props.color ? props.color : color} size={"sm"} >{ props.label ? props.label:<HistoryIcon />}</Button>;
}
