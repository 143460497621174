import axios from "axios";
import { tokenConfig } from "./cuentas";
import { API_PERMISSIONS_URL, API_PERMISSIONS_GROUP_URL } from "../../constants";
import { LIST_USER_PERMISSIONS, LIST_USER_PERMISSIONS_GROUP } from "../types/permissions";


export const listPermissions = () => (dispatch, getState) => {
    axios
        .get(`${API_PERMISSIONS_URL}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_USER_PERMISSIONS,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err);
        });
};


export const listPermissionsGroup = () => (dispatch, getState) => {
    axios
        .get(`${API_PERMISSIONS_GROUP_URL}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_USER_PERMISSIONS_GROUP,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err);
        });
};