import React, {useEffect } from "react";
import { connect } from 'react-redux';
import {listConexionprovByCliente} from '../../../redux/actions/conexionesprov';
import CopyToClipboard from "../../common/CopyToClipboard";
import {  Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';




function DisplayConexionprov(props) {
    const { t } = useTranslation();

    
    useEffect(() => {
        (!props.conexiones || !props.conexiones.hasOwnProperty(props.clienteId)) && props.listConexionprovByCliente(props.clienteId)
    },[])
    
    return <>
        {props.conexiones && props.conexiones.hasOwnProperty(props.clienteId) && props.conexiones[props.clienteId].map((conexion, i) =>
            <Col sm="12" md="6" className={props.conexiones[props.clienteId][i+1] && "border-right"}>
                <Row>
                    <Col className="col-info" md="3">Anydesk id</Col>
                    <Col className="col-info-result" md="3" ><CopyToClipboard> {conexion.anydesk_id}</CopyToClipboard></Col>
                
                    <Col className="col-info" md="2">Ip id</Col>
                    <Col className="col-info-result"  md="4" ><CopyToClipboard>{conexion.ip_id}</CopyToClipboard></Col>
                </Row>

                <Row>
                    <Col className="col-info" md="3">{t('conexionesprov:anydesk_password.label')}</Col>
                    <Col className="col-info-result"md="3"><CopyToClipboard>{conexion.anydesk_contraseña}</CopyToClipboard></Col>
                    
                    <Col className="col-info" md="2">{t("conexionesprov:key_ip.label")}</Col>
                    <Col className="col-info-result" md="4"><CopyToClipboard>{conexion.clave_ip_id}</CopyToClipboard></Col>
                </Row>

                <Row>
                    <Col className="col-info" md="3" >Ip</Col>
                    <Col className="col-info-result" md="3"><CopyToClipboard>{conexion.ip}</CopyToClipboard></Col>
                </Row>
            </Col>                    
        )}
    </>;
}



const mapStateToProps = state => ({
    conexiones: state.conexionesprov.conexionesprovByCliente,
});


export default connect(mapStateToProps, {listConexionprovByCliente})(DisplayConexionprov);
