import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { ListItemText, Tabs } from '@material-ui/core';
import { AddButton, CloseButton } from "../buttons/Buttons";
import { AppBar } from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Notificaciones from "./Notificaciones";
import { listChannelsByUser, updateChannels, createChannels } from "../../../redux/actions/notificaciones"
import { patchProfile, patchUser } from "../../../redux/actions/cuentas";
import {setManualErrores} from "../../../redux/actions/errores"
import { makeStyles, useTheme } from '@material-ui/core/styles';



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      className={classes.appBar}
      {...other}
    >
      {value === index && (
          children
      )}
    </div>
  );
}


const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: 20
  },
}));

function SettingsModal(props) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [value, setValue] = React.useState(0);
  const [channels, setChannels] = React.useState({});
  const [profile, setProfile] = React.useState({});
  

  useEffect(() => {
    !props.channels && props.listChannelsByUser(props.userId)
  }, []);

  const toggle = () => setModal(!modal);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangeChannels = (name, checked) => {
    setChannels({ ...channels, [name]: checked });
  };

  const onChangeProfile = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  useEffect(() => {
    if (props.erroring === false && props.isLoading === false) {
      setModal(false);
    }
  }, [props.erroring, props.isLoading]);


  const isValid = () => {
    if (channels && channels.email && profile.email === '') {
      props.setManualErrores({'EMAIL': ['EMPTY VALUE']}, {'type': 'PROFILE'});
      return false;
    }
    return true;
  };

  const save = () => {
    if (isValid()) {
      if (channels && Object.keys(channels).length !== 0) {
        Object.entries(props.channels).length ? props.updateChannels(props.channels.id, { ...props.channels, ...channels }) : props.createChannels({ ...channels, user: props.userId });
      }

      if (profile.email && profile.email !== props.profile.email) {
        props.patchUser(props.profile.id, { 'email': profile.email });
      }

      // Para cuando el telefono se modifique, y futuros datos personales
      // if (profile && Object.keys(profile).length !== 0) {
      //   const payload = profile.email ? {...profile, 'user': {'email': profile.email}} : profile;
      //   props.patchProfile(props.profile.id, payload);
      // }
      // toggle();
    }
  }

  return <>
    <ListItemText onClick={toggle} disabled={props.channelsIsLoading || props.channelsIsLoading === undefined}>{t("common:settings.label")}</ListItemText>

    <Modal isOpen={modal} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>{t("common:settings.label")}</ModalHeader>
      <ModalBody>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} centered aria-label="simple tabs example">
            {/* <Tab disabled label={t("common:settings.label")} icon={<PersonIcon />} /> */}
            <Tab label={t("notificaciones:notifications.label")} icon={<NotificationsIcon />} />
          </Tabs>
        </AppBar>
        {/* <TabPanel value={value} index={0}>
                Item One
                </TabPanel> */}
        <TabPanel value={value} index={0}>
          {props.channelsIsLoading ? null : <Notificaciones channels={{ ...props.channels, ...channels }} onChangeChannels={onChangeChannels} onChangeProfile={onChangeProfile} profile={{ ...props.profile, ...profile }} />}
        </TabPanel>
      </ModalBody>
      <ModalFooter>
        <CloseButton onClick={toggle} label={t("common:cancel.label")} />
        <AddButton onClick={save} label={t("common:save.label")} />
      </ModalFooter>
    </Modal>
  </>;
}


const mapStateToProps = state => ({
  channels: state.notificaciones.notificacionesChannels,
  channelsIsLoading: state.notificaciones.notificacionesChannelsIsLoading,
  userId: state.cuentas.user.id,
  profile: { ...state.cuentas.profile, 'email': state.cuentas.user.email },
  erroring: state.errores && state.errores.status && state.errores.context.type === 'PROFILE' && state.errores.status !== 200 ? true: false,
  isLoading: state.errores.isLoading === 'PROFILE',
});


export default connect(mapStateToProps, { listChannelsByUser, updateChannels, createChannels, patchProfile, patchUser, setManualErrores })(SettingsModal);
