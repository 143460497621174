import React, { useState, useEffect }  from 'react';
import Datatable from "react-bs-datatable";
import { useTranslation } from 'react-i18next';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import {CustomButton} from "frontend/src/components/common/buttons/Buttons";
import { datetimeFromUStoEU } from '../../helpers/helpers';


function DisplayPagos(props) {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        props.modal && setModal(props.modal)        
    },[]);
    
    const header = [
        {title: t("pagos:payment.label"), prop: 'pago', sortable: true},
        {title: t("ingresos:date.label"), prop: 'fecha_eu', sortable: true},
    ];

    const customLabels = {
        noResults: 'No existen pagos'
    };

    const pagos = props.pagos.map(p => ({
        ...p,
        'fecha_eu': datetimeFromUStoEU(p.fecha),        
    }))

    return <>
        <CustomButton color="info" onClick={toggle} style={{margin: "1px"}}/>
        <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle}>{t("ingresos:history.label")}</ModalHeader>
            <ModalBody>
                <div className="horizontal-scroll">
                <Datatable
                    tableHeaders={header}
                    tableBody={pagos}
                    tableClass="striped responsive"
                    labels={customLabels}
                />
                </div>
            </ModalBody>
            <ModalFooter>
            <CustomButton onClick={toggle} label={t("common:close.label")} style={{margin: "1px"}}/>
            </ModalFooter>
        </Modal>
    </>
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.pago
});


export default connect(mapStateToProps)(DisplayPagos);
