export const getBalance = contabilidad => {
    //Si tiene fecha fin siempre debería tener saldo final, son cosas que van de la mano quiero repasarlo
    if (!contabilidad.saldo_final || !contabilidad.fecha_fin || !contabilidad.fecha_inicio) {
        return null
    }
    //Si la api devuelve ingresos y retiradas entre las fechas correspondientes porque añadimos otra condición?
    const ingresos = contabilidad.bookmaker.ingresos.reduce((acc, i) => i.fecha <= contabilidad.fecha_fin && (acc + parseFloat(parseFloat(i.transferencia))), 0)
    const retiradas = contabilidad.bookmaker.retiradas.reduce((acc, i) => i.fecha <= contabilidad.fecha_fin && (acc + parseFloat(parseFloat(i.transferencia))), 0)
    const saldoInicial = contabilidad.saldo_inicial ? parseFloat(contabilidad.saldo_inicial) : 0;
    const saldoFinal = contabilidad.saldo_final ? parseFloat(contabilidad.saldo_final) : 0;
    const balance = saldoFinal + retiradas - ingresos - saldoInicial;
    return balance.toFixed(2);
}

export const getBalanceSumary = contabilidad => {
    
    if (!contabilidad.saldo_final || !contabilidad.fecha_fin ) {

        const fecha_estimacion = contabilidad.estimaciones && contabilidad.estimaciones.length > 0 && new Date(contabilidad.estimaciones[contabilidad.estimaciones.length-1].fecha)
        const fecha_inicio = new Date(contabilidad.fecha_inicio)
        const ingresos = contabilidad.bookmaker.ingresos.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia)), 0)
        const retiradas = contabilidad.bookmaker.retiradas.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia)), 0)
        const saldoInicial = contabilidad.saldo_inicial ? parseFloat(contabilidad.saldo_inicial) : 0;
        const saldoFinal = fecha_estimacion && fecha_estimacion >= fecha_inicio ? parseFloat(contabilidad.estimaciones[contabilidad.estimaciones.length-1].estimacion) : 0;
        const balance = saldoFinal + retiradas - ingresos - saldoInicial;
        return balance.toFixed(2);
    }
    
    return getBalance(contabilidad);
}
