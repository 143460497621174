import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import NewApuestaModal from "./NewApuestaModal";
import { Col, Row, ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import DeleteApuestaModal from "./DeleteApuestaModal";
import ExportApuestasModal from "./exportacion/ExportApuestasModal";
import ImportApuestasModal from "./importacion/ImportApuestasModal";
import {datetimeFromUStoEU} from "../../helpers/helpers";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {paginationOptions, CustomToggleList, getOpciones, helperFormatter, addToCopyFormatter} from '../common/BootstrapTableHelper';

import {CustomButton} from "../common/buttons/Buttons";


const OBSERVACIONES_STRING_LIMIT = 17;


class ListApuestas extends React.Component {

    state = {
      dropdownOpen: false,
      selectedRows: [],
        filters: {
            canper_id: localStorage.getItem('filters.apuestas.canper_id'),
            trader: localStorage.getItem('filters.apuestas.trader'),
            bookmaker: localStorage.getItem('filters.apuestas.bookmaker'),
            usuario: localStorage.getItem('filters.apuestas.usuario'),
            p_l: localStorage.getItem('filters.apuestas.p_l'),
            s_m: localStorage.getItem('filters.apuestas.s_m'),
            procedencia: localStorage.getItem('filters.apuestas.procedencia'),
            beneficio: localStorage.getItem('filters.apuestas.beneficio'),
            fecha: localStorage.getItem('filters.apuestas.fecha'),
            apuesta: localStorage.getItem('filters.apuestas.apuesta'),
            sobrante: localStorage.getItem('filters.apuestas.sobrante'),
            potencial: localStorage.getItem('filters.apuestas.potencial'),
            devuelto: localStorage.getItem('filters.apuestas.devuelto'),
            mercado: localStorage.getItem('filters.apuestas.mercado'),
            evento: localStorage.getItem('filters.apuestas.evento'),
            estado: localStorage.getItem('filters.apuestas.estado'),
            observaciones: localStorage.getItem('filters.apuestas.observaciones'),            
          }
    }

    canper_idFilter;
    traderFilter;
    bookmakerFilter;
    usuarioFilter;
    p_lFilter;
    s_mFilter;
    procedenciaFilter;
    beneficioFilter;
    fechaFilter;
    apuestaFilter;
    sobranteFilter;
    potencialFilter;
    devueltoFilter;
    mercadoFilter;
    eventoFilter;
    estadoFilter;
    observacionesFilter;

    clearFilters = () => {
      this.canper_idFilter && this.canper_idFilter('');
      this.traderFilter && this.traderFilter('');
      this.bookmakerFilter && this.bookmakerFilter('');
      this.usuarioFilter && this.usuarioFilter('');
      this.p_lFilter && this.p_lFilter('');
      this.s_mFilter && this.s_mFilter('');
      this.procedenciaFilter && this.procedenciaFilter('');
      this.beneficioFilter && this.beneficioFilter('');
      this.fechaFilter && this.fechaFilter('');
      this.apuestaFilter && this.apuestaFilter('');
      this.sobranteFilter && this.sobranteFilter('');
      this.potencialFilter && this.potencialFilter('');
      this.devueltoFilter && this.devueltoFilter('');
      this.mercadoFilter && this.mercadoFilter('');
      this.eventoFilter && this.eventoFilter('');
      this.estadoFilter && this.estadoFilter('');
      this.observacionesFilter && this.observacionesFilter('');
    }

    toggle = () => {
      this.setState(previous => ({
          dropdownOpen: !previous.dropdownOpen
      }));
    };


    transformApuestasIntoTable = (apuestas, t) => {
        return apuestas.map((apuesta) => ({
        'fecha_eu': apuesta.fecha ? datetimeFromUStoEU(apuesta.fecha) : "",
        'observaciones_short': apuesta.observaciones,
        'observaciones_extended': apuesta.observaciones && apuesta.observaciones.length > OBSERVACIONES_STRING_LIMIT ? apuesta.observaciones : '',
        'trader_nick': apuesta.trader ? apuesta.trader.username : "",
        'bookmaker_name': apuesta.bookmaker ? apuesta.bookmaker.bookmaker : "",
        'acciones': <>
        {this.props.permissions.apuesta.includes("change") && <NewApuestaModal create={false} apuesta={apuesta} />}
        {this.props.permissions.apuesta.includes("delete") && <DeleteApuestaModal id={apuesta.id}/>}
        </>,
        ...apuesta
        }));
    };


    ObservacionesFormatter = (cell, row) => {
      return cell && cell.length > OBSERVACIONES_STRING_LIMIT ?
      helperFormatter(cell.substring(0, OBSERVACIONES_STRING_LIMIT), true) :
      cell
    }
    

    handleOnSelect = (row, isSelect) => {
      if (isSelect) {
        this.setState(() => ({
          selectedRows: [...this.state.selectedRows, row.id]
        }));
      } else {
        this.setState(() => ({
          selectedRows: this.state.selectedRows.filter(x => x !== row.id)
        }));
      }
    }
  
    handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      isSelect ?
      this.setState(() => ({selectedRows: ids})) :
      this.setState(() => ({selectedRows: []}));
    }

    render() {
        const { t } = this.props;
        const apuestas = this.props.apuestas;
        const rows = this.transformApuestasIntoTable(apuestas);
        const headers = [{
            text: 'Id',
            dataField: 'canper_id',
            hidden: localStorage.getItem('selectedColumns.apuestas.canper_id') === 'true',
            formatter: addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.canper_id,
              getFilter: (filter) => {
                this.canper_idFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.canper_id', value);
              },
            }),
            sort: true
          },
          {
            text: 'Trader', 
            dataField: 'trader_nick',
            hidden: localStorage.getItem('selectedColumns.apuestas.trader_nick') === 'true',
            formatter: addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.trader,
              getFilter: (filter) => {
                this.traderFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.trader', value);
              },
            }),
            sort: true
          },
          {
            text: 'Bookmaker', 
            dataField: 'bookmaker_name',
            hidden: localStorage.getItem('selectedColumns.apuestas.bookmaker_name') === 'true',
            filter: selectFilter({
              defaultValue: this.state.filters.bookmaker,
              options: getOpciones('bookmaker_name', rows),
              getFilter: (filter) => {
                this.bookmakerFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.bookmaker', value);
              },
            }),
            sort: true
          },
          {
            text: t("user.label"), 
            dataField: 'usuario',
            hidden: localStorage.getItem('selectedColumns.apuestas.usuario') === 'true',
            filter: textFilter({
              defaultValue: this.state.filters.usuario,
              getFilter: (filter) => {
                this.usuarioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.usuario', value);
              },
            }),
            sort: true
          },
          {
            text: "P/L", 
            dataField: 'p_l',
            hidden: localStorage.getItem('selectedColumns.apuestas.p_l') === 'true',
            filter: selectFilter({
              defaultValue: this.state.filters.p_l,
              options: getOpciones('p_l', rows),
              getFilter: (filter) => {
                this.p_lFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.p_l', value);
              },
            }),
            sort: true
          },
          {
            text: "S/M", 
            dataField: 's_m',
            hidden: localStorage.getItem('selectedColumns.apuestas.s_m') === 'true',
            filter: selectFilter({
              defaultValue: this.state.filters.s_m,
              options: getOpciones('s_m', rows),
              getFilter: (filter) => {
                this.s_mFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.s_m', value);
              },
            }),
            sort: true
          },
          {
            text: t("source.label"), 
            dataField: 'procedencia',
            hidden: localStorage.getItem('selectedColumns.apuestas.procedencia') === 'true',
            filter: selectFilter({
              defaultValue: this.state.filters.procedencia,
              options: getOpciones('procedencia', rows),
              getFilter: (filter) => {
                this.procedenciaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.procedencia', value);
              },
            }),
            sort: true
          },
          {
            text: t("profit.label"), 
            dataField: 'beneficio',
            hidden: localStorage.getItem('selectedColumns.apuestas.beneficio') === 'true',
            filter: textFilter({
              defaultValue: this.state.filters.beneficio,
              getFilter: (filter) => {
                this.beneficioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.beneficio', value);
              },
            }),
            sort: true
          },
          {
            text: t("date.label"), 
            dataField: 'fecha_eu',
            hidden: localStorage.getItem('selectedColumns.apuestas.fecha_eu') === 'true',
            filter: textFilter({
              defaultValue: this.state.filters.fecha,
              getFilter: (filter) => {
                this.fechaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.fecha', value);
              },
            }),
            sort: true
          },
          {
            text: t("stake.label"), 
            dataField: 'apuesta',
            hidden: localStorage.getItem('selectedColumns.apuestas.apuesta') === 'true',
            filter: textFilter({
              defaultValue: this.state.filters.apuesta,
              getFilter: (filter) => {
                this.apuestaFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.apuesta', value);
              },
            }),
            sort: true
          },
          {
            text: t("odd.label"), 
            dataField: 'sobrante',
            hidden: localStorage.getItem('selectedColumns.apuestas.sobrante') === 'true',
            filter: textFilter({
              defaultValue: this.state.filters.sobrante,
              getFilter: (filter) => {
                this.sobranteFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.sobrante', value);
              },
            }),
            sort: true
          },
          {
            text: t("potential.label"), 
            dataField: 'potencial',
            hidden: localStorage.getItem('selectedColumns.apuestas.potencial') === 'true',
            filter: textFilter({
              defaultValue: this.state.filters.potencial,
              getFilter: (filter) => {
                this.potencialFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.potencial', value);
              },
            }),
            sort: true
          },
          {
            text: t("return.label"), 
            dataField: 'devuelto',
            hidden: localStorage.getItem('selectedColumns.apuestas.devuelto') === 'true',
            filter: textFilter({
              defaultValue: this.state.filters.devuelto,
              getFilter: (filter) => {
                this.devueltoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.devuelto', value);
              },
            }),
            sort: true
          },
          {
            text: t("market.label"), 
            dataField: 'mercado',
            hidden: localStorage.getItem('selectedColumns.apuestas.mercado') === 'true',
            filter: textFilter({
              defaultValue: this.state.filters.mercado,
              getFilter: (filter) => {
                this.mercadoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.mercado', value);
              },
            }),
            sort: true
          },
          {
            text: t("event.label"), 
            dataField: 'evento',
            hidden: localStorage.getItem('selectedColumns.apuestas.evento') === 'true',
            filter: textFilter({
              defaultValue: this.state.filters.evento,
              getFilter: (filter) => {
                this.eventoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.evento', value);
              },
            }),
            sort: true
          },
          {
            text: t("status.label"), 
            dataField: 'estado',
            hidden: localStorage.getItem('selectedColumns.apuestas.estado') === 'true',
            filter: selectFilter({
              defaultValue: this.state.filters.estado,
              options: getOpciones('estado', rows),
              getFilter: (filter) => {
                this.estadoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.estado', value);
              },
            }),
            sort: true
          },
          {
            text: t("notes.label"), 
            dataField: 'observaciones_short',
            hidden: localStorage.getItem('selectedColumns.apuestas.observaciones_short') === 'true',
            formatter: this.ObservacionesFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.observaciones,
              getFilter: (filter) => {
                this.observacionesFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.apuestas.observaciones', value);
              },
            }),
            title: (cell, row, rowIndex, colIndex) => row.observaciones_extended,
            sort: true
          },
          {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]

        const selectRow = {
          mode: 'checkbox',
          clickToSelect: false,
          bgColor: '#D5F2F7',
          onSelect: this.handleOnSelect,
          onSelectAll: this.handleOnSelectAll
        };

        

        return (
          <PaginationProvider  pagination={ paginationFactory(paginationOptions(apuestas.length)) }>
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
              <ToolkitProvider keyField='id' data={ rows } columns={ headers } columnToggle>
              {
                ({
                  columnToggleProps,
                  baseProps
                }) => (
              <>
              <div className="pagination-table">
                <Row >
                  <Col>
                  <CustomButton color="secondary" style={{margin: "1px"}} onClick={this.clearFilters} label={t('common:remove_filters.label')} />
                  </Col>
                  <Col className="col-auto">
                    <CustomToggleList { ...columnToggleProps} label={t("common:columns.label")} entity='apuestas' />
                  </Col>
                  <Col className="col-auto">
                    <PaginationListStandalone
                      { ...paginationProps }
                    />
                  </Col>
                  <Col className="col-auto">
                    <SizePerPageDropdownStandalone
                      { ...paginationProps }
                    />
                  </Col>
                  <Col className="col-auto">
                    { this.props.permissions.apuesta.includes("view") &&
                    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                      <DropdownToggle caret size="sm" color="info">
                          {t('common:actions.label')}
                      </DropdownToggle>
                      <DropdownMenu persist={true}>
                          <ExportApuestasModal apuestas={apuestas} selectedApuestas={this.state.selectedRows}/>
                          <ImportApuestasModal />
                      </DropdownMenu>
                    </ButtonDropdown>
                    }
                  </Col>            
                  <Col className="col-auto">
                    { this.props.permissions.apuesta.includes("add") && <NewApuestaModal create={true} apuesta={{}}/>}
                  </Col>
                </Row>
              </div>
              <BootstrapTable
                bootstrap4
                hover
                condensed
                bordered={ false }
                filter={ filterFactory() }
                selectRow={ selectRow }
                defaultSorted={ [{dataField: 'canper_id', order: 'asc'}] }
                wrapperClasses="apuestas-datatable"
                { ...paginationTableProps }
                { ...baseProps }
              />
              </>
              )}
              </ToolkitProvider>
          )}
          </PaginationProvider>
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions,
  });

export default connect(mapStateToProps, {})(withTranslation('apuestas', 'common')(ListApuestas));