export const ADD_CONTABILIDAD = 'ADD_CONTABILIDAD';
export const UPDATE_CONTABILIDAD = 'UPDATE_CONTABILIDAD';
export const LIST_CONTABILIDADES = 'LIST_CONTABILIDADES';
export const LIST_ACTIVE_CONTABILIDADES = 'LIST_ACTIVE_CONTABILIDADES';
export const LIST_ACTIVE_CONTABILIDADES_LOADING = 'LIST_ACTIVE_CONTABILIDADES_LOADING';
export const LIST_CONTABILIDADES_LOADING = 'LIST_CONTABILIDADES_LOADING';
export const UPDATE_CONTABILIDAD_BOOKMAKER = 'UPDATE_CONTABILIDAD_BOOKMAKER';
export const REMOVE_CONTABILIDAD_INGRESO = 'REMOVE_CONTABILIDAD_INGRESO';
export const REMOVE_CONTABILIDAD_RETIRADA = 'REMOVE_CONTABILIDAD_RETIRADA';
export const ADD_CONTABILIDAD_INGRESO = 'ADD_CONTABILIDAD_INGRESO';
export const ADD_CONTABILIDAD_RETIRADA = 'ADD_CONTABILIDAD_RETIRADA';
export const LIST_HISTORIC_CONTABILIDADES = 'LIST_HISTORIC_CONTABILIDADES';
export const LIST_HISTORIC_CONTABILIDADES_LOADING = 'LIST_HISTORIC_CONTABILIDADES_LOADING';
export const ARCHIVE_CLIENTE = 'ARCHIVE_CLIENTE';
export const UPDATE_HISTORIC_CONTABILIDAD = 'UPDATE_HISTORIC_CONTABILIDAD';
export const LIST_CONTABILIDADES_ESTIMACIONES_LOADING = 'LIST_CONTABILIDADES_ESTIMACIONES_LOADING';
export const LIST_CONTABILIDADES_ESTIMACIONES = 'LIST_CONTABILIDADES_ESTIMACIONES';
export const LIST_CANPER_IDS_BY_ENTIDAD = 'LIST_CANPER_IDS_BY_ENTIDAD';
export const LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID = 'LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID';
export const LIST_CANPER_IDS_BY_ENTIDAD_LOADING = 'LIST_CANPER_IDS_BY_ENTIDAD_LOADING';
export const LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID_LOADING = 'LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID_LOADING';
export const CLOSE_CONTABILIDAD_BOOKMAKERS = 'CLOSE_CONTABILIDAD_BOOKMAKERS';
export const LIST_CONTABILIDADES_CANPER_IDS_LOADING = 'LIST_CONTABILIDADES_CANPER_IDS_LOADING';
export const LIST_CONTABILIDADES_CANPER_IDS = 'LIST_CONTABILIDADES_CANPER_IDS';
