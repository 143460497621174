import React from "react";
import { Row, Col, Container, Card, CardHeader, CardBody } from "reactstrap";
import BreadCrumbs from "../common/BreadCrumbs";
import Header from "../common/Header";
import Footer from "../common/Footer";
import NewClienteModal from "./NewClienteModal";
import Bookmakers from "./bookmakers/Bookmakers";
import Banco from "./banco/Banco";
import Conexionprov from "./conexionprov/Conexionprov"
import Monederos from "./monederos/Monederos";
import CopyToClipboard from "../common/CopyToClipboard";
import {dateFromUStoEU, addTooltipToObservaciones} from "../../helpers/helpers";
import FicherosContainer from "./ficheros/Ficheros";
import Prioridades from "./prioridades/Prioridades";
import { Alert } from 'reactstrap';
import moment from 'moment';


export default class DisplayProfile extends React.Component {
    state = {
        edited: false,
        cliente: {}
    }

    render() {
        const cliente = this.props.cliente || {};
        const clienteId = cliente && cliente.id;
        const permissions = this.props.permissions || [];
        const fullName = clienteId ?
         `${cliente.nombre} ${cliente.apellido_1} ${cliente.apellido_2}` :
         '';

        const {t} = this.props;
        
        return (
            <>
            <Header />
            <Container fluid>
                <BreadCrumbs items={[
                    {'href': '/#', 'name': 'Home', 'active': false},
                    {'href': '/#/perfil', 'name': `${t("profile.label")}`, 'active': false},
                    {'href': '', 'name': `${fullName}`, 'active': true}
                ]}/>
                {!permissions.includes('view') ? null :
                <>
                {cliente && cliente.fecha_caducidad && cliente.fecha_caducidad < moment().format("YYYY-MM-DD") && <Alert color="warning">
                    {t('expiry.warning')}
                </Alert>}
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                            <h5>{t("profile.label")}</h5>
                            </Col>
                            {!clienteId && <Col className="col-auto">
                                {permissions.includes('add') && <NewClienteModal create={true} cliente={this.props.cliente} />}
                            </Col>}
                            {clienteId && <Col className="col-auto">
                                {permissions.includes('change') && <NewClienteModal create={false} cliente={this.props.cliente} />}
                            </Col>}
                            {/* <Col className="col-auto">
                                {permissions.includes('delete') && <DeleteClienteModal id={cliente.id}/>}
                            </Col> */}
                        </Row>
                    </CardHeader>

                    {clienteId && <CardBody>

                    <Row>
                        <Col sm="12" md="4" className="border-right">
                            <Row>
                                <Col className="col-info" xs="4" md="3">Nombre</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{fullName}</CopyToClipboard></Col>
                            </Row>

                            <Row>
                                <Col className="col-info" xs="4" md="3">DNI</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.dni}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("expiry.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{dateFromUStoEU(cliente.fecha_caducidad)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("birthday.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{dateFromUStoEU(cliente.fecha_nacimiento)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("phone.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.telefono}</CopyToClipboard></Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="4" className="border-right">
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("common:commercials.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.comercial ? cliente.comercial.nombre : ''}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">Email</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.email}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("email_key.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.clave_email}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("nacionality.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.nacionalidad}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("address.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.direccion}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("city.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.ciudad}</CopyToClipboard></Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="4">
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("region.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.provincia}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">CP</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{cliente.codigo_postal}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("state.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard >{t(`${cliente.estado}.estado`)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("notes.label")}</Col>
                                <Col className="col-info-result" xs="8" md="9">{cliente.observaciones && addTooltipToObservaciones(cliente.observaciones, 'cliente')}</Col>
                            </Row>
                        </Col>
                    </Row>
                    </CardBody> }
                </Card>
                </>}
                {clienteId && <>
                <Banco clienteId={clienteId} />
                <Monederos clienteId={clienteId} />
                <Conexionprov clienteId={clienteId}/>
                <Prioridades clienteId={clienteId}/>
                <Bookmakers clienteId={clienteId}/>                 
                <FicherosContainer clienteId={clienteId}/>
                </>}
                
            </Container>
            <Footer />
        </>
        );
    }

}
