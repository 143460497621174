import React from 'react';
import { connect } from 'react-redux';
import {listConexionprovByCliente} from '../../../redux/actions/conexionesprov'
import CopyToClipboard from "../../common/CopyToClipboard";
import { withTranslation } from 'react-i18next';
import { Row, Col, CardHeader, CardBody, Card } from 'reactstrap';


class Conexionprov extends React.Component {

    componentDidMount() {
        (!this.props.conexiones || !this.props.conexiones.hasOwnProperty(this.props.clienteId)) && this.props.listConexionprovByCliente(this.props.clienteId)
    }

    render() {
        const { t } = this.props;
        const permissions = this.props.permissions || [];
        const conexiones = this.props.conexiones && this.props.conexiones.hasOwnProperty(this.props.clienteId) ? this.props.conexiones[this.props.clienteId] : [];


        return (
            <>
            {!permissions.includes('view') ? 
            null :
            <Card style={{ marginTop: "10px" }}>
                <CardHeader>
                <Row>                    
                    <Col>
                        <h5>{t("conexiones:connection.label")}</h5>
                    </Col>
                </Row>
                </CardHeader>
                <CardBody>
                    {conexiones && conexiones.length > 0 ? conexiones.sort((a ,b) => (a.ip_id > b.ip_id) ? 1 : -1).map((conexion, i) =>
                        <Row className={conexiones[i+1] && "border-bottom mb-3 pb-3"}>
                            <Col sm="12" md="4" className="border-right">
                                <Row>
                                    <Col className="col-info" xs="5" md="4" >Anydesk id</Col>
                                    <Col className="col-info-result" xs="7" md="8" ><CopyToClipboard>{conexion.anydesk_id}</CopyToClipboard></Col>
                                </Row>
                                <Row>
                                    <Col className="col-info" xs="5" md="4" >Anydesk contraseña</Col>
                                    <Col className="col-info-result" xs="7" md="8" ><CopyToClipboard>{conexion.anydesk_contraseña}</CopyToClipboard></Col>
                                </Row>
                            </Col>
                            <Col sm="12" md="4" className="border-right">                            
                                <Row>
                                    <Col className="col-info" xs="4" md="3" >Ip id</Col>
                                    <Col className="col-info-result" xs="8" md="9" ><CopyToClipboard>{conexion.ip_id}</CopyToClipboard></Col>
                                </Row>                            
                                <Row>
                                    <Col className="col-info" xs="4" md="3">Clave ip id</Col>
                                    <Col className="col-info-result" xs="8" md="9" ><CopyToClipboard>{conexion.clave_ip_id}</CopyToClipboard></Col>
                                </Row>
                            </Col>
                            <Col sm="12" md="4" >
                                <Row>
                                    <Col className="col-info" xs="4" md="3">Ip</Col>
                                    <Col className="col-info-result" xs="8" md="9" ><CopyToClipboard>{conexion.ip}</CopyToClipboard></Col>
                                </Row>
                            </Col>
                        </Row>) :
                            <span>{t("clientes:dont_exist_connection.label")}</span>
                        }
                </CardBody>
            </Card>
                }
            </>
        );
    }
}


const mapStateToProps = state => ({
    conexiones: state.conexionesprov.conexionesprovByCliente,
    permissions: state.cuentas.permissions.conexionprov
});


export default connect(mapStateToProps, {listConexionprovByCliente})(withTranslation('conexiones', 'raspberries', 'clientes')(Conexionprov));