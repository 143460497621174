import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CardActions } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTranslation } from 'react-i18next';
import { ADMINISTRADOR, GESTOR, TRADER, URGENTE } from '../../constants';
import NewNotificacionModal from './NewNotificacionModal';
import moment from 'moment';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    marginTop: 3,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '90%'
  },
  content: {
    flex: '1 0 auto',
  },
  actions: {
    width: 50,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end'
  },
}));


export default function ListNotificaciones(props) {
  const notificaciones = props.notificaciones;
  const classes = useStyles();
  const { t } = useTranslation();

  const fetchNotificationColor = (notificacion) => {
  return notificacion.completed && notificacion.verified ? undefined :
          notificacion.priority === URGENTE ? 'LightCoral' :
          'LightGrey'

  }

  const extractContext = context => {
    const filteredContext = props.userGroup === TRADER ? Object.entries(context).filter(([k, v]) => k !== 'enlace') :
      props.userGroup === GESTOR ? Object.entries(context).filter(([k, v]) => k !== 'estrategia') : 
      Object.entries(context)

    return filteredContext.map(([key, value]) => `${t(`notificaciones:${key}.context`)}:${value}`).join(' / ')
  }


  return <>
    { notificaciones ? 
    notificaciones.map((n, index) => 
    <Card className={classes.root} key={index} style={{backgroundColor: fetchNotificationColor(n)}}>
      <div className={classes.details}>
        <CardContent className={classes.content} >
          <Typography component="h6" variant="h6">
            {t(`notificaciones:${n.payload.header}.header`)}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {n.receiver.id === props.user.id ? n.sender.username : n.receiver.username} {moment(n.actualizado_a).format("DD/MM/YYYY HH:mm")}
          </Typography>
          {n.payload.context && <Typography variant="subtitle1" color="textSecondary">
            {extractContext(n.payload.context)}
          </Typography>}
          <Typography variant="body2" color="textSecondary">
            {n.payload.body}
          </Typography>
        </CardContent>
      </div>
      <CardActions className={classes.actions}>
        
        {n.payload.link && props.col !== 'sentNotificaciones' &&
        <Tooltip title={t('notificaciones:visit.label')} href={n.payload.link}>
            <IconButton aria-label="view">
              <VisibilityIcon fontSize="small" color="primary"/>
            </IconButton>
        </Tooltip>}
        
        {/* Forward icon, solo para administradores */}
        {!n.completed && props.userGroup === ADMINISTRADOR && n.forward &&
        <NewNotificacionModal edit={true} sender={props.user} notificacion={n}/>
        }

        {/* Complete icon */}
        {n.receiver.id === props.user.id && <Tooltip title={t('notificaciones:complete.label')} onClick={() => !n.completed ? props.markNotificacionAsCompleted(n.id) : null} disabled={n.completed}>
            <IconButton aria-label="complete" color="primary">
              <CheckCircleIcon fontSize="small" />
            </IconButton>
        </Tooltip>}

        {/* Complete icon */}
        {n.sender.id === props.user.id && props.userGroup === ADMINISTRADOR && <Tooltip title={t('notificaciones:complete_sent.label')} onClick={() => !n.completed ? props.markNotificacionAsCompleted(n.id) : null} disabled={n.completed}>
            <IconButton aria-label="complete" color="secondary">
              <CheckCircleIcon fontSize="small" />
            </IconButton>
        </Tooltip>}

        {/* Verify icon for admin when following notifications */}
        {props.col === 'followingNotificaciones' && n.completed && <Tooltip title={t('notificaciones:verify_following.label')} onClick={() => props.markNotificacionAsVerified(n.id)} disabled={n.verified}>
            <IconButton aria-label="verify" style={{ color: 'green' }}>
              <CheckCircleIcon fontSize="small" />
            </IconButton>
        </Tooltip>}

      </CardActions>
    </Card>
    ) : null}
    </>
}