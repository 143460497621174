import { Modal } from "reactstrap";

// Los modales cuando son cerrados dejan la classe 'modal-open' en el body.
// Esto ocasiona que la DisplayClient site no pueda scroll down. Aqui hay mas
// informacion sobre el problema https://github.com/reactstrap/reactstrap/issues/1626.
// En teoria esto ha sido solucionado, pero el problema persiste para mi
// Probar a upgrade reactstrap cuando una nueva version disponible o utilizar bootstrap modal 
// para solucionar el problema. Por el momento este hotfix soluciona el problema pero es bastante cochino :)
/** Hotfix css-modal */
Modal.prototype.componentWillUnmount = function() {
    if (this.props.onExit) {
      this.props.onExit();
    }
  
    if (this._element) {
      this.destroy();
      if (this.props.isOpen || this.state.isOpen) {
        this.close();
      }
    }
    this._isMounted = false;
  };
  /** Hotfix css-modal */