import React from "react";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import countryList from 'react-select-country-list'
import {defaultIfEmpty} from '../../helpers/helpers';
import { withTranslation } from "react-i18next";


class NewClienteForm extends React.Component {

  state = {
    nacionalities: []
  };  

  componentDidMount = () => {
    this.setState({nacionalities: countryList().getData()});
  }

  render() {
    const {t} = this.props;
    return (
        <Row>
          <Col>
            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="nombre">{t("name.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="nombre"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.nombre)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="apellido_1">{t("lastname_1.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="apellido_1"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.apellido_1)}
                  />
                </Col>
              </Row>
            </FormGroup>
            
            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="apellido_2">{t("lastname_2.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="apellido_2"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.apellido_2)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="dni">DNI:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="dni"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.dni)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="fecha_caducidad">{t("expiry.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="date"
                    name="fecha_caducidad"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.fecha_caducidad)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="fecha_nacimiento">{t("birthday.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="date"
                    name="fecha_nacimiento"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.fecha_nacimiento)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="email">Email:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="email"
                    name="email"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.email)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="clave_email">{t("email_key.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="clave_email"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.clave_email)}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="telefono">{t("phone.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="telefono"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.telefono)}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>

          <Col>
          <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="comercial">{t("common:commercials.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="comercial" onChange={this.props.onChangeComercial} value={this.props.cliente.comercial ? defaultIfEmpty(this.props.cliente.comercial.id) : null}>
                      <option value="">{t("common:option.label")}</option>
                      {this.props.comerciales.map((n, index) => (<option key={index} value={n.id}>{n.nombre}</option>))}
                    </Input>
                  </Col>
              </Row>
            </FormGroup>
            
            <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="nacionalidad">{t("nacionality.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="nacionalidad" onChange={this.props.onChange} value={defaultIfEmpty(this.props.cliente.nacionalidad)}>
                      <option value="">{t("common:option.label")}</option>
                      <option value="ES">Spain</option>
                      {this.state.nacionalities.map((n, index) => (<option key={index} value={n.value}>{n.label}</option>))}
                    </Input>
                  </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="direccion">{t("address.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="direccion"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.direccion)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="ciudad">{t("city.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="ciudad"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.ciudad)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="provincia">{t("region.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="provincia"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.provincia)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="codigo_postal">CP:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="codigo_postal"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.codigo_postal)}
                  />
                </Col>
              </Row>
            </FormGroup>                                

          <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="estado">{t("state.label")}</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="select"
                    name="estado"
                    onChange={this.props.onChange}
                    value={defaultIfEmpty(this.props.cliente.estado)}
                    disabled={!this.props.create && this.props.bookmakersEstados.find(estado => estado !== 'COMPLETADA')}
                  >
                    <option value="">{t("common:option.label")}</option>
                    <option value="ACTIVO">{t("ACTIVO.estado")}</option>          
                    <option value="EN PROCESO">{t("EN PROCESO.estado")}</option>
                    {(!this.props.create && this.props.bookmakersEstados.length === 0) || (this.props.cliente.estado === 'ARCHIVADO') && <option value="ARCHIVADO">{t("ARCHIVADO.estado")}</option>}
                  </Input>
                </Col>
            </Row>
          </FormGroup>

          <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="observaciones">{t("notes.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="textarea"
                    name="observaciones"
                    onChange={this.props.onChange}
                    value={this.props.cliente.observaciones}
                  />
                </Col>
              </Row>
            </FormGroup>
        </Col>
      </Row>
    );
  }
}



export default withTranslation('clientes', 'common')(NewClienteForm);