import { LIST_CONTABILIDADES,UPDATE_HISTORIC_CONTABILIDAD, UPDATE_CONTABILIDAD, ADD_CONTABILIDAD, LIST_ACTIVE_CONTABILIDADES, LIST_ACTIVE_CONTABILIDADES_LOADING, LIST_CONTABILIDADES_LOADING, UPDATE_CONTABILIDAD_BOOKMAKER, REMOVE_CONTABILIDAD_INGRESO, REMOVE_CONTABILIDAD_RETIRADA, ADD_CONTABILIDAD_INGRESO, ADD_CONTABILIDAD_RETIRADA, LIST_HISTORIC_CONTABILIDADES, LIST_HISTORIC_CONTABILIDADES_LOADING, ARCHIVE_CLIENTE, LIST_CONTABILIDADES_ESTIMACIONES_LOADING, LIST_CONTABILIDADES_ESTIMACIONES, LIST_CANPER_IDS_BY_ENTIDAD, LIST_CANPER_IDS_BY_ENTIDAD_LOADING, LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID, LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID_LOADING, CLOSE_CONTABILIDAD_BOOKMAKERS, LIST_CONTABILIDADES_CANPER_IDS, LIST_CONTABILIDADES_CANPER_IDS_LOADING } from "../types/contabilidades";
import moment from 'moment';
import { isActionAllowed } from "redux-state-sync";

const initialState = {
    contabilidades: null
};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_CONTABILIDADES:
            return {
                ...state,
                contabilidades: action.payload,
                isLoading: false
            }
        case LIST_HISTORIC_CONTABILIDADES:
            return {
                ...state,
                contabilidades_historico: action.payload,
                isLoadingHistoric: false
            }
        case UPDATE_HISTORIC_CONTABILIDAD:
            return {
                ...state,
                contabilidades_historico: (state.contabilidades_historico || []).map(contabilidad => contabilidad.id !== action.payload.id ? contabilidad : action.payload),
                isLoadingHistoric: false
            }
        case LIST_CONTABILIDADES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case LIST_HISTORIC_CONTABILIDADES_LOADING:
            return {
                ...state,
                isLoadingHistoric: true
            }
        case LIST_CONTABILIDADES_ESTIMACIONES_LOADING:
            return {
                ...state,
                isLoadingContabilidadesWithEstimaciones: true
            }
        case LIST_CONTABILIDADES_ESTIMACIONES:
            return {
                ...state,
                contabilidadesWithEstimaciones: action.payload,
                isLoadingContabilidadesWithEstimaciones: false
            }
        case LIST_ACTIVE_CONTABILIDADES:
            return {
                ...state,
                contabilidades: action.payload,
                isLoadingActiveContabilidades: false
            }
        case LIST_ACTIVE_CONTABILIDADES_LOADING:
            return {
                ...state,
                isLoadingActiveContabilidades: true
            }

        case ADD_CONTABILIDAD:
            return {
                ...state,
                contabilidades: [...(state.contabilidades || []), action.payload]
            }

        case UPDATE_CONTABILIDAD:
            return {
                ...state,
                contabilidades: (state.contabilidades || []).map(contabilidad => contabilidad.id !== action.payload.id ? contabilidad : {...action.payload, 'bookmaker': contabilidad.bookmaker}),
                contabilidad: action.payload
            }

        case UPDATE_CONTABILIDAD_BOOKMAKER:
            return {
                ...state,
                contabilidades: (state.contabilidades || []).map(c =>
                    c.bookmaker.id === action.payload.id ?
                    { 
                        ...c,
                        bookmaker: {
                            ...action.payload, 
                            ingresos: c.bookmaker.ingresos, 
                            retiradas: c.bookmaker.retiradas,
                            cliente: c.bookmaker.cliente,
                            creado_por: c.bookmaker.creado_por
                        }
                    }
                    : c
                )
            }

        case CLOSE_CONTABILIDAD_BOOKMAKERS:
            const bookmakerIds = action.payload;
            return {
                ...state,
                contabilidades: (state.contabilidades || []).map(c =>
                    bookmakerIds.includes(c.bookmaker.id) ?
                    { 
                        ...c,
                        bookmaker: {
                            ...c.bookmaker, 
                            estado: 'COMPLETADA'
                        }
                    }
                    : c
                )
            }

        case REMOVE_CONTABILIDAD_INGRESO:
            return {
                ...state,
                contabilidades: state.contabilidades.map(c =>
                    c.bookmaker.ingresos.length === 0 ? c :
                    // Elimino ingreso si coincide con el ingreso eliminado
                    {...c, bookmaker: {...c.bookmaker, ingresos: c.bookmaker.ingresos.filter(i => i.id !== action.payload)}})
            }

        case REMOVE_CONTABILIDAD_RETIRADA:
            return {
                ...state,
                contabilidades: state.contabilidades.map(c =>
                    c.bookmaker.retiradas.length === 0 ? c :
                    // Elimino ingreso si coincide con el ingreso eliminado
                    {...c, bookmaker: {...c.bookmaker, retiradas: c.bookmaker.retiradas.filter(i => i.id !== action.payload)}})
            }

        case ADD_CONTABILIDAD_INGRESO:
            return {
                ...state,
                contabilidades: state.contabilidades.map(c =>
                    c.bookmaker.id === action.payload.bookmaker && c.fecha_inicio <= action.payload.fecha.substring(0, 10) && (c.fecha_fin || moment().format("YYYY-MM-DD")) >= action.payload.fecha.substring(0, 10) ?
                    {...c, bookmaker: {...c.bookmaker, ingresos: [...c.bookmaker.ingresos, action.payload]}} :
                    c
                )
            }

        case ADD_CONTABILIDAD_RETIRADA:
            return {
                ...state,
                contabilidades: state.contabilidades.map(c =>
                    c.bookmaker.id === action.payload.bookmaker && c.fecha_inicio <= action.payload.fecha.substring(0, 10) && (c.fecha_fin || moment().format("YYYY-MM-DD")) >= action.payload.fecha.substring(0, 10)  ?
                    {...c, bookmaker: {...c.bookmaker, retiradas: [...c.bookmaker.retiradas, action.payload]}} :
                    c
                )
            }
        
        case ARCHIVE_CLIENTE:
            const clienteId = action.payload.id;
            return {
                ...state,
                contabilidades: (state.contabilidades || []).filter(c => c.bookmaker.cliente.id !== clienteId)
            }

        case LIST_CANPER_IDS_BY_ENTIDAD:
            return {
                ...state,
                canperIdsByEntidadIsLoading: false,
                canperIdsByEntidad: {...state.canperIdsByEntidad, [action.entidad]: action.payload}
            }

        case LIST_CANPER_IDS_BY_ENTIDAD_LOADING:
            return {
                ...state,
                canperIdsByEntidadIsLoading: true,
            }

        case LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID:
            return {
                ...state,
                contabilidadesByEntidadAndCanperIdIsLoading: false,
                contabilidadesByEntidadAndCanperId: action.payload,
            }

        case LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID_LOADING:
            return {
                ...state,
                contabilidadesByEntidadAndCanperIdIsLoading: true,
            }

        case LIST_CONTABILIDADES_CANPER_IDS_LOADING:
            return {
                ...state,
                canperIdsIsLoading: true
            }
        case LIST_CONTABILIDADES_CANPER_IDS:
            return {
                ...state,
                canperIdsIsLoading: false,
                canperIds: action.payload
            }

        default:
            return state;
    }
}
