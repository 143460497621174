import React from "react";
import ListClientes from "./ListClientes";
import { listSummaryClientes } from "../../redux/actions/clientes"
import { Spinner, Container } from "reactstrap";
import { connect } from "react-redux";
import Header from "../common/Header";
import BreadCrumbs from "../common/BreadCrumbs";
import { withTranslation } from "react-i18next";
import { useTranslation } from 'react-i18next';



class ClientesView extends React.Component {

    componentDidMount() {

        const { t } = this.props;



        if (!this.props.clientes && !this.props.isLoading) {
            const storedEstado = localStorage.getItem('filters.clientes.estado');
            if (storedEstado === null) {
                localStorage.setItem('filters.clientes.estado', 'ACTIVO');
            }

            let estado = (storedEstado === null || storedEstado === undefined) ? 'ACTIVO' : storedEstado === '' ? 'ALL' : t(`clientes:${storedEstado}.estado`);
            estado = estado.replace(' ', '_')
            this.props.listSummaryClientes(estado);

        }

    }


    render() {

        const { t } = this.props;

        return (

            <>

                <Header />

                <Container fluid>

                    <BreadCrumbs items={[

                        { 'href': '/#', 'name': 'Home', 'active': false },

                        { 'name': t("common:account.label"), 'active': true },

                        { 'name': `${t("client.label")}s`, 'active': true },

                    ]} />

                    {this.props.isLoading ? <Spinner animation="border" variant="primary" /> :

                        <ListClientes clientes={this.props.clientes} />}

                </Container>

            </>

        );

    }

}


const mapStateToProps = state => ({
    clientes: state.clientes.clientes,
    isLoading: state.clientes.isLoading,
});



export default connect(mapStateToProps, { listSummaryClientes })(withTranslation('clientes')(ClientesView));