import React, {Component} from "react";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next'
import { Redirect} from "react-router-dom";
import i18n from '../../translations/i18n';
import { login } from "../../redux/actions/cuentas";
import { addAjustes } from "../../redux/actions/ajustes";
import { Spinner } from 'reactstrap';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import Tronadvisor from '../../assets/images/Tronadvisor.png';
import Copyright from './Copyright'
import { Link } from "@material-ui/core";


const styles = theme => ({
  paper: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginTop: 50,
    marginBottom: 10,
    width: 70,
    height: 80,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 1,
  },
  submit: {
    margin: (3, 0, 2),
  },
});


class SignIn extends Component {

  state = {
    username: "",
    password: "",
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password)
  }

  changeToStoredLanguage = (userId, ajustes, addAjustes) => {
    const detectedLanguage = i18n.language;
    const storedLanguage = ajustes.idioma;
    if (!storedLanguage) {
      addAjustes({
        user: userId,
        idioma: detectedLanguage
      });
    } else if (detectedLanguage !== storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }

  render() {
    const { t, classes } = this.props;
    
    if (this.props.isAuthenticated) {
      if (!this.props.ajustes || this.props.ajustes.isLoading || !this.props.permissions) {
        return <Spinner animation="border" variant="primary"/>;
      }

      this.changeToStoredLanguage(this.props.user.id, this.props.ajustes, this.props.addAjustes)
      const state = this.props.location.state;
      return <Redirect to={state ? state.originalRoute : "/#"} />;
    }
  
    return (
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img alt="Tronadvisor" src={Tronadvisor} className={classes.avatar} />
        <Typography component="h1" variant="h4">
          Gestion Manager
        </Typography>
        <form className={classes.form} onSubmit={this.onSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label={t('user.label')}
            name="username"
            autoComplete="username"
            autoFocus
            onChange={e => this.setState({username: e.target.value})}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('pass.label')}
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => this.setState({password: e.target.value})}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}            
          >
            Sign In
          </Button>          
        </form>
      </div>
      <Box mt={1}>
        <Typography variant="body2" color="textSecondary" align="center">
          {t("cuentas:sign_up.message")}
          <Link color="primary" href="/#/register">
            Sign Up
          </Link>
        </Typography>
      </Box>
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>    
    )
  }
}

const mapStateToProps = state => ({
    isAuthenticated: state.cuentas.isAuthenticated,
    ajustes: state.cuentas.ajustes,
    user: state.cuentas.user,
    permissions: state.cuentas.permissions
});


export default connect(mapStateToProps, { login, addAjustes })(withTranslation('cuentas')(withStyles(styles)(SignIn)));