import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, Row, Col, FormGroup, Label, Input } from "reactstrap";

import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { withTranslation } from "react-i18next";
import { defaultIfEmpty } from "../../helpers/helpers";


class NewRangoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
    if (this.props.rango) {
      this.setState({rango: this.props.rango})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rango && prevState.rango && prevState.rango.id !== prevProps.rango.id) {
      this.setState({rango: prevProps.rango})
    }
  }

  onChange = e => {
    e.preventDefault();
    this.setState(
      { rango: {
        ...this.state.rango,
        [e.target.name]: e.target.value
      }
    });
  };

  onChangePago = e => {
    e.preventDefault();
    let tipo_pago = "";
    let pago_id = 0;
    const value = e.target.value;
    if (value) {
      const tipo_id = value.split('_');
      tipo_pago = tipo_id[0];
      pago_id = tipo_id[1];
    }
    this.setState(
      { rango: {
        ...this.state.rango,
        tipo_pago,
        pago_id
      }
    });
  }

  createRango = e => {
    e.preventDefault();
    const estado = !this.state.rango.estado ? 'SIN LIMITAR' : this.state.rango.estado;
    const rango = {...this.state.rango, estado, bookmaker: this.props.bookmakerId}
    this.props.createRango(rango);
    this.setState({modal: false});
  }
  
  editRango = e => {
    e.preventDefault();
    this.props.editRango(this.state.rango);
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({rango: {}})
    }
  }

  render() {
    const { t } = this.props;
    const create = this.props.create;
    const rango = this.state.rango || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;  

    return (
    <Fragment>
      { create ?
        <Button color="primary" size="sm" onClick={this.onClick}> {t("common:add.label")} </Button> :
        <Button color="warning" size="sm" onClick={this.onClick}> {t("common:edit.label")} </Button>
      }
      <LoadingOverlay active={this.props.isLoading} spinner={true}>
        <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === rango.id || this.props.isLoading)} toggle={this.toggle} backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t("common:add.label")} ${t("rangos:rank.label")}` : `${t("common:edit.label")} ${t("rangos:rank.label")}`}
        </ModalHeader>
        <ModalBody>                   
          <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="tipo">{t("type.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="tipo" onChange={this.onChange} value={defaultIfEmpty(rango.tipo)}>
                      <option value="">{t("common:option.label")}</option>
                      <option value="DEPOSITO">{t("DEPOSITO.tipo")}</option>
                      <option value="RETIRADA">{t("RETIRADA.tipo")}</option>
                    </Input>
                  </Col>
              </Row>
            </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="minimo">Minimo:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="minimo"
                  onChange={this.onChange}
                  value={defaultIfEmpty(rango.minimo)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="maximo">Maximo:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="text"
                  name="maximo"
                  onChange={this.onChange}
                  value={defaultIfEmpty(rango.maximo)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="tipo_pago">{t("bank.label")}</Label>
                </Col>
                <Col md="8">
                  <Input type="select" name="tipo_pago" onChange={this.onChangePago} value={rango.tipo_pago ? `${rango.tipo_pago}_${rango.pago_id}` : ""}>
                    <option value="">{t("common:option.label")}</option>
                    {
                      this.props.monederoOpciones.map((m, i) => (<option value={`MONEDERO_${m.id}`} key={i}>{m.monedero}</option>)).concat(
                        this.props.bancoOpciones.map((b, i) => (<option value={`BANCO_${b.id}`} key={i}>{b.banco}</option>)
                      ))
                    }
                  </Input>
                </Col>
            </Row>
          </FormGroup>

        </ModalBody>
        <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>
            {t("common:cancel.label")}
            </Button>
          { create ? 
            (<Button type="button" color="primary" onClick={this.createRango}>
              {t("common:add.label")}
            </Button>) :
            (<Button type="button" color="warning" onClick={this.editRango}>
              {t("common:edit.label")}
            </Button>)
          }
        </ModalFooter>
        </Modal>
      </LoadingOverlay>
    </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'RANGOS',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'RANGOS' && state.errores.status !== 200 ? state.errores.context.id : false,
  bancoOpciones: state.opciones.banco,
  monederoOpciones: state.opciones.monedero
});


export default connect(mapStateToProps)(withTranslation('rangos', 'common')(NewRangoModal));