import {ADMINISTRADOR, SUPERUSER, TRADER, GESTOR, URGENTE, SHORT, LARGE} from "../../constants/index"

export function fetchNotificacionesTemplates(group) {
    switch(group) {
        case TRADER:
            return traderNotificaciones
        case GESTOR:
            return gestorNotificaciones
        case ADMINISTRADOR:
            return administradorNotificaciones
        case SUPERUSER:
            // Devolvemos todas las notificaciones para superusers
            return allNotificaciones
        default:
            return []
    }
}


const gestorNotificaciones = [
    {'id': 'g1', 'header': 1, 'receiver': ADMINISTRADOR},
    {'id': 'g2', 'header': 9, 'receiver': ADMINISTRADOR},
]

const administradorNotificaciones = [
    {'id': 'a1', 'header': 2, 'receiver': GESTOR},
    {'id': 'a2', 'header': 9, 'receiver': GESTOR},
]

const traderNotificaciones = [
    {'id': 't1', 'header': 3, 'receiver': GESTOR, 'follower': ADMINISTRADOR, 'estrategia': LARGE, 'link': '/#/contabilidades?', verified: false},
    {'id': 't2', 'header': 4, 'receiver': GESTOR, 'follower': ADMINISTRADOR, 'estrategia': LARGE, 'link': '/#/contabilidades?', verified: false},
    {'id': 't3', 'header': 5, 'receiver': ADMINISTRADOR, 'estrategia': LARGE, 'forward': true},
    {'id': 't4', 'header': 6, 'receiver': ADMINISTRADOR, 'estrategia': SHORT, 'priority': URGENTE, 'forward': true},
    {'id': 't5', 'header': 7, 'receiver': ADMINISTRADOR, 'estrategia': SHORT, 'priority': URGENTE, 'forward': true},
    {'id': 't6', 'header': 8, 'receiver': ADMINISTRADOR, 'estrategia': LARGE, 'link': '/#/contabilidades?'},
    {'id': 't7', 'header': 9, 'receiver': ADMINISTRADOR},
]

export const allNotificaciones = traderNotificaciones.concat(gestorNotificaciones).concat(administradorNotificaciones);