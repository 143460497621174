import React from 'react';
import { useTranslation } from 'react-i18next';
import filterFactory, { textFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import { paginationOptions } from '../common/BootstrapTableHelper';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { dateFromUStoEU } from '../../helpers/helpers';
import { getBalanceSumary } from '../contabilidades/helper';
import { EN_PROGRESO } from '../../constants';



export default function ListResumenTrader(props) {

    const filters = React.useState({
        trader: localStorage.getItem('filters.resumenTrader.trader'),
        canperId: localStorage.getItem('filters.resumenTrader.canperId'),
        enlace: localStorage.getItem('filters.resumenTrader.enlace'),
        fechaInicio: localStorage.getItem('filters.resumenTrader.fechaInicio'),
        fechaFin: localStorage.getItem('filters.resumenTrader.fechaFin'),
        balance: localStorage.getItem('filters.resumenTrader.balance'),
        estimacion: localStorage.getItem('filters.resumenTrader.estimacion'),
        estrategia: localStorage.getItem('filters.resumenTrader.estrategia'),       
        nBookmakersProgresoFilter: localStorage.getItem('filters.resumenTrader.nBookmakersProgresoFilter'),       
        objetivo: localStorage.getItem('filters.resumenTraobjetiv.objetivo'),
        total: localStorage.getItem('filters.resumenTraobjetiv.total'),
    });
    const { t } = useTranslation();

    let canperIdFilter = undefined;
    let enlaceFilter = undefined;
    let fechaInicioFilter = undefined;
    let fechaFinFilter = undefined;
    let balanceFilter = undefined;
    let estimacionFilter = undefined;
    let estrategiaFilter = undefined;
    let nBookmakersProgresoFilter = undefined;
    let objetivoFilter = undefined;
    let totalFilter = undefined;

    const clearFilters = () => {
      canperIdFilter('');
      enlaceFilter('');
      fechaInicioFilter('');
      fechaFinFilter('');
      balanceFilter('');
      estrategiaFilter('');
      estimacionFilter('');
      nBookmakersProgresoFilter('');
      objetivoFilter('');
      totalFilter('');
    }

    const addBalance = estrategias => {
      return estrategias.map(e => ({...e, balance: getBalanceSumary(e)}));
    }

    const transformEstrategiasByEstrategia = estrategias => {
      return estrategias.reduce((obj, e) => (
        obj.hasOwnProperty(`${e['asignacion']['id']}-${e['canper_id']}`) ?
          {...obj, [`${e.asignacion.id}-${e.canper_id}`]: [...obj[`${e.asignacion.id}-${e.canper_id}`], e]} :
          {...obj, [`${e.asignacion.id}-${e.canper_id}`]: [e]})
        , {});
    }

    const transformEstrategiasIntoTable = (estrategias) => {
        return estrategias.map(e => ({
          'fecha_inicio_filter': e.fecha_inicio ? e.fecha_inicio : "",
          'fecha_inicio_date': e.fecha_inicio ? dateFromUStoEU(e.fecha_inicio) : "",
          'fecha_fin_filter': e.fecha_fin ? e.fecha_fin : "",
          'fecha_fin_date': e.fecha_fin ? dateFromUStoEU(e.fecha_fin) : "",
          'estrategia': e.asignacion.id,
          'canper_id': e.canper_id,
          'enlace': e.bookmaker.cliente.enlace,
          'nBookmakersProgreso': e.asignacion.fecha_fin ? 0 : estrategias.reduce((acc, est) => est.asignacion.id === e.asignacion.id && est.bookmaker.estado === EN_PROGRESO ? 1 + acc : acc, 0),
          'objetivo': e.asignacion.objetivo.objetivo,
            ...e
        }))
    }

    const estrategiasIntoGrouping = estrategiasByEstrategia => {
      const balances = Object.values(estrategiasByEstrategia).map(e => e.reduce((sum, b) => b.fecha_fin && b.balance ? sum + parseFloat(b.balance) : sum, 0)).map(b => b.toFixed(2));
      const estimaciones = Object.values(estrategiasByEstrategia).map(e => e.reduce((sum, b) => !b.fecha_fin && b.estimaciones && b.estimaciones.length > 0 ? sum + parseFloat(b.balance) : sum, 0)).map(b => b.toFixed(2));
      const fechas_inicios = Object.values(estrategiasByEstrategia).map(e => (new Date(Math.min(...e.map(f => new Date(f.fecha_inicio)))))).map(f => f.toLocaleDateString("sv-SE"))
      const fechas_finales = Object.values(estrategiasByEstrategia).map(e => (new Date(Math.max(...e.map(f => f.fecha_fin ? new Date(f.fecha_fin) : new Date()))))).map(f => f.toLocaleDateString("sv-SE"))

      return Object.keys(estrategiasByEstrategia).map((estrategia, i) => ({...estrategiasByEstrategia[estrategia][0], 'asignacionCanperId': estrategia, 'fecha_inicio': fechas_inicios[i], 'fecha_fin': fechas_finales[i], 'balance': balances[i], 'estimacion': estimaciones[i], 'total': parseFloat(parseFloat(balances[i]) + parseFloat(estimaciones[i])).toFixed(2)}))
    }

    const estrategias = addBalance(props.estrategias);
    const estrategiasByEstrategia = transformEstrategiasByEstrategia(estrategias);
    const estrategiasGrouping = estrategiasIntoGrouping(estrategiasByEstrategia);

    const rows = transformEstrategiasIntoTable(estrategiasGrouping);

    const headers = [
            
      {
        text: t("Id"), 
        dataField: 'canper_id',
        filter: textFilter({
          defaultValue: filters.estrategia,
          getFilter: (filter) => {
            estrategiaFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenTrader.canperId', value);
          },
        }),
        sort: true
      },
      {
        text: t("estrategias:strategy.label"), 
        dataField: 'estrategia',
        filter: textFilter({
          defaultValue: filters.estrategia,
          getFilter: (filter) => {
            estrategiaFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenTrader.estrategia', value);
          },
        }),
        sort: true
      },
      {
        text: t("clientes:link.label"), 
        dataField: 'enlace',
        filter: textFilter({
          defaultValue: filters.enlace,
          getFilter: (filter) => {
            enlaceFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenTrader.enlace', value);
          },
        }),
        sort: true
      },
      {
        text: 'Bookmakers', 
        dataField: 'nBookmakersProgreso',
        filter: textFilter({
          defaultValue: filters.nBookmakersProgreso,
          getFilter: (filter) => {
            nBookmakersProgresoFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.nBookmakersProgreso', value);
          },
        }),
        sort: true
      },
      {
        text: t("contabilidades:date_start.label"), 
        dataField: '',
        formatter: (cell, row) => row.fecha_inicio_date,         
        filter: textFilter({
          defaultValue: filters.fechaInicio,
          getFilter: (filter) => {
            fechaInicioFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenTrader.fechaInicio', value);
          },
        }),
        sort: true
      },
      {
        text: t("contabilidades:date_end.label"), 
        dataField: 'fecha_fin_filter',
        formatter: (cell, row) => row.fecha_fin_date,
        filter: textFilter({     
          defaultValue: filters.fechaFin,
          getFilter: (filter) => {
            fechaFinFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenTrader.fechaFin', value);
          },
        }),
        sort: true
      },
      {
        text: 'Balance', 
        dataField: 'balance',
        filter: textFilter({
          defaultValue: filters.balance,
          getFilter: (filter) => {
            balanceFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenTrader.balance', value);
          },
        }),
        sort: true
      },
      {
        text: t("contabilidades:estimation.label"),
        dataField: 'estimacion',
        filter: textFilter({
          defaultValue: filters.estimacion,
          getFilter: (filter) => {
            estimacionFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenTrader.estimacion', value);
          },
        }),
        sort: true
      },
      {
        text: 'Total', 
        dataField: 'total',
        filter: numberFilter({
          defaultValue: {
            number: filters.total,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            totalFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenTrader.total', value);
          },
        }),
        sort: true
      },  
      {
        text: t("estrategias:target.label"),
        dataField: 'objetivo',
        filter: numberFilter({
          defaultValue: {
            number: filters.objetivo,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            objetivoFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenTrader.objetivo', value);
          },
        }),
        style: (cell, row, rowIndex, colIndex) => {
          if (parseFloat(row.objetivo) > parseFloat(row.total)) {
            return {
              backgroundColor: '#e69aa6'
            };
          }
        },
        sort: true
      },  
    ]

    return <PaginationProvider  pagination={ paginationFactory(paginationOptions(rows.length)) }>
    {
      ({
        paginationProps,
        paginationTableProps
      }) => (
        <>
        <div className="pagination-table">
          <Row >
            <Col>
              <Button color="secondary" size="sm" onClick={clearFilters}>{t('common:remove_filters.label')}</Button>
            </Col>
            <Col className="col-auto">
              <PaginationListStandalone
                { ...paginationProps }
              />
            </Col>
            <Col className="col-auto">
              <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
            </Col>
          </Row>
        </div>
        <BootstrapTable
            bootstrap4
            hover
            condensed
            bordered={ false }
            keyField='id'
            data={rows}
            columns={ headers }
            filter={ filterFactory() }
            defaultSorted={ [{dataField: 'id', order: 'asc'}] }
            wrapperClasses="resumen-traders-datatable"
            { ...paginationTableProps }
            />
        </>
        )}
    </PaginationProvider>
}
