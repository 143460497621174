import { API_BOOKMAKERS_URL, API_CLIENTES_BOOKMAKERS_URL, API_CLIENTES_URL, API_CONTABILIDADES_URL, API_ENTIDADES_URL, nowDatetime } from "../../constants";
import { ADD_CONTABILIDAD, UPDATE_CONTABILIDAD, UPDATE_HISTORIC_CONTABILIDAD,LIST_CONTABILIDADES, LIST_ACTIVE_CONTABILIDADES, LIST_ACTIVE_CONTABILIDADES_LOADING, LIST_CONTABILIDADES_LOADING, UPDATE_CONTABILIDAD_BOOKMAKER, REMOVE_CONTABILIDAD_INGRESO, REMOVE_CONTABILIDAD_RETIRADA, ADD_CONTABILIDAD_INGRESO, ADD_CONTABILIDAD_RETIRADA, LIST_HISTORIC_CONTABILIDADES_LOADING, LIST_HISTORIC_CONTABILIDADES, ARCHIVE_CLIENTE, LIST_CONTABILIDADES_ESTIMACIONES_LOADING, LIST_CONTABILIDADES_ESTIMACIONES, LIST_CANPER_IDS_BY_ENTIDAD, LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID, LIST_CANPER_IDS_BY_ENTIDAD_LOADING, LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID_LOADING, CLOSE_CONTABILIDAD_BOOKMAKERS, LIST_CONTABILIDADES_CANPER_IDS_LOADING, LIST_CONTABILIDADES_CANPER_IDS } from "../types/contabilidades"
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';
import { addEstrategia, patchEstrategia } from "./estrategias";
import moment from 'moment';


const CONTABILIDADES = 'CONTABILIDADES';
const CLIENTES = 'CLIENTES';

// Add Contabilidad
export const addContabilidad = (contabilidad) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, CONTABILIDADES));
    return axios
        .post(`${API_CONTABILIDADES_URL}`, contabilidad, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_CONTABILIDAD,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("contabilidades:added_contabilidad.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONTABILIDADES, 'id': contabilidad.id}));
        })
};


// Update Contabilidad
export const updateContabilidad = (id, Contabilidad) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, CONTABILIDADES));
    axios
        .put(`${API_CONTABILIDADES_URL}${id}/`, Contabilidad, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CONTABILIDAD,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("contabilidades:updated_contabilidad.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONTABILIDADES, 'id': id}));
        })
};

export const patchContabilidad = (id, Contabilidad) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, CONTABILIDADES));
    axios
        .patch(`${API_CONTABILIDADES_URL}${id}/`, Contabilidad, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CONTABILIDAD,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("contabilidades:updated_contabilidad.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONTABILIDADES, 'id': id}));
        })
};

export const archiveCliente = (clienteId) => (dispatch, getState) => {
    axios
        .patch(`${API_CLIENTES_URL}${clienteId}/`, {estado: 'ARCHIVADO'}, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ARCHIVE_CLIENTE,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("clientes:updated_client.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CLIENTES, 'id': id}));
        })
};

// Update Contabilidad
export const updateContabilidadAndEstadoAndSaldoInicial = (id, contabilidad, estado, saldoInicial, asignacion) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, CONTABILIDADES));
    axios
        .put(`${API_CONTABILIDADES_URL}${id}/`, contabilidad, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CONTABILIDAD,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("contabilidades:updated_contabilidad.message")));

            const isNewEstadoBookmaker = contabilidad.bookmaker.estado !== estado;
            const isNewSaldoInicialBookmaker = contabilidad.bookmaker.saldo_inicial !== saldoInicial;

            if (isNewEstadoBookmaker || isNewSaldoInicialBookmaker) {
                const newAttrs = {estado, saldo_inicial: saldoInicial}
                // Miro si necesito añadir una estrategia, si el enlace se encuentra asignado
                /***TODO: revisar en el momento que se llama a "updateBookmaker" dentro del método
                 * se crea una nueva estrategia si fuese necesario, esta estrategia se le a añade 
                 * una fecha de inicio diferente a la que lleva el registro en contabilidad, repasar 
                 * para tener en cuenta si sería lo correcto o lo correcto sería que tenga la misma fecha_inicio
                 * que contabilidad
                ***/ 
                dispatch(updateBookmaker(
                    contabilidad.bookmaker.id,
                    {
                        ...contabilidad.bookmaker,
                        ...newAttrs,
                        cliente: contabilidad.bookmaker.cliente.id,
                        canper_id: contabilidad.canper_id
                    },
                    asignacion ? {asignacion, cliente: contabilidad.bookmaker.cliente} : false
                ));
              }
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONTABILIDADES, 'id': id}));
        })
};


// List Contabilidads of a cliente
export const listContabilidades = () => (dispatch, getState) => {
    dispatch({ type: LIST_CONTABILIDADES_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONTABILIDADES,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_CONTABILIDADES,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listActivasContabilidades = () => (dispatch, getState) => {
    dispatch({ type: LIST_ACTIVE_CONTABILIDADES_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}activo/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ACTIVE_CONTABILIDADES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listHistoricContabilidades = () => (dispatch, getState) => {
    dispatch({ type: LIST_HISTORIC_CONTABILIDADES_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}historico/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_HISTORIC_CONTABILIDADES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listHistoricContabilidadesByGestor = (gestor) => (dispatch, getState) => {
    dispatch({ type: LIST_HISTORIC_CONTABILIDADES_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}historico/?pertenece_a=${gestor}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_HISTORIC_CONTABILIDADES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listHistoricContabilidadesByGestorByCanperId = (gestor, canperId) => (dispatch, getState) => {
    dispatch({ type: LIST_HISTORIC_CONTABILIDADES_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}historico/?pertenece_a=${gestor}&canper_id=${canperId}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_HISTORIC_CONTABILIDADES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listContabilidadesWithEstimaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_CONTABILIDADES_ESTIMACIONES_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}with_estimaciones/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONTABILIDADES_ESTIMACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listContabilidadesWithEstimacionesByCanperId = (canperId) => (dispatch, getState) => {
    dispatch({ type: LIST_CONTABILIDADES_ESTIMACIONES_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}with_estimaciones/?canper_id=${canperId}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONTABILIDADES_ESTIMACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const updateHistoricContabilidad = (id, Contabilidad) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, CONTABILIDADES));
    axios
        .put(`${API_CONTABILIDADES_URL}${id}/`, Contabilidad, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_HISTORIC_CONTABILIDAD,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("contabilidades:updated_contabilidad.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONTABILIDADES, 'id': id}));
        })
};


// Update bookmaker
export const updateBookmaker = (id, bookmaker, asignacion) => (dispatch, getState) => {
    axios
        .put(`${API_CLIENTES_BOOKMAKERS_URL}${id}/`, bookmaker, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CONTABILIDAD_BOOKMAKER,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("bookmakers:updated_bookmaker.message")));
            const fecha_inicio = nowDatetime;
            
            /**const canper_id = new Date().toLocaleString("en-GB", {month: "short", year: "numeric"}).toUpperCase().replace(" ", "");
             * Comento "canper_id" puesto que la estrategia que se genera desde contabilidad siempre va a tener que el mismo canper_id que en contabilidad.
             * Y si damos la opción de utilizar uno nuevo esto puede llevar a error.
             */
            
            // Añado estrategia en el caso de que el bookmaker pase a EN PROGRESO, y el cliente del bookmaker este asignado
            

            if (asignacion) {
                dispatch(addEstrategia({
                    canper_id: bookmaker.canper_id,
                    fecha_inicio,
                    saldo_inicial: res.data.saldo_inicial,
                    asignacion: asignacion.asignacion,
                    bookmaker: {...res.data, cliente: asignacion.cliente, creado_por: {id: res.data.creado_por, username: 'to_be_overwritten'}}
                }))
            }
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONTABILIDADES, 'id': id}));
        })
};


export const patchBookmaker = (id, bookmaker) => (dispatch, getState) => {
    axios
        .patch(`${API_CLIENTES_BOOKMAKERS_URL}${id}/`, bookmaker, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CONTABILIDAD_BOOKMAKER,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("bookmakers:updated_bookmaker.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONTABILIDADES, 'id': id}));
        })
};


export const patchBulkCompleteBookmakers = (contabilidades, estrategias, saldoFinales) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, {type: 'CIERRE_MULTIPLE_CONTABILIDADES', id: contabilidades[0].bookmaker.cliente.id}));
    const bookmakerIds = contabilidades.map(c => c.bookmaker.id);
    axios
        .patch(`${API_CLIENTES_BOOKMAKERS_URL}bulk_complete/`, bookmakerIds, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: CLOSE_CONTABILIDAD_BOOKMAKERS,
                payload: bookmakerIds
            });
            contabilidades.map((c, index) => {
                dispatch(patchContabilidad(c.id, {saldo_final: saldoFinales[index], fecha_fin: nowDatetime, tipo_cierre: 'CIERRE'}));
                const estrategia = estrategias.find(e => e.bookmaker.id === c.bookmaker.id && !e.saldo_final && !e.fecha_fin)
                if (estrategia) {
                    dispatch(patchEstrategia(estrategia.id, {saldo_final: saldoFinales[index], fecha_fin: nowDatetime}))
                }
            });
            dispatch(setErroresLoading(false));
        })
        .catch(err => {
            console.log(err);
        })
};


// Remove Ingreso
export const removeIngreso = id => (dispatch, getState) => {
    axios
        .delete(`${API_BOOKMAKERS_URL}ingresos/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_CONTABILIDAD_INGRESO,
                payload: id
            });
            dispatch(createMessage(i18n.t("ingresos:remove_ingreso.message")));
        })
        .catch(err => console.log(err))
};


// Remove Retirada
export const removeRetirada = id => (dispatch, getState) => {
    axios
        .delete(`${API_BOOKMAKERS_URL}retiradas/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_CONTABILIDAD_RETIRADA,
                payload: id
            });
            dispatch(createMessage(i18n.t("ingresos:remove_retirada.message")));
        })
        .catch(err => console.log(err))
};

// Add Ingreso
export const addIngreso = (ingreso) => (dispatch, getState) => {
    return axios
        .post(`${API_BOOKMAKERS_URL}ingresos/`, ingreso, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_CONTABILIDAD_INGRESO,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("ingresos:added_ingreso.message")));
        })
        .catch(err => console.log(err))
};


// Add Retirada
export const addRetirada = (retirada) => (dispatch, getState) => {
    return axios
        .post(`${API_BOOKMAKERS_URL}retiradas/`, retirada, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_CONTABILIDAD_RETIRADA,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("ingresos:added_retirada.message")));
        })
        .catch(err => console.log(err))
};


export const updateSaldoInicialContabilidad = (bookmaker) => (dispatch, getState) => {
    return axios
        .get(`${API_CLIENTES_BOOKMAKERS_URL}${bookmaker.id}/contabilidades/`, tokenConfig(getState))
        .then(res => {
            const firstContabilidad = res.data[0];
            dispatch(updateContabilidad(firstContabilidad.id, {...firstContabilidad, saldo_inicial: bookmaker.saldo_inicial}));
        })
        .catch(err => console.log(err))
}

export const listCanperIdsByEntidad = (entidad) => (dispatch, getState) => {
    dispatch({ type: LIST_CANPER_IDS_BY_ENTIDAD_LOADING });
    axios
    .get(`${API_ENTIDADES_URL}${entidad}/contabilidades/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CANPER_IDS_BY_ENTIDAD,
            payload: res.data,
            entidad
        });
    })
}


export const listContabilidadesByEntidadAndCanperId = (entidad, canper_id) => (dispatch, getState) => {
    dispatch({ type: LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}?entidad=${entidad}&canper_id=${canper_id}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONTABILIDADES_BY_ENTIDAD_CANPERID,
            payload: res.data,
        });
    })
}

export const listCanperIds = () => (dispatch, getState) => {
    dispatch({ type: LIST_CONTABILIDADES_CANPER_IDS_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}canper_ids/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONTABILIDADES_CANPER_IDS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listCanperIdsByGestor = (gestor) => (dispatch, getState) => {
    dispatch({ type: LIST_CONTABILIDADES_CANPER_IDS_LOADING });
    axios
    .get(`${API_CONTABILIDADES_URL}canper_ids/?gestor=${gestor}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONTABILIDADES_CANPER_IDS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};