import React from 'react';
import { useTranslation } from 'react-i18next';
import filterFactory, { textFilter, selectFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import { paginationOptions, getOpciones } from '../../common/BootstrapTableHelper';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { dateFromUStoEU } from '../../../helpers/helpers';
import { getBalanceSumary } from '../../contabilidades/helper';
import NewObjetivoModal from '../../estrategias/asignacion/NewObjetivoModal';


export default function ListResumenTrader(props) {

    const filters = React.useState({
        trader: localStorage.getItem('filters.resumenAdministradorTrader.trader'),
        entidad: localStorage.getItem('filters.resumenAdministradorTrader.entidad'),
        enlace: localStorage.getItem('filters.resumenAdministradorTrader.enlace'),
        canperId: localStorage.getItem('filters.resumenAdministradorGestor.canperId'),
        fechaInicio: localStorage.getItem('filters.resumenAdministradorTrader.fechaInicio'),
        fechaFin: localStorage.getItem('filters.resumenAdministradorTrader.fechaFin'),
        balance: localStorage.getItem('filters.resumenAdministradorTrader.balance'),
        estimacion: localStorage.getItem('filters.resumenAdministradorTrader.estimacion'),
        total: localStorage.getItem('filters.resumenAdministradorTrader.total'),
        estrategia: localStorage.getItem('filters.resumenAdministradorTrader.estrategia'),       
        nBookmakersProgreso: localStorage.getItem('filters.resumenAdministradorTrader.nBookmakersProgreso'),       
        enlace: localStorage.getItem('filters.resumenAdministradorTrader.enlace'),
        objetivo: localStorage.getItem('filters.resumenAdministradorTrader'),
    });
    const { t } = useTranslation();

    let traderFilter = undefined;
    let entidadFilter = undefined;
    let enlaceFilter = undefined;
    let canperIdFilter = undefined;
    let fechaInicioFilter = undefined;
    let fechaFinFilter = undefined;
    let balanceFilter = undefined;
    let estimacionFilter = undefined;
    let estrategiaFilter = undefined;
    let totalFilter = undefined;
    let nBookmakersProgresoFilter = undefined;
    let objetivoFilter = undefined;

    const clearFilters = () => {
      traderFilter('');
      entidadFilter('');
      enlaceFilter('');
      canperIdFilter('');
      fechaInicioFilter('');
      fechaFinFilter('');
      balanceFilter('');
      estrategiaFilter('');
      estimacionFilter('');
      totalFilter('');
      nBookmakersProgresoFilter('');
      objetivoFilter('');
    }

    const addBalance = estrategias => {
      return (estrategias || []).map(e => ({...e, balance: getBalanceSumary(e)}));
    }

    const transformEstrategiasByEstrategia = estrategias => {
      return estrategias.reduce((obj, e) => (
        obj.hasOwnProperty(`${e['asignacion']['id']}-${e['canper_id']}`) ?
          {...obj, [`${e.asignacion.id}-${e.canper_id}`]: [...obj[`${e.asignacion.id}-${e.canper_id}`], e]} :
          {...obj, [`${e.asignacion.id}-${e.canper_id}`]: [e]})
        , {});
    }

    const transformEstrategiasIntoTable = (estrategias) => {
        return estrategias.map(e => ({
          'fecha_inicio_filter': e.fecha_inicio ? e.fecha_inicio : "",
          'fecha_inicio_date': e.fecha_inicio ? dateFromUStoEU(e.fecha_inicio) : "",
          'fecha_fin_filter': e.fecha_fin ? e.fecha_fin : "",
          'fecha_fin_date': e.fecha_fin ? dateFromUStoEU(e.fecha_fin) : "",
          'trader': e.asignacion.trader.username,
          'estrategia': e.asignacion.id,
          'canper_id': e.canper_id,
          'enlace': e.bookmaker.cliente.enlace,
          'objetivo': e.asignacion.objetivo.objetivo,
          'acciones': <NewObjetivoModal asignacion={{'asignacion': e.asignacion, 'entidad': e.entidad, 'bookmaker': e.bookmaker}} fromResumen={true} />,
          'nBookmakersProgreso': e.saldo_final ? 0 : props.estrategias.reduce((acc, est) => est.asignacion.id === e.asignacion.id && !est.saldo_final ? 1 + acc : acc, 0),
            ...e
        }))
    }

    const estrategiasIntoGrouping = estrategiasByEstrategia => {
      const balances = Object.values(estrategiasByEstrategia).map(e => e.reduce((sum, b) => b.fecha_fin && b.balance ? sum + parseFloat(b.balance) : sum, 0)).map(b => b.toFixed(2));
      const estimaciones = Object.values(estrategiasByEstrategia).map(e => e.reduce((sum, b) => !b.fecha_fin && b.estimaciones && b.estimaciones.length > 0 ? sum + parseFloat(b.balance) : sum, 0)).map(b => b.toFixed(2));
      const fechas_inicios = Object.values(estrategiasByEstrategia).map(e => (new Date(Math.min(...e.map(f => new Date(f.fecha_inicio)))))).map(f => f.toLocaleDateString("sv-SE"))
      const fechas_finales = Object.values(estrategiasByEstrategia).map(e => (new Date(Math.max(...e.map(f => f.fecha_fin ? new Date(f.fecha_fin) : new Date()))))).map(f => f.toLocaleDateString("sv-SE"))

      return Object.keys(estrategiasByEstrategia).map((estrategia, i) => ({...estrategiasByEstrategia[estrategia][0], 'asignacionCanperId': estrategia, 'fecha_inicio': fechas_inicios[i], 'fecha_fin': fechas_finales[i], 'balance': balances[i], 'estimacion': estimaciones[i], 'total': parseFloat(parseFloat(balances[i]) + parseFloat(estimaciones[i])).toFixed(2)}))
    }

    const estrategias = addBalance(props.estrategias);
    const estrategiasByEstrategia = transformEstrategiasByEstrategia(estrategias);
    const estrategiasGrouping = estrategiasIntoGrouping(estrategiasByEstrategia);

    const rows = transformEstrategiasIntoTable(estrategiasGrouping);

    const headers = [
            
      {
        text: t("Id"), 
        dataField: 'canper_id',
        filter: textFilter({
          defaultValue: filters.estrategia,
          getFilter: (filter) => {
            canperIdFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.canperId', value);
          },
        }),
        sort: true
      },
      {
        text: 'Trader', 
        dataField: 'trader',
        filter: selectFilter({
          defaultValue: filters.entidad,
          options: getOpciones('trader', rows),
          getFilter: (filter) => {
            traderFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.entidad', value);
          },
        }),
        sort: true
      },
      {
        text: t('clientes:link.label'), 
        dataField: 'enlace',
        filter: textFilter({
            defaultValue: filters.enlace,
            getFilter: (filter) => {
            enlaceFilter = filter;
            },
            onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.enlace', value);
            },
        }),
        sort: true
    },
      {
        text: t("estrategias:strategy.label"), 
        dataField: 'estrategia',
        filter: textFilter({
          defaultValue: filters.estrategia,
          getFilter: (filter) => {
            estrategiaFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.estrategia', value);
          },
        }),
        sort: true
      },
      {
        text: t("contabilidades:entity.label"), 
        dataField: 'entidad',
        filter: selectFilter({
          defaultValue: filters.entidad,
          options: getOpciones('entidad', rows),
          getFilter: (filter) => {
            entidadFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.entidad', value);
          },
        }),
        sort: true
      },
      {
        text: t("clientes:link.label"), 
        dataField: 'enlace',
        filter: textFilter({
          defaultValue: filters.enlace,
          getFilter: (filter) => {
            enlaceFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.enlace', value);
          },
        }),
        sort: true
      },
      {
        text: t("common:in_progress.label"), 
        dataField: 'nBookmakersProgreso',
        filter: textFilter({
          defaultValue: filters.nBookmakersProgreso,
          getFilter: (filter) => {
            nBookmakersProgresoFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.nBookmakersProgreso', value);
          },
        }),
        sort: true
      },
      {
        text: t("contabilidades:date_start.label"), 
        dataField: '',
        formatter: (cell, row) => row.fecha_inicio_date,         
        filter: textFilter({
          defaultValue: filters.fechaInicio,
          getFilter: (filter) => {
            fechaInicioFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.fechaInicio', value);
          },
        }),
        sort: true
      },
      {
        text: t("contabilidades:date_end.label"), 
        dataField: 'fecha_fin_filter',
        formatter: (cell, row) => row.fecha_fin_date,
        filter: textFilter({     
          defaultValue: filters.fechaFin,
          getFilter: (filter) => {
            fechaFinFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.fechaFin', value);
          },
        }),
        sort: true
      },
      {
        text: 'Balance', 
        dataField: 'balance',
        filter: textFilter({
          defaultValue: filters.balance,
          getFilter: (filter) => {
            balanceFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.balance', value);
          },
        }),
        sort: true
      },
      {
        text: t("contabilidades:estimation.label"),
        dataField: 'estimacion',
        filter: textFilter({
          defaultValue: filters.estimacion,
          getFilter: (filter) => {
            estimacionFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.estimacion', value);
          },
        }),
        sort: true
      },    
      {
        text: 'Total', 
        dataField: 'total',
        filter: textFilter({
          defaultValue: filters.total,
          getFilter: (filter) => {
            totalFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.total', value);
          },
        }),
        style: (cell, row, rowIndex, colIndex) => {
          if (parseFloat(row.total) < 0) {
            return {
              backgroundColor: '#e69aa6'
            };
          }
        },
        sort: true
      },
      {
        text: t("estrategias:target.label"),
        dataField: 'objetivo',
        filter: numberFilter({
          defaultValue: {
            number: filters.objetivo,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            objetivoFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorTrader.objetivo', value);
          },
        }),
        style: (cell, row, rowIndex, colIndex) => {
          if (parseFloat(row.objetivo) > parseFloat(row.total)) {
            return {
              backgroundColor: '#e69aa6'
            };
          }
        },
        sort: true
      },
      {
        text: "",
        dataField: 'acciones',
        sort: false
      }
    ]

    return <PaginationProvider  pagination={ paginationFactory(paginationOptions(rows.length)) }>
    {
      ({
        paginationProps,
        paginationTableProps
      }) => (
        <>
        <div className="pagination-table">
          <Row >
            <Col>
              <Button color="secondary" size="sm" onClick={clearFilters}>{t('common:remove_filters.label')}</Button>
            </Col>
            <Col className="col-auto">
              <PaginationListStandalone
                { ...paginationProps }
              />
            </Col>
            <Col className="col-auto">
              <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
            </Col>
          </Row>
        </div>
        <BootstrapTable
            bootstrap4
            hover
            condensed
            bordered={ false }
            keyField='id'
            data={rows}
            columns={ headers }
            filter={ filterFactory() }
            defaultSorted={ [{dataField: 'id', order: 'asc'}] }
            wrapperClasses="resumen-traders-datatable"
            { ...paginationTableProps }
            />
        </>
        )}
    </PaginationProvider>
}
