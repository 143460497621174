import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import { removeBanco } from "../../../redux/actions/banco";
import { withTranslation } from "react-i18next";
import {CloseButton,DeleteButton} from "../../common/buttons/Buttons";



class DeleteBancoModal extends Component {
  state = {
    modal: false
  };


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteBanco = () => {
    this.props.removeBanco(this.props.id)
    this.toggle()
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <DeleteButton onClick={this.toggle} />
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} {t("banco:bank.label")}
          </ModalHeader>
          <ModalBody>
            {t("banco:remove_confirmation.label")}
          </ModalBody>
          <ModalFooter>
            <CloseButton onClick={this.toggle} label={t("cancel.label")} style={{margin: "1px"}}/>
            <DeleteButton onClick={this.deleteBanco} label={t("remove.label")} style={{margin: "1px"}}/>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeBanco})(withTranslation(['common', 'banco'])(DeleteBancoModal));