import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import NewConexionModal from "./NewConexionModal";
import DeleteConexionprovModal from "./DeleteConexionModal";
import { Col, Row, ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {paginationOptions, CustomToggleList, helperFormatter} from '../common/BootstrapTableHelper';
import {CustomButton} from "../common/buttons/Buttons";
import ExportConexionesprovModal from "./exportacion/ExportConexionesprovModal";
import ImportConexionesprovModal from "./importacion/ImportConexionesprovModal";
import BulkDeleteConexionModal from "./BulkDeleteConexionModal";
import AssignConexionesModal from "./AssignConexionesModal";
const OBSERVACIONES_STRING_LIMIT = 17;


class ListConexiones extends React.Component {

    state = {
      dropdownOpen: false,
      selectedRows: [],
        filters: {
            anydesk_id: localStorage.getItem('filters.conexionprov.anydesk_id'),
            anydesk_contraseña: localStorage.getItem('filters.conexionprov.anydesk_contraseña'),
            ip_id: localStorage.getItem('filters.conexionprov.ip_id'),
            clave_ip_id: localStorage.getItem('filters.conexionprov.clave_ip'),
            ip: localStorage.getItem('filters.conexionprov.ip'),
            entidad: localStorage.getItem('filters.conexionprov.entidad'),
            enlace: localStorage.getItem('filters.conexionprov.enlace'),
            trader: localStorage.getItem('filters.conexionprov.trader'),
            gestor: localStorage.getItem('filters.conexionprov.gestor')
          },
        
    }

    anydesk_idFilter;
    anydesk_contraseñaFilter;
    ip_idFilter;
    clave_ip_idFilter;
    ipFilter;
    entidadFilter;
    enlaceFilter;
    traderFilter;
    gestorFilter;

   

    clearFilters = () => {
      this.anydesk_idFilter && this.anydesk_idFilter('');
      this.anydesk_contraseñaFilter && this.anydesk_contraseñaFilter('');
      this.ip_idFilter && this.ip_idFilter('');
      this.clave_ip_idFilter && this.clave_ip_idFilter('');
      this.ipFilter && this.ipFilter('');
      this.entidadFilter && this.entidadFilter('');
      this.enlaceFilter && this.enlaceFilter('');
      this.traderFilter && this.traderFilter('');
      this.gestorFilter && this.gestorFilter('');
    }

    toggle = () => {
      this.setState(previous => ({
          dropdownOpen: !previous.dropdownOpen
      }));
    };

    transformApuestasIntoTable = (conexionesprov, t) => {
        return conexionesprov.map((conexion) => ({
        'trader' : conexion.pertenece_trader ? <i className="fa fa-check"></i> : "",
        'gestor' : conexion.pertenece_gestor ? <i className="fa fa-check"></i> : "",
        'acciones': <>
        {this.props.permissions.conexionprov.includes("change") && <NewConexionModal create={false} conexionprov={conexion} clientes={this.props.clientes} />}
        {this.props.permissions.conexionprov.includes("delete") && <DeleteConexionprovModal create={false} id={conexion.id} />}
        </>,
        ...conexion
        }));
    };

    ObservacionesFormatter = (cell, row) => {
      return cell && cell.length > OBSERVACIONES_STRING_LIMIT ?
      helperFormatter(cell.substring(0, OBSERVACIONES_STRING_LIMIT), true) :
      cell
    }
    
    handleOnSelect = (row, isSelect) => {
      if (isSelect) {
        this.setState(() => ({
          selectedRows: [...this.state.selectedRows, row.id]
        }));
      } else {
        this.setState(() => ({
          selectedRows: this.state.selectedRows.filter(x => x !== row.id)
        }));
      }
    }
  
    handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      isSelect ?
      this.setState(() => ({selectedRows: ids})) :
      this.setState(() => ({selectedRows: []}));
    }

    render() {
        const { t } = this.props;
        const conexiones = this.props.conexionesprov;
        const rows = this.transformApuestasIntoTable(conexiones);
        const headers = [{
            text: 'Anydesk',
            dataField: 'anydesk_id',
            filter: textFilter({
              defaultValue: this.state.filters.anydesk_id,
              getFilter: (filter) => {
                this.anydesk_idFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.conexionesprov.anydesk_id', value);
              },
            }),
            sort: true
          },
          {
            text: t("anydesk_password.label"), 
            dataField: 'anydesk_contraseña',
            filter: textFilter({
              defaultValue: this.state.filters.anydesk_contraseña,
              getFilter: (filter) => {
                this.traderFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.conexionesprov.anydesk_contraseña', value);
              },
            }),
            sort: true
          },
          {
            text: 'Ip id', 
            dataField: 'ip_id',
            filter: textFilter({
              defaultValue: this.state.filters.ip_id,
              getFilter: (filter) => {
                this.ip_idFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.conexionesprov.ip_id', value);
              },
            }),
            sort: true
          },
          {
            text: t("key_ip.label"), 
            dataField: 'clave_ip_id',
            filter: textFilter({
              defaultValue: this.state.filters.clave_ip_id,
              getFilter: (filter) => {
                this.clave_ip_idFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.conexionesprov.clave_ip_id', value);
              },
            }),
            sort: true
          },
          {
            text: "Ip", 
            dataField: 'ip',
            filter: textFilter({
              defaultValue: this.state.filters.ip,
              getFilter: (filter) => {
                this.ipFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.conexionesprov.ip', value);
              },
            }),
            sort: true
          },
          {
            text: t('cuentas:entity.label'), 
            dataField: 'entidad',
            filter: textFilter({
              defaultValue: this.state.filters.entidad,
              getFilter: (filter) => {
                this.entidadFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.conexionesprov.entidad', value);
              },
            }),
            sort: true
          },
          {
            text: t('clientes:link.label'), 
            dataField: 'cliente.enlace',
            filter: textFilter({
              defaultValue: this.state.filters.enlace,
              getFilter: (filter) => {
                this.enlaceFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.conexionesprov.enlace', value);
              },
            }),
            sort: true
          },
          {
            text: "Trader", 
            dataField: 'trader',
            filter: textFilter({
              defaultValue: this.state.filters.cliente,
              getFilter: (filter) => {
                this.clienteFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.conexionesprov.cliente', value);
              },
            }),
            sort: true
          },{
            text: "Gestor", 
            dataField: 'gestor',
            filter: textFilter({
              defaultValue: this.state.filters.cliente,
              getFilter: (filter) => {
                this.clienteFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.conexionesprov.cliente', value);
              },
            }),
            sort: true
          },
          {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]

        const selectRow = {
          mode: 'checkbox',
          clickToSelect: false,
          bgColor: '#D5F2F7',
          onSelect: this.handleOnSelect,
          onSelectAll: this.handleOnSelectAll
        };

        

        return (
          <PaginationProvider  pagination={ paginationFactory(paginationOptions(conexiones.length)) }>
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
              <ToolkitProvider keyField='id' data={ rows } columns={ headers } columnToggle>
              {
                ({
                  columnToggleProps,
                  baseProps
                }) => (
              <>
               <div className="pagination-table">
                <Row >
                  <Col>
                  <CustomButton color="secondary" style={{margin: "1px"}} onClick={this.clearFilters} label={t('common:remove_filters.label')} />
                  </Col>
                  <Col className="col-auto">
                    <CustomToggleList { ...columnToggleProps} label={t("common:columns.label")} entity='conexionesprov' />
                  </Col>
                  <Col className="col-auto">
                    <PaginationListStandalone
                      { ...paginationProps }
                    />
                  </Col>
                  <Col className="col-auto">
                    <SizePerPageDropdownStandalone
                      { ...paginationProps }
                    />
                  </Col>
                  <Col className="col-auto">
                    { this.props.permissions.conexionprov.includes("view") &&
                    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                      <DropdownToggle caret size="sm" color="info">
                          {t('common:actions.label')}
                      </DropdownToggle>
                      <DropdownMenu persist={true}>
                          <ExportConexionesprovModal conexionesprov={conexiones} selectedConexionesprov={this.state.selectedRows}/>
                          <ImportConexionesprovModal test={this.props.clientes} />
                          {<BulkDeleteConexionModal selectedConexionesprov={this.state.selectedRows}/>}
                          {<AssignConexionesModal conexionesprov={conexiones} clientes={this.props.clientes}/>}
                      </DropdownMenu>
                    </ButtonDropdown>
                    }
                  </Col>                
                  <Col className="col-auto">
                    { this.props.permissions.conexionprov.includes("add") && <NewConexionModal create={true} clientes={this.props.clientes}/>}
                  </Col>
                </Row>
              </div>
              <BootstrapTable
                bootstrap4
                hover
                condensed
                bordered={ false }
                data={rows}
                columns={ headers }
                filter={ filterFactory() }
                selectRow={ selectRow }
                { ...paginationTableProps }
                { ...baseProps }
              />
              </>
              )}
              </ToolkitProvider>
          )}
          </PaginationProvider>
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions
  });

export default connect(mapStateToProps, {})(withTranslation('conexionesprov', 'common')(ListConexiones));