import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

// Esta funcion espera un objeto de la siguiente forma
// {'sheet_name': [{col1: value1, col2: value2}, {col1: value5, col: value6}]}
export const exportToXLSX = (data, fileName) => {
    let wb = XLSX.utils.book_new()
    for (let [entidad, entidadData] of Object.entries(data)) {
        const ws = XLSX.utils.json_to_sheet(entidadData);
        XLSX.utils.book_append_sheet(wb, ws, entidad)
    }

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
    const dataToSave = new Blob([excelBuffer], {type: fileType});

    FileSaver.saveAs(dataToSave, fileName + fileExtension);
}
