import React, {useEffect} from 'react';
import { connect } from "react-redux";
import { listEntidades } from "../../../redux/actions/cuentas";
import ListResumenComparison from './ListResumenComparison';
import Spinner from 'reactstrap/lib/Spinner';


function ResumenComparisonView(props) {
    useEffect(() => {
        !props.entidades && !props.isLoadingEntidades && props.listEntidades()
    });

    return <>
            {!props.permissions ? null :
            props.isLoadingEntidades === false ?
            <ListResumenComparison entidades={props.entidades}/> :
            <Spinner animation="border" variant="primary"/>}
    </>;
}


const mapStateToProps = state => ({
    entidades: state.cuentas.entidades,
    isLoadingEntidades: state.cuentas.isLoadingEntidades,
    permissions: state.cuentas.permissions.estrategia && state.cuentas.permissions.contabilidad,
});

export default connect(mapStateToProps, {listEntidades})(ResumenComparisonView);