import {LIST_USERS_BY_GROUPS, LIST_USERS_BY_GROUP, LIST_USERS_BY_GROUP_LOADING} from "../types/cuentas"

const initialState = {
    trader: null
};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_USERS_BY_GROUP:
            const group = action.group;

            return {
                ...state,
                [group]: action.payload,
                isLoading: false
            }
        case LIST_USERS_BY_GROUPS:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            }
        case LIST_USERS_BY_GROUP_LOADING:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state;
    }
}