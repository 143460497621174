import { API_MONEDEROS_URL, API_CLIENTES_URL } from "../../constants";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { ADD_MONEDERO, UPDATE_MONEDERO, REMOVE_MONEDERO, LIST_MONEDEROS_BY_CLIENTE_ID } from "../types/monederos";
import { createMessage } from "../actions/mensajes";
import { setErrores, setErroresLoading } from "../actions/errores";
import i18n from '../../translations/i18n';

const MONEDEROS = 'MONEDEROS';


// Add monedero
export const addMonedero = (monedero) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, MONEDEROS));
    return axios
        .post(`${API_MONEDEROS_URL}`, monedero, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_MONEDERO,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("monederos:added_wallet.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': MONEDEROS, 'id': monedero.id}));
        })
};


// Remove monedero
export const removeMonedero = id => (dispatch, getState) => {
    axios
        .delete(`${API_MONEDEROS_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_MONEDERO,
                payload: id
            });
            dispatch(createMessage(i18n.t("monederos:remove_wallet.message")));
        })
        .catch(err => console.log(err));
};

// Update monedero
export const updateMonedero = (id, monedero) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, MONEDEROS));
    axios
        .put(`${API_MONEDEROS_URL}${id}/`, monedero, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_MONEDERO,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("monederos:updated_wallet.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': MONEDEROS, 'id': id}));
        })
};


// List Monederos
export const listMonderosByClienteId = (clienteId) => (dispatch, getState) => {
    axios
        .get(`${API_CLIENTES_URL}${clienteId}/monederos/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_MONEDEROS_BY_CLIENTE_ID,
                payload: res.data
            });
        })
        .catch(err => {
            if (err.response.status === 403) {
                dispatch({
                    type: LIST_MONEDEROS_BY_CLIENTE_ID,
                    payload: 'unauthorized'
                });
            }
            console.log(err);
        });
};