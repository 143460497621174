import axios from 'axios';
import { SET_ERRORES, SET_ERRORES_LOADING, SET_EMPTY_ERRORES } from '../types/errores';

// SET ERRORES
export const setErrores = (response, context) => {
    const error = {
        msg: response.data,
        status: response.status,
        context
    }
    return {
        type: SET_ERRORES,
        payload: error
    }
};


export const setManualErrores = (msg, context) => {
    const error = {
        msg,
        status: 400,
        context
    }
    return {
        type: SET_ERRORES,
        payload: error
    }
};


export const setEmptyErrores = () => {
    return {
        type: SET_EMPTY_ERRORES,
    }
};


export const setErroresLoading = (isLoading, context=false) => {
    const loading = {
        isLoading,
        context
    }
    return {
        type: SET_ERRORES_LOADING,
        payload: loading
    }
};


export const sendErrorToSlack = (error, errorInfo) => (dispatch, getState) => {

    const user = getState().cuentas.user;
    const userGroup = getState().cuentas.permissions_group;

    const attachments =  [
      {
        'title': `Error: ${error.toString()}`,
        'color': 'danger',
        'fields': [
          {
            "title": "User",
            "value": `id: ${user.id} username: ${user.username}`,
            "short": true,
          },
          {
            "title": "User Group",
            "value": userGroup,
            "short": true,
          },
        ],
      },
    ]

    // add main error message body
    attachments.push({
        'color': 'danger',
        'title': 'Details',
        'text': error.stack,
        'ts': new Date(),
    })
    attachments.push({
    'color': 'danger',
    'title': 'Details 2',
    'text': errorInfo.componentStack,
    'ts': new Date(),
    })
    
    const data = JSON.stringify({
        attachments: attachments
    });


    axios
        .post(process.env.REACT_APP_SLACK_WEBHOOK_ERROR, data)
        .catch(err => console.log(err))
};


export const sendIncidenceToSlack = (title, description) => (dispatch, getState) => {

    const user = getState().cuentas.user;
    const userGroup = getState().cuentas.permissions_group;

    const attachments =  [
      {
        'title': `Incidencia: ${title}`,
        'color': 'danger',
        'fields': [
          {
            "title": "User",
            "value": `id: ${user.id} username: ${user.username}`,
            "short": true,
          },
          {
            "title": "User Group",
            "value": userGroup,
            "short": true,
          },
        ],
      },
    ]

    // add main error message body
    attachments.push({
        'title': 'Details',
        'text': description,
        'ts': new Date(),
    })
    const data = JSON.stringify({
        attachments: attachments
    });

    axios
        .post(process.env.REACT_APP_SLACK_WEBHOOK_INCIDENCE, data)
        .catch(err => console.log(err))
};