import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import { removeComercial } from "../../redux/actions/comerciales";
import { withTranslation } from "react-i18next";
import {DeleteButton, CustomButton} from "../common/buttons/Buttons";


class DeleteComercialModal extends Component {
  state = {
    modal: false
  };


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteComercial = () => {
    this.props.removeComercial(this.props.id)
    this.toggle()
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <DeleteButton onClick={this.toggle}/>
        
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} {t("comerciales:comercial.label")}
          </ModalHeader>
          <ModalBody>
            {t("comerciales:remove_confirmation.label")}
          </ModalBody>
          <ModalFooter>
          <CustomButton color="outline-secondary"  onClick={() => {this.setState({'forceModalClose': true}); this.toggle()}} label={t("cancel.label")} style={{margin: "1px"}} />
          <DeleteButton  onClick={this.deleteComercial} label={t("remove.label")} style={{margin: "1px"}}/>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeComercial})(withTranslation(['common', 'comerciales'])(DeleteComercialModal));