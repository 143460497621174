import { CREATE_MENSAJE } from '../types/mensajes';

const initialState = {
    mensaje: ""
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CREATE_MENSAJE:
            return (state = action.payload);
        default:
            return state;
    }
}