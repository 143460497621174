import { FormControlLabel, TextField } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";


export default function Notificaciones(props) {
    const { t } = useTranslation();
    
    return <>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.channels.email}
                onChange={e => props.onChangeChannels(e.target.name, e.target.checked)}
                name="email"
                color="primary"
              />
            }
            label="Email"
          />

          {props.channels.email && 
          <TextField
          name="email"
          required
          onChange={(e) => props.onChangeProfile(e.target.name, e.target.value)}
          value={props.profile.email} />}

        </FormGroup>
      </>;
}