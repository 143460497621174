import {LIST_RASPBERRIES, REMOVE_RASPBERRY, UPDATE_RASPBERRY, ADD_RASPBERRY, LIST_RASPBERRY, LIST_RASPBERRIES_LOADING,  } from "../types/raspberries";
import {LIST_CONEXIONES, UPDATE_CONEXION, UPDATE_CONEXIONES, ADD_CONEXION, REMOVE_CONEXION, LIST_CONEXIONES_BY_RASPBERRY_ID } from "../types/conexiones";
import {UPDATE_CONEXION_CLIENTE, ADD_CONEXION_CLIENTE, REMOVE_CONEXION_CLIENTE, LIST_CONEXIONES_CLIENTES_BY_RASPBERRY_ID} from "../types/conexion_cliente"

const initialState = {
    raspberries: null
};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_RASPBERRIES:
            return {
                ...state,
                raspberries: action.payload,
                isLoading: false
            }
        case LIST_RASPBERRIES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case REMOVE_RASPBERRY:
            return {
                ...state,
                // Devuelve todos los raspberries excepto el borrado
                raspberries: state.raspberries.filter(raspberry => raspberry.id !== action.payload)
            }
        case ADD_RASPBERRY:
            return {
                ...state,
                raspberries: [...state.raspberries, action.payload]
            }
        case LIST_RASPBERRY:
            return {
                ...state,
                raspberry: action.payload,
            }

        case UPDATE_RASPBERRY:
            return {
                ...state,
                raspberries: (state.raspberries || []).map(raspberry => raspberry.id !== action.payload.id ? raspberry : action.payload),
                raspberry: action.payload
            }

        // Conexiones reducers
        case UPDATE_CONEXION:
            return {
                ...state,
                conexiones: state.conexiones.map(c => c.id === action.payload.id ? action.payload : c)
            }
        case UPDATE_CONEXIONES:
            return {
                ...state,
                conexiones: state.conexiones.map(c => action.payload.find(p => p.id === c.id) ? action.payload.find(p => p.id === c.id) : c)
            }

        case LIST_CONEXIONES:
        case LIST_CONEXIONES_BY_RASPBERRY_ID:
            return {
                ...state,
                conexiones: action.payload
            }

        case ADD_CONEXION:
            return {
                ...state,
                conexiones: [...state.conexiones, action.payload]
            }

        case REMOVE_CONEXION:
            return {
                ...state,
                conexiones: state.conexiones.filter(c => c.id !== action.payload)
            }

        // Conexion Cliente reducers
        case UPDATE_CONEXION_CLIENTE:
            return {
                ...state,
                conexiones_clientes: state.conexiones_clientes.map(c => c.id === action.payload.id ? action.payload : c)
            }
            
        case LIST_CONEXIONES_CLIENTES_BY_RASPBERRY_ID:
            return {
                ...state,
                conexiones_clientes: action.payload
            }

        case ADD_CONEXION_CLIENTE:
            return {
                ...state,
                conexiones_clientes: [...state.conexiones_clientes, action.payload]
            }

        case REMOVE_CONEXION_CLIENTE:
            return {
                ...state,
                conexiones_clientes: state.conexiones_clientes.filter(c => c.id !== action.payload)
            }

        default:
            return state;
    }
}
