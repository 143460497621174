import {LOADING_ASIGNACION_OR_ESTRATEGIA, LIST_ESTRATEGIAS, UPDATE_HISTORIC_ESTRATEGIA, UPDATE_ESTRATEGIA, ADD_ESTRATEGIA, LIST_ESTRATEGIAS_LOADING, LIST_ASIGNACIONES, LIST_ASIGNACIONES_LOADING, ADD_ASIGNACION, UPDATE_ASIGNACION, LIST_HISTORIC_ESTRATEGIAS, LIST_HISTORIC_ESTRATEGIAS_LOADING, ADD_ESTIMACION, LIST_ESTRATEGIAS_ESTIMACIONES, LIST_ESTRATEGIAS_ESTIMACIONES_LOADING, LIST_ACTIVE_ASIGNACIONES, LIST_ACTIVE_ASIGNACIONES_LOADING, LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES, LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES_LOADING, LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES_LOADING, LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES, CLOSE_ESTRATEGIA, LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID, LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID_LOADING, ADD_OBJETIVO, ADD_OBJETIVO_RESUMEN , LIST_ESTRATEGIAS_CANPER_IDS_LOADING, LIST_ESTRATEGIAS_CANPER_IDS} from "../types/estrategias"

const initialState = {
    estrategias: null,
};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_ESTRATEGIAS:
            return {
                ...state,
                estrategias: action.payload,
                isLoadingEstrategias: false
            }
        case LIST_ESTRATEGIAS_LOADING:
            return {
                ...state,
                isLoadingEstrategias: true
            }
        
        case LIST_HISTORIC_ESTRATEGIAS:
            return {
                ...state,
                estrategias_historico: action.payload,
                isLoadingHistoric: false
            }
        case UPDATE_HISTORIC_ESTRATEGIA:
            return {
                ...state,
                estrategias_historico: (state.estrategias_historico || []).map(estrategia => estrategia.id !== action.payload.id ? estrategia : action.payload),
                isLoadingHistoric: false
            }
        case LIST_HISTORIC_ESTRATEGIAS_LOADING:
            return {
                ...state,
                isLoadingHistoric: true
            }
        case CLOSE_ESTRATEGIA:
            const closedEstrategia = state.estrategias.find(e => e.id === action.payload.id)
            return {
                ...state,
                estrategias_historico: [...state.estrategias_historico, {...closedEstrategia, saldo_final: action.payload.saldo_final, fecha_fin: action.payload.fecha_fin}]
            }
        
        case ADD_ESTRATEGIA:
            return {
                ...state,
                estrategias: [...(state.estrategias || []), action.payload],
                isSaveLoading: {...state.isSaveLoading, ADD_ESTRATEGIA: false}
            }

        case UPDATE_ESTRATEGIA:
            return {
                ...state,
                estrategias: (state.estrategias || []).map(estrategia => estrategia.id !== action.payload.id ? estrategia : action.payload),
                estrategia: action.payload,
                isSaveLoading: {...state.isSaveLoading, UPDATE_ESTRATEGIA: false}
            }

        case LIST_ASIGNACIONES:
            return {
                ...state,
                asignaciones: action.payload,
                isLoadingAsignaciones: false
            }
        case LIST_ASIGNACIONES_LOADING:
            return {
                ...state,
                isLoadingAsignaciones: true
            }

        case LIST_ACTIVE_ASIGNACIONES:
            return {
                ...state,
                activeAsignaciones: action.payload,
                isLoadingActiveAsignaciones: false
            }
        case LIST_ACTIVE_ASIGNACIONES_LOADING:
            return {
                ...state,
                isLoadingActiveAsignaciones: true
            }

        case ADD_ASIGNACION:
            return {
                ...state,
                asignaciones: [...(state.asignaciones || []), action.payload],
                activeAsignaciones: [...(state.activeAsignaciones || []), action.payload],
                isSaveLoading: {...state.isSaveLoading, ADD_ASIGNACION: false}
            }

        case UPDATE_ASIGNACION:
            return {
                ...state,
                asignaciones: (state.asignaciones || []).map(asignacion => asignacion.id !== action.payload.id ? asignacion : action.payload),
                asignacion: action.payload,
                isSaveLoading: {...state.isSaveLoading, UPDATE_ASIGNACION: false}
            }

        case LOADING_ASIGNACION_OR_ESTRATEGIA:
            return {
                ...state,
                isSaveLoading: {...state.isLoading, [action.payload]: true}
            }

        case ADD_ESTIMACION:
        return {
            ...state,
            // Se añade las estimaciones junto con las estrategias
            actualesEstimaciones: (state.actualesEstimaciones || []).map(e => e.id === action.payload.estrategia ? {...e, estimaciones: [...e.estimaciones, action.payload]} : e)
        }
        case LIST_ESTRATEGIAS_ESTIMACIONES:
            return {
                ...state,
                estimaciones: action.payload,
                isLoadingEstimaciones: false
            }
        case LIST_ESTRATEGIAS_ESTIMACIONES_LOADING:
            return {
                ...state,
                isLoadingEstimaciones: true
            }

        case LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES:
            return {
                ...state,
                actualesEstimaciones: action.payload,
                isLoadingActualesEstimaciones: false
            }
        case LIST_ACTUALES_ESTRATEGIAS_ESTIMACIONES_LOADING:
            return {
                ...state,
                isLoadingActualesEstimaciones: true
            }

        case LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES_LOADING:
            return {
                ...state,
                isLoadingHistoricEstimaciones: true
            }

        case LIST_HISTORIC_ESTRATEGIAS_ESTIMACIONES:
            return {
                ...state,
                historicEstimaciones: action.payload,
                isLoadingHistoricEstimaciones: false
            }

        case LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID:
            return {
                ...state,
                estrategiasByEntidadAndCanperIdIsLoading: false,
                estrategiasByEntidadAndCanperId: action.payload,
            }

        case LIST_ESTRATEGIAS_BY_ENTIDAD_CANPERID_LOADING:
            return {
                ...state,
                estrategiasByEntidadAndCanperIdIsLoading: true,
            }
        case LIST_ESTRATEGIAS_CANPER_IDS_LOADING:
            return {
                ...state,
                canperIdsIsLoading: true
            }
        case LIST_ESTRATEGIAS_CANPER_IDS:
            return {
                ...state,
                canperIdsIsLoading: false,
                canperIds: action.payload
            }

        case ADD_OBJETIVO:
            return {
                ...state,
                estrategias: (state.estrategias || []).map(e => e.asignacion.id === action.payload.asignacion ? 
                    {...e, asignacion: {...e.asignacion, objetivo: action.payload}}: e)
            }
        case ADD_OBJETIVO_RESUMEN:
            return {
                ...state,
                estimaciones: (state.estimaciones || []).map(e => e.asignacion.id === action.payload.asignacion ? 
                    {...e, asignacion: {...e.asignacion, objetivo: action.payload}}: e)
            }

        default:
            return state;
    }
}
