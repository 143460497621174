import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";
import {addApuesta, updateApuesta} from "../../redux/actions/apuestas";
import {defaultIfEmpty} from "../../helpers/helpers";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import {listBookmakers} from "../../redux/actions/bookmakers";
import {listUsersByGroup} from "../../redux/actions/cuentas"

class NewApuestaModal extends Component {
  state = {
    modal: false,
    forceModalClose: false,
    apuesta: {},
  };

  componentDidMount() {
    this.setState({
      apuesta: this.props.apuesta,
    })
    if (!this.props.bookmakers && !this.props.isLoadingBookmakers) {
      this.props.listBookmakers();
    }
    if (!this.props.traders && !this.props.isLoadingTraders) {
      this.props.listUsersByGroup('trader');
    }
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChange = e => {
    e.preventDefault();
    this.setState(
      { apuesta: {
        ...this.state.apuesta,
        [e.target.name]: e.target.value
      }
    });
  };

  onChangeBookmaker = e => {
    e.preventDefault();
    this.setState(
      { apuesta: {
        ...this.state.apuesta,
        bookmaker: this.props.bookmakers.find(p => p.id === parseInt(e.target.value))
      }
    });
  }

  onChangeTrader = e => {
    e.preventDefault();
    this.setState(
      { apuesta: {
        ...this.state.apuesta,
        trader: this.props.traders.find(p => p.id === parseInt(e.target.value))
      }
    });
  }
  
  createApuesta = e => {
    e.preventDefault();
    this.props.addApuesta(this.state.apuesta);
    this.setState({modal: false});
  }

  editApuesta = e => {
    e.preventDefault();
    let apuesta = this.state.apuesta;
    if (!apuesta.bookmaker) {
      delete apuesta.bookmaker;
    }
    if (!apuesta.trader) {
      delete apuesta.trader;
    }
    this.props.updateApuesta(apuesta.id, apuesta);
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({apuesta: {}})
    }
  };


  render() {
    const create = this.props.create;
    const apuesta = this.state.apuesta || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;
    const { t } = this.props;
    
    return (
      <>
      { this.props.isLoadingBookmakers || this.props.isLoadingTraders ? null :
      <Fragment>
      { create ?
        <Button color="primary" size="sm" onClick={() => this.onClick()}> {t("add.label")} </Button> :
        <Button color="warning" size="sm" onClick={() => this.onClick()}> {t("edit.label")}  </Button>
      }
      <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === apuesta.id|| this.props.isLoading)} toggle={this.toggle} size='lg' backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t('add.label')} ${t("apuestas:bet.label")}`: `${t('edit.label')} ${t("apuestas:bet.label")}`}
        </ModalHeader>
        <ModalBody>
        <LoadingOverlay active={this.props.isLoading} spinner={true}>
          <Row>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="canper_id">Id:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="canper_id"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.canper_id)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="trader">Trader:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="trader" onChange={(e) => this.onChangeTrader(e)} value={apuesta.trader ? defaultIfEmpty(apuesta.trader.id) : ""}>
                      <option value="">{t("common:option.label")}</option>
                        {(this.props.traders || []).map((n, index) => (<option key={index} value={n.id}>{n.username}</option>))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="bookmaker">Bookmaker:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="bookmaker" onChange={(e) => this.onChangeBookmaker(e)} value={apuesta.bookmaker ? defaultIfEmpty(apuesta.bookmaker.id) : ""}>
                      <option value="">{t("common:option.label")}</option>
                        {(this.props.bookmakers || []).map((n, index) => (<option key={index} value={n.id}>{n.bookmaker}</option>))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="usuario">{t("apuestas:user.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="usuario"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.usuario)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="p_l">P/L:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="p_l" onChange={this.onChange} value={defaultIfEmpty(apuesta.p_l)}>
                      <option value="">{t("common:option.label")}</option>
                      <option value="LIVE">LIVE</option>          
                      <option value="PREMATCH">PREMATCH</option>          
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="s_m">S/M:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="s_m" onChange={this.onChange} value={defaultIfEmpty(apuesta.s_m)}>
                      <option value="">{t("common:option.label")}</option>
                      <option value="SIMPLE">SIMPLE</option>          
                      <option value="MULTIPLE">MULTIPLE</option>                 
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="procedencia">{t("apuestas:source.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="procedencia" onChange={this.onChange} value={defaultIfEmpty(apuesta.procedencia)}>
                        <option value="">{t("common:option.label")}</option>
                        <option value="BALANCE">BALANCE</option>          
                        <option value="FREEBET">FREEBET</option>                 
                      </Input>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="fecha">{t("apuestas:date.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="datetime-local"
                      name="fecha"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.fecha)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="beneficio">{t("apuestas:profit.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      name="beneficio"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.beneficio)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="apuesta">{t("apuestas:stake.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      name="apuesta"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.apuesta)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="sobrante">{t("apuestas:odd.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      name="sobrante"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.sobrante)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="potencial">{t("apuestas:potential.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      name="potencial"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.potencial)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="devuelto">{t("apuestas:return.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="number"
                      step="0.01"
                      name="devuelto"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.devuelto)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="mercado">{t("apuestas:market.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="mercado"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.mercado)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="evento">{t("apuestas:event.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="evento"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.evento)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="estado">{t("apuestas:status.label")}</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="estado" onChange={this.onChange} value={defaultIfEmpty(apuesta.estado)}>
                      <option value="">{t("common:option.label")}</option>
                      <option value="WON">WON</option>          
                      <option value="LOST">LOST</option>          
                      <option value="VOIDED">VOIDED</option>          
                      <option value="CASHED OUT">CASHED OUT</option>          
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="observaciones">{t("apuestas:notes.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="observaciones"
                      onChange={this.onChange}
                      value={defaultIfEmpty(apuesta.observaciones)}
                    />
                  </Col>
                </Row>
              </FormGroup>
          </Col>
        </Row>
        </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>
              {t("cancel.label")}
            </Button>
          { create ? 
            (<Button type="button" color="primary" onClick={this.createApuesta}>
              {t("add.label")}
            </Button>) :
            (<Button type="button" color="warning" onClick={this.editApuesta}>
              {t("edit.label")}
            </Button>)
          }
        </ModalFooter>
      </Modal>
    </Fragment> }
    </>
    );
  }
}


const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'APUESTAS',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'APUESTAS' && state.errores.status !== 200 ? state.errores.context.id : false,
  bookmakers: state.bookmakers.bookmakers,
  isLoadingBookmakers: state.bookmakers.isLoading,
  isLoadingTraders: state.usuarios.isLoading,
  traders: state.usuarios.trader,
});

export default connect(mapStateToProps, {addApuesta, updateApuesta, listBookmakers, listUsersByGroup})(withTranslation('common', 'apuestas', 'bookmakers')(NewApuestaModal));