import React from 'react';
import { useTranslation } from 'react-i18next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { getOpciones } from '../../common/BootstrapTableHelper';
import DisplayIngresos from '../../clientes/bookmakers/ingresos/DisplayIngresos';
import DisplayRetiradas from '../../clientes/bookmakers/retiradas/DisplayRetiradas';
import { datetimeFromUStoEU } from '../../../helpers/helpers';
import { getBalance } from '../../contabilidades/helper';
import BootstrapTable from 'react-bootstrap-table-next';
import { Col, Row } from 'reactstrap';
import { COMPLETADA } from '../../../constants';


function ListResumenContabilidadesVsEstrategias(props) {
    const { t } = useTranslation();
    const filters = React.useState({
        canperId: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.canperId'),
        enlace: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.enlace'),
        bookmaker: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.bookmaker'),
        usuario: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.usuario'),
        clave: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.clave'),
        saldoMaximo: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.saldoMaximo'),
        saldoInicial: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.saldoInicial'),
        saldoBono: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.saldoBono'),
        saldoFinal: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.saldoFinal'),
        ingresos: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.ingresos'),
        retiradas: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.retiradas'),
        fechaInicio: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.fechaInicio'),
        fechaFin: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.fechaFin'),
        balance: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.balance'),
        estado: localStorage.getItem('filters.resumenContabilidadesVsEstrategias.estado'),
    });

    let canperIdFilter = undefined;
    let enlaceFilter = undefined;
    let bookmakerFilter = undefined;
    let usuarioFilter = undefined;
    let claveFilter = undefined;
    let saldoMaximoFilter = undefined;
    let saldoInicialFilter = undefined;
    let saldoBonoFilter = undefined;
    let saldoFinalFilter = undefined;
    let ingresosFilter = undefined;
    let retiradasFilter = undefined;
    let fechaInicioFilter = undefined;
    let fechaFinFilter = undefined;
    let balanceFilter = undefined;
    let estadoFilter = undefined;

    const clearFilters = () => {
        canperIdFilter('');
        enlaceFilter('');
        bookmakerFilter('');
        usuarioFilter('');
        claveFilter('');
        saldoMaximoFilter('');
        saldoInicialFilter('');
        saldoBonoFilter('');
        saldoFinalFilter('');
        ingresosFilter('');
        retiradasFilter('');
        fechaInicioFilter('');
        fechaFinFilter('');
        balanceFilter('');
        estadoFilter('');
    }

    const transformIntoTable = (contabilidades, estrategias) => {
        const transformed = [];
        contabilidades.forEach(contabilidad => {

          const selectedEstrategias = contabilidad.fecha_fin ? estrategias.filter(
            e => e.bookmaker.id === contabilidad.bookmaker.id)
            : [];
          
          transformed.push({
            'ingresos': <>
            {contabilidad.bookmaker.ingresos.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia).toFixed(2)), 0)}
            {contabilidad.bookmaker.ingresos && contabilidad.bookmaker.ingresos.length > 0 && <DisplayIngresos ingresos={contabilidad.bookmaker.ingresos} />}
            </>,
            'retiradas': <>
            {contabilidad.bookmaker.retiradas.reduce((acc, r) => acc + parseFloat(parseFloat(r.transferencia).toFixed(2)), 0)}
            {contabilidad.bookmaker.retiradas && contabilidad.bookmaker.retiradas.length > 0 && <DisplayRetiradas retiradas={contabilidad.bookmaker.retiradas} />}
            </>,
            'fecha_inicio_filter': contabilidad.fecha_inicio ? new Date(contabilidad.fecha_inicio).toLocaleDateString("sv-SE") : "",
            'fecha_inicio_date': contabilidad.fecha_inicio ? datetimeFromUStoEU(contabilidad.fecha_inicio) : "",
            'fecha_inicio_format' : new Date(contabilidad.fecha_inicio).toLocaleDateString("sv-SE"),
            'fecha_fin_format': contabilidad.fecha_fin ? new Date(contabilidad.fecha_fin).toLocaleDateString("sv-SE"): "",
            'fecha_fin_date': contabilidad.fecha_fin ? datetimeFromUStoEU(contabilidad.fecha_fin) : "",
            'estado': contabilidad.fecha_fin ? 'COMPLETADA' : contabilidad.bookmaker.estado,
            'balance': getBalance(contabilidad),
            'enlace': contabilidad.bookmaker.cliente.enlace,
            'estrategias': selectedEstrategias,
            'estrategias_balance': selectedEstrategias.reduce((acc, e) => acc + parseFloat(getBalance(e)), 0),
            ...contabilidad});
          });
        return transformed;
    };

    const rows = props.discrepancies ? transformIntoTable(props.contabilidades, props.estrategias).filter(r => r.balance !== r.estrategias_balance.toFixed(2) && r.bookmaker.estado === COMPLETADA):
      transformIntoTable(props.contabilidades, props.estrategias);

    const headers = [
        {
            text: 'Id', 
            dataField: 'canper_id',
            filter: textFilter({
                defaultValue: filters.canperId,
                getFilter: (filter) => {
                canperIdFilter = filter;
                },
                onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.canperId', value);
                },
            }),
            sort: true
        },
        {
          text: t('clientes:link.label'), 
          dataField: 'enlace',
          filter: textFilter({
              defaultValue: filters.enlace,
              getFilter: (filter) => {
              enlaceFilter = filter;
              },
              onFilter: (value) => {
              localStorage.setItem('filters.contabilidades.enlace', value);
              },
          }),
          sort: true
      },
        {
            text: 'Bookmaker', 
            dataField: 'bookmaker.bookmaker',
            filter: textFilter({
              defaultValue: filters.bookmaker,
              getFilter: (filter) => {
                bookmakerFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.bookmaker', value);
              },
            }),
            sort: true
          },
          {
            text: t("bookmakers:user.label"), 
            dataField: 'bookmaker.usuario',
            filter: textFilter({
              defaultValue: filters.usuario,
              getFilter: (filter) => {
                usuarioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.usuario', value);
              },
            }),
            sort: true
          },
          {
            text: t("bookmakers:key.label"), 
            dataField: 'bookmaker.clave_acceso',
            filter: textFilter({
              defaultValue: filters.clave,
              getFilter: (filter) => {
                claveFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.clave', value);
              },
            }),
            sort: true
          },
          {
            text: t("bookmakers:maximum_balance.label"), 
            dataField: 'bookmaker.saldo_maximo',
            filter: textFilter({
              defaultValue: filters.saldoMaximo,
              getFilter: (filter) => {
                saldoMaximoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoMaximo', value);
              },
            }),
            sort: true
          },
          {
            text: t("contabilidades:initial_balance.label"), 
            dataField: 'saldo_inicial',
            filter: textFilter({
              defaultValue: filters.saldoInicial,
              getFilter: (filter) => {
                saldoInicialFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoInicial', value);
              },
            }),
            sort: true
          },
          {
            text: t("ingresos:entries.label"), 
            dataField: 'ingresos',
            filter: textFilter({
              defaultValue: filters.ingresos,
              getFilter: (filter) => {
                ingresosFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.ingresos', value);
              },
            }),
            sort: true
          },
          {
            text: t("ingresos:withdrawals.label"), 
            dataField: 'retiradas',
            filter: textFilter({
              defaultValue: filters.retiradas,
              getFilter: (filter) => {
                retiradasFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.retiradas', value);
              },
            }),
            sort: true
          },
          {
            text: t("contabilidades:date_start.label"), 
            dataField: 'fecha_inicio_format',
            formatter: (cell, row) => row.fecha_inicio_date,         
            filter: textFilter({
              defaultValue: filters.fechaInicio,
              getFilter: (filter) => {
                fechaInicioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.fechaInicio', value);
              },
            }),
            sort: true
          },
          {
            text: t("contabilidades:date_end.label"), 
            dataField: 'fecha_fin_format',
            formatter: (cell, row) => row.fecha_fin_date,
            filter: textFilter({     
              defaultValue: filters.fechaFin,
              getFilter: (filter) => {
                fechaFinFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.fechaFin', value);
              },
            }),
            sort: true
          },
          {
            text: t("contabilidades:final_balance.label"), 
            dataField: 'saldo_final',
            filter: textFilter({
              defaultValue: filters.saldoFinal,
              getFilter: (filter) => {
                saldoFinalFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.saldoFinal', value);
              },
            }),
            sort: true
          },
          {
            text: 'Balance', 
            dataField: 'balance',
            filter: textFilter({
              defaultValue: filters.balance,
              getFilter: (filter) => {
                balanceFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.balance', value);
              },
            }),
            sort: true
          },
          {
            text: t("bookmakers:state.label"), 
            dataField: 'estado',
            align: 'center',
            filter: selectFilter({
              defaultValue: filters.estado,
              options: getOpciones('estado', rows),
              getFilter: (filter) => {
                estadoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.contabilidades.estado', value);
              },
            }),
            sort: true
          },
    ];
    
    const expandRow = {
        renderer: row =>
        (
          <>
            <Row>
              <Col>{t("contabilidades:date_start.label")}</Col>
              <Col>{t("contabilidades:date_end.label")}</Col>
              <Col>Trader</Col>
              <Col>{t("contabilidades:initial_balance.label")}</Col>
              <Col>{t("contabilidades:final_balance.label")}</Col>
              <Col>Balance</Col>
            </Row>
            {row.estrategias.map(e =>
              <Row>
                <Col>{datetimeFromUStoEU(e.fecha_inicio)}</Col>
                <Col>{datetimeFromUStoEU(e.fecha_fin)}</Col>
                <Col>{e.asignacion.trader.username}</Col>
                <Col>{e.saldo_inicial}</Col>
                <Col>{e.saldo_final}</Col>
                <Col>{getBalance(e)}</Col>
              </Row>
            )}
            <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col>TOTAL: </Col>
                <Col>{row.estrategias_balance.toFixed(2)}</Col>
              </Row>
          </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        nonExpandable: props.contabilidades.map(c => !c.fecha_fin ? c.id : undefined).filter(c => c)
      };

      const rowCompletedStyle = (row, rowIndex) => {
        const style = {};
        if (row.fecha_fin) {
          if (row.balance === row.estrategias_balance.toFixed(2)) {
            style.backgroundColor = '#c8e6c9';
          } else {
            style.backgroundColor = '#e69aa6';
          }
        } 
        return style;
      };

    return <>
    { !props.entidad || !props.canperId ? null :
    <BootstrapTable
      bootstrap4
      hover
      condensed
      bordered={ false }
      keyField='id'
      data={rows}
      columns={ headers }
      filter={ filterFactory() }
      defaultSorted={ [{dataField: 'id', order: 'asc'}] }
      wrapperClasses="resumen-traders-datatable"
      expandRow={ expandRow }
      rowStyle={ rowCompletedStyle }
        />
      }
    </>
}

export default ListResumenContabilidadesVsEstrategias;