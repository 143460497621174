const COLS = {
    'anydesk_id': 'Anydesk id',
    'anydesk_contraseña': 'Anydesk contraseña',
    'ip_id': 'Ip id',
    'clave_ip_id': 'Clave ip id',
    'ip': 'Ip'
}



const buildData = (conexionesprov) => {
    return conexionesprov.map(function(a) {
        let payload = {};
        Object.entries(a).forEach(([key, value]) => {
            if (COLS.hasOwnProperty(key)) {
                payload[COLS[key]] = value;
            }
        });
        return payload
    });
}


export const exportConexionesprov = (conexionesprov, selectedConexionesprov) => {
    let data = {};  
    const conexionesprovSelection = conexionesprov.filter((c) => selectedConexionesprov.includes(c.id));
    data['RECORD'] = buildData(conexionesprovSelection)
    
    return data;
}