import React, {useEffect} from 'react';
import { connect } from "react-redux";

import {listUsersByGroups} from "../../redux/actions/cuentas";
import {listCanperIdsByGestor} from "../../redux/actions/contabilidades";
import {addPago} from "../../redux/actions/pagos";
import NewPagoModal from './NewPagoModal';


function NewPagoView(props) {
    
    useEffect(() => {
        !props.gestores && !props.usuarios && !props.gestoresIsLoading && props.listUsersByGroups('gestor,cliente');
    }, []);

    const onChangeGestor = gestorId => {
        props.listCanperIdsByGestor(gestorId);
    }

    return props.permissions && props.gestoresIsLoading === false ?
    <NewPagoModal gestores={props.gestores.concat(props.usuarios)} canperIds={props.canperIds} onChangeGestor={onChangeGestor} addPago={props.addPago} addPagoIsLoading={props.addPagoIsLoading} erroring={props.erroring}/> : 
    null
}


const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.pago && state.cuentas.permissions.pago.includes('add'),
    gestores: state.usuarios.gestor,
    usuarios: state.usuarios.cliente,
    canperIds: state.contabilidades.canperIds,
    gestoresIsLoading: state.usuarios.isLoading,
    addPagoIsLoading: state.errores.isLoading === 'PAGOS',
    erroring: state.errores && state.errores.status && state.errores.context.type === 'PAGOS' && state.errores.status !== 200 ? true : false,
  });


export default connect(mapStateToProps, {listUsersByGroups, listCanperIdsByGestor, addPago})(NewPagoView);