import React from "react";
import { connect } from "react-redux";
import Header from "../common/Header";
import BreadCrumbs from "../common/BreadCrumbs";
import { listConexionesprov } from "../../redux/actions/conexionesprov"
import { withTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import ListConexiones from "./ListConexiones";
import { listClientesActivosWithBookmakers } from "../../redux/actions/clientes"



class ConexionesprovView extends React.Component {

    componentDidMount() {
        if (!this.props.conexionesprov) {
            this.props.listConexionesprov()
        }
        if (!this.props.clientes) {
            this.props.listClientesActivosWithBookmakers();
        }
    }


    render() {
        return (
            <>
            <Header />
            <Container fluid>
                <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'href': '/#/conexionesprov', 'name': 'Conexiones provisionales', 'active': true},
                ]}/>
                { !this.props.conexionesprov ? <Spinner animation="border" variant="primary"/> : 
                <ListConexiones conexionesprov={this.props.conexionesprov} clientes={(this.props.clientes || []).sort((a, b) => (a.entidad > b.entidad) ? 1 : -1)}/>}
            </Container>
            </>
        );
    }
}


const mapStateToProps = state => ({
    conexionesprov:state.conexionesprov.conexionesprov,
    clientes: state.clientes.activeClientes,
});

export default connect(mapStateToProps, {listConexionesprov,listClientesActivosWithBookmakers})(withTranslation('conexionesprov')(ConexionesprovView));