import React, {useEffect} from 'react';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { listContabilidadesWithEstimaciones, listCanperIds, listContabilidadesWithEstimacionesByCanperId } from "../../../redux/actions/contabilidades";
import { listPagos, listPagosByCanperId } from "../../../redux/actions/pagos"
import ListResumenGestor from './ListResumenGestor';
import Spinner from 'reactstrap/lib/Spinner';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import ListResumenGestorPayment from './ListResumenGestorPayment';
import moment from 'moment';
import { Row } from 'reactstrap';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NewPagoView from '../../pagos/NewPagoView';

const useStyles = makeStyles((theme) => ({
    row: {
        marginTop: '12px',
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

function ResumenGestorView(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showPayment, setShowPayment] = React.useState(false);
    const [canperId, setCanperId] = React.useState(moment().format("MMMYYYY").toUpperCase());

    useEffect(() => {
        !props.canperIds && props.listCanperIds();
        !props.contabilidades && props.listContabilidadesWithEstimacionesByCanperId(canperId);
        !props.pagos && props.listPagosByCanperId(canperId);
    }, []);

    const onChangeCanperId = (event) => {
        const canperId = event.target.value;
        setCanperId(canperId);
        canperId === 'ALL' ? props.listContabilidadesWithEstimaciones() : props.listContabilidadesWithEstimacionesByCanperId(canperId);
        canperId === 'ALL' ? props.listPagos() : props.listPagosByCanperId(canperId);
    };

    return <>
    <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Id</InputLabel>
            <Select
                labelId="canper-label"
                id="canper-id"
                value={canperId}
                onChange={onChangeCanperId}
                label="Id"
            >
                {(props.canperIds || []).map(e => 
                    <MenuItem value={e}>{e}</MenuItem>)
                }
                <MenuItem value='ALL'>{t('common:all.label').toUpperCase()}</MenuItem>
            </Select>
    </FormControl>
    {props.isLoading === true ? <Row><Spinner style={{marginTop: '30px', marginLeft: '100px'}} animation="border" color="primary"/></Row> :
        showPayment ?
            <>
                <Tooltip title="" onClick={() => setShowPayment(false)}>
                    <IconButton aria-label="">
                        <FormatAlignJustifyIcon fontSize="large" color="primary"/>
                    </IconButton>
                </Tooltip>
                <NewPagoView/>
                <ListResumenGestorPayment contabilidades={props.contabilidades} pagos={props.pagos} canperId={canperId}/>
            </>
            :
            <>
                <Tooltip title=""  onClick={() => setShowPayment(true)}>
                    <IconButton aria-label="">
                        <AccountBalanceIcon fontSize="large" color="primary"/>
                    </IconButton>
                </Tooltip>
                <ListResumenGestor contabilidades={props.contabilidades} />
            </>
        }
    </>;
}

const mapStateToProps = state => ({
    contabilidades: state.contabilidades.contabilidadesWithEstimaciones,
    canperIds: state.contabilidades.canperIds,
    isLoading: state.contabilidades.isLoadingContabilidadesWithEstimaciones,
    permissions: state.cuentas.permissions.contabilidad,
    pagos: state.pagos.pagos
});

export default connect(mapStateToProps, {listContabilidadesWithEstimaciones, listCanperIds, listContabilidadesWithEstimacionesByCanperId, listPagos, listPagosByCanperId})(ResumenGestorView);