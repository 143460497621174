import React from 'react';
import Datatable from "react-bs-datatable";
import { Row } from 'reactstrap';
import NewBookmakersModal from './NewBookmakersModal';
import DeleteBookmakerModal from './DeleteBookmakerModal';
import NewIngresoModal from './ingresos/NewIngresoModal'
import DisplayIngresos from './ingresos/DisplayIngresos'
import CopyToClipboard from "../../common/CopyToClipboard";
import {addTooltipToObservaciones} from "../../../helpers/helpers";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DisplayRetiradas from './retiradas/DisplayRetiradas';
import NewRetiradaModal from './retiradas/NewRetiradaModal';


const AddCopyToClipboardToProps = props => {
    let newObject = {};
    for (let key in props) {        
        newObject[key] = <CopyToClipboard>{props[key]}</CopyToClipboard>;
    }
  
    return newObject;
  };


class DisplayBookmakers extends React.Component {

    getExistingBookmakers = () => {
        return this.props.bookmakers.map((b) => b.bookmaker);
    }

    render() {
        const {t} = this.props;
        const permissionsBookmaker = this.props.permissions.bookmaker || [];
        const permissionsIngreso = this.props.permissions.ingreso || [];
        const permissionsRetirada = this.props.permissions.retirada || [];
        const header = [
            {title: 'Bookmaker', prop: 'bookmaker', sortable: true},
            {title: t("user.label"), prop: 'usuario'},
            {title: t("key.label"), prop: 'clave_acceso'},
            {title: t("question.label"), prop: 'pregunta_o_pin'},
            {title: t("answer.label"), prop: 'respuesta'},
            {title: t("minimum_balance.label"), prop: 'saldo_inicial'},
            {title: t("maximum_balance.label"), prop: 'saldo_maximo'},
            {title: t("state.label"), prop: 'estado', sortable: true},
            {title: t("notes.label"), prop: 'observaciones'},
            {title: t("common:actions.label"), prop: 'action'},
            permissionsIngreso.includes('view') && {title: `${t('ingresos:entry.label')} ${t('common:actions.label')}`, prop: 'ingreso_action'},
            permissionsRetirada.includes('view') && {title: `${t('ingresos:withdrawal.label')} ${t('common:actions.label')}`, prop: 'retirada_action'},
        ];

        const bookmakers = this.props.bookmakers;
        // Add CopyToClipboard wrapper
        const bookmakersWithClipboard = bookmakers.map((m) => AddCopyToClipboardToProps(m));


        // Add action buttons
        let body = bookmakers.map((b, i) =>({
            ...bookmakersWithClipboard[i],
            'estado': t(`${b.estado}.estado`),
            'observaciones': addTooltipToObservaciones(b.observaciones, 'bookmakers'),
            'action':
            <div>
                <Row>
                    {permissionsBookmaker.includes('change') && <NewBookmakersModal existingBookmakers={this.getExistingBookmakers} editBookmaker={this.props.editBookmaker} create={false} bookmaker={b}/>}
                    {permissionsBookmaker.includes('delete')&& b.estado === 'COMPLETADA' && <DeleteBookmakerModal  initialModalState={false} id={b.id} />}
                </Row>
            </div>,
            'ingreso_action':
            <>
                <Row>
                    {((permissionsIngreso.includes('add') && b.estado === 'EN PROGRESO') || permissionsIngreso.includes('change')) && <NewIngresoModal create={true} bookmakerId={b.id} />}
                    {permissionsIngreso.includes('view') && this.props.ingresos.find(i => i.bookmaker === b.id) && <DisplayIngresos ingresos={this.props.ingresos.filter(i => i.bookmaker === b.id)} />}
                </Row>
            </>,
            'retirada_action':
            <>
                <Row>
                    {((permissionsRetirada.includes('add') && b.estado === 'EN PROGRESO') || permissionsRetirada.includes('change')) && <NewRetiradaModal create={true} bookmakerId={b.id} />}
                    {permissionsRetirada.includes('view') && this.props.retiradas.find(i => i.bookmaker === b.id) && <DisplayRetiradas retiradas={this.props.retiradas.filter(i => i.bookmaker === b.id)} />}
                </Row>
            </>
        }));
        // Añadir total como ultima linea de la tabla
        body = [
            ...body,
            {
                'ingreso_action': `${t('ingresos:entry_total.label')}: ${this.props.ingresos ? this.props.ingresos.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia).toFixed(2)), 0) : 0}`,
                'retirada_action': `${t('ingresos:withdrawal_total.label')}: ${this.props.retiradas ? this.props.retiradas.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia).toFixed(2)), 0) : 0}`,
            }
        ]

        const customLabels = {
            noResults: 'No existen bookmakers'
        };

        return (
            <div className="horizontal-scroll">
                <Datatable
                    tableHeaders={header}
                    tableBody={body}
                    tableClass="striped responsive"
                    labels={customLabels}
                />
            </div>
        );
    }
}


const mapStateToProps = state => ({
    permissions: state.cuentas.permissions
});

export default connect(mapStateToProps)(withTranslation('bookmakers', 'common', 'ingresos')(DisplayBookmakers));