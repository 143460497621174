import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, DropdownItem } from "reactstrap";

import { removeConexion } from "../../../redux/actions/conexiones";
import { withTranslation } from "react-i18next";


class DeleteConexionModal extends Component {
  state = {
    modal: false
  };


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteConexiones = () => {
    this.props.conexiones.map(c => this.props.removeConexion(c.id))
    this.toggle()
    this.props.clearselectedRows()
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <DropdownItem onClick={() => this.toggle()}>
          {t("remove.label")}
          </DropdownItem>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} {t("conexiones:conection.label")}
          </ModalHeader>
          <ModalBody>
            {t("conexiones:remove_confirmation.label")}
            <ul className="list-group mt-2">
                  {this.props.conexiones.map((c, i) => (
                  <li key={i} className="list-group-item list-group-item-primary">
                      {c.canper_id}
                  </li>
                  ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={() => this.toggle()}>
              {t("cancel.label")}
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={this.deleteConexiones}
            >
              {t("remove.label")}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeConexion})(withTranslation(['common', 'conexiones'])(DeleteConexionModal));