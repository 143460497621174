import React from 'react';
import Datatable from "react-bs-datatable";
import { Row } from 'reactstrap';
import NewRangoModal from './NewRangoModal';
import DeleteRangoModal from './DeleteRangoModal ';
import CopyToClipboard from "../common/CopyToClipboard";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const AddCopyToClipboardToProps = props => {
    let newObject = {};
    for (let key in props) {        
        newObject[key] = <CopyToClipboard>{props[key]}</CopyToClipboard>;
    }
  
    return newObject;
  };


class DisplayRangos extends React.Component {

    getPago = (tipo_pago, id) => {
        if (tipo_pago === 'MONEDERO') {
            return this.props.monederoOpciones.find(m => m.id === id).monedero;
        } else if (tipo_pago === 'BANCO') {
            return this.props.bancoOpciones.find(m => m.id === id).banco;
        } else {
            return ""
        }
    }

    render() {
        const {t} = this.props;
        const permissions = this.props.permissions || [];
        const header = [
            {title: t("type.label"), prop: 'tipo'},
            {title: t("minimum.label"), prop: 'minimo'},
            {title: t("maximum.label"), prop: 'maximo'},
            {title: t("bank.label"), prop: 'pago'},
            {title: 'Actions', prop: 'action'}
        ];

        const rangos = this.props.rangos;
        // Add CopyToClipboard wrapper
        const rangosWithClipboard = rangos.map((m) => AddCopyToClipboardToProps(m));


        // Add action buttons
        const body = rangos.map((b, i) =>({
            ...rangosWithClipboard[i],
            'tipo': t(`${b.tipo}.tipo`),
            'pago': this.getPago(b.tipo_pago, b.pago_id),
            'action':
            <div>
                <Row>
                    {permissions.includes('change') && <NewRangoModal editRango={this.props.editRango} create={false} rango={b}/>}
                    {permissions.includes('delete') && <DeleteRangoModal  initialModalState={false} id={b.id} />}
                </Row>
            </div>
        }));

        const customLabels = {
            noResults: 'No existen rangos'
        };

        return (
            <div className="horizontal-scroll">
                <Datatable
                    tableHeaders={header}
                    tableBody={body}
                    tableClass="striped responsive"
                    labels={customLabels}
                />
            </div>
        );
    }
}


const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.rango,
    bancoOpciones: state.opciones.banco,
    monederoOpciones: state.opciones.monedero
});

export default connect(mapStateToProps)(withTranslation('rangos')(DisplayRangos));