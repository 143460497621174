import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CopyToClipboard from "../common/CopyToClipboard";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import NewComercialModal from "./NewComercialModal";
import { Col, Row } from "reactstrap";
import DeleteComercialModal from "./DeleteComercialModal";
import {paginationOptions, getOpciones} from '../common/BootstrapTableHelper';
import {CustomButton} from "../common/buttons/Buttons";


class ListComerciales extends React.Component {

    state = {
        filters: {
            comercial: localStorage.getItem('filters.comerciales.comercial'),
            telefono: localStorage.getItem('filters.comerciales.telefono'),
            valoracion: localStorage.getItem('filters.comerciales.valoracion'),
            pago: localStorage.getItem('filters.comerciales.pago'),
            estado: localStorage.getItem('filters.comerciales.estado')
          }
    }

    comercialFilter;
    telefonoFilter;
    valoracionFilter;
    pagoFilter;

    clearFilters = () => {
        this.comercialFilter('');
        this.telefonoFilter('');
        this.valoracionFilter('');
        this.pagoFilter('');
        this.estadoFilter('');
    }

    transformComercialesIntoTable = (comerciales, t) => {
        return comerciales.map((comercial) => ({
        'comercial':`${comercial.nombre} ${comercial.apellido_1} ${comercial.apellido_2}`,
        'acciones': <>
        {this.props.permissions.comercial.includes("change") && <NewComercialModal create={false} comercial={comercial} />}
        {this.props.permissions.comercial.includes("delete") && <DeleteComercialModal id={comercial.id}/>}
        </>,
        ...comercial
        }));
    };

    addToCopyFormatter = (cell) => {
        return <CopyToClipboard>{cell}</CopyToClipboard>
    }

    render() {
        const { t } = this.props;
        const comerciales = this.props.comerciales;
        const rows = this.transformComercialesIntoTable(comerciales);
        const headers = [{
            text: t("comercial.label"), 
            dataField: 'comercial',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.comercial,
              getFilter: (filter) => {
                this.comercialFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.comerciales.comercial', value);
              },
            }),
            sort: true
          },
          {
            text: t("phone.label"), 
            dataField: 'telefono',
            filter: textFilter({
              defaultValue: this.state.filters.telefono,
              getFilter: (filter) => {
                this.telefonoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.comerciales.telefono', value);
              },
            }),
            sort: true
          },
          {
            text: t("ranking.label"), 
            dataField: 'valoracion',
            align: 'center',
            filter: selectFilter({
              defaultValue: this.state.filters.valoracion,
              options: getOpciones('valoracion', rows),
              getFilter: (filter) => {
                this.valoracionFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.comerciales.valoracion', value);
              },
            }),
            sort: true
          },
          {
            text: t("payment.label"), 
            dataField: 'pago',
            align: 'center',
            filter: selectFilter({
              defaultValue: this.state.filters.pago,
              options: getOpciones('pago', rows),
              getFilter: (filter) => {
                this.pagoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.comerciales.pago', value);
              },
            }),
            sort: true
          },
          {
            text: t("state.label"), 
            dataField: 'estado',
            align: 'center',
            filter: selectFilter({
              defaultValue: this.state.filters.estado,
              options: getOpciones('estado', rows),
              getFilter: (filter) => {
                this.estadoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.comerciales.estado', value);
              },
            }),
            sort: true
          },
          {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]

        return (
        <PaginationProvider  pagination={ paginationFactory(paginationOptions(comerciales.length)) }>
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
            <>
            <div className="pagination-table">
              <Row >
                <Col>
                  <CustomButton color="secondary" onClick={this.clearFilters} label={t('common:remove_filters.label')} style={{margin: "1px"}} />
                </Col>
                <Col className="col-auto">
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </Col>
                <Col className="col-auto">
                  <SizePerPageDropdownStandalone
                    { ...paginationProps }
                  />
                </Col>                
                <Col className="col-auto">
                  { this.props.permissions.comercial.includes("add") && <NewComercialModal create={true} comercial={{}}/>}
                </Col>
              </Row>
            </div>
              <BootstrapTable
                bootstrap4
                hover
                condensed
                bordered={ false }
                keyField='id'
                data={rows}
                columns={ headers }
                filter={ filterFactory() }
                // rowEvents={ this.rowEvents }
                defaultSorted={ [{dataField: 'comercial', order: 'asc'}] }
                wrapperClasses="comerciales-datatable"
                { ...paginationTableProps }
              />
            </>
            )}
        </PaginationProvider>
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions,
  });

export default connect(mapStateToProps, {})(withTranslation('comerciales', 'common')(ListComerciales));