import React from 'react';

import { CopyToClipboard as Clipboard } from 'react-copy-to-clipboard';
import { createMessage } from "../../redux/actions/mensajes";
import { connect } from 'react-redux';


class CopyToClipboard extends React.Component {
  state = {
    value: '',
    copied: false,
  };

  onCopy = () => {
    this.setState({copied: true});
    this.props.createMessage('Copiado');
  };

  render() {
    return (
        <Clipboard text={this.props.children} options={{format:"text/plain"}}
          onCopy={this.onCopy }>
          <span style={{ cursor: "pointer" }}>{this.props.children}</span>
        </Clipboard>
    );
  }
}

export default connect(null, {createMessage})(CopyToClipboard);
