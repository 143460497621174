import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, Button, ModalFooter, ModalBody } from "reactstrap";

import { removeBookmaker } from "../../redux/actions/bookmakers";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";


class DeleteBookmakersModal extends Component {
  state = {
    modal: false,
    redirectToListBookmakers: false
  };


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteBookmakers = () => {
    this.props.removeBookmaker(this.props.id)
    this.toggle()
    this.setState({redirectToListBookmakers: true})
  };

  render() {
    const { t } = this.props;

    return (
      this.state.redirectToListBookmakers ? <Redirect to="/bookmakers"/> :
      <Fragment>
        <Button style={{marginLeft: "5px"}} color="danger" size="sm" onClick={() => this.toggle()}>
          {t("remove.label")}
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} Bookmaker
          </ModalHeader>
          <ModalBody>
            {t("bookmakers:remove_confirmation.label")}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={() => this.toggle()}>
              {t("cancel.label")}
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={this.deleteBookmakers}
            >
              {t("remove.label")}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeBookmaker})(withTranslation(['common', 'bookmakers'])(DeleteBookmakersModal));