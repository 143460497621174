import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import NewBookmakersForm from "./NewBookmakersForm";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { withTranslation } from "react-i18next";

import {EditButton,AddButton,CloseButton} from "../../common/buttons/Buttons";

class NewBookmakerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
    if (this.props.bookmaker) {
      this.setState({bookmaker: this.props.bookmaker})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookmaker && prevState.bookmaker && prevState.bookmaker.id !== prevProps.bookmaker.id) {
      this.setState({bookmaker: prevProps.bookmaker})
    }
  }

  onChange = e => {
    e.preventDefault();
    this.setState(
      { bookmaker: {
        ...this.state.bookmaker,
        [e.target.name]: e.target.value
      }
    });
  };

  createBookmaker = e => {
    e.preventDefault();
    const bookmaker = {...this.state.bookmaker, cliente: this.props.clienteId}
    this.props.createBookmaker(bookmaker);
    this.setState({modal: false});
  }
  
  editBookmaker = e => {
    e.preventDefault();
    this.props.editBookmaker(this.state.bookmaker);
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({bookmaker: {}})
    }
  }

  render() {
    const { t } = this.props;
    const create = this.props.create;
    const bookmaker = this.state.bookmaker || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;  

    return (
    <Fragment>
      { this.props.clienteEstado === "EN PROCESO" &&
      (this.props.create ? <AddButton  onClick={this.onClick} style={{margin: "1px"}}  /> : <EditButton  onClick={this.onClick} style={{margin: "1px"}}  />)}
      <LoadingOverlay active={this.props.isLoading} spinner={true}>
        <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === bookmaker.id || this.props.isLoading)} toggle={this.toggle} backdrop="static" trapFocus={false}>
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t("add.label")} Bookmaker` : `${t("edit.label")} Bookmaker`}
        </ModalHeader>
        <ModalBody>                   
          <NewBookmakersForm onChange={this.onChange} bookmaker={bookmaker} existingBookmakers={this.props.existingBookmakers} create={create}/>
        </ModalBody>
        <ModalFooter>
            <CloseButton onClick={() => {this.setState({'forceModalClose': true});this.toggle()} } label={t("cancel.label")} style={{margin: "1px"}} />
          { create ? <AddButton  onClick={this.createBookmaker} label={t("add.label")} style={{margin: "1px"}}/>: <EditButton onClick={this.editBookmaker} label={t("edit.label")} style={{margin: "1px"}}/>  }
        </ModalFooter>
        </Modal>
      </LoadingOverlay>
    </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'CLIENTES_BOOKMAKERS',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'CLIENTES_BOOKMAKERS' && state.errores.status !== 200 ? state.errores.context.id : false,
  clienteEstado: state.clientes.cliente.estado
});


export default connect(mapStateToProps)(withTranslation('common')(NewBookmakerModal));