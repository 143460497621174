import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, DropdownItem } from "reactstrap";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { exportClientes } from "./ExportClientes" 
import { exportToXLSX } from "../../../utilities/Export";
import { createMessage } from "../../../redux/actions/mensajes";
import { listBookmakersByClienteIds } from "../../../redux/actions/clientes_bookmakers"
import LoadingOverlay from "react-loading-overlay";
import { withTranslation } from "react-i18next";


class ExportClientesModal extends Component {
  state = {
    modal: false,
    selectedEntidades: []
  };

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChangeCheckBox = (checked, entidad) => {
    if (checked) {
      if (entidad === 'bookmakers') {
        this.props.listBookmakersByClienteIds(this.props.selectedClientes)
      }
      this.setState({ selectedEntidades: [...this.state.selectedEntidades, entidad] });
    } else {
      this.setState({ selectedEntidades: this.state.selectedEntidades.filter((e) => e !== entidad) });
    }
  }


  export = (t) => {
    const data = exportClientes(this.props.clientes, this.props.selectedClientes, this.state.selectedEntidades, this.props.bookmakers, this.props.permissions);
    const filename = `export_${this.props.selectedClientes.join('_')}_${this.state.selectedEntidades.join('_')}`;
    exportToXLSX(data, filename)
    this.toggle()
    this.props.createMessage(`${t("ficheros:export.message")}: ${filename}`);
    this.setState({ selectedEntidades: [] });
  }


  render() {
    const {t} = this.props;
    const permissions = this.props.permissions || {};
    return (
      <Fragment>
        <DropdownItem onClick={() => {this.toggle()}}>
          {t("export.label")}
        </DropdownItem>
          <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
            <ModalHeader toggle={this.toggle}>
              {t("export.label")} {t("clientes:client.label")}
            </ModalHeader>
            <LoadingOverlay active={this.props.isLoading} spinner={true}>
            <ModalBody>
              {t("clientes:export.label")}:

              {permissions.cliente && permissions.cliente.includes('view') &&
              <FormControlLabel
                  control={
                  <Checkbox                
                      color="default"
                      value="cliente"
                      onChange={(e) => this.onChangeCheckBox(e.target.checked, e.target.value)}
                  />
                  }
                  label={t("clientes:client.label")}
              />}

              {permissions.banco && permissions.banco.includes('view') &&
              <FormControlLabel
                  control={
                  <Checkbox                
                      color="default"
                      value="banco"
                      onChange={(e) => this.onChangeCheckBox(e.target.checked, e.target.value)}
                  />
                  }
                  label={t("banco:bank.label")}
              />}

              {permissions.monedero && permissions.monedero.includes('view') &&
              <FormControlLabel
                  control={
                  <Checkbox                
                      color="default"
                      value="monederos"
                      onChange={(e) => this.onChangeCheckBox(e.target.checked, e.target.value)}
                  />
                  }
                  label={t("monederos:wallet.label")}
              />}

              {permissions.bookmaker && permissions.bookmaker.includes('view') &&
              <FormControlLabel
                  control={
                  <Checkbox                
                      color="default"
                      value="bookmakers"
                      onChange={(e) => this.onChangeCheckBox(e.target.checked, e.target.value)}
                  />
                  }
                  label="Bookmakers"
              />}

            </ModalBody>
            </LoadingOverlay>
            <ModalFooter>
              <Button type="button" color="outline-secondary" onClick={() => this.toggle()}>
              {t("cancel.label")}
              </Button>
              <Button
                type="button"
                color="info"
                onClick={() => this.export(t)}
              >
                {t("export.label")}
              </Button>
            </ModalFooter>
          </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'BOOKMAKERS',
  bookmakers: state.clientes.bookmakersByClientesIds,
  permissions: state.cuentas.permissions
});


export default connect(mapStateToProps, {createMessage, listBookmakersByClienteIds})(withTranslation(['common', 'clientes', "monederos", "banco", "ficheros"])(ExportClientesModal));