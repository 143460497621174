import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";
import {addComercial, updateComercial} from "../../redux/actions/comerciales";
import {defaultIfEmpty} from "../../helpers/helpers";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import {EditButton,AddButton, CustomButton} from "../common/buttons/Buttons";


class NewComercialModal extends Component {
  state = {
    modal: false,
    forceModalClose: false,
    comercial: {}
  };

  componentDidMount() {
    this.setState({comercial: this.props.comercial})
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChange = e => {
    e.preventDefault();
    this.setState(
      { comercial: {
        ...this.state.comercial,
        [e.target.name]: e.target.value
      }
    });
  };

  
  createComercial = e => {
    e.preventDefault();
    this.props.addComercial(this.state.comercial);
    this.setState({modal: false});
  }

  editComercial = e => {
    e.preventDefault();
    this.props.updateComercial(this.state.comercial.id, this.state.comercial);
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({comercial: {}})
    }
  };


  render() {
    const create = this.props.create;
    const comercial = this.state.comercial || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;
    const { t } = this.props;
    
    return (
    <Fragment>
      { create ?
        <AddButton onClick={ this.onClick} label={t("add.label")} style={{margin: "1px"}}/>:
        <EditButton onClick={ this.onClick} style={{margin: "1px"}}/>
      }
      
      <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === comercial.id || this.props.isLoading)} toggle={this.toggle} size='md' backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t('add.label')} ${t('comerciales:comercial.label')}`: `${t('edit.label')} ${t("comerciales:comercial.label")}`}
        </ModalHeader>
        <ModalBody>
        <LoadingOverlay active={this.props.isLoading} spinner={true}>
          <Row>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="nombre">{t("comerciales:name.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="nombre"
                      onChange={this.onChange}
                      value={defaultIfEmpty(comercial.nombre)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="apellido_1">{t("comerciales:lastname_1.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="apellido_1"
                      onChange={this.onChange}
                      value={defaultIfEmpty(comercial.apellido_1)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="apellido_2">{t("comerciales:lastname_2.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="apellido_2"
                      onChange={this.onChange}
                      value={defaultIfEmpty(comercial.apellido_2)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="telefono">{t("comerciales:phone.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="telefono"
                      onChange={this.onChange}
                      value={defaultIfEmpty(comercial.telefono)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                  <Row>
                    <Col md="4">
                      <Label for="valoracion">{t('comerciales:ranking.label')}:</Label>
                    </Col>
                    <Col md="8">
                      <Input type="select" name="valoracion" onChange={this.onChange} value={defaultIfEmpty(comercial.valoracion)}>
                        <option value="">{t("option.label")}</option>
                        <option value="MALO">{t("comerciales:MALO.valoracion")}</option>
                        <option value="NEUTRO">{t("comerciales:NEUTRO.valoracion")}</option>
                        <option value="BUENO">{t("comerciales:BUENO.valoracion")}</option>
                      </Input>
                    </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                  <Row>
                    <Col md="4">
                      <Label for="pago">{t('comerciales:payment.label')}:</Label>
                    </Col>
                    <Col md="8">
                      <Input type="select" name="pago" onChange={this.onChange} value={defaultIfEmpty(comercial.pago)}>
                        <option value="">{t("option.label")}</option>
                        <option value="IBAN">IBAN</option>
                        <option value="NETELLER">NETELLER</option>
                      </Input>
                    </Col>
                </Row>
              </FormGroup>
            
              <FormGroup>
                  <Row>
                    <Col md="4">
                      <Label for="estado">{t('comerciales:state.label')}:</Label>
                    </Col>
                    <Col md="8">
                      <Input type="select" name="estado" onChange={this.onChange} value={defaultIfEmpty(comercial.estado)}>
                        <option value="">{t("option.label")}</option>
                        <option value="ACTIVO">{t("comerciales:ACTIVO.estado")}</option>
                        <option value="INACTIVO">{t("comerciales:INACTIVO.estado")}</option>
                      </Input>
                    </Col>
                </Row>
              </FormGroup>
       
          </Col>
        </Row>
        </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <CustomButton color="outline-secondary"  onClick={() => {this.setState({'forceModalClose': true});this.toggle()}} label={t("cancel.label")} style={{margin: "1px"}}/>
            { create ?
              <AddButton onClick={ this.createComercial} label={t("add.label")} style={{margin: "1px"}}/>:
              <EditButton onClick={ this.editComercial} label={t("edit.label")} style={{margin: "1px"}}/>
            }
          
        </ModalFooter>
      </Modal>
    </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'COMERCIALES',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'COMERCIALES' && state.errores.status !== 200 ? state.errores.context.id : false,
});

export default connect(mapStateToProps, {addComercial, updateComercial})(withTranslation('common', 'comerciales')(NewComercialModal));