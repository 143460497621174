import React from 'react';
import { useTranslation } from 'react-i18next';
import filterFactory, { textFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import { paginationOptions} from '../common/BootstrapTableHelper';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';


export default function ListResumenGestorPayment(props) {

    const filters = React.useState({
        cobrado: localStorage.getItem('filters.resumenAdministradorGestorPayment.cobrado'),
    });
    const { t } = useTranslation();

    let cobradoFilter = undefined;

    const clearFilters = () => {
      cobradoFilter('');
    }

    const transformIntoTable = (contabilidades) => {
      return contabilidades.map(e => ({
        'cobrado': (props.pagos || []).reduce((sum, p) => p.canper_id === e.canper_id && p.user === e.pertenece_a.id ? sum + parseFloat(p.pago) : sum, 0),
          ...e
      }))
  }

    const rows = transformIntoTable(props.contabilidades);
    
    const headers = [
      {
        text: 'Cobrado', 
        dataField: 'cobrado',
        filter: numberFilter({
          defaultValue: {
            number: filters.cobrado,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            cobradoFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorGestorPayment.cobrado', value);
          },
        }),
        sort: true
      },   
    ]

    return <PaginationProvider  pagination={ paginationFactory(paginationOptions(rows.length)) }>
    {
      ({
        paginationProps,
        paginationTableProps
      }) => (
        <>
        <div className="pagination-table">
          <Row >
            <Col>
              <Button color="secondary" size="sm" onClick={clearFilters}>{t('common:remove_filters.label')}</Button>
            </Col>
            <Col className="col-auto">
              <PaginationListStandalone
                { ...paginationProps }
              />
            </Col>
            <Col className="col-auto">
              <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
            </Col>
          </Row>
        </div>
        <BootstrapTable
            bootstrap4
            hover
            condensed
            bordered={ false }
            keyField='id'
            data={rows}
            columns={ headers }
            filter={ filterFactory() }
            defaultSorted={ [{dataField: 'id', order: 'asc'}] }
            wrapperClasses="resumen-gestores-datatable"
            { ...paginationTableProps }
            />
        </>
        )}
    </PaginationProvider>
}
