import React, {useEffect}  from 'react';
import { connect } from 'react-redux';
import { Row, Col, CardHeader, CardBody, Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {listPrioridadesByCliente} from '../../../redux/actions/bookmakers'
import DisplayPrioridades from './DisplayPrioridades'

function Prioridades(props) {
    const { t } = useTranslation();

    useEffect(() => {
        !props.prioridades && props.listPrioridadesByCliente(props.clienteId)
    ,[]});

    return <>
        {!props.permissions.includes('view') ?
        null :
        <Card style={{ marginTop: "10px" }}>
            <CardHeader>
            <Row>                    
                <Col>
                    <h5>{t('bookmakers:priorities.label')}</h5>
                </Col>                    
            </Row>
            </CardHeader>
            <CardBody>
                {props.prioridades && props.bookmakers && props.all_bookmakers ?
                <DisplayPrioridades prioridades={props.prioridades} all_bookmakers={props.all_bookmakers} existingBookmakers={props.bookmakers}/> :
                <span>{t("bookmakers:dont_exist.label")}</span>
                }
            </CardBody>
        </Card>}
    </>
}

const mapStateToProps = state => ({
    prioridades: state.clientes.prioridades,
    bookmakers: state.clientes.bookmakers,
    all_bookmakers: state.opciones.bookmaker,
    permissions: state.cuentas.permissions.prioridad
  });

export default connect(mapStateToProps, {listPrioridadesByCliente})(Prioridades);