import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { FormGroup, Input, Modal, ModalHeader, ModalFooter, ModalBody, Col, Label, Form, DropdownItem } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { withTranslation } from "react-i18next";
import { updateConexionprov} from "../../redux/actions/conexionesprov";
import {CloseButton} from "../common/buttons/Buttons";


class AssignConexionesModal extends Component {
    state = {
        modal: false,
        conexionesprov: [],
    }

    componentDidMount() {
      this.setState({
        conexionesprov: this.props.conexionesprov,
      })
    }

    componentDidUpdate(prevProps) {
      if (this.props.conexionesprov !== prevProps.conexionesprov) {
        this.setState({
          conexionesprov: this.props.conexionesprov,
        })
      }
    }

    
    onChange = (index, e, checkbox=false) => {
      const attr = e.target.name;
      const value = !checkbox ? e.target.value : e.target.checked

      this.setState({
        conexionesprov: [
           ...this.state.conexionesprov.slice(0, index),
           Object.assign({}, this.state.conexionesprov[index], {[attr]: value}),
           ...this.state.conexionesprov.slice(index + 1)
        ]
      });
    };

    toggle = () => {
        this.setState(previous => ({
          modal: !previous.modal
        }));
    };

    
    close = () => {
            this.state.conexionesprov.forEach(conexion => {
              let clienteId = conexion.cliente.id ? conexion.cliente.id : conexion.cliente;
              let cliente = this.props.clientes.find(c => c.id === parseInt(clienteId));
              this.props.updateConexionprov(conexion.id, {...conexion, cliente});
            });
          // Re-initialize data
          this.setState({
            conexionesprov: this.props.conexionesprov,
          })
          this.toggle();
    };
    

    render() {
        const {t} = this.props;
        const conexiones = this.state.conexionesprov;
        const clientes = this.props.clientes;
        return (
            <Fragment>
              { conexiones.length > 0 && <DropdownItem onClick={() => {this.toggle()}} key="conexionesprov-assignment">{t("conexionesprov:assignment.label")}</DropdownItem>}
              
                <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static" size='xl'>
                  <ModalHeader toggle={this.toggle}>
                    {t("conexionesprov:bulk_assignment.label")}
                  </ModalHeader>
                  <ModalBody>
                  <LoadingOverlay active={this.props.isLoading} spinner={true}>
                    {conexiones.map((conexion, index) =>
                      <Form inline>
                        <FormGroup className="mb-6 mr-sm-2">
                          <Col md={4}>
                            <span style={{fontWeight: "bold"}}>{conexion.ip_id}</span>
                          </Col>
                        </FormGroup>                      
                        <FormGroup className="mb-4 mr-sm-2">
                          <Label md={1} for="cliente">{t("clientes:client.label")}:</Label>
                          <Col md={4}>
                            <Input type="select" name="cliente" onChange={(e) => this.onChange(index,e)} value={conexion.cliente ? conexion.cliente.id : ''}>
                            <option value={null}>{t("estrategias:no_assign.label")}</option>
                              {
                               clientes && clientes.map((t, i) => (<option key={i} value={t.id}>{`${t.entidad} - ${t.enlace}`}</option>))
                              }
                            </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label className="col-form-label" for="pertenece_trader">{t("conexionesprov:trader_connection.label")}:</Label>
                          <Col>
                            <Input
                              type="checkbox"
                              name="pertenece_trader"
                              onChange={(e) => this.onChange(index,e ,true)}
                              checked={conexion.pertenece_trader? true : false}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label" for="pertenece_gestor">{t("conexionesprov:manager_connection.label")}:</Label>
                          <Col>
                            <Input
                              type="checkbox"
                              name="pertenece_gestor"
                              onChange={(e) => this.onChange(index,e ,true)}
                              checked={conexion.pertenece_gestor? true : false}
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    )}
                  </LoadingOverlay>
                  </ModalBody>
                  <ModalFooter>
                    <CloseButton onClick={() => this.toggle()} style={{margin: "1px"}} color={"primary"} label={t("common:cancel.label")} />
                    <CloseButton color={"primary"} onClick={() => this.close()} label= {t("common:save.label")} style={{margin: "1px"}} /> 
                  </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'CONEXIONESPROV',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'CONEXIONESPROV' && state.errores.status !== 200 ? state.errores.context.id : false,
});

export default connect(mapStateToProps, {updateConexionprov})(withTranslation(['common', 'conexionesprov','clientes'])(AssignConexionesModal));