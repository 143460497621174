import React from 'react';
import { useTranslation } from 'react-i18next';
import filterFactory, { selectFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import { getOpciones, paginationOptions} from '../../common/BootstrapTableHelper';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { getBalanceSumary } from '../../contabilidades/helper';
import DisplayPagos from '../../pagos/DisplayPagos';


export default function ListResumenGestorPayment(props) {

    const filters = React.useState({
        entidad: localStorage.getItem('filters.resumenAdministradorGestorPayment.entidad'),
        balance: localStorage.getItem('filters.resumenAdministradorGestorPayment.balance'),
        estimacion: localStorage.getItem('filters.resumenAdministradorGestorPayment.estimacion'),
        pendiente: localStorage.getItem('filters.resumenAdministradorGestorPayment.pendiente'),
        pagado: localStorage.getItem('filters.resumenAdministradorGestorPayment.pagado'),
        diferencia: localStorage.getItem('filters.resumenAdministradorGestorPayment.diferencia'),
    });
    const { t } = useTranslation();

    let entidadFilter = undefined;
    let balanceFilter = undefined;
    let estimacionFilter = undefined;
    let pendienteFilter = undefined;
    let pagadoFilter = undefined;
    let diferenciaFilter = undefined;

    const clearFilters = () => {
      entidadFilter('');
      balanceFilter('');
      estimacionFilter('');
      pendienteFilter('');
      pagadoFilter('');
      diferenciaFilter('');
    }

    const addBalance = contabilidades => {
      return (contabilidades || []).map(e => ({...e, balance: getBalanceSumary(e)}));
    }

    const transformContabilidadesByGestor = contabilidades => {
      return contabilidades.reduce((obj, e) => (
        
        obj.hasOwnProperty(`${e.pertenece_a.username}`) ?
          {...obj, [`${e.pertenece_a.username}`]: [...obj[`${e.pertenece_a.username}`], e]}  :
          {...obj, [`${e.pertenece_a.username}`]: [e]})
        
          , {});
    }

    const estrategiasIntoGrouping = contabilidadesByGestor => {
      const balances = Object.values(contabilidadesByGestor).map(e => e.reduce((sum, b) => b.saldo_final && b.fecha_fin && b.balance ? sum + parseFloat(b.balance) : sum, 0)).map(b => b.toFixed(2));
      const estimaciones = Object.values(contabilidadesByGestor).map(e => e.reduce((sum, b) => !b.saldo_final && !b.fecha_fin && b.estimaciones && b.estimaciones.length > 0 ? sum + parseFloat(b.balance) : sum, 0)).map(b => b.toFixed(2));
      return Object.keys(contabilidadesByGestor).map((c, i) => ({...contabilidadesByGestor[c][0], 'balance': balances[i], 'estimacion': estimaciones[i], 'total': parseFloat(parseFloat(balances[i]) + parseFloat(estimaciones[i])).toFixed(2)}))
    }

    const transformIntoTable = (estrategias) => {
      const transformedEstrategias = [];
      estrategias.forEach(e => {
        let pagos = (props.pagos || []).filter(p => p.user === e.pertenece_a.id)
        if (props.canperId !== 'ALL') {
          pagos = pagos.filter(p => p.canper_id === e.canper_id)
        }
        const pagado = pagos.reduce((sum, p) => p.pago ? sum + parseFloat(p.pago) : sum, 0);
        transformedEstrategias.push({
        'pagado_value': pagado.toFixed(2),
        'pagado': <>
          {pagado}
          <DisplayPagos pagos={pagos}/>
          </>,
        'pendiente': (parseFloat(e.balance) + parseFloat(e.estimacion)).toFixed(2),
          ...e
        })
        }
      )

      return transformedEstrategias.map(e => ({
        'diferencia': (e.pendiente - e.pagado_value).toFixed(2),
        ...e
      }))
  }

    const contabilidades = addBalance(props.contabilidades);
    const contabilidadesByGestor = transformContabilidadesByGestor(contabilidades);
    const grouped = estrategiasIntoGrouping(contabilidadesByGestor);
    const rows = transformIntoTable(grouped);
    
    const headers = [
      {
        text: t('cuentas:entity.label'), 
        dataField: 'entidad',
        filter: selectFilter({
          defaultValue: filters.entidad,
          options: getOpciones('entidad', rows),
          getFilter: (filter) => {
            entidadFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorGestorPayment.entidad', value);
          },
        }),
        sort: true
      },      
      
      
      {
        text: 'Balance', 
        dataField: 'balance',
        filter: numberFilter({
          defaultValue: {
            number: filters.balance,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            balanceFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorGestorPayment.balance', value);
          },
        }),
        sort: true
      },
      {
        text: 'Estimacion', 
        dataField: 'estimacion',
        filter: numberFilter({
          defaultValue: {
            number: filters.estimacion,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            estimacionFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorGestorPayment.estimacion', value);
          },
        }),
        sort: true
      },
      {
        text: t("common:pending.label"), 
        dataField: 'pendiente',
        filter: numberFilter({
          defaultValue: {
            number: filters.pendiente,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            pendienteFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorGestorPayment.pendiente', value);
          },
        }),
        sort: true
      },
      {
        text: t('common:paid.label'), 
        dataField: 'pagado',
        filter: numberFilter({
          defaultValue: {
            number: filters.pagado,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            pagadoFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorGestorPayment.pagado', value);
          },
        }),
        sort: true
      },
      {
        text: t('common:difference.label'), 
        dataField: 'diferencia',
        filter: numberFilter({
          defaultValue: {
            number: filters.diferencia,
            comparator: Comparator.EQ},
            comparatorStyle: { display: 'none' },
          getFilter: (filter) => {
            diferenciaFilter = filter;
          },
          onFilter: (value) => {
            localStorage.setItem('filters.resumenAdministradorGestorPayment.diferencia', value);
          },
        }),
        style: (cell, row, rowIndex, colIndex) => {
          return cell === '0.00' ? {backgroundColor: '#c8e6c9'} : {backgroundColor: '#e69aa6'};
        },
        sort: true
      },   
    ]

    return <PaginationProvider  pagination={ paginationFactory(paginationOptions(rows.length)) }>
    {
      ({
        paginationProps,
        paginationTableProps
      }) => (
        <>
        <div className="pagination-table">
          <Row >
            <Col>
              <Button color="secondary" size="sm" onClick={clearFilters}>{t('common:remove_filters.label')}</Button>
            </Col>
            <Col className="col-auto">
              <PaginationListStandalone
                { ...paginationProps }
              />
            </Col>
            <Col className="col-auto">
              <SizePerPageDropdownStandalone
                { ...paginationProps }
              />
            </Col>
          </Row>
        </div>
        <BootstrapTable
            bootstrap4
            striped
            hover
            condensed
            bordered={ true }
            keyField='id'
            data={rows}
            columns={ headers }
            filter={ filterFactory() }
            defaultSorted={ [{dataField: 'id', order: 'asc'}] }
            wrapperClasses="resumen-gestores-datatable"
            { ...paginationTableProps }
            />
        </>
        )}
    </PaginationProvider>
}
