import { API_BOOKMAKERS_URL, API_CLIENTES_URL, API_PRIORIDADES_URL } from "../../constants";
import {  LIST_BOOKMAKER, ADD_BOOKMAKER, REMOVE_BOOKMAKER, UPDATE_BOOKMAKER, LIST_BOOKMAKERS, LIST_BOOKMAKERS_LOADING, REMOVE_PRIORIDADES, ADD_PRIORIDADES, LIST_PRIORIDADES_BY_TRADER, LIST_PRIORIDADES_BY_TRADER_LOADING, LIST_PRIORIDADES_BY_CLIENTE_LOADING, LIST_PRIORIDADES_BY_CLIENTE} from "../types/bookmakers";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';


const BOOKMAKERS = 'BOOKMAKERS';

// Add Bookmaker
export const addBookmaker = (bookmaker) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, BOOKMAKERS));
    return axios
        .post(`${API_BOOKMAKERS_URL}`, bookmaker, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_BOOKMAKER,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("bookmakers:added_bookmaker.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': BOOKMAKERS, 'id': bookmaker.id}));
        })
};


// List Bookmaker
export const listBookmaker = id => (dispatch, getState) => {    
    axios
        .get(`${API_BOOKMAKERS_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_BOOKMAKER,
                payload: res.data
            });
        })
        .catch(err => console.log(err))
};


// Remove Bookmaker
export const removeBookmaker = id => (dispatch, getState) => {
    axios
        .delete(`${API_BOOKMAKERS_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_BOOKMAKER,
                payload: id
            });
            dispatch(createMessage(i18n.t("bookmakers:remove_bookmaker.message")));
        })
        .catch(err => console.log(err))
};


// Update Bookmaker
export const updateBookmaker = (id, Bookmaker) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, BOOKMAKERS));
    axios
        .put(`${API_BOOKMAKERS_URL}${id}/`, Bookmaker, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_BOOKMAKER,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("bookmakers:updated_bookmaker.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': BOOKMAKERS, 'id': id}));
        })
};


// List Bookmakers of a cliente
export const listBookmakers = () => (dispatch, getState) => {
    dispatch({ type: LIST_BOOKMAKERS_LOADING });
    axios
    .get(`${API_BOOKMAKERS_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_BOOKMAKERS,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_BOOKMAKERS,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listPrioridadesByTrader = () => (dispatch, getState) => {
    dispatch({ type: LIST_PRIORIDADES_BY_TRADER_LOADING });
    axios
    .get(`${API_PRIORIDADES_URL}trader/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_PRIORIDADES_BY_TRADER,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listPrioridadesByCliente = (clienteId) => (dispatch, getState) => {
    axios
    .get(`${API_PRIORIDADES_URL}cliente/${clienteId}/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_PRIORIDADES_BY_CLIENTE,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const refreshPrioridadesByClienteId = (clienteId, prioridades) => (dispatch, getState) => {
    axios
    .delete(`${API_CLIENTES_URL}${clienteId}/bookmakers/prioridades/`, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: REMOVE_PRIORIDADES,
            payload: res.data
        });
        axios
        .post(`${API_PRIORIDADES_URL}`, prioridades, tokenConfig(getState))
        .then(res2 => {
            dispatch({
                type: ADD_PRIORIDADES,
                payload: res2.data
            });
        })
    });
};