import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, DropdownItem, Row, Col } from "reactstrap";
import { addApuesta, importFichero } from "../../../redux/actions/apuestas"
import { connect } from "react-redux";
import Dropzone from 'react-dropzone'
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";


class ImportApuestasModal extends Component {
    state = {
        modal: false,
        forceModalClose: false,
        file: null
    }

    toggle = () => {
        this.setState(previous => ({
          modal: !previous.modal
        }));
    };

    import = () => {
        const file = this.state.file;
        if (file) {
            this.props.importFichero(file)
        }
        this.setState({modal: false});
    }

    onClick = () => {
        this.toggle();
        this.setState({'forceModalClose': false});
        if (this.props.create) {
          this.setState({file: null})
        }
      };

    render() {
        const {t} = this.props;
        const maxSize = 20971520; // 20 MB
        return (
        <Fragment>
            <DropdownItem onClick={() => {this.onClick()}} key="apuestas-import">
                {t("import.label")}
            </DropdownItem>
            <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring || this.props.importationIsLoading)} toggle={this.toggle} backdrop="static">
            <ModalHeader toggle={this.toggle}>
                {t("import.label")} {t("apuestas:bet.label")}s
            </ModalHeader>
            <ModalBody>
                <LoadingOverlay active={this.props.importationIsLoading} spinner={true}>
                <Dropzone
                    // Types: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    minSize={0}
                    maxSize={maxSize}
                    maxFiles={1}
                    multiple={false}
                    onDropAccepted={(f) => this.setState({file: f[0]})}
                    >
                    {({getRootProps, getInputProps, acceptedFiles, isDragActive, isDragReject, rejectedFiles}) => {
                    const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;            
                    
                    return (
                        <>
                        <div>
                            <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            
                            <Row style={{borderStyle: "dotted", paddingTop: "50px", paddingBottom: "50px", marginLeft: "25px", marginRight: "25px"}}>
                                <Col md="4"></Col>
                                <Col md="4">
                                    <Row style={{justifyContent: "center"}}><i className="fa fa-cloud-upload fa-2x"></i></Row>
                                    <Row />
                                    {!isDragActive &&
                                        <Row style={{justifyContent: "center"}}>{t("ficheros:drag.label")}</Row>}
                                    {isDragActive && !isDragReject && 
                                        <Row style={{justifyContent: "center"}}>{t("ficheros:drop.label")}</Row>}
                                    {isDragReject &&
                                        <Row className="text-danger" style={{justifyContent: "center"}}>{t("ficheros:multiple.label")}</Row>}
                                    {isFileTooLarge && 
                                        <Row className="text-danger" style={{justifyContent: "center"}}>{t("ficheros:too_big.label")}</Row>}
                                </Col>
                                <Col md="4"></Col>
                            </Row>    
                            </div>
                        </div>
                        </>
                    )}
                    }
                </Dropzone>
                {this.state.file ? <><br/><span>{t("apuestas:import.label")}: {this.state.file.name}</span></> : ''}
                </LoadingOverlay>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="outline-secondary" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>
                {t("cancel.label")}
                </Button>
                <Button
                type="button"
                color="info"
                onClick={this.import}
                >
                {t("import.label")}
                </Button>
                </ModalFooter>
            </Modal> 
        </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    importationIsLoading: state.errores.isLoading === 'IMPORTATION_APUESTAS',
    erroring: state.errores && state.errores.status && state.errores.context.type === 'IMPORTATION_APUESTAS' && state.errores.status !== 200 ? true : false,

});

export default connect(mapStateToProps, {addApuesta, importFichero})(withTranslation(['common', 'apuestas'])(ImportApuestasModal));