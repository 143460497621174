import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, Button, ModalFooter, ModalBody } from "reactstrap";
import DropZoneConexiones from "./DropZoneConexiones";
import {generate} from "generate-password";
import { addConexion } from '../../../redux/actions/conexiones';
import { withTranslation } from "react-i18next";


class NewConexionFicheroModal extends Component {

    state = {
        modal: false,
        files: []
    };
    
    toggle = () => {
        this.setState(previous => ({
            modal: !previous.modal
        }));
    };

    aceptedFicheros = (files) => {
        this.setState({ files: [
            ...this.state.files,
            ...files
        ]})
    };


    deleteAceptedFichero = (fileName) => {
        this.setState({ files: this.state.files.filter((f) => f.name !== fileName)})
    }


    uploadFicheros = () => {
        this.state.files.map((file) => this.props.addConexion({
            'fichero_ovpn': file,
            'canper_id': file.name.replace('.ovpn', ''),
            'exe_contraseña': generate({length: 12, numbers: true}),
            'raspberry': parseInt(this.props.raspberryId)
        }))
        this.setState({files: []});
        this.toggle();
    }

    render () {
        const {t} = this.props;
        const aceptedConnectionIndexes = this.state.files.map(function(c) {const file_name = c.name.split('-'); return parseInt(file_name[1].replace('.ovpn', ''))})
        return (
            <Fragment>
                <Button onClick={() => this.toggle()} color="primary" size="sm">{t("add.label")}</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} keyboard={false} backdrop="static">
                    <ModalHeader color="danger" toggle={this.toggle}>
                    {t("add.label")} {t("conexiones:conections.label")}
                    </ModalHeader>
                    <ModalBody>
                        <DropZoneConexiones files={this.state.files} rb={this.props.rb} existingConnections={[...this.props.conexiones, ...aceptedConnectionIndexes]} deleteFichero={this.deleteAceptedFichero} addFicheros={this.aceptedFicheros}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="outline-secondary" onClick={() => this.toggle()}>
                        {t("cancel.label")}
                        </Button>
                        <Button
                        type="button"
                        color="primary"
                        onClick={this.uploadFicheros}
                        >
                        {t("add.label")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    conexiones: state.raspberries.conexiones.map(function(c) {const file_name = c.canper_id.split('-'); return parseInt(file_name[1])}),
    rb: state.raspberries.raspberry.rb
  });


export default connect(mapStateToProps, { addConexion })(withTranslation(['common', 'ficheros', 'conexiones'])(NewConexionFicheroModal));