import React from 'react';
import { connect } from 'react-redux';
import NewFicherosModal from "./NewFicheroModal";
import { Card, CardHeader, Col, CardBody, Row, DropdownItem, ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import {listFicherosByClienteId, downloadFichero} from '../../../redux/actions/ficheros'
import DeleteFicheroModal from './DeleteFicheroModal';
import DisplayFicheros from './DisplayFicheros';
import LoadingOverlay from 'react-loading-overlay';
import { withTranslation } from 'react-i18next';


class Ficheros extends React.Component {

    state = {
        dropdownOpen: false,
        selectedFicheros: [],
        checkedFicheros: {}
      };
    
    toggle = () => {
        this.setState(previous => ({
            dropdownOpen: !previous.dropdownOpen
        }));
    };

    getShortName = (fullName) => {
        if (!fullName || !fullName.includes('/')) {
            return fullName
        }
        const f = fullName.split('/')
        return f[f.length -1].replace('.zip', ' ')
    }

    getExtension = (fullName) => {
        if (!fullName || !fullName.includes('.')) {
            return fullName
        }
        const f = fullName.split('.')
        return f[f.length -1]
    }

    downloadFichero = (url) => {
        this.state.selectedFicheros.map((f) => this.props.downloadFichero(f.fichero));
        this.clearSelectedFicheros();
    }

    clearSelectedFicheros = () => {
        this.setState({
            selectedFicheros: [],
            checkedFicheros: {}
        })
    }

    addFicheroToSelectedFicheros = (fichero) => {
        this.setState({
            selectedFicheros: [
                ...this.state.selectedFicheros,
                fichero
            ],
            checkedFicheros : {...this.state.checkedFicheros, [fichero.id]: true},
        })
    }

    deleteFicheroFromSelectedFicheros = (fichero) => {
        this.setState({
            selectedFicheros: this.state.selectedFicheros.filter((f) => f.id !== fichero.id),
            checkedFicheros : {...this.state.checkedFicheros, [fichero.id]: false},
        })
    }

    onChangeCheckBox = (chequed, ficheroIndex) => {
        const fichero = this.props.ficheros[ficheroIndex]
        chequed ? this.addFicheroToSelectedFicheros(fichero) : this.deleteFicheroFromSelectedFicheros(fichero)
    }


    render() {
        const ficheros = this.props.ficheros || [];
        const {t} = this.props;

        return (
            <>
            {!this.props.ficheros ?
            null :
            this.props.ficheros === 'unauthorized' ? 
            null :
            <Card style={{ marginTop: "10px" }}>
                <CardHeader>
                    <Row>
                        <Col>
                            <h5>{t("ficheros:file.label")}s</h5>
                        </Col>
                        <Col className="col-auto">
                            {this.state.selectedFicheros.length ?
                            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                <DropdownToggle caret size="sm" color="info">
                                    {t("actions.label")}
                                </DropdownToggle>
                                <DropdownMenu persist={true}>
                                    <DropdownItem onClick={this.downloadFichero}>{t("download.label")}</DropdownItem>
                                    <DeleteFicheroModal clearSelectedFicheros={this.clearSelectedFicheros} fileNames={this.state.selectedFicheros.map((f) => this.getShortName(f.fichero))} fileIds={this.state.selectedFicheros.map((f) => f.id)} />
                                </DropdownMenu>
                            </ButtonDropdown> : null}
                        </Col>
                        <Col className="col-auto">
                            <NewFicherosModal />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {ficheros && ficheros.length === 0 ?
                    <Row><Col><span>{t("ficheros:dont_exist.label")}</span></Col></Row> :
                    <DisplayFicheros 
                    getExtension={this.getExtension}
                    getShortName={this.getShortName}
                    ficheros={ficheros}
                    downloadFichero={this.downloadFichero}
                    onChangeCheckBox={this.onChangeCheckBox}
                    checkedFicheros={this.state.checkedFicheros}
                    />}
                </CardBody>
            </Card>
            }
            </>
        );
    }
}


const mapStateToProps = state => ({
    ficheros: state.clientes.ficheros
});


class FicherosContainer extends React.Component {

    componentDidMount() {
        this.props.listFicherosByClienteId(this.props.clienteId)
    }    

    render() {
        return <LoadingOverlay
            active={!this.props.ficheros}
            spinner={true}
            >
            <Ficheros {...this.props}/>
        </LoadingOverlay>
    }
}

export default connect(mapStateToProps, {listFicherosByClienteId, downloadFichero})(withTranslation(['common', 'ficheros'])(FicherosContainer));