import { GET_BANCO_OPCIONES } from '../types/banco';
import { GET_BOOKMAKERS } from '../types/clientes_bookmakers'
import { GET_MONEDEROS_OPCIONES } from '../types/monederos';

const initialState = {
    banco: [],
    bookmaker: [],
    monedero: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_BANCO_OPCIONES:
            return {
                ...state,
                banco: action.payload
            };
        case GET_BOOKMAKERS:
            return {
                ...state,
                bookmaker: action.payload
            };
        case GET_MONEDEROS_OPCIONES:
            return {
                ...state,
                monedero: action.payload
        };
        default:
            return state;
    }
}
