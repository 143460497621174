import React, { Component, Fragment } from 'react';
import { withAlert } from 'react-alert'
import { connect } from 'react-redux';



export class Alerts extends Component {

    createAlert(errs) {
        Object.keys(errs).map((atributo) => 
                this.props.alert.error(`${atributo}: ${errs[atributo][0]}`)
        )
    }

    componentDidUpdate(prevProps) {
        const { errores, mensaje, alert } = this.props
        if (errores !== prevProps.errores) {
            const errs = errores.msg;
            if (Array.isArray(errs)) {
                errs.map((e, index) => Object.keys(e).map(
                    (atributo) => this.props.alert.error(`${index}- ${atributo}: ${e[atributo][0]}`)
        ))
            } else {
                Object.keys(errs).map((atributo) => 
                this.props.alert.error(`${atributo}: ${errs[atributo][0]}`))
            }

            // Podria tener errores especificos por tipos
            // if (errores.type === 'CLIENTES') {                
            // } else {
            //     if (errores.type === 'CUENTAS') {
            //     }
            // }

        }

        if (mensaje !== prevProps.mensaje) {
            alert.success(mensaje.mensaje)
        }

    }
    render() {
        return <Fragment />;
    }
}

const mapStatetoProps = state => ({
    errores: state.errores,
    mensaje: state.mensajes
});

export default connect(mapStatetoProps)(withAlert()(Alerts));