import React from 'react';
import Datatable from "react-bs-datatable";
import { Row } from 'reactstrap';
import NewMonederosModal from './NewMonederosModal';
import DeleteMonederoModal from './DeleteMonederoModal';
import CopyToClipboard from "../../common/CopyToClipboard";
import {addTooltipToObservaciones} from "../../../helpers/helpers";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const AddCopyToClipboardToProps = props => {
    let newObject = {};
    for (let key in props) {
      newObject[key] = <CopyToClipboard>{props[key]}</CopyToClipboard>;
    }
  
    return newObject;
  };
  

class DisplayMonederos extends React.Component {

    getExistingMonederos = () => {
        return this.props.monederos.map((m) => m.monedero);
    };

    render() {
        const {t} = this.props;
        const permissions = this.props.permissions || [];
        const header = [
            {title: t("wallet.label"), prop: 'monedero', sortable: true},
            {title: t("user.label"), prop: 'email_o_usuario'},
            {title: t("key.label"), prop: 'clave_acceso'},
            {title: 'Secure/Pin', prop: 'secure_o_pin'},
            {title: t("identificator.label"), prop: 'identificador'},
            {title: t("question.label"), prop: 'pregunta'},
            {title: t("answer.label"), prop: 'respuesta'},
            {title: t("state.label"), prop: 'estado', sortable: true},
            {title: "Vip", prop: 'vip', sortable: true},
            {title: t("notes.label"), prop: 'observaciones'},
            {title: t("common:actions.label"), prop: 'action'}
        ];
        const monederos = this.props.monederos;
        // Add CopyToClipboard wrapper
        const bookmakersWithClipboard = monederos.map((m) => AddCopyToClipboardToProps(m));
        
        // Add action buttons
        const body = monederos.map((m, i) =>({
            ...bookmakersWithClipboard[i],
            'estado': t(`${m.estado}.estado`),
            'vip': !m.vip ? <i className="fa fa-times"></i> : <i className="fa fa-check"></i>,
            'observaciones': addTooltipToObservaciones(m.observaciones, 'monederos'),
            'action':
            <div>
                <Row>
                    {permissions.includes('change') && <NewMonederosModal editMonedero={this.props.editMonedero} create={false} monedero={m} existingMonederos={this.getExistingMonederos}/>}
                    {permissions.includes('delete') && <DeleteMonederoModal id={m.id} />}
                </Row>
            </div>
        }));

        const customLabels = {
            noResults: 'No existen monederos'
          };

        return (
            <div className="horizontal-scroll">
                <Datatable
                    tableHeaders={header}
                    tableBody={body}
                    tableClass="striped hover responsive"
                    labels={customLabels}
                />
            </div>
        );
    }
}


const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.monedero
  });

export default connect(mapStateToProps)(withTranslation('monederos', 'common')(DisplayMonederos));