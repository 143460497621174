export const ADD_CLIENTE = "ADD_CLIENTE";
export const LIST_CLIENTES = "LIST_CLIENTES";
export const LIST_CLIENTE = "LIST_CLIENTE";
export const REMOVE_CLIENTE= "REMOVE_CLIENTE";
export const UPDATE_CLIENTE = 'UPDATE_CLIENTE';
export const LIST_SUMMARY_CLIENTES_LOADING = 'LIST_SUMMARY_CLIENTES_LOADING';
export const LIST_SUMMARY_CLIENTES = 'LIST_SUMMARY_CLIENTES';
export const LIST_ACTIVE_CLIENTES_LOADING = 'LIST_ACTIVE_CLIENTES_LOADING';
export const LIST_ACTIVE_CLIENTES = 'LIST_ACTIVE_CLIENTES';
export const LIST_CLIENTES_LOADING = 'LIST_CLIENTES_LOADING';
