import React, { useState }  from 'react';
import Datatable from "react-bs-datatable";
import { useTranslation } from 'react-i18next';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {CustomButton} from "../../common/buttons/Buttons";
import moment from 'moment';


function DisplayEstimaciones(props) {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    
    const header = [
        {title: t("estrategias:estimation.label"), prop: 'estimacion', sortable: true},
        {title: t("ingresos:date.label"), prop: 'fecha_eu', sortable: true},
    ];

    const customLabels = {
        noResults: 'No existen estimaciones'
    };

    const estimaciones = props.estimaciones.map(i => ({
        ...i,
        'fecha_eu': moment(i.fecha).format("DD/MM/YYYY HH:mm")
    }))

    return <>
        <CustomButton color="info" onClick={toggle} style={{margin: "1px"}}/>
        <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle}>{t("estrategias:estimation.label")}</ModalHeader>
            <ModalBody>
                <div className="horizontal-scroll">
                <Datatable
                    tableHeaders={header}
                    tableBody={estimaciones}
                    tableClass="striped responsive"
                    labels={customLabels}
                />
                </div>
            </ModalBody>
            <ModalFooter>
            <CustomButton onClick={toggle} label={t("common:close.label")} style={{margin: "1px"}}/>
            </ModalFooter>
        </Modal>
    </>
}




export default DisplayEstimaciones;
