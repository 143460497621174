import React, { useState } from "react";
import { Modal, ModalHeader,  ModalBody, ModalFooter } from "reactstrap";
import Input from 'reactstrap/lib/Input';
import { ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddButton, CloseButton } from "./buttons/Buttons";
import { Button, Col, FormGroup, Label, Row } from "reactstrap/lib";
import { connect } from 'react-redux';
import {sendIncidenceToSlack, setManualErrores} from '../../redux/actions/errores'


function IncidenceModal(props) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const toggle = () => setModal(!modal);

  const isValid = () => {
    const errs = {};
      if (title === "") {
        errs[t('common:title.label')] = ['EMPTY VALUE'];
      }
      if (description === "") {
        errs[t('common:description.label')] = ['EMPTY VALUE'];
      }
      props.setManualErrores(errs, {'type': 'NOTIFICACIONES'})
      return Object.entries(errs).length === 0 ? true : false;
  }

  const send = () => {
    if (isValid()) {
      process.env.REACT_APP_SLACK_WEBHOOK_INCIDENCE && props.sendIncidenceToSlack(title, description);
      toggle();
      setTitle("");
      setDescription("");
    }
  }

  return <>
    {props.btn ? <Button color="warning" size="md" onClick={toggle}> {t("common:open_incidence.label")} </Button> :
    <ListItemText onClick={toggle}>{t("common:open_incidence.label")}</ListItemText>}

    <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader toggle={toggle}>{t("common:open_incidence.label")}</ModalHeader>
        <ModalBody>
          <Label style={{marginBottom: '25px'}}>{t("common:incidence_description.label")}</Label>
          <FormGroup>
            <Row>
              <Col md="3">
                <Label for="title">{t('common:title.label')}:</Label>
              </Col>
              <Col md="9">
                <Input type="text" name="title" id="title" onChange={e => setTitle(e.target.value)} value={title} required={true}/>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
              <Row>
                <Col md="3">
                  <Label for="description">{t('common:description.label')}:</Label>
                </Col>
                <Col md="9">
                  <Input type="textarea" maxLength="500" name="description" onChange={e => setDescription(e.target.value)} value={description} rows={3}/>
                </Col>
              </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
        <CloseButton onClick={toggle} label={t("common:cancel.label")} />
        <AddButton onClick={send} label={t("common:send.label")} />
        </ModalFooter>
    </Modal>
  </>
}


export default connect(null, {sendIncidenceToSlack,setManualErrores})(IncidenceModal);