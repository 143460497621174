import React, {useEffect}  from "react";
import { connect } from "react-redux";
import Header from "../../common/Header";
import BreadCrumbs from "../../common/BreadCrumbs";
import { listHistoricContabilidades } from "../../../redux/actions/contabilidades"
import { withTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import ListHistoricContabilidades from "./ListHistoricContabilidades";
import { urlParams } from "../../../helpers/helpers";


function ContabilidadesHistoricView(props) {

    useEffect(() => {
        if (!props.contabilidades) {
            props.listHistoricContabilidades()
        }
    }, []);

    const url = urlParams();
    const { t } = props;
    return (
        <>
        <Header />
        <Container fluid>
            <BreadCrumbs items={[
            {'href': '/#', 'name': 'Home', 'active': false},
            {'name': t('accounting.label'), 'active': true},
            {'name': t('historic.label'), 'active': true},
            ]}/>
            { props.isLoading !== false ? <Spinner animation="border" variant="primary"/> :
            <ListHistoricContabilidades contabilidades={props.contabilidades} permissions={props.permissions} urlParams={url}/> }
        </Container>
        </>
    );
}


const mapStateToProps = state => ({
    contabilidades: state.contabilidades.contabilidades_historico,
    isLoading: state.contabilidades.isLoadingHistoric,
    permissions: state.cuentas.permissions.contabilidad,
});

export default connect(mapStateToProps, {listHistoricContabilidades})(withTranslation('contabilidades')(ContabilidadesHistoricView));