import axios from 'axios';
import { API_CUENTAS_URL, API_ENTIDADES_URL, API_PROFILE_URL, API_USUARIOS_URL } from '../../constants/index';
import { setErrores, setErroresLoading } from "../actions/errores";
import { listClienteAjustes, listClienteProfile } from '../actions/ajustes'
import { listPermissions, listPermissionsGroup } from '../actions/permissions'

import {
  USUARIO_LOADING,
  USUARIO_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LIST_USERS_BY_GROUP,
  LIST_USERS_BY_GROUP_LOADING,
  UPDATE_PROFILE,
  LIST_ENTIDADES_LOADING,
  LIST_ENTIDADES,
  REGISTER,
  CODE_VALIDATION,
  LIST_USERS_BY_GROUPS,
} from '../types/cuentas';
import { getOpciones } from './opciones';
import { createMessage } from './mensajes';
import i18n from '../../translations/i18n';


const CUENTAS = 'CUENTAS';
const PROFILE = 'PROFILE'

const dispatchStartingActions = (userId, dispatch, getState) => {
  // Load ajustes after user is loged
  if (!getState().cuentas.ajustes) {
    dispatch(listClienteProfile(userId));
  }
  if (!getState().cuentas.ajustes) {
    dispatch(listClienteAjustes(userId));
  }
  if (!getState().cuentas.permissions) {
    dispatch(listPermissions());
  }
  if (!getState().cuentas.group) {
    dispatch(listPermissionsGroup());
  }
  if (!getState().opcions) {
    dispatch(getOpciones());
  }
}


// LOAD USER
export const loadUser = () => (dispatch, getState) => {
    // User loading
    dispatch({ type: USUARIO_LOADING });
    axios
    .get(`${API_CUENTAS_URL}user`, tokenConfig(getState))
    .then ( res => {
        dispatch({
            type: USUARIO_LOADED,
            payload: res.data
        });
        dispatchStartingActions(res.data.id, dispatch, getState)
    })
    .catch(err => {
        dispatch({
            type: AUTH_ERROR
        });
    })
};

export const patchUser = (id, user)  => (dispatch, getState) => {
  dispatch(setErroresLoading(true, PROFILE));
  axios
  .patch(`${API_CUENTAS_URL}user`, user, tokenConfig(getState))
  .then(res => {
      dispatch({
          type: UPDATE_PROFILE,
          payload: res.data
      });
      dispatch(setErroresLoading(false));
      dispatch(createMessage(i18n.t("common:updated_profile.message")));
  })
  .catch(err => {
      dispatch(setErrores(err.response, {'type': PROFILE, 'id': id}));
  })
};

// LOGIN USER
export const login = ( username, password ) => (dispatch, getState) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Client-Location': Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  };

  // Request Body
  const body = JSON.stringify({ username, password });

    axios
    .post(`${API_CUENTAS_URL}login`, body, config)
    .then(res => {
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatchStartingActions(res.data.user.id, dispatch, getState)
    })
    .catch(err => {
        dispatch({ type: LOGIN_FAIL });
        dispatch(setErrores(err.response, CUENTAS));
    })
};


const markAsRegistered = (id)  => (dispatch, getState) => {
  axios
  .patch(`${API_CUENTAS_URL}invitation/${id}`, {'registered': true}, tokenConfig(getState))
  .then(res => {
      dispatch({
          type: REGISTER,
          payload: res.data
      });
  })  
};


export const register = (signUp, invitationId)  => (dispatch, getState) => {
  dispatch(setErroresLoading(true, CUENTAS));
  axios
  .post(`${API_CUENTAS_URL}register`, signUp, tokenConfig(getState))
  .then(res => {
      dispatch({
          type: REGISTER,
          payload: res.data
      });
      dispatch(markAsRegistered(invitationId))
      dispatch(setErroresLoading(false));
      dispatch(createMessage(i18n.t("cuentas:register.message")));
  })
  .catch(err => {
      dispatch(setErrores(err.response, {'type': CUENTAS}));
  })
};


export const isCodeValid = (code)  => (dispatch, getState) => {
  dispatch(setErroresLoading(true, CUENTAS));
  axios
  .get(`${API_CUENTAS_URL}invitation?code=${code}`, tokenConfig(getState))
  .then(res => {
      const valid = res.data.valid;
      const message = valid ? i18n.t("cuentas:valid_code.message") : i18n.t("cuentas:invalid_code.message");
      dispatch(createMessage(message));
      dispatch({
        type: CODE_VALIDATION,
        payload: res.data
    });
  })
  .catch(err => {
      dispatch(setErrores(err.response, {'type': CUENTAS, 'id': id}));
  })
};

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  axios
  .post(`${API_CUENTAS_URL}logout`, null, tokenConfig(getState))
  .then(res => {
      dispatch({
          type: LOGOUT_SUCCESS
        });
  })
  .catch(err => {
      console.log(err);
  })
};

// helper function
export const tokenConfig = getState => {
  // Get token
  const token = getState().cuentas.token;

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Client-Location': Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  };

  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }

  return config;
};

export const listUsersByGroup = (group) => (dispatch, getState) => {
  dispatch({ type: LIST_USERS_BY_GROUP_LOADING });
  axios
  .get(`${API_USUARIOS_URL}?group=${group}`, tokenConfig(getState))
  .then(res => {
      // eslint-disable-next-line 
      dispatch({
          type: LIST_USERS_BY_GROUP,
          payload: res.data,
          group
      });
  })
  .catch(err => {
      console.log(err)
  })
}

export const listUsersByGroups = (groups) => (dispatch, getState) => {
  dispatch({ type: LIST_USERS_BY_GROUP_LOADING });
  axios
  .get(`${API_USUARIOS_URL}?groups=${groups.toString()}`, tokenConfig(getState))
  .then(res => {
      // eslint-disable-next-line 
      dispatch({
          type: LIST_USERS_BY_GROUPS,
          payload: res.data,
      });
  })
  .catch(err => {
      console.log(err)
  })
}


export const patchProfile = (id, profile)  => (dispatch, getState) => {
  dispatch(setErroresLoading(true, PROFILE));
  axios
  .patch(`${API_PROFILE_URL}${id}/`, profile, tokenConfig(getState))
  .then(res => {
      dispatch({
          type: UPDATE_PROFILE,
          payload: res.data
      });
      dispatch(setErroresLoading(false));
      dispatch(createMessage(i18n.t("common:updated_profile.message")));
  })
  .catch(err => {
      dispatch(setErrores(err.response, {'type': PROFILE, 'id': id}));
  })
};


export const listEntidades = () => (dispatch, getState) => {
  dispatch({ type: LIST_ENTIDADES_LOADING });
  axios
  .get(`${API_ENTIDADES_URL}`, tokenConfig(getState))
  .then(res => {
      // eslint-disable-next-line 
      dispatch({
          type: LIST_ENTIDADES,
          payload: res.data,
      });
  })
}
