import { API_CLIENTES_URL } from "../../constants";
import axios from "axios";

import { tokenConfig } from './cuentas';
import { LIST_CLIENTES, LIST_CLIENTE, REMOVE_CLIENTE, UPDATE_CLIENTE, ADD_CLIENTE, LIST_SUMMARY_CLIENTES, LIST_SUMMARY_CLIENTES_LOADING, LIST_ACTIVE_CLIENTES_LOADING, LIST_ACTIVE_CLIENTES, LIST_CLIENTES_LOADING } from '../types/clientes'
import { createMessage } from "../actions/mensajes";
import { setErrores, setErroresLoading } from "../actions/errores";
import {listFicherosByClienteId} from '../../redux/actions/ficheros';
import i18n from '../../translations/i18n';

const CLIENTES = 'CLIENTES';

// Add cliente
export const addCliente = (cliente) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, CLIENTES));
    return axios
        .post(`${API_CLIENTES_URL}`, cliente, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_CLIENTE,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("clientes:added_client.message")))
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CLIENTES, 'id': cliente.id}));
        })
};


// Remove cliente
export const removeCliente = id => (dispatch, getState) => {
    axios
        .delete(`${API_CLIENTES_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_CLIENTE,
                payload: id
            });
            dispatch(createMessage(i18n.t("clientes:remove_client.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, CLIENTES));
        })
};

// Update cliente
export const updateCliente = (id, cliente) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, CLIENTES));
    axios
        .put(`${API_CLIENTES_URL}${id}/`, cliente, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CLIENTE,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            // Listo Ficheros en el caso de que el estado del cliente haya sido modificado, y los archivos hayan sido comprimidos/descomprimidos
            dispatch(listFicherosByClienteId(id));
            dispatch(createMessage(i18n.t("clientes:updated_client.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CLIENTES, 'id': id}));
        })
};

export const patchCliente = (id, cliente) => (dispatch, getState) => {
    axios
        .patch(`${API_CLIENTES_URL}${id}/`, cliente, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CLIENTE,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("clientes:updated_client.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CLIENTES, 'id': id}));
        })
};

// List all clientes
export const listClientes = () => (dispatch, getState) => {
    axios
        .get(API_CLIENTES_URL, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_CLIENTES,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err)
        })
};


// List all clientes version resument (no bookmakers)
export const listSummaryClientes = (estado) => (dispatch, getState) => {
    dispatch({ type: LIST_SUMMARY_CLIENTES_LOADING });
    axios
        .get(`${API_CLIENTES_URL}summary/?estado=${estado}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_SUMMARY_CLIENTES,
                payload: res.data
            });
        })
        .catch(err => console.log(err))
};


// List a cliente
export const listCliente = (id) => (dispatch, getState) => {
    axios
        .get(`${API_CLIENTES_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_CLIENTE,
                payload: res.data
            });
        })
        .catch(err => {
            if (err.response.status === 403) {
                dispatch({
                    type: LIST_CLIENTE,
                    payload: 'unauthorized'
                });
            }
            console.log(err)
        })
};


export const listClienteByUser = () => (dispatch, getState) => {
    dispatch({ type: LIST_CLIENTES_LOADING });
    axios
        .get(`${API_CLIENTES_URL}user/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_CLIENTE,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err)
        })
};


export const listActiveClientes = () => (dispatch, getState) => {
    dispatch({ type: LIST_ACTIVE_CLIENTES_LOADING });
    axios
    .get(`${API_CLIENTES_URL}?estado=activo`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_ACTIVE_CLIENTES,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_ACTIVE_CLIENTES,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listClientesActivosWithBookmakers = () => (dispatch, getState) => {
    dispatch({ type: LIST_ACTIVE_CLIENTES_LOADING });
    axios
        .get(`${API_CLIENTES_URL}with_bookmakers/?estado=en proceso&bookmakers_estado=en progreso`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LIST_ACTIVE_CLIENTES,
                payload: res.data
            });
        })
        .catch(err => console.log(err))
}