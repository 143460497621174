import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CopyToClipboard from "../common/CopyToClipboard";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Col, Row, Button } from "reactstrap";
import NewBookmakerModal from "./NewBookmakerModal";
import DeleteBookmakerModal from "./DeleteBookmakerModal";
import {paginationOptions, getOpciones} from '../common/BootstrapTableHelper';
import {CustomButton} from "../common/buttons/Buttons";


class ListBookmakers extends React.Component {

    state = {
        filters: {
            bookmaker: localStorage.getItem('filters.bookmakers.bookmaker'),
            email: localStorage.getItem('filters.bookmakers.email'),
            telefono: localStorage.getItem('filters.bookmakers.telefono'),
            chat: localStorage.getItem('filters.bookmakers.chat'),
            bonoTipo: localStorage.getItem('filters.bookmakers.bonoTipo'),
            bonoCantidad: localStorage.getItem('filters.bookmakers.bonoCantidad'),
            bonoRollover: localStorage.getItem('filters.bookmakers.bonoRollover'),
          }
    }

    bookmakerFilter;
    emailFilter;
    telefonoFilter;
    chatFilter;
    bonoTipoFilter;
    bonoCantidadFilter;
    bonoRolloverFilter;


    clearFilters = () => {
        this.bookmakerFilter('');
        this.emailFilter('');
        this.telefonoFilter('');
        this.chatFilter('');
        this.bonoTipoFilter('');
        this.bonoCantidadFilter('');
        this.bonoRolloverFilter('');
    }

    transformBookmakersIntoTable = (bookmakers, t) => {
        return bookmakers.map((bookmaker) => ({
        'chat_string': bookmaker.chat ? bookmaker.chat.toString() : "false",
        'acciones': <>
        {this.props.permissions.bookmakeropciones.includes("view") && <Button color="primary" size="sm" href={`#/bookmakers/${bookmaker.id}`}>{t('common:view.label')}</Button>}
        {this.props.permissions.bookmakeropciones.includes("change") && <NewBookmakerModal create={false} bookmaker={bookmaker} />}
        {this.props.permissions.bookmakeropciones.includes("delete") && <DeleteBookmakerModal id={bookmaker.id}/>}
        </>,
        ...bookmaker
        }));
    };


    addToCopyFormatter = (cell) => {
        return <CopyToClipboard>{cell}</CopyToClipboard>
    }

    chatFormatter = (cell) => {
      return cell ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>;
    }

    render() {
        const { t } = this.props;
        const bookmakers = this.props.bookmakers;
        const rows = this.transformBookmakersIntoTable(bookmakers, t);
        const headers = [{
            text: 'Bookmaker',
            dataField: 'bookmaker',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.bookmaker,
              getFilter: (filter) => {
                this.bookmakerFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.bookmakers.bookmaker', value);
              },
            }),
            sort: true
          },
          {
            text: 'Email', 
            dataField: 'email',
            filter: textFilter({
              defaultValue: this.state.filters.email,
              getFilter: (filter) => {
                this.emailFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.bookmakers.email', value);
              },
            }),
            sort: true
          },
          {
            text: t("phone.label"), 
            dataField: 'telefono',
            filter: textFilter({
              defaultValue: this.state.filters.telefono,
              getFilter: (filter) => {
                this.telefonoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.bookmakers.telefono', value);
              },
            }),
            sort: true
          },
          {
            text: 'Chat', 
            dataField: 'chat',
            formatter: this.chatFormatter,
            align: 'center',
            filter: selectFilter({
              defaultValue: this.state.filters.chat,
              options: getOpciones('chat_string', rows),
              getFilter: (filter) => {
                this.chatFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.bookmakers.chat', value);
              },
            }),
            sort: true
          },
          {
            text: t("token_type.label"), 
            dataField: 'bono_tipo',
            align: 'center',
            filter: selectFilter({
              defaultValue: this.state.filters.bonoTipo,
              options: getOpciones('bono_tipo', rows),
              getFilter: (filter) => {
                this.bonoTipoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.bookmakers.bonoTipo', value);
              },
            }),
            sort: true
          },
          {
            text: t("token_amount.label"), 
            dataField: 'bono_cantidad',
            filter: textFilter({
              defaultValue: this.state.filters.bonoCantidad,
              getFilter: (filter) => {
                this.bonoCantidadFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.bookmakers.bonoCantidad', value);
              },
            }),
            sort: true
          },
          {
            text: t("token_rollover.label"), 
            dataField: 'bono_rollover',
            filter: textFilter({
              defaultValue: this.state.filters.bonoRollover,
              getFilter: (filter) => {
                this.bonoRolloverFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.bookmakers.bonoRollover', value);
              },
            }),
            sort: true
          },
          {
            text: "",
            dataField: 'acciones',
            sort: false
          }
        ]
        
        return (
        <PaginationProvider  pagination={ paginationFactory(paginationOptions(bookmakers.length)) }>
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
            <>
            <div className="pagination-table">
              <Row >
                <Col>
                <CustomButton color="secondary" style={{margin: "1px"}} onClick={this.clearFilters} label={t('common:remove_filters.label')} />
                </Col>
                <Col className="col-auto">
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </Col>
                <Col className="col-auto">
                  <SizePerPageDropdownStandalone
                    { ...paginationProps }
                  />
                </Col>                
                <Col className="col-auto">
                  { this.props.permissions.bookmaker.includes("add") && <NewBookmakerModal create={true} bookmaker={{}}/>}
                </Col>
              </Row>
            </div>
              <BootstrapTable
                bootstrap4
                hover
                condensed
                bordered={ false }
                keyField='id'
                data={rows}
                columns={ headers }
                filter={ filterFactory() }
                // rowEvents={ this.rowEvents }
                defaultSorted={ [{dataField: 'canper_id', order: 'asc'}] }
                wrapperClasses="bookmakers-datatable"
                { ...paginationTableProps }
              />
            </>
            )}
        </PaginationProvider>
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions,
  });

export default connect(mapStateToProps, {})(withTranslation('bookmakers', 'common')(ListBookmakers));