import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, Button, ModalFooter, ModalBody } from "reactstrap";

import { removeConexionprov } from "../../redux/actions/conexionesprov";
import { withTranslation } from "react-i18next";
import {DeleteButton} from "../common/buttons/Buttons";


class DeleteConexionprovModal extends Component {
  state = {
    modal: false
  };


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteConexionprov = () => {
    this.props.removeConexionprov(this.props.id)
    this.toggle()
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <DeleteButton onClick={this.toggle} style={{margin: "1px"}}/>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} {t("conexionesprov:connection.label")} 
          </ModalHeader>
          <ModalBody>
            {t("conexionesprov:remove_confirmation.label")}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={() => this.toggle()}>
              {t("cancel.label")}
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={this.deleteConexionprov}
            >
              {t("remove.label")}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeConexionprov})(withTranslation(['common', 'conexionesprov'])(DeleteConexionprovModal));