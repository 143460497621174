import React from 'react';

import DisplayConexiones from "./DisplayConexiones"
import { addConexion, listConexionesByRaspberryId, downloadFichero, checkConexiones } from "../../../redux/actions/conexiones";
import {listConexionesClientesByRaspberryId} from "../../../redux/actions/conexion_cliente"
import { connect } from 'react-redux';
import { Row, Col, CardBody, Card, CardHeader, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { createMessage } from "../../../redux/actions/mensajes";
import NewConexionFicheroModal from "./NewConexionFicheroModal"
import DeleteConexionModal from "./DeleteConexionModal "
import {listActiveClientes} from "../../../redux/actions/clientes"


class Conexiones extends React.Component {

    state = {
        dropdownOpen: false,
        selectedRows: []
      };

    toggle = () => {
        this.setState(previous => ({
            dropdownOpen: !previous.dropdownOpen
        }));
    };

    componentDidMount() {
        this.props.listConexionesByRaspberryId(this.props.raspberryId);
        this.props.listConexionesClientesByRaspberryId(this.props.raspberryId);

        if (!this.props.clientes && !this.props.isLoadingActiveClientes) {
            this.props.listActiveClientes();
        }
    };

    createConexion = conexion => {
        this.props.addConexion(conexion);
    };

    clipboardConexiones = () => {
        const conexiones = this.state.selectedRows;
        let parsedConexiones = conexiones.map(c => Object.keys(c).filter(key => key !== 'id' && !key.includes('_a') && !key.includes('fichero') && key !== 'raspberry').map(key => c[key]))
        parsedConexiones = parsedConexiones.map(p => p.join(' '))
        navigator.clipboard.writeText(parsedConexiones.join('\n'))
        this.props.createMessage(`${this.props.t("conexiones:clipboard.message")}`);
    };

    setStateFunction(state, props) {
        const newState = {...state, selectedRows: [...state.selectedRows, props.newSelectedRows]};
        return newState;
    }

    addFicheroToselectedRows = (fichero) => {
        this.setState(prevState => ({
                selectedRows: [
                    fichero,
                    ...prevState.selectedRows
                ]
        }))
    }

    deleteFicheroFromselectedRows = (fichero) => {
        this.setState({
            selectedRows: this.state.selectedRows.filter((f) => f.id !== fichero.id),
        })
    }

    downloadFichero = (type) => {
        this.state.selectedRows.map((f) =>
            this.props.downloadFichero(type === 'ovpn' ? f.fichero_ovpn : f.fichero_exe));
    }

    clearselectedRows = () => {
        this.setState({
            selectedRows: []
        })
    }

    render() {
        const { t } = this.props;
        const permissions = this.props.permissions || [];
        return (
            <>
            {!this.props.conexiones || !this.props.conexiones_clientes || !permissions.includes('view') ?
            null :
            <Card style={{ marginTop: "10px" }}>
                <CardHeader>
                <Row>                    
                    <Col>
                        <h5>{t("conexiones:connections.label")}</h5>
                    </Col>
                    <Col className="col-auto">
                        {permissions.includes('view') && this.props.conexiones.length > 0 &&
                        <Button color="info" size="sm" onClick={() => this.props.checkConexiones(this.props.conexiones)}> {t("conexiones:check_connection.label")} </Button>}
                    </Col>
                    <Col className="col-auto">
                        {this.state.selectedRows.length ?
                        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle caret size="sm" color="info">
                                {t("actions.label")}
                            </DropdownToggle>
                            <DropdownMenu persist={true}>
                            <DropdownItem onClick={() => this.downloadFichero('ovpn')}>{t("download.label")} ovpn</DropdownItem>
                            <DropdownItem onClick={() => this.downloadFichero('exe')}>{t("download.label")} exe</DropdownItem>
                            <DropdownItem onClick={this.clipboardConexiones} >{t("conexiones:copy_all.label")}</DropdownItem>
                            <DeleteConexionModal clearselectedRows={this.clearselectedRows} conexiones={this.state.selectedRows} />
                            </DropdownMenu>
                        </ButtonDropdown> : null}
                    </Col>
                    <Col className="col-auto">
                        { permissions.includes('add') && <NewConexionFicheroModal raspberryId={this.props.raspberryId}/>}
                    </Col>
                </Row>
                </CardHeader>
                <CardBody>
                    {this.props.conexiones.length > 0 ?
                    <DisplayConexiones conexiones={this.props.conexiones} conexiones_clientes={this.props.conexiones_clientes} deleteFicheroFromselectedRows={this.deleteFicheroFromselectedRows} addFicheroToselectedRows={this.addFicheroToselectedRows} clearselectedRows={this.clearselectedRows} clientes={this.props.clientes}/> :
                    <span>{t("conexiones:dont_exist.label")}</span>}
                </CardBody>
            </Card>
                }
            </>
        );
    }
}


const mapStateToProps = state => ({
    conexiones: state.raspberries.conexiones,
    conexiones_clientes: state.raspberries.conexiones_clientes,
    permissions: state.cuentas.permissions.conexion,
    clientes: state.clientes.activeClientes,
    isLoadingActiveClientes: state.clientes.isLoadingActiveClientes,
});


export default connect(mapStateToProps, {addConexion, listActiveClientes, listConexionesByRaspberryId, createMessage, downloadFichero, checkConexiones, listConexionesClientesByRaspberryId})(withTranslation('common', 'conexiones')(Conexiones));