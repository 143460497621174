import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";

import {defaultIfEmpty} from "../../../helpers/helpers";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { withTranslation } from "react-i18next";
import {EditButton,AddButton,CloseButton} from "../../common/buttons/Buttons";


const BIC = {
  'ImaginBank': 'CAIXESBBXXX',
  'Caixabank': 'CAIXESBBXXX',
  'BBVA': 'BBVAESMM'
}


class NewBancoModal extends Component {
  state = {
    modal: false,
    forceModalClose: false,
    banco: {}
  };

  componentDidMount() {
    this.setState({banco: this.props.banco})
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChange = e => {
    e.preventDefault();
    this.setState(
      { banco: {
        ...this.state.banco,
        [e.target.name]: e.target.value
      }
    });
  };

  onChangeEntidad = entidad => {
    this.setState(
      { banco: {
        ...this.state.banco,
        'bic_o_swift': BIC[entidad],
        'banco': entidad
      }
    });
  };

  addLastDayToDate = date => {
    if (!date || !date.includes('-')) {
      return date
    }
    const d = date.split('-')
    const day = new Date(d[0], d[1], 0).getDate()
    return `${d[0]}-${d[1]}-${day}`

  }

  // Eliminamos posibles guiones y espacios
  formatIban = iban => {
    if (!iban) {
      return iban;
    }
    return iban.replace(/-/g, "").replace(/ /g, "")
  };

  createBanco = e => {
    e.preventDefault();
    const banco = {
      ...this.state.banco,
      cliente: this.props.clienteId,
      fecha_caducidad: this.addLastDayToDate(this.state.banco.fecha_caducidad),
      iban: this.formatIban(this.state.banco.iban)
    }
    this.props.createBanco(banco);
    this.setState({modal: false});
  }

  editBanco = e => {
    e.preventDefault();
    const banco = {
      ...this.state.banco,
      fecha_caducidad: this.addLastDayToDate(this.state.banco.fecha_caducidad),
      iban: this.formatIban(this.state.banco.iban)
    }
    this.props.editBanco(banco);
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({banco: {}})
    }
  };

  getMonthYear = date => {
    if (!date || !date.includes('-')) {
        return date
    }
    const d = date.split("-")
    return `${d[0]}-${d[1]}`
  };

  render() {
    const create = this.props.create;
    const banco = this.state.banco || {};
    const bancoOpciones = this.props.banco_opciones;
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;
    const { t } = this.props;
    
    return (
    <Fragment>
      { create ?
        <AddButton onClick={this.onClick} style={{margin: "1px"}}/>:
        <EditButton onClick={this.onClick} style={{margin: "1px"}}/>
      }
      <LoadingOverlay active={this.props.isLoading} spinner={true}>
        <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring|| this.props.isLoading)} toggle={this.toggle} size='lg' backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t('add.label')} ${t('banco:bank.label')}`: `${t('edit.label')} ${t("banco:bank.label")}`}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="banco">{t("banco:bank.label")}:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="banco" onChange={(e) => this.onChangeEntidad(e.target.value)} value={defaultIfEmpty(banco.banco)}>
                      <option value="">{t("option.label")}</option>
                      {bancoOpciones.map((b, index) => <option key={index}>{b.banco}</option>)}
                    </Input>
                  </Col>
              </Row>
            </FormGroup>


            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="iban">IBAN:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="iban"
                    onChange={this.onChange}
                    value={defaultIfEmpty(banco.iban)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="bic_o_swift">BIC/SWIFT:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="bic_o_swift"
                    value={defaultIfEmpty(banco.bic_o_swift)}
                    />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="usuario">{t('banco:user.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="usuario"
                    onChange={this.onChange}
                    value={defaultIfEmpty(banco.usuario)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="clave_acceso">{t('banco:key.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="clave_acceso"
                    onChange={this.onChange}
                    value={defaultIfEmpty(banco.clave_acceso)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="tarjeta">{t('banco:card.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="tarjeta"
                    onChange={this.onChange}
                    value={defaultIfEmpty(banco.tarjeta)}
                  />
                </Col>
              </Row>
            </FormGroup>
            
          </Col>

          <Col>            
            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="fecha_caducidad">{t('banco:expired.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="month"
                    name="fecha_caducidad"
                    onChange={this.onChange}
                    value={this.getMonthYear(defaultIfEmpty(banco.fecha_caducidad))}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="pin">PIN:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="pin"
                    onChange={this.onChange}
                    value={defaultIfEmpty(banco.pin)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="cvc">CVC:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="text"
                    name="cvc"
                    onChange={this.onChange}
                    value={defaultIfEmpty(banco.cvc)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="estado">{t('banco:state.label')}:</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" name="estado" onChange={this.onChange} value={defaultIfEmpty(banco.estado)}>
                      <option value="">{t("option.label")}</option>
                      <option value="DISPONIBLE">{t("banco:DISPONIBLE.estado")}</option>
                      <option value="DISPONIBLE NO VERIFICADO">{t("banco:DISPONIBLE NO VERIFICADO.estado")}</option>
                      <option value="NO DISPONIBLE">{t("banco:NO DISPONIBLE.estado")}</option>
                    </Input>
                  </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label className="col-form-label" for="observaciones">{t('banco:notes.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="textarea"
                    name="observaciones"
                    onChange={this.onChange}
                    value={defaultIfEmpty(banco.observaciones)}
                  />
                </Col>
              </Row>
            </FormGroup>            
          </Col>
        </Row>
        </ModalBody>
        <ModalFooter>
            <CloseButton onClick={() => {this.setState({'forceModalClose': true}); this.toggle()}} label={t("cancel.label")} style={{margin: "1px"}} />
          { create ? <AddButton onClick={this.createBanco} label={t("add.label")}/>   : <EditButton onClick={this.editBanco} label={t("edit.label")} style={{margin: "1px"}}/>}
        </ModalFooter>
        </Modal>
      </LoadingOverlay>
    </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'BANCO',
  erroring: state.errores && state.errores.status && state.errores.context === 'BANCO' && state.errores.status !== 200 ? true : false,
  banco_opciones: state.opciones.banco
});

export default connect(mapStateToProps)(withTranslation('common', 'banco')(NewBancoModal));