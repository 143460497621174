import React, {useEffect}  from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {refreshPrioridadesByClienteId} from '../../../redux/actions/bookmakers';
import { CustomButton } from '../../common/buttons/Buttons';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import DragnDropList from '../../common/DragnDropList'


function PrioridadesModal(props) {
    const { t } = useTranslation();
    const [modal, setModal] = React.useState(false);
    const [bookmakers, setBookmakers] = React.useState({});
    const toggle = () => setModal(!modal);

    const save = () => {
        const prioridades = bookmakers.map((b, index) => ({
            bookmaker: b,
            prioridad: index + 1,
            cliente: props.clienteId,
            trader: props.userId

        }));
        props.refreshPrioridadesByClienteId(props.clienteId, prioridades)
        toggle();
    };

    useEffect(() => {
        const prioridades = props.prioridades
        if (prioridades && props.all_bookmakers) {
            const bookmakers = prioridades.length > 0 ? prioridades.map(p => p.bookmaker) : props.all_bookmakers;
            setBookmakers(bookmakers.map(b => ({...b, id: b.id.toString(), content: b.bookmaker})))
        }
    }, [])

    return <>
        <CustomButton onClick={toggle} color="secondary" label={t("estrategias:priorities.label")} style={{marginLeft: '10px'}}/>
        <Modal isOpen={modal} toggle={toggle} backdrop="static"scrollable={true}>
            <ModalHeader toggle={toggle}>
              {t("bookmakers:priorities.label")}
            </ModalHeader>
            <ModalBody>
                <div style={{padding: '5px 15px 20px'}}>{t("bookmakers:prioridad.message")}</div>
                <DragnDropList items={bookmakers} setState={setBookmakers} />
            </ModalBody>
            <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={toggle}>
                {t("common:cancel.label")}
            </Button>
            <Button
                type="button"
                color="danger"
                onClick={save}
            >
                {t("common:save.label")}
            </Button>
            </ModalFooter>
        </Modal>
    </>
}

const mapStateToProps = state => ({
    all_bookmakers: state.opciones.bookmaker,
    userId: state.cuentas.user.id
  });


export default connect(mapStateToProps, {refreshPrioridadesByClienteId})(PrioridadesModal);