import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row } from 'reactstrap/lib';
import Button from 'reactstrap/lib/Button';
import PageError from '../../assets/images/PageError.png';
import {sendErrorToSlack} from '../../redux/actions/errores';
import IncidenceModal from './IncidenceModal';


class ErrorFallback extends React.Component {
    state = { error: null, errorInfo: null };

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // Send error messages to slack
        process.env.REACT_APP_SLACK_WEBHOOK && this.props.sendErrorToSlack(error, errorInfo)
    }

    render() {
        const { history } = this.props;

        if (this.state.errorInfo) {
            // Error path
            return (
                <div>
                    <img alt="Page Error" src={PageError} style={{ width: 600, height: 500, display: 'block', margin: 'auto', position: 'relative' }} />
                    <br />
                    <center>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                        </details>
                            <Button color="primary" size="md" style={{marginRight: '15px'}} onClick={() => history.push('/')}> Home </Button>
                            <IncidenceModal btn={true}/>
                    </center>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default connect (null, {sendErrorToSlack}) (withRouter(ErrorFallback))