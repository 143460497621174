import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, FormGroup, Label, Input } from "reactstrap";
import moment from 'moment';

import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { withTranslation } from "react-i18next";
import { defaultIfEmpty } from "../../../helpers/helpers";
import { addEstimacion } from "../../../redux/actions/estrategias";
import {AddButton,CloseButton} from "../../common/buttons/Buttons";


class NewEstimacionModal extends Component {

  state = {
    modal: false,
    estimacion: {}
  };

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  onChange = e => {
    e.preventDefault();
    this.setState(
      { estimacion: {
        ...this.state.estimacion,
        [e.target.name]: e.target.value
      }
    });
  };

  createEstimacion = e => {
    e.preventDefault();
    const estimacion = {...this.state.estimacion, estrategia: this.props.estrategiaId}
    this.props.addEstimacion(estimacion);
    this.setState({modal: false, estimacion: {}});
  }
  
  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({estimacion: {}})
    }
  }

  render() {
    const { t } = this.props;
    const estimacion = this.state.estimacion;
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;  

    return (
    <Fragment>
      <AddButton onClick={this.onClick} style={{margin: "1px"}}></AddButton>
        <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === estimacion.id || this.props.isLoading)} toggle={this.toggle} backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {t("common:add.label")} {t("estrategias:estimation.label")}
        </ModalHeader>
        <ModalBody>                   
          <LoadingOverlay active={this.props.isLoading} spinner={true}>
          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="estimacion">{t("estrategias:estimation.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="number"
                  step="0.01"
                  name="estimacion"
                  onChange={this.onChange}
                  value={defaultIfEmpty(estimacion.estimacion)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="fecha">{t("ingresos:date.label")}:</Label>
              </Col>
              <Col md="8">
                <Input
                  type="datetime-local"
                  name="fecha"
                  onChange={this.onChange}
                  value={estimacion.fecha}
                  min={moment(this.props.estrategiaFechaInicio).format("YYYY-MM-DDThh:mm")}
                  max={moment().format("YYYY-MM-DDThh:mm")}
                />
              </Col>
            </Row>
          </FormGroup>

          </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <CloseButton onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}} label={t("common:cancel.label")} style={{margin: "1px"}}/>
          <AddButton onClick={this.createEstimacion} label={t("common:add.label")} style={{margin: "1px"}}/>
        </ModalFooter>
        </Modal>
    </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'INGRESOS',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'INGRESOS' && state.errores.status !== 200 ? state.errores.context.id : false,
});


export default connect(mapStateToProps, {addEstimacion})(withTranslation('estimacions', 'common')(NewEstimacionModal));