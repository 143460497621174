import {
    LIST_NOTIFICACIONES, UPDATE_NOTIFICACION, LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS, LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS_LOADING, LIST_RECEIVED_NOTIFICACIONES, LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES, LIST_SENT_NOTIFICACIONES, LIST_NOTIFICACIONES_LOADING, LIST_RECEIVED_NOTIFICACIONES_LOADING, LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES_LOADING, LIST_SENT_NOTIFICACIONES_LOADING, LIST_FOLLOWED_NOTIFICACIONES, LIST_FOLLOWED_NOTIFICACIONES_LOADING, ADD_NOTIFICACION, LIST_NOTIFICACIONES_CHANNELS_LOADING, LIST_NOTIFICACIONES_CHANNELS, UPDATE_NOTIFICACION_CHANNEL, CREATE_NOTIFICACION_CHANNEL
} from "../types/notificaciones";

const initialState = {
    notificaciones: null
};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_NOTIFICACIONES:
            return {
                ...state,
                notificaciones: action.payload,
                isLoading: false
            }
        case LIST_NOTIFICACIONES_LOADING:
            return {
                ...state,
                isLoading: true
            }

        case LIST_RECEIVED_NOTIFICACIONES:
            return {
                ...state,
                receivedNotificaciones: action.payload,
                receivedNotificacionesisLoading: false
            }
        case LIST_RECEIVED_NOTIFICACIONES_LOADING:
            return {
                ...state,
                receivedNotificacionesisLoading: true
            }
        case LIST_FOLLOWED_NOTIFICACIONES:
            return {
                ...state,
                followedNotificaciones: action.payload,
                followedNotificacionesisLoading: false
            }
        case LIST_FOLLOWED_NOTIFICACIONES_LOADING:
            return {
                ...state,
                followedNotificacionesisLoading: true
            }
        case LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES:
            return {
                ...state,
                recievedUncompletedNotificaciones: action.payload,
                recievedUncompletedNotificacionesisLoading: false
            }
        case LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES_LOADING:
            return {
                ...state,
                recievedUncompletedNotificacionesisLoading: true
            }
        case LIST_SENT_NOTIFICACIONES:
            return {
                ...state,
                sentNotificaciones: action.payload,
                sentNotificacionesisLoading: false
            }
        case LIST_SENT_NOTIFICACIONES_LOADING:
            return {
                ...state,
                sentNotificacionesisLoading: true
            }
        case LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS:
            return {
                ...state,
                possibleReceivers: action.payload,
                possibleReceiversAreLoading: false
            }
        case LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS_LOADING:
            return {
                ...state,
                possibleReceiversAreLoading: true
            }

        case ADD_NOTIFICACION:
            return {
                ...state,
                sentNotificaciones: [action.payload, ...(state.sentNotificaciones || [])]
            }

        case UPDATE_NOTIFICACION:
            return {
                ...state,
                receivedNotificaciones: (state.receivedNotificaciones || []).map(n => n.id !== action.payload.id ? n : action.payload),
                followedNotificaciones: (state.followedNotificaciones || []).map(n => n.id !== action.payload.id ? n : action.payload),
                recievedUncompletedNotificaciones: (state.recievedUncompletedNotificaciones || []).map(n => n.id !== action.payload.id ? n : action.payload ),
                sentNotificaciones: (state.sentNotificaciones || []).map(n => n.id !== action.payload.id ? n : action.payload),
                notificaciones: (state.notificaciones || []).map(n => n.id !== action.payload.id ? n : action.payload),
            }

        case LIST_NOTIFICACIONES_CHANNELS_LOADING:
            return {
                ...state,
                notificacionesChannelsIsLoading: true
            }
        case LIST_NOTIFICACIONES_CHANNELS:
            return {
                ...state,
                notificacionesChannels: action.payload,
                notificacionesChannelsIsLoading: false
            }
        case UPDATE_NOTIFICACION_CHANNEL:
            return {
                ...state,
                notificacionesChannels: action.payload
            }
        case CREATE_NOTIFICACION_CHANNEL:
            return {
                ...state,
                notificacionesChannels: action.payload
            }

        default:
            return state;
    }
}
