import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, Button, ModalFooter, ModalBody } from "reactstrap";

import { removeRaspberry } from "../../redux/actions/raspberries";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";


class DeleteRaspberriesModal extends Component {
  state = {
    modal: false,
    redirectToListRaspberries: false
  };


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteRaspberries = () => {
    this.props.removeRaspberry(this.props.id)
    this.toggle()
    this.setState({redirectToListRaspberries: true})
  };

  render() {
    const { t } = this.props;

    return (
      this.state.redirectToListRaspberries ? <Redirect to="/raspberries"/> :
      <Fragment>
        <Button style={{marginLeft: "5px"}} color="danger" size="sm" onClick={() => this.toggle()}>
          {t("remove.label")}
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} Raspberry
          </ModalHeader>
          <ModalBody>
            {t("raspberries:remove_confirmation.label")}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={() => this.toggle()}>
              {t("cancel.label")}
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={this.deleteRaspberries}
            >
              {t("remove.label")}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeRaspberry})(withTranslation(['common', 'raspberries'])(DeleteRaspberriesModal));