import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import DropZoneFicheros from "./DropZoneFicheros";

import { addFichero } from '../../../redux/actions/ficheros';
import { withTranslation } from "react-i18next";
import {AddButton,CloseButton} from "../../common/buttons/Buttons";


class NewFicherosModal extends Component {

    state = {
        modal: false,
        files: []
    };
    
    toggle = () => {
        this.setState(previous => ({
            modal: !previous.modal
        }));
    };

    aceptedFicheros = (files) => {
        this.setState({ files: [
            ...this.state.files,
            ...files
        ]})
    };


    deleteAceptedFichero = (fileName) => {
        this.setState({ files: this.state.files.filter((f) => f.name !== fileName)})
    }


    uploadFicheros = () => {
        this.state.files.map((file) => this.props.addFichero(this.props.clienteId, file))
        this.setState({files: []});
        this.toggle();
    }

    render () {
        const {t} = this.props;
        return (
            <Fragment>
                <AddButton onClick={this.toggle} />
                <Modal isOpen={this.state.modal} toggle={this.toggle} keyboard={false} backdrop="static">
                    <ModalHeader color="danger" toggle={this.toggle}>
                    {t("add.label")} {t("ficheros:file.label")}s
                    </ModalHeader>
                    <ModalBody>
                        <DropZoneFicheros files={this.state.files} deleteFichero={this.deleteAceptedFichero} addFicheros={this.aceptedFicheros}/>
                    </ModalBody>
                    <ModalFooter>
                        <CloseButton color="outline-secondary" onClick={ this.toggle} label={t("cancel.label")} style={{margin: "1px"}} />
                        <AddButton onClick={this.uploadFicheros} label={t("add.label")} style={{margin: "1px"}} />
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    clienteId: state.clientes.cliente.id
  });


export default connect(mapStateToProps, { addFichero })(withTranslation(['common', 'ficheros'])(NewFicherosModal));