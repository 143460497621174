import React from 'react';
import { addBookmaker, removeBookmaker, updateBookmaker, listBookmaker} from "../../redux/actions/bookmakers";
import { connect } from 'react-redux';
import { Row, Col, CardHeader, CardBody, Card } from 'reactstrap';

import NewBookmakerModal from "./NewBookmakerModal";
import DeleteBookmakerModal from './DeleteBookmakerModal';
import CopyToClipboard from "../common/CopyToClipboard";
import { withTranslation } from 'react-i18next';
import Footer from '../common/Footer';
import BreadCrumbs from '../common/BreadCrumbs';
import Header from '../common/Header';
import Container from 'reactstrap/lib/Container';
import Rangos from './Rangos';

class Bookmaker extends React.Component {

    componentDidMount() {
        const bookmakerId = this.props.match.params.id
        this.props.listBookmaker(bookmakerId)
    }
    
    createBookmaker = bookmaker => {
        this.props.addBookmaker(bookmaker);
    };
    
    editBookmaker = bookmaker => {
        this.props.updateBookmaker(bookmaker.id, bookmaker);
    };


    render() {
        const { t } = this.props;
        const bookmaker = this.props.bookmaker || {};
        const permissions = this.props.permissions || [];

        return (
        <>
            <Header />
            <Container fluid>
            <BreadCrumbs items={[
                    {'href': '/#', 'name': 'Home', 'active': false},
                    {'href': '/#/bookmakers', 'name': 'Bookmakers', 'active': false},
                    {'href': '', 'name': `${bookmaker.id}`, 'active': true}
            ]}/>
            {!permissions.includes('view') ?
            null :
            <Card style={{ marginTop: "10px" }}>
                <CardHeader>
                <Row>                    
                    <Col>
                        <h5>Bookmaker</h5>
                    </Col>                    

                    {!bookmaker.id ?
                        <Col className="col-auto">
                            {permissions.includes('add') &&
                            <NewBookmakerModal create={true} bookmaker={bookmaker} createBookmaker={this.createBookmaker} clienteId={this.props.clienteId}/>}
                        </Col> : <>
                        
                            <Col className="col-auto">
                                {permissions.includes('change') &&
                                <NewBookmakerModal create={false} bookmaker={bookmaker} editBookmaker={this.editBookmaker}/>}
                            </Col>
                            <Col className="col-auto">
                                {permissions.includes('delete') &&
                                <DeleteBookmakerModal id={bookmaker.id}/>}
                            </Col>
                        </>
                    }
                </Row>
                </CardHeader>
                <CardBody>

                    {!bookmaker.id ? <Row><Col><span>{t("dont_exist.label")}</span></Col></Row> :
                    
                    <Row>
                        <Col sm="12" md="4" className="border-right">
                            <Row>
                                <Col className="col-info" xs="4" md="3">Bookmaker:</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{bookmaker.bookmaker}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">Email:</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{bookmaker.email}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("phone.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{bookmaker.telefono}</CopyToClipboard></Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="4" className="border-right">                            
                            <Row>
                                <Col className="col-info" xs="4" md="3">Chat:</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{bookmaker.chat ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="3">{t("token_type.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="9"><CopyToClipboard>{bookmaker.bono_tipo ? t(`${bookmaker.bono_tipo}.bono_tipo`) : ""}</CopyToClipboard></Col>
                            </Row>                            
                        </Col>
                        <Col sm="12" md="4" >
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("token_amount.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{bookmaker.bono_cantidad}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t('token_rollover.label')}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{bookmaker.bono_rollover}</CopyToClipboard></Col>
                            </Row>                            
                        </Col>
                    </Row>
                    }
                </CardBody>
            </Card>}
            <Rangos bookmakerId={this.props.match.params.id}/>
            </Container>
            <Footer />
        </>
        );
    }
}


const mapStateToProps = state => ({
    bookmaker: state.bookmakers.bookmaker,
    permissions: state.cuentas.permissions.bookmaker
  });

export default connect(mapStateToProps, {addBookmaker, removeBookmaker, updateBookmaker, listBookmaker})(withTranslation('bookmakers', 'comerciales')(Bookmaker));