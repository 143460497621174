import {LIST_COMERCIALES, REMOVE_COMERCIAL, UPDATE_COMERCIAL, ADD_COMERCIAL, LIST_ACTIVE_COMERCIALES, LIST_ACTIVE_COMERCIALES_LOADING, LIST_COMERCIALES_LOADING } from "../types/comerciales";

const initialState = {
    comerciales: null
};

export default function(state = initialState, action) {
    switch(action.type) {

        case LIST_COMERCIALES:
            return {
                ...state,
                comerciales: action.payload,
                isLoading: false
            }
        case LIST_COMERCIALES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case LIST_ACTIVE_COMERCIALES:
            return {
                ...state,
                activeComerciales: action.payload,
                isLoadingActiveComerciales: false
            }
        case LIST_ACTIVE_COMERCIALES_LOADING:
            return {
                ...state,
                isLoadingActiveComerciales: true
            }
        case REMOVE_COMERCIAL:
            return {
                ...state,
                // Devuelve todos los comerciales excepto el borrado
                comerciales: state.comerciales.filter(comercial => comercial.id !== action.payload)
            }
        case ADD_COMERCIAL:
            return {
                ...state,
                comerciales: [...state.comerciales, action.payload]
            }

        case UPDATE_COMERCIAL:
            return {
                ...state,
                comerciales: (state.comerciales || []).map(comercial => comercial.id !== action.payload.id ? comercial : action.payload),
                comercial: action.payload
            }

        default:
            return state;
    }
}
