import { SET_EMPTY_ERRORES, SET_ERRORES, SET_ERRORES_LOADING } from '../types/errores';

const initialState = {
    msg: {},
    status: null,
    context: null,
    isLoading: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_ERRORES:
            return {
                msg: action.payload.msg,
                status: action.payload.status,
                context: action.payload.context,
                isLoading: false
            }; 
        case SET_ERRORES_LOADING:
            return {
                ...initialState,
                isLoading: action.payload.context
            }
        case SET_EMPTY_ERRORES:
            return initialState;
        default:
            return state;
    }
}