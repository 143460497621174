import React, {useEffect} from "react";
import { Row, Col, Input, Label, FormGroup } from "reactstrap";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from '@material-ui/core';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Modal from 'reactstrap/lib/Modal';
import { AddButton, CloseButton } from "../common/buttons/Buttons";
import moment from 'moment';


export default function NewPagoModal(props) {
    const [modal, setModal] = React.useState(false);
    const [pago, setPago] = React.useState({});

    const toggle = () => setModal(!modal);
    const { t } = useTranslation();
    
    useEffect(() => {
      if (modal && !props.erroring && !props.addPagoIsLoading) {
        setModal(false);
        setPago({});
      }
    }, [props.erroring, props.addPagoIsLoading]);
    
    const onChange = e => {
      const attr = e.target.name;
      const value = e.target.value;
      setPago({...pago, [attr]: value})
    }


    const addPago = () => {
      props.addPago(pago);
    }

    return <>
    <Tooltip title="" onClick={toggle}>
        <IconButton aria-label="">
            <AddBoxOutlinedIcon fontSize="large" color="primary"/>
        </IconButton>
    </Tooltip>
    <Modal isOpen={modal} toggle={toggle} backdrop="static" >
        <ModalHeader color="danger" toggle={toggle}>
            {t('common:add.label')} {t('pagos:payment.label')} 
        </ModalHeader>
        <ModalBody>

            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="gestor">{t('cuentas:entity.label')}:</Label>
                </Col>
                <Col md="8">
                  <Input type="select" name="user" onChange={(e) => {onChange(e); props.onChangeGestor(e.target.value)}} value={pago.user}>
                    <option value="">{t("common:option.label")}</option>
                    {
                      props.gestores.map((g, i) => (<option key={i} value={g.id}>{g.entidad}</option>))
                    }
                  </Input>
                </Col>
                </Row>
            </FormGroup>

            {pago.user && props.canperIds.length > 0 ? <>
            
            <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="canper_id">Id:</Label>
                </Col>
                <Col md="8">
                  <Input type="select" name="canper_id" onChange={onChange} value={pago.canper_id}>
                      <option value="">{t("common:option.label")}</option>
                      {props.canperIds.map((c, i) => (<option key={i}>{c}</option>))}
                  </Input>
                </Col>
             </Row>
            </FormGroup>

            {pago.canper_id && <FormGroup>
              <Row>
                <Col md="4">
                  <Label for="fecha">{t("ingresos:date.label")}:</Label>
                </Col>
                <Col md="8">
                  <Input
                    type="datetime-local"
                    name="fecha"
                    onChange={onChange}
                    value={!pago.canper_id ? null : pago.fecha}
                    // Min y maximo depende del canperId elegido
                    min={moment(`01${pago.canper_id}`).format("YYYY-MM-DDThh:mm")}
                    max={moment(moment(pago.canper_id).format("YYYY-MM-") + moment(pago.canper_id).daysInMonth()).format("YYYY-MM-DDThh:mm")}
                  />
                </Col>
              </Row>
            </FormGroup>}

            <FormGroup>
                <Row>
                <Col md="4">
                    <Label for="pago">{t('pagos:payment.label')}:</Label>
                </Col>
                <Col md="8">
                <Input
                  type="number"
                  step="0.01"
                  name="pago"
                  onChange={onChange}
                  value={pago.pago}
                />
                </Col>
                </Row>
            </FormGroup> </> : !pago.user ? null : t("pagos:no_ids.message")
            }

        </ModalBody>
        <ModalFooter>
            <CloseButton  onClick={toggle} label={t("common:cancel.label")} />
            <AddButton onClick={addPago} label={t("common:add.label")} />
        </ModalFooter>
    </Modal>
    </>
}