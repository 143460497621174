import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import { removeIngreso } from "../../../../redux/actions/ingresos";
import { removeIngreso as removeIngresoFromContabilidades } from "../../../../redux/actions/contabilidades";
import { withTranslation } from "react-i18next";
import {CloseButton,DeleteButton} from "../../../common/buttons/Buttons";


class DeleteIngresoModal extends Component {
  state = {
    modal: false
  };


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteIngreso = () => {
    this.props.fromContabilidad ? this.props.removeIngresoFromContabilidades(this.props.id) : this.props.removeIngreso(this.props.id)
    this.toggle()
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
         <DeleteButton  onClick={this.toggle} />

        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} {t("ingresos:entry.label")}
          </ModalHeader>
          <ModalBody>
            {t("ingresos:remove_confirmation_ingreso.label")}
          </ModalBody>
          <ModalFooter>
            <CloseButton onClick={this.toggle} label={t("cancel.label")} style={{margin: "1px"}}/>
            <DeleteButton  onClick={this.deleteIngreso} label={t("remove.label")} style={{margin: "1px"}}/>
            
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeIngreso, removeIngresoFromContabilidades})(withTranslation(['common', 'ingresos'])(DeleteIngresoModal));