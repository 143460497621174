import { API_CONEXIONESPROV_URL } from "../../constants";
import {LIST_CONEXIONESPROV, LIST_CONEXIONPROVCLIENTE,ADD_CONEXIONPROV, UPDATE_CONEXIONPROV,REMOVE_CONEXIONPROV,IMPORT_CONEXION_FICHERO} from "../types/conexionesprov";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';


const CONEXIONESPROV = 'CONEXIONESPROV';
const IMPORTATION_CONEXIONESPROV = 'IMPORTATION_CONEXIONESPROV';

// List Apuestas of a cliente
export const listConexionesprov = () => (dispatch, getState) => {
    axios
    .get(`${API_CONEXIONESPROV_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONEXIONESPROV,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};



// Add Apuesta
export const addConexionprov= (conexionprov) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, CONEXIONESPROV));
    return axios
        .post(`${API_CONEXIONESPROV_URL}`, conexionprov, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_CONEXIONPROV,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("conexionesprov:added_connection.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONEXIONESPROV, 'id': conexionprov.id}));
        })
};

export const updateConexionprov = (id, conexionprov) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, CONEXIONESPROV));
    axios
        .put(`${API_CONEXIONESPROV_URL}${id}/`, conexionprov, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CONEXIONPROV,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("conexionesprov:updated_connection.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONEXIONESPROV, 'id': conexionprov.id}));
        })
};


export const removeConexionprov = ids => (dispatch, getState) => {
    axios
        .delete(`${API_CONEXIONESPROV_URL}${ids}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_CONEXIONPROV,
                payload: ids
            });
            dispatch(createMessage(i18n.t("conexionesprov:remove_connection.message")));
        })
        .catch(err => console.log(err))
};


export const importFichero = (fichero) => (dispatch, getState) => {
    const config = tokenConfig(getState);
    config.headers['Content-Disposition'] = `attachment; filename=temp_${fichero.name}`;
    config.headers['Content-Type'] = 'application/octet-stream';
    dispatch(setErroresLoading(true, IMPORTATION_CONEXIONESPROV));
    dispatch(createMessage(`${i18n.t("apuestas:importing_file.message")}`))
    axios
        .post(`${API_CONEXIONESPROV_URL}import/`, fichero, config)
        .then(res => {
                dispatch({
                    type: IMPORT_CONEXION_FICHERO,
                    payload: res.data
                });
                dispatch(setErroresLoading(false));
                dispatch(createMessage(`${i18n.t("conexionesprov:imported_file.message")}`))
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONEXIONESPROV}));
        })
};

export const listConexionprovByCliente = (cliente_id) => (dispatch, getState) => {
    axios
    .get(`${API_CONEXIONESPROV_URL}${cliente_id}/cliente/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONEXIONPROVCLIENTE,
            payload: {conexiones: res.data, clienteId: cliente_id}
        });
    })
    .catch(err => {
        console.log(err)
    })
};