import React from 'react';
import { addRaspberry, removeRaspberry, updateRaspberry, listRaspberry} from "../../redux/actions/raspberries";
import { connect } from 'react-redux';
import { Row, Col, CardHeader, CardBody, Card } from 'reactstrap';

import NewRaspberryModal from "./NewRaspberryModal";
import DeleteRaspberryModal from './DeleteRaspberryModal';
import CopyToClipboard from "../common/CopyToClipboard";
import { dateFromUStoEU} from "../../helpers/helpers";
import { withTranslation } from 'react-i18next';
import Footer from '../common/Footer';
import BreadCrumbs from '../common/BreadCrumbs';
import Header from '../common/Header';
import Container from 'reactstrap/lib/Container';
import Conexiones from './conexiones/Conexiones';

class Raspberry extends React.Component {
    
    createRaspberry = raspberry => {
        this.props.addRaspberry(raspberry);
    };
    
    editRaspberry = raspberry => {
        this.props.updateRaspberry(raspberry.id, raspberry);
    };


    render() {
        const { t } = this.props;
        const raspberry = this.props.raspberry || {};
        const permissions = this.props.permissions || [];

        return (
        <>
            <Header />
            <Container fluid>
            <BreadCrumbs items={[
                    {'href': '/#', 'name': 'Home', 'active': false},
                    {'href': '/#/raspberries', 'name': 'Raspberries', 'active': false},
                    {'href': '', 'name': `${raspberry.id}`, 'active': true}
            ]}/>
            {!permissions.includes('view') ?
            null :
            <Card style={{ marginTop: "10px" }}>
                <CardHeader>
                <Row>                    
                    <Col>
                        <h5>Raspberry</h5>
                    </Col>                    
                        <Col className="col-auto">
                            {permissions.includes('change') &&
                            <NewRaspberryModal create={false} raspberry={raspberry} editRaspberry={this.editRaspberry}/>}
                        </Col>
                        <Col className="col-auto">
                            {permissions.includes('delete') &&
                            <DeleteRaspberryModal id={raspberry.id}/>}
                        </Col>
                </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm="12" md="4" className="border-right">
                            <Row>
                                <Col className="col-info" xs="4" md="4">Id:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.canper_id}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">Mac:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.mac}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">Rb:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.rb}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("state.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{t(`${raspberry.estado}.estado`)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">Anydesk Id:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.anydesk_id}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t('anydesk_key.label')}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.anydesk_contraseña}</CopyToClipboard></Col>
                            </Row>
                            
                        </Col>
                        <Col sm="12" md="4" className="border-right">
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("ovpn_key.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.ovpn_contraseña}</CopyToClipboard></Col>
                            </Row>                            
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("expiry_date.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{dateFromUStoEU(raspberry.fecha_expiracion)}</CopyToClipboard></Col>
                            </Row>   
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("expiry_time.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.hora_expiracion}</CopyToClipboard></Col>
                            </Row>                              
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t('raspberry_key.label')}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.raspberry_contraseña}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">Isp:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.isp}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("router_user.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.router_usuario}</CopyToClipboard></Col>
                            </Row>                            
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("router_key.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{raspberry.router_contraseña}</CopyToClipboard></Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="4" >
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("date_rb_delivery.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{dateFromUStoEU(raspberry.fecha_rb_entrega)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("date_rb_collection.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{dateFromUStoEU(raspberry.fecha_rb_recogida)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("date_service_start.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{dateFromUStoEU(raspberry.fecha_servicio_inicio)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("date_service_end.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard>{dateFromUStoEU(raspberry.fecha_servicio_fin)}</CopyToClipboard></Col>
                            </Row>
                            <Row>
                                <Col className="col-info" xs="4" md="4">{t("comerciales:comercial.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard >{raspberry.comercial ? raspberry.comercial.nombre : ''}</CopyToClipboard></Col>
                            </Row>
                            {/* <Row>
                                <Col className="col-info" xs="4" md="4">{t("clientes:client.label")}:</Col>
                                <Col className="col-info-result" xs="8" md="8"><CopyToClipboard >{raspberry.cliente ? `${raspberry.cliente.nombre} ${raspberry.cliente.apellido_1} ${raspberry.cliente.apellido_2}`: ''}</CopyToClipboard></Col>
                            </Row> */}
                        </Col>

                    </Row>
                </CardBody>
            </Card>}
            <Conexiones raspberryId={this.props.match.params.id} raspberryRb={raspberry.rb}/>
            </Container>
            <Footer />
        </>
        );
    }
}


const mapStateToProps = state => ({
    raspberry: state.raspberries.raspberry,
    permissions: state.cuentas.permissions.raspberry
  });

class RaspberryContainer extends React.Component {
    componentDidMount() {
        const raspberryId = this.props.match.params.id
        this.props.listRaspberry(raspberryId)
    }

    render() {
        return <>
        {
            !this.props.raspberry ?
            null :
            <Raspberry {...this.props}/>
        }
        </>;
    }

}
export default connect(mapStateToProps, {addRaspberry, removeRaspberry, updateRaspberry, listRaspberry})(withTranslation('raspberries', 'comerciales')(RaspberryContainer));