import React from 'react';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import Header from '../../common/Header';
import BreadCrumbs from '../../common/BreadCrumbs';
import Container from 'reactstrap/lib/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ResumenGestorView from './ResumenGestorView';
import ResumenTraderView from './ResumenTraderView';
import ResumenComparisonView from './ResumenComparisonView';


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        marginBottom: '10px'
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            children
        )}
      </div>
    );
  }

function ResumenAdministradorView(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <>
        <Header />
        <Container fluid>
            <BreadCrumbs items={[
                { 'href': '/#', 'name': 'Home', 'active': false },
                { 'href': `/#/resumen_a`, 'name': t('estrategias:summary.label'), 'active': true },
            ]} />
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Gestor" />
                    <Tab label="Trader" />
                    <Tab label="Comparativa" />
                </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
                {props.permissionsContabilidad && <ResumenGestorView/>}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {props.permissionsEstrategia && <ResumenTraderView/>}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {props.permissionsEstrategia && <ResumenComparisonView />}
            </TabPanel>
        </Container>
    </>;

}

const mapStateToProps = state => ({
    permissionsContabilidad: state.cuentas.permissions.contabilidad,
    permissionsEstrategia: state.cuentas.permissions.estrategia,
});

export default connect(mapStateToProps, null)(ResumenAdministradorView);