import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, Row, Col, Input, Label, FormGroup } from "reactstrap";
import {addBookmaker, updateBookmaker} from "../../redux/actions/bookmakers";
import {defaultIfEmpty} from "../../helpers/helpers";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";


class NewBookmakerModal extends Component {
  state = {
    modal: false,
    forceModalClose: false,
    bookmaker: {}
  };

  componentDidMount() {
    this.setState({bookmaker: this.props.bookmaker})
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  onChange = (e, checkbox=false) => {
    // e.preventDefault();
    const value = !checkbox ? e.target.value : e.target.checked
    this.setState(
      { bookmaker: {
        ...this.state.bookmaker,
        [e.target.name]: value
      }
    });
  };

  onChangeComercial = e => {
    e.preventDefault();
    this.setState(
      { bookmaker: {
        ...this.state.bookmaker,
        comercial: this.props.comerciales.find(c => c.id === parseInt(e.target.value))
      }
    });
  }

  createBookmaker = e => {
    e.preventDefault();
    this.props.addBookmaker(this.state.bookmaker);
    this.setState({modal: false});
  }

  editBookmaker = e => {
    e.preventDefault();
    const bookmaker = this.state.bookmaker;
    if (!bookmaker.comercial){
      delete bookmaker.comercial;
    }

    this.props.updateBookmaker(bookmaker.id, bookmaker);
    this.setState({modal: false});
  };

  onClick = () => {
    this.toggle();
    this.setState({'forceModalClose': false});
    if (this.props.create) {
      this.setState({bookmaker: {}})
    }
  };


  render() {
    const create = this.props.create;
    const bookmaker = this.state.bookmaker || {};
    const closeBtn = <button className="close" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>&times;</button>;
    const { t } = this.props;
    
    return (
    <>
    <Fragment>
      { create ?
        <Button color="primary" size="sm" onClick={() => this.onClick()}> {t("add.label")} </Button> :
        <Button style={{marginLeft: "5px"}} color="warning" size="sm" onClick={() => this.onClick()}> {t("edit.label")}  </Button>
      }
      <Modal isOpen={!this.state.forceModalClose && (this.state.modal || this.props.erroring === bookmaker.id || this.props.isLoading)} toggle={this.toggle} size='md' backdrop="static">
        <ModalHeader color="danger" close={closeBtn}>
        {create ? `${t('add.label')} Bookmaker`: `${t('edit.label')} Bookmaker`}
        </ModalHeader>
        <ModalBody>
        <LoadingOverlay active={this.props.isLoading} spinner={true}>
          <Row>
            <Col>
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="bookmaker">Bookmaker:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="bookmaker"
                      onChange={this.onChange}
                      value={defaultIfEmpty(bookmaker.bookmaker)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="email">Email:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="email"
                      onChange={this.onChange}
                      value={defaultIfEmpty(bookmaker.email)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="telefono">{t('bookmakers:phone.label')}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="telefono"
                      onChange={this.onChange}
                      value={defaultIfEmpty(bookmaker.telefono)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="vip">Chat:</Label>
                  </Col>
                  <Col md="7">
                      <input 
                      type="checkbox"
                      name="chat"
                      checked={!bookmaker.chat ? false : true}
                      onChange={(e) => this.onChange(e, true)}
                      />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                  <Row>
                    <Col md="4">
                      <Label for="bono_tipo">{t('bookmakers:token_type.label')}:</Label>
                    </Col>
                    <Col md="8">
                      <Input type="select" name="bono_tipo" onChange={this.onChange} value={defaultIfEmpty(bookmaker.estado)}>
                        <option value="">{t("option.label")}</option>
                        <option value="BONO">{t("bookmakers:BONO.bono_tipo")}</option>
                        <option value="FREEBET">FREEBET</option>
                      </Input>
                    </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="bono_cantidad">{t('bookmakers:token_amount.label')}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="bono_cantidad"
                      onChange={this.onChange}
                      value={defaultIfEmpty(bookmaker.bono_cantidad)}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label className="col-form-label" for="bono_rollover">{t('bookmakers:token_rollover.label')}:</Label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="text"
                      name="bono_rollover"
                      onChange={this.onChange}
                      value={defaultIfEmpty(bookmaker.bono_rollover)}
                    />
                  </Col>
                </Row>
              </FormGroup>
          </Col>
        </Row>
        </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="outline-secondary" onClick={() => {this.setState({'forceModalClose': true}); this.toggle();}}>
              {t("cancel.label")}
            </Button>
          { create ? 
            (<Button type="button" color="primary" onClick={this.createBookmaker}>
              {t("add.label")}
            </Button>) :
            (<Button type="button" color="warning" onClick={this.editBookmaker}>
              {t("edit.label")}
            </Button>)
          }
        </ModalFooter>
      </Modal>
    </Fragment>
    </>);
  }
}

const mapStateToProps = state => ({
  isLoading: state.errores.isLoading === 'BOOKMAKERS',
  erroring: state.errores && state.errores.status && state.errores.context.type === 'BOOKMAKERS' && state.errores.status !== 200 ? state.errores.context.id : false,
});

export default connect(mapStateToProps, {addBookmaker, updateBookmaker})(withTranslation('common', 'bookmakers', 'comerciales')(NewBookmakerModal));