import { API_NOTIFICACIONES_URL, API_USERS_URL } from "../../constants";
import { ADD_NOTIFICACION, UPDATE_NOTIFICACION, LIST_NOTIFICACIONES, LIST_NOTIFICACIONES_LOADING, LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS, LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS_LOADING, LIST_SENT_NOTIFICACIONES_LOADING, LIST_SENT_NOTIFICACIONES, LIST_RECEIVED_NOTIFICACIONES_LOADING, LIST_RECEIVED_NOTIFICACIONES, LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES_LOADING, LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES, LIST_FOLLOWED_NOTIFICACIONES_LOADING, LIST_FOLLOWED_NOTIFICACIONES, LIST_NOTIFICACIONES_CHANNELS_LOADING, LIST_NOTIFICACIONES_CHANNELS, UPDATE_NOTIFICACION_CHANNEL, CREATE_NOTIFICACION_CHANNEL } from "../types/notificaciones"
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';


const NOTIFICACIONES = 'NOTIFICACIONES';

// Add Notificacion
export const addNotificacion = (notificacion) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, NOTIFICACIONES));
    return axios
        .post(`${API_NOTIFICACIONES_URL}`, notificacion, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_NOTIFICACION,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("notificaciones:added_notificacion.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': NOTIFICACIONES, 'id': notificacion.id}));
        })
};

// Update Notificacion
export const updateNotificacion = (id, Notificacion) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, NOTIFICACIONES));
    axios
        .put(`${API_NOTIFICACIONES_URL}${id}/`, Notificacion, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_NOTIFICACION,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("notificaciones:updated_notificacion.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': NOTIFICACIONES, 'id': id}));
        })
};


export const markNotificacionAsCompleted = (id) => (dispatch, getState) => {
    axios
        .patch(`${API_NOTIFICACIONES_URL}${id}/`, {completed: true}, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_NOTIFICACION,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("notificaciones:mark_completed.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': NOTIFICACIONES, 'id': id}));
        })
};


export const markNotificacionAsVerified = (id) => (dispatch, getState) => {
    axios
        .patch(`${API_NOTIFICACIONES_URL}${id}/`, {verified: true}, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_NOTIFICACION,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("notificaciones:mark_completed.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': NOTIFICACIONES, 'id': id}));
        })
};


export const listNotificaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_NOTIFICACIONES_LOADING });
    axios
    .get(`${API_NOTIFICACIONES_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_NOTIFICACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};

export const listReceivedNotificaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_RECEIVED_NOTIFICACIONES_LOADING });
    axios
    .get(`${API_NOTIFICACIONES_URL}receiver/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_RECEIVED_NOTIFICACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};



export const listRecivedUncompletedNotificaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES_LOADING });
    axios
    .get(`${API_NOTIFICACIONES_URL}receiver/uncompleted/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_RECEIVED_UNCOMPLETED_NOTIFICACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listSentNotificaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_SENT_NOTIFICACIONES_LOADING });
    axios
    .get(`${API_NOTIFICACIONES_URL}sender/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_SENT_NOTIFICACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};

export const listFollowedNotificaciones = () => (dispatch, getState) => {
    dispatch({ type: LIST_FOLLOWED_NOTIFICACIONES_LOADING });
    axios
    .get(`${API_NOTIFICACIONES_URL}follower/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_FOLLOWED_NOTIFICACIONES,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listNotificacionesPossibleReceivers = () => (dispatch, getState) => {
    dispatch({ type: LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS_LOADING });
    axios
    .get(`${API_NOTIFICACIONES_URL}possible_receivers/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_NOTIFICACIONES_POSSIBLE_RECEIVERS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listChannels = () => (dispatch, getState) => {
    dispatch({ type: LIST_NOTIFICACIONES_CHANNELS_LOADING });
    axios
    .get(`${API_NOTIFICACIONES_URL}channels/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_NOTIFICACIONES_CHANNELS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const listChannelsByUser = (userId) => (dispatch, getState) => {
    dispatch({ type: LIST_NOTIFICACIONES_CHANNELS_LOADING });
    axios
    .get(`${API_USERS_URL}${userId}/channels/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_NOTIFICACIONES_CHANNELS,
            payload: res.data
        });
    })
    .catch(err => {
        console.log(err)
    })
};


export const updateChannels = (id, channels) => (dispatch, getState) => {
    axios
        .put(`${API_NOTIFICACIONES_URL}channels/${id}/`, channels, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_NOTIFICACION_CHANNEL,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("notificaciones:updated_notificacion.message")));
        })
        .catch(err => {
            console.log(err)
        })
};


export const createChannels = ( channels) => (dispatch, getState) => {
    axios
        .post(`${API_NOTIFICACIONES_URL}channels/`, channels, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: CREATE_NOTIFICACION_CHANNEL,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("notificaciones:added_notificacion.message")));
        })
        .catch(err => {
            console.log(err)
        })
};
