import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import { removeMonedero } from "../../../redux/actions/monederos";
import { withTranslation } from "react-i18next";
import { DeleteButton,CloseButton } from "../../common/buttons/Buttons";


class DeleteMonederoModal extends Component {
  state = {
    modal: false
  };


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteMonedero = () => {
    this.props.removeMonedero(this.props.id)
    this.toggle()
  };

  render() {
    const { t } = this.props;
    
    return (
      <Fragment>
        <DeleteButton backdrop="static" onClick={ this.toggle} style={{margin: "1px"}}/>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} {t("monederos:wallet.label")}
          </ModalHeader>
          <ModalBody>
            {t('monederos:remove_confirmation.label')}
          </ModalBody>
          <ModalFooter>
            <CloseButton onClick={this.toggle} label={t("cancel.label")} style={{margin: "1px"}}/>
            <DeleteButton onClick={ this.toggle} label={t("remove.label")} style={{margin: "1px"}}/>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeMonedero})(withTranslation(['common', 'monederos'])(DeleteMonederoModal));