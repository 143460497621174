import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { updateAjustes } from '../redux/actions/ajustes'

function LanguageSelector(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const { t, i18n } = useTranslation('common')

  const changeLanguage = (language) => {
    props.updateAjustes(props.ajustesId, {
      user: props.user_id,
      idioma: language
    })
    i18n.changeLanguage(language);
  }
  const selectedLanguage = i18n.language;

  return (
    <div onChange={changeLanguage}>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret size="sm" color="#3f51b5">
              <span style={{color: "white"}}>{t("language.label")}</span>
          </DropdownToggle>
          <DropdownMenu persist={true}>
              <DropdownItem
                onClick={() => changeLanguage('es')}
                disabled={selectedLanguage === 'es' ? true : false}>
                Español
              </DropdownItem>
              <DropdownItem
                onClick={() => changeLanguage('en')}
                disabled={selectedLanguage === 'en' ? true : false}>
                English
              </DropdownItem>
          </DropdownMenu>
      </Dropdown>
    </div>
  )
}

const mapStateToProps = state => ({
  user_id: state.cuentas.user.id,
  ajustesId: state.cuentas.ajustes.id
});

export default connect(mapStateToProps, {updateAjustes})(LanguageSelector)