import React, { useState }  from 'react';
import Datatable from "react-bs-datatable";
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { dateFromUStoEU } from '../../../helpers/helpers';
import DisplayIngresos from '../../clientes/bookmakers/ingresos/DisplayIngresos';
import DisplayRetiradas from '../../clientes/bookmakers/retiradas/DisplayRetiradas';
import { getBalance } from '../helper';
import {CloseButton, CustomButton} from "../../common/buttons/Buttons";


export default function DisplayHistoryContabilidades(props) {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const headers = [
        {title: 'Id', prop: 'canper_id', sortable: false},
        {title: t("contabilidades:initial_balance.label"), prop: 'saldo_inicial', sortable: false},
        {title: t("contabilidades:voucher_balance.label"), prop: 'saldo_bono', sortable: false},
        {title: t("ingresos:entries.label"), prop: 'ingresos', sortable: false},
        {title: t("ingresos:withdrawals.label"), prop: 'retiradas', sortable: false},
        {title: t("contabilidades:date_start.label"), prop: 'fecha_inicio_eu', sortable: false},
        {title: t("contabilidades:date_end.label"), prop: 'fecha_fin_eu', sortable: false},
        {title: t("contabilidades:final_balance.label"), prop: 'saldo_final', sortable: false},
        {title: "Balance", prop: 'balance', sortable: false},
        {title: t("bookmakers:state.label"), prop: 'estado', sortable: false}
    ];

    // const permissions = props.permissions || [];
    const customLabels = {
        noResults: 'No existen contabilidades'
    };


    const contabilidades = props.contabilidades.map(c => ({
        ...c,
        'ingresos': c.bookmaker.ingresos && c.bookmaker.ingresos.length > 0 ?
            <> {c.bookmaker.ingresos.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia).toFixed(2)), 0)} <DisplayIngresos ingresos={c.bookmaker.ingresos} fromContabilidad={true}/></> : 0,
        'retiradas': c.bookmaker.retiradas && c.bookmaker.retiradas.length > 0 ? 
            <> {c.bookmaker.retiradas.reduce((acc, r) => acc + parseFloat(parseFloat(r.transferencia).toFixed(2)), 0)} <DisplayRetiradas retiradas={c.bookmaker.retiradas} fromContabilidad={true} /></> : 0,

        'fecha_inicio_eu': dateFromUStoEU(c.fecha_inicio),
        'fecha_fin_eu': dateFromUStoEU(c.fecha_fin),
        'estado': 'COMPLETADA',
        'balance': getBalance(c)
    }));

    return <>
        <CustomButton color={"info"} onClick={toggle} style={{margin: " 1px"}}/>                
        <Modal isOpen={modal} toggle={toggle} backdrop="static" size='xl'>
            <ModalHeader toggle={toggle}>{t("ingresos:history.label")}</ModalHeader>
            <ModalBody>
                <div className="horizontal-scroll">
                <Datatable
                    tableHeaders={headers}
                    tableBody={contabilidades}
                    tableClass="striped responsive"
                    labels={customLabels}
                />
                </div>
            </ModalBody>
            <ModalFooter>
                <CloseButton onClick={toggle} label={t("common:close.label")} style={{margin: "1px"}}/>
            </ModalFooter>
        </Modal>
    </>
}