import { API_RASPBERRIES_URL, API_CLIENTES_URL } from "../../constants"
import { ADD_CONEXION_CLIENTE, REMOVE_CONEXION_CLIENTE, UPDATE_CONEXION_CLIENTE, LIST_CONEXION_BY_CLIENTE_ID, LIST_CONEXIONES_CLIENTES_BY_RASPBERRY_ID} from "../types/conexion_cliente";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores } from "./errores";
import i18n from '../../translations/i18n';

const CONEXION_CLIENTE = 'CONEXION_CLIENTE';


// Add ConexionCliente
export const addConexionCliente = (conexion_cliente) => (dispatch, getState) => {

    return axios
        .post(`${API_RASPBERRIES_URL}conexiones/cliente/`, conexion_cliente, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_CONEXION_CLIENTE,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("conexiones_clientes:added_cliente_conexion.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONEXION_CLIENTE, 'id': conexion_cliente.id}));
        })
};


// Remove ConexionCliente
export const removeConexionCliente = id => (dispatch, getState) => {
    axios
        .delete(`${API_RASPBERRIES_URL}conexiones/cliente/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_CONEXION_CLIENTE,
                payload: id
            });
            dispatch(createMessage(i18n.t("conexiones_clientes:remove_confirmation.message")));
        })
        .catch(err => console.log(err))
};


// Update ConexionCliente
export const updateConexionCliente = (id, ConexionCliente) => (dispatch, getState) => {
    axios
        .put(`${API_RASPBERRIES_URL}conexiones/cliente/${id}/`, ConexionCliente, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CONEXION_CLIENTE,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("conexiones_clientes:updated_cliente_conexion.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': CONEXION_CLIENTE, 'id': id}));
        })
};


export const listConexionByClienteId = (clienteId) => (dispatch, getState) => {
    axios
    .get(`${API_CLIENTES_URL}${clienteId}/conexiones_clientes/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONEXION_BY_CLIENTE_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_CONEXION_BY_CLIENTE_ID,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listConexionesClientesByRaspberryId = (raspberryId) => (dispatch, getState) => {
    axios
    .get(`${API_RASPBERRIES_URL}${raspberryId}/conexiones_clientes/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_CONEXIONES_CLIENTES_BY_RASPBERRY_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_CONEXIONES_CLIENTES_BY_RASPBERRY_ID,
                payload: []
            });
        }
        console.log(err)
    })
};