import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import { removeRetirada } from "../../../../redux/actions/retiradas";
import { removeRetirada as removeRetiradaFromContabilidades} from "../../../../redux/actions/contabilidades";
import { withTranslation } from "react-i18next";
import {CloseButton,DeleteButton} from "../../../common/buttons/Buttons";


class DeleteRetiradaModal extends Component {
  state = {
    modal: false
  };


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteRetirada = () => {
    this.props.fromContabilidad ? this.props.removeRetiradaFromContabilidades(this.props.id) : this.props.removeRetirada(this.props.id);
    this.props.removeRetirada(this.props.id)
    this.toggle()
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <DeleteButton  onClick={this.toggle} label={t("remove.label")} />
  
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
          <ModalHeader color="danger" toggle={this.toggle}>
            {t("remove.label")} {t("ingresos:withdrawal.label")}
          </ModalHeader>
          <ModalBody>
            {t("ingresos:remove_confirmation_retirada.label")}
          </ModalBody>
          <ModalFooter>
            <CloseButton onClick={ this.toggle} label={t("cancel.label")} style={{margin: "1px"}} />
            <DeleteButton  onClick={this.deleteRetirada} label={t("remove.label")} style={{margin: "1px"}} />
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default connect(null, {removeRetiradaFromContabilidades, removeRetirada})(withTranslation(['common', 'retiradas'])(DeleteRetiradaModal));