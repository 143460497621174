import React, {useEffect} from 'react';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ListNotificaciones from './ListNotificaciones';
import NewNotificacionModal from './NewNotificacionModal';
import {
    listRecivedUncompletedNotificaciones, markNotificacionAsVerified, listNotificacionesPossibleReceivers, markNotificacionAsCompleted, listReceivedNotificaciones, listSentNotificaciones, listFollowedNotificaciones
} from '../../redux/actions/notificaciones';
import { listEstrategiasActuales } from "../../redux/actions/estrategias"
import { SUPERUSER, TRADER } from '../../constants';
import Header from '../common/Header';
import BreadCrumbs from '../common/BreadCrumbs';
import Container from 'reactstrap/lib/Container';
import { Col, Row } from "reactstrap";
import HistoryIcon from '@material-ui/icons/History';
import ListHistoricNotificaciones from './ListHistoricNotificaciones';
import RefreshIcon from '@material-ui/icons/Refresh';


const useStyles = makeStyles((theme) => ({
    row: {
        marginTop: '12px',
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    }
}));


function NotificacionesView(props) {
    const { t } = useTranslation();
    const classes = useStyles();

    const [showHistoric, setShowHistoric] = React.useState(false);

    useEffect(() => {
        if (!props.receivedNotificaciones && props.receivedNotificacionesisLoading === false) {
            props.listReceivedNotificaciones();
        }
        if (!props.sentNotificaciones && props.sentNotificacionesisLoading === false) {
            props.listSentNotificaciones();
        }
        if (!props.followedNotificaciones && props.followedNotificacionesisLoading === false) {
            props.listFollowedNotificaciones();
        }
        if (!props.possibleReceivers && props.possibleReceiversAreLoading === false) {
            props.listNotificacionesPossibleReceivers();
        }
        if (!props.estrategias && !props.isEstrategiasLoading && (props.userGroup === TRADER || props.userGroup === SUPERUSER)) {
            props.listEstrategiasActuales();
        }
    }, [props]);

    const refreshNotificaciones = () => {
        props.listReceivedNotificaciones();
        props.listSentNotificaciones();
        props.listFollowedNotificaciones();
    }


    return <>
        <Header />
        <Container fluid>
            <BreadCrumbs items={[
                {'href': '/#', 'name': 'Home', 'active': false},
                {'href': `/#/notificaciones`, 'name': t('notificaciones:notifications.label'), 'active': true},
            ]}/>
            <Row>
                <Col md='2'>
                    <Typography variant="h4">            
                        {t('notificaciones:notifications.label')}
                    </Typography>
                </Col>
                <Col md='10'>
                    <Tooltip title="" className={classes.text}  onClick={() => refreshNotificaciones()}>
                        <IconButton aria-label="">
                            <RefreshIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                    <NewNotificacionModal userGroup={props.userGroup} sender={props.user} possibleReceivers={props.possibleReceivers} estrategias={props.estrategias} fontSize="large"/>
                    {showHistoric ? 
                    <Tooltip title="" className={classes.text}  onClick={() => setShowHistoric(false)}>
                        <IconButton aria-label="">
                            <NotificationsActiveIcon color="primary"/>
                        </IconButton>
                    </Tooltip> : 
                    <Tooltip title="" className={classes.text}  onClick={() => setShowHistoric(true)}>
                        <IconButton aria-label="">
                            <HistoryIcon color="primary"/>
                        </IconButton>
                    </Tooltip>}
                </Col>
            </Row>

            {showHistoric ? <ListHistoricNotificaciones notificaciones={[...(props.receivedNotificaciones || []), ...(props.sentNotificaciones || []), ...(props.followedNotificaciones || [])]} userGroup={props.userGroup}/> :
            <Row className={classes.row}>
                <Col>
                    {/* Notificaciones recibidas */}
                    <Typography variant="h5">{t("notificaciones:received.label")}</Typography>
                    <div className={'vertical-scroll-large'}>
                        <ListNotificaciones notificaciones={(props.receivedNotificaciones || []).filter(n => !n.completed)} markNotificacionAsCompleted={props.markNotificacionAsCompleted} userGroup={props.userGroup} user={props.user} />
                    </div>
                </Col>
                <Col>
                    {/* Notificaciones enviadas */}
                    <Typography variant="h5">{t("notificaciones:sent.label")}</Typography>
                    <div className={'vertical-scroll-large'}>
                        <ListNotificaciones notificaciones={(props.sentNotificaciones || []).filter(n => !n.completed || !n.verified)} markNotificacionAsCompleted={props.markNotificacionAsCompleted} userGroup={props.userGroup} user={props.user} col="sentNotificaciones"/>
                    </div>
                </Col>
                <Col>
                    {/* Notificaciones informadas */}
                    <Typography variant="h5">{t("notificaciones:following.label")}</Typography>
                    <div className={'vertical-scroll-large'}>
                        <ListNotificaciones notificaciones={(props.followedNotificaciones || []).filter(n => !n.completed || !n.verified)} markNotificacionAsCompleted={props.markNotificacionAsCompleted} markNotificacionAsVerified={props.markNotificacionAsVerified} userGroup={props.userGroup} user={props.user} col="followingNotificaciones"/>
                    </div>
                </Col>
            </Row>}
        </Container>
    </>;

}

const mapStateToProps = state => ({
    receivedNotificaciones: state.notificaciones.receivedNotificaciones,
    receivedNotificacionesisLoading: state.notificaciones.receivedNotificacionesisLoading || false,
    sentNotificaciones: state.notificaciones.sentNotificaciones,
    sentNotificacionesisLoading: state.notificaciones.sentNotificacionesisLoading || false,
    followedNotificaciones: state.notificaciones.followedNotificaciones,
    followedNotificacionesisLoading: state.notificaciones.followedNotificacionesisLoading || false,
    possibleReceivers: state.notificaciones.possibleReceivers,
    possibleReceiversAreLoading: state.notificaciones.possibleReceiversAreLoading || false,
    userGroup: state.cuentas.permissions_group,
    user: state.cuentas.user,
    estrategias: state.estrategias.estrategias,
    isEstrategiasLoading: state.estrategias.isLoadingEstrategias,
});

export default connect(mapStateToProps, {listEstrategiasActuales, markNotificacionAsCompleted, listRecivedUncompletedNotificaciones, listNotificacionesPossibleReceivers, listReceivedNotificaciones, listSentNotificaciones, listFollowedNotificaciones, markNotificacionAsVerified})(NotificacionesView);