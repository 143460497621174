import React, {useState} from 'react'
import Dropzone from 'react-dropzone'
import { Row, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';


export default function DropZoneConexiones(props) {
    const [errorFileName, seterrorFileName] = useState(false);

    const { t } = useTranslation();
    const maxSize = 20971520; // 20 MB

    function onDropAccepted(files) {
        // Firstly, check if there is any no .ovpn file
        if (files.find(f => !f.name.endsWith('.ovpn'))) {
            seterrorFileName(t("conexiones:error_file_ovpn.message"))
            return
        } else {
            // Check if matches regex expression
            const regexExpression = new RegExp(`(?<=^)${props.rb}-[1-9].ovpn(?=$)`);
            if (files.find(f => !regexExpression.exec(f.name))) {
                seterrorFileName(t("conexiones:error_file_format.message"))
                return
            } else {
                const newConnections = files.map(function(f) {const file_name = f.name.split('-'); return parseInt(file_name[1].replace('.ovpn', ''))})
                const sortedNewConnections = newConnections
                const connections = [...props.existingConnections, ...sortedNewConnections].sort()

                let j = 0;
                for (let i = 1; i <= connections.length; i++) {
                    if (i !== connections[j]) {
                        seterrorFileName(`${t("conexiones:error_file_existing_index.message")} : ${props.rb}-${i}.ovpn`)
                        return
                    }
                    j++;
                }
            }
        }
        seterrorFileName(false);
        props.addFicheros(files)
    }

    return (
        <Dropzone
            // Types: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
            // accept=".ovpn "
            minSize={0}
            maxSize={maxSize}
            maxFiles={20}
            multiple
            onDropAccepted={(f) => onDropAccepted(f)}
        >
            {({getRootProps, getInputProps, acceptedFiles, isDragActive, isDragReject, rejectedFiles}) => {
                const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;            
                
                return (
                    <>
                    <div>
                        <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        
                        <Row style={{justifyContent: "center", paddingTop: "10px", paddingBottom: "20px", marginLeft: "25px", marginRight: "25px"}}>{t("conexiones:dropzone.label")}</Row>
                        <Row style={{borderStyle: "dotted", paddingTop: "50px", paddingBottom: "50px", marginLeft: "25px", marginRight: "25px"}}>
                            <Col md="4"></Col>
                            <Col md="4">
                                <Row style={{justifyContent: "center"}}><i className="fa fa-cloud-upload fa-2x"></i></Row>
                                <Row />
                                {!isDragActive &&
                                    <Row style={{justifyContent: "center"}}>{t("ficheros:drag.label")}</Row>}
                                {isDragActive && !isDragReject && 
                                    <Row style={{justifyContent: "center"}}>{t("ficheros:drop.label")}</Row>}
                                {errorFileName && 
                                    <Row className="text-danger" style={{justifyContent: "center"}}>{errorFileName}</Row>}
                                {isFileTooLarge && 
                                    <Row className="text-danger" style={{justifyContent: "center"}}>{t("ficheros:too_big.label")}</Row>}
                            </Col>
                            <Col md="4"></Col>
                        </Row>    
                        </div>
                    </div>
                    <ul className="list-group mt-4">
                        {props.files.length > 0 && props.files.map((file, i) => (
                        <li key={i} className="list-group-item list-group-item-primary">
                            <Row>
                                <Col>
                                    {file.name}
                                </Col>   
                                <Col className="col-auto">
                                <Button size="sm" color="danger" onClick={() => props.deleteFichero(file.name)}>
                                    {t("common:cancel.label")}
                                </Button>
                                </Col>  
                            </Row>
                        </li>
                        ))}
                    </ul>
                    </>
                )}
                }
        </Dropzone>
    );
}
