import { API_APUESTAS_URL } from "../../constants";
import { ADD_APUESTA, REMOVE_APUESTA, UPDATE_APUESTA, LIST_APUESTAS, IMPORT_APUESTA_FICHERO } from "../types/apuestas"
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';


const APUESTAS = 'APUESTAS';
const IMPORTATION_APUESTAS = 'IMPORTATION_APUESTAS';


// Add Apuesta
export const addApuesta = (apuesta) => (dispatch, getState) => {

    return axios
        .post(`${API_APUESTAS_URL}`, apuesta, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_APUESTA,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("apuestas:added_apuesta.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': APUESTAS, 'id': apuesta.id}));
        })
};


// Remove Apuesta
export const removeApuesta = id => (dispatch, getState) => {
    axios
        .delete(`${API_APUESTAS_URL}${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_APUESTA,
                payload: id
            });
            dispatch(createMessage(i18n.t("apuestas:remove_apuesta.message")));
        })
        .catch(err => console.log(err))
};


// Update Apuesta
export const updateApuesta = (id, Apuesta) => (dispatch, getState) => {
    axios
        .put(`${API_APUESTAS_URL}${id}/`, Apuesta, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_APUESTA,
                payload: res.data
            });
            dispatch(createMessage(i18n.t("apuestas:updated_apuesta.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': APUESTAS, 'id': id}));
        })
};


// List Apuestas of a cliente
export const listApuestas = () => (dispatch, getState) => {
    axios
    .get(`${API_APUESTAS_URL}`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_APUESTAS,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_APUESTAS,
                payload: []
            });
        }
        console.log(err)
    })
};


export const importFichero = (fichero) => (dispatch, getState) => {
    const config = tokenConfig(getState);
    config.headers['Content-Disposition'] = `attachment; filename=temp_${fichero.name}`;
    config.headers['Content-Type'] = 'application/octet-stream';
    dispatch(setErroresLoading(true, IMPORTATION_APUESTAS));
    dispatch(createMessage(`${i18n.t("apuestas:importing_file.message")}`))
    axios
        .post(`${API_APUESTAS_URL}import/`, fichero, config)
        .then(res => {
                dispatch({
                    type: IMPORT_APUESTA_FICHERO,
                    payload: res.data
                });
                dispatch(setErroresLoading(false));
                dispatch(createMessage(`${i18n.t("apuestas:imported_file.message")}`))
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': IMPORTATION_APUESTAS}));
        })
};