import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, numberFilter, Comparator  } from 'react-bootstrap-table2-filter';
import DisplayIngresos from "../../clientes/bookmakers/ingresos/DisplayIngresos";
import DisplayRetiradas from "../../clientes/bookmakers/retiradas/DisplayRetiradas";
import NewEstimacionModal from "./NewEstimacionModal";
import DisplayEstimaciones from "./DisplayEstimaciones";
import { getBalanceSumary } from "../../contabilidades/helper";
import moment from 'moment';
import {patchEstrategia} from '../../../redux/actions/estrategias';


class ListEstimaciones extends React.Component {

    state = {
        filters: {            
            bookmaker: localStorage.getItem('filters.estimaciones.bookmaker'),
            usuario: localStorage.getItem('filters.estimaciones.usuario'),
            clave: localStorage.getItem('filters.estimaciones.clave'),
            saldoMaximo: localStorage.getItem('filters.estimaciones.saldoMaximo'),
            saldoInicial: localStorage.getItem('filters.estimaciones.saldoInicial'),
            ingresos: localStorage.getItem('filters.estimaciones.ingresos'),
            retiradas: localStorage.getItem('filters.estimaciones.retiradas'),
            saldoFinal: localStorage.getItem('filters.estimaciones.saldoFinal'),
            balance: localStorage.getItem('filters.estimaciones.balance'),
            objetivo: localStorage.getItem('filters.estimaciones.balance'),
          }
    }
    bookmakerFilter;
    usuarioFilter;
    claveFilterFilter;
    saldoMaximoFilter;
    saldoInicialFilter;
    ingresosFilter;
    retiradasFilter;
    saldoFinalFilter;
    balanceFilter;
    objetivoFilter;

    clearFilters = () => {
      this.bookmakerFilter('');
      this.usuarioFilter('');
      this.claveFilterFilter('');
      this.saldoMaximoFilter('');
      this.saldoInicialFilter('');
      this.ingresosFilter('');
      this.retiradasFilter('');
      this.saldoInicialFilter('');
      this.saldoFinalFilter('');
      this.balanceFilter('');
      this.objetivoFilter('');
    }

    transformEstimacionesIntoTable = (estimaciones) => {
      const transformedEstimaciones = [];
      
      estimaciones.forEach(e => {
        const isEstimacionCompletada = e.bookmaker.estado === 'COMPLETADA';
        const estimacion = e.estimaciones.length > 0 ? e.estimaciones[e.estimaciones.length - 1].estimacion : 0;
        const ingresos_value = e.bookmaker.ingresos.reduce((acc, i) => acc + parseFloat(parseFloat(i.transferencia).toFixed(2)), 0);
        const retiradas_value = e.bookmaker.retiradas.reduce((acc, r) => acc + parseFloat(parseFloat(r.transferencia).toFixed(2)), 0);
        const prioridad = this.props.prioridades.find(p => p.bookmaker.bookmaker === e.bookmaker.bookmaker)

        transformedEstimaciones.push({
          'ingresos_value': ingresos_value,
          'retiradas_value': retiradas_value,
          'estimacion': <>
            {
            isEstimacionCompletada ?
              e.saldo_final :
              <>
              {estimacion}
              {this.props.permissions.includes('change') && <NewEstimacionModal estrategiaId={e.id} estrategiaFechaInicio={e.fecha_inicio}/>}
              {e.estimaciones && e.estimaciones.length > 0 && <DisplayEstimaciones estimaciones={e.estimaciones} />}
              </>
            }
          </>,
          'bookmakerEstado': e.bookmaker.estado,
          'bookmaker_usuario': isEstimacionCompletada ? '-' : e.bookmaker.usuario,
          'bookmaker_clave_acceso': isEstimacionCompletada ? '-' : e.bookmaker.clave_acceso,
          'prioridad': prioridad ? prioridad.prioridad : this.props.all_bookmakers.find(b => b.bookmaker === e.bookmaker.bookmaker).prioridad,
          'ingresos': <>
            {ingresos_value}
            {e.bookmaker.ingresos && e.bookmaker.ingresos.length > 0 && <DisplayIngresos ingresos={e.bookmaker.ingresos} fromContabilidad={true}/>}
          </>,
            'retiradas': <>
            {retiradas_value}
            {e.bookmaker.retiradas && e.bookmaker.retiradas.length > 0 && <DisplayRetiradas retiradas={e.bookmaker.retiradas} fromContabilidad={true} />}
          </>,
          'balance': getBalanceSumary({
            ...e,
            ingresos: e.bookmaker.ingresos,
            retiradas: e.bookmaker.retiradas,
            fecha_fin: e.fecha_fin || moment().endOf('month').format('YYYY-MM-DD'),
            saldo_final: isEstimacionCompletada ? e.saldo_final : estimacion
          }),
          ...e
        })
      });
      return transformedEstimaciones;
    };


    render() {
        const { t } = this.props;
        const estimaciones = this.props.estimaciones;
        const rows = this.transformEstimacionesIntoTable(estimaciones);
        const totalBalance = rows.reduce((acc, r) => r.balance ? parseFloat(r.balance) + acc : acc, 0);
        rows.push({estimacion: `${t("estrategias:target.label")}: ${estimaciones[0].asignacion.objetivo.objetivo}`, balance: `Total: ${totalBalance.toFixed(2)}`})

        const headers = [
          this.props.permissions.includes('view') && {
            text: 'Bookmaker', 
            dataField: 'bookmaker.bookmaker',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.bookmaker,
              getFilter: (filter) => {
                this.bookmakerFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.bookmaker', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:user.label"), 
            dataField: 'bookmaker_usuario',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: 'bookmaker.estado' === 'COMPLEADA' ? '' : this.state.filters.usuario,
              getFilter: (filter) => {
                this.usuarioFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.usuario', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:key.label"), 
            dataField: 'bookmaker_clave_acceso',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.clave,
              getFilter: (filter) => {
                this.claveFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.clave', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("bookmakers:maximum_balance.label"), 
            dataField: 'bookmaker.saldo_maximo',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoMaximo,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoMaximoFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.saldoMaximo', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("contabilidades:initial_balance.label"), 
            dataField: 'saldo_inicial',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoInicial,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoInicialFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.saldoInicial', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("ingresos:entries.label"), 
            dataField:  'ingresos_value',
            formatter: (cell, row) => row.ingresos,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.ingresos,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.ingresosFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.ingresos', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("ingresos:withdrawals.label"), 
            dataField: 'retiradas_value',
            formatter: (cell, row) => row.retiradas,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.retiradas,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.retiradasFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.retiradas', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: t("estrategias:priority.label"), 
            dataField: 'prioridad',
            formatter: this.addToCopyFormatter,
            filter: textFilter({
              defaultValue: this.state.filters.prioridad,
              getFilter: (filter) => {
                this.prioridadFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.prioridad', value);
              },
            }),
            sort: true
        },
          this.props.permissions.includes('view') && {
            text: t("contabilidades:final_balance.label"), 
            dataField: 'estimacion',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.saldoFinal,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.saldoFinalFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.saldoFinal', value);
              },
            }),
            sort: true
          },
          this.props.permissions.includes('view') && {
            text: 'Balance', 
            dataField: 'balance',
            formatter: this.addToCopyFormatter,
            filter: numberFilter({
              defaultValue: {
                number: this.state.filters.balance,
                comparator: Comparator.EQ},
                comparatorStyle: { display: 'none' },
              getFilter: (filter) => {
                this.balanceFilter = filter;
              },
              onFilter: (value) => {
                localStorage.setItem('filters.estimaciones.balance', value);
              },
            }),
            sort: true
          },                                 
          // Utilizado para ordenar la tabla
          {
            dataField: 'bookmaker.estado',
            hidden: true
          },
        ]

        const defaultSorted = [
          {
            dataField: 'bookmaker.estado',
            order: 'desc'
          },
        ];

        const rowCompletedStyle = (row, rowIndex) => {
          const style = {};
          if (row.bookmaker && row.bookmaker.estado === 'COMPLETADA') {
            style.backgroundColor = '#c8e6c9';
          } 
          return style;
        };

        return (
            <BootstrapTable
              bootstrap4
              hover
              striped
              condensed
              bordered={ false }
              keyField='id'
              data={rows}
              columns={ headers }
              filter={ filterFactory() }
              wrapperClasses="estimaciones-datatable"
              defaultSorted={ defaultSorted }
              rowStyle={ rowCompletedStyle }
            />

        );
    }
}

const mapStateToProps = state => ({
    permissions: state.cuentas.permissions.estimacion,
    all_bookmakers: state.opciones.bookmaker
  });

export default connect(mapStateToProps, {patchEstrategia})(withTranslation('estimaciones', 'common')(ListEstimaciones));