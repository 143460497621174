import React from 'react';
import { Tooltip } from 'reactstrap';


export default class CustomTooltip extends React.Component {
  constructor(props) {
    super(props);

    // this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  componentDidMount() {
    this.setState({text: this.props.text})
  }
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <>
        <span>{this.props.text.substring(0, this.props.stringLimit)}
          <span id={this.props.toTarget}>
            {/* Icon */}
            <svg style={{marginLeft: "5px"}} className="bi bi-chat-square-dots" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M14 1H2a1 1 0 00-1 1v8a1 1 0 001 1h2.5a2 2 0 011.6.8L8 14.333 9.9 11.8a2 2 0 011.6-.8H14a1 1 0 001-1V2a1 1 0 00-1-1zM2 0a2 2 0 00-2 2v8a2 2 0 002 2h2.5a1 1 0 01.8.4l1.9 2.533a1 1 0 001.6 0l1.9-2.533a1 1 0 01.8-.4H14a2 2 0 002-2V2a2 2 0 00-2-2H2z" clipRule="evenodd"/>
              <path d="M5 6a1 1 0 11-2 0 1 1 0 012 0zm4 0a1 1 0 11-2 0 1 1 0 012 0zm4 0a1 1 0 11-2 0 1 1 0 012 0z"/>
            </svg>
          </span>
        </span>
        <Tooltip placement="top" isOpen={this.state.tooltipOpen} autohide={false} target={this.props.toTarget} toggle={this.toggle} >
          {this.props.text}
        </Tooltip>
      </>
    );
  }
}