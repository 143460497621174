import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { Row, FormGroup, Input, Modal, ModalHeader, ModalFooter, ModalBody, Col, Label } from "reactstrap";
import { withTranslation } from "react-i18next";
import {updateContabilidad, addContabilidad, listContabilidades, updateBookmaker} from "../../../redux/actions/contabilidades"
import {addEstrategia, patchEstrategia} from "../../../redux/actions/estrategias"
import {setManualErrores} from "../../../redux/actions/errores"
import { defaultIfEmpty } from "../../../helpers/helpers";
import moment from 'moment';
import { getBalance } from "../helper";
import {CloseButton} from "../../common/buttons/Buttons";


class CloseContabilidadesModal extends Component {
    state = {
        modal: false,
        contabilidad: {}
    }


    componentDidMount() {
      this.setState({
        contabilidad: this.props.contabilidad,
      });
    }

    onChangeTipoCierre = e => {
      e.preventDefault();
      const value = e.target.value;
      const fecha_fin = value === 'CIERRE MENSUAL' ? moment().endOf('month').format('YYYY-MM-DD') : this.state.fecha_fin;

      this.setState(
        { contabilidad: {
          ...this.state.contabilidad,
          [e.target.name]: e.target.value,
          fecha_fin
        }
      })
    };

    onChange = e => {
      e.preventDefault();
      const attr = e.target.name;
      
      this.setState(
        { contabilidad: {
          ...this.state.contabilidad,
          [attr]: e.target.value
        }
      });
    };

    toggle = () => {
        this.setState(previous => ({
          modal: !previous.modal
        }));
    };


    isValid = (keys=['tipo_cierre', 'fecha_fin', 'saldo_final']) => {
      const errs = keys.reduce((acc, k) => !this.state.contabilidad[k] ? {...acc, [k]: [`empty value`]} : acc, {})
      if (Object.entries(errs).length === 0) {
        return true;
      } else {
        this.props.setManualErrores(errs, {'type': 'CONTABILIDADES'});
        return false;
      }
    };


    close = () => {
        const contabilidad = this.state.contabilidad;
        const isClosureMonth = contabilidad.tipo_cierre && contabilidad.tipo_cierre === 'CIERRE MENSUAL';
        const fecha_fin = moment(contabilidad.fecha_fin).format().slice(0, 19).replace('T', ' ');
        const canper_id = new Date(contabilidad.canper_id)

        if (this.isValid()) {

          this.props.updateContabilidad(contabilidad.id, {...contabilidad});
          const estrategia = this.props.estrategias.find(t => t.bookmaker.id === contabilidad.bookmaker.id && !t.saldo_final && !t.fecha_fin)
          estrategia && this.props.patchEstrategia(estrategia.id, {fecha_fin: fecha_fin, saldo_final: contabilidad.saldo_final})          
          
          if (isClosureMonth) {
            const new_canper_id = new Date(canper_id.getFullYear(), canper_id.getMonth()+1, 1).toLocaleString("en-GB", {month: "short", year: "numeric"}).toUpperCase().replace(" ", "");
            const fecha_inicio = fecha_fin;
            const saldo_inicial = contabilidad.saldo_final;
            this.props.addContabilidad({canper_id: new_canper_id, fecha_inicio, saldo_inicial, bookmaker: contabilidad.bookmaker, pertenece_a: {id: contabilidad.pertenece_a.id, username: 'to_be_overwriten'}});
            estrategia && this.props.addEstrategia({fecha_inicio, saldo_inicial, bookmaker: contabilidad.bookmaker, asignacion: estrategia.asignacion, canper_id: new_canper_id})
          } else { 
            this.props.updateBookmaker(contabilidad.bookmaker.id, {...contabilidad.bookmaker, 'estado': 'COMPLETADA', 'observaciones': 'LIMITADA', 'cliente': contabilidad.bookmaker.cliente.id});
          }
          this.toggle();
        }
    };
    

    render() {
        const {t} = this.props;
        const contabilidad = this.state.contabilidad;
        const balance = getBalance({
          ...contabilidad,
          saldo_inicial: this.props.contabilidad.saldo_inicial,
          fecha_inicio: this.props.contabilidad.fecha_inicio,
          bookmaker: {...contabilidad.bookmaker, ingresos: this.props.contabilidad.bookmaker.ingresos, retiradas: this.props.contabilidad.bookmaker.retiradas},
        });
        return (
            <Fragment>
              <CloseButton onClick={() => {this.toggle()}} style={{margin: "1px"}} color={"primary"}  />

                <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static">
                  <ModalHeader toggle={this.toggle}>
                    {t("contabilidades:closure.label")} {t("contabilidades:accounting.label")}
                  </ModalHeader>
                    
                  <ModalBody>                  
                    <FormGroup>
                        <Row>
                          <Col md="4">
                            <Label for="tipo_cierre">{t("contabilidades:closure_type.label")}</Label>
                          </Col>
                          <Col md="8">
                            <Input type="select" name="tipo_cierre" onChange={this.onChangeTipoCierre} value={defaultIfEmpty(contabilidad.tipo_cierre)}>
                              <option value="">{t("option.label")}</option>
                              <option value="CIERRE">{t("contabilidades:CIERRE.tipo_cierre")}</option>
                              <option value="CIERRE MENSUAL" disabled={moment().format('YYYY-MM-DD') < contabilidad.fecha_inicio}>{t("contabilidades:CIERRE MENSUAL.tipo_cierre")}</option>
                            </Input>
                          </Col>
                        </Row>
                    </FormGroup>
                    {!!contabilidad.tipo_cierre && <>
                    <FormGroup>
                      <Row>
                        <Col md="4">
                          <Label for="fecha_fin">{t("contabilidades:date_end.label")}</Label>
                        </Col>
                        <Col md="8">
                          <Input
                            type="datetime-local"
                            name="fecha_fin"
                            onChange={(e) => this.onChange(e)}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col md="4">
                          <Label for="saldo_final">{t("contabilidades:final_balance.label")}</Label>
                        </Col>
                        <Col md="8">
                          <Input
                            type="number"
                            step="0.01"
                            min="0"
                            name="saldo_final"
                            onChange={(e) => {this.onChange(e)}}
                            value={defaultIfEmpty(contabilidad.saldo_final)}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col md="4">
                          <Label for="balance">Balance</Label>
                        </Col>
                        <Col md="8">
                          <Input
                            type="number"
                            name="balance"                    
                            value={defaultIfEmpty(balance)}
                            readOnly
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    </>}
                  </ModalBody>
                  <ModalFooter>
                    <CloseButton onClick={() => this.toggle()} style={{margin: "1px"}} color={"primary"} label={t("cancel.label")} />
                    <CloseButton color={"primary"} onClick={() => this.close()} label= {t("contabilidades:closure.label")} style={{margin: "1px"}} /> 
                  </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}


export default connect(null, {setManualErrores, listContabilidades, addContabilidad, updateContabilidad, patchEstrategia, addEstrategia, updateBookmaker})(withTranslation(['common', 'contabilidades'])(CloseContabilidadesModal));