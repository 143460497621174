import { API_BOOKMAKERS_URL, API_CLIENTES_URL } from "../../constants";
import {  ADD_RETIRADA, REMOVE_RETIRADA, UPDATE_RETIRADA, LIST_RETIRADAS, LIST_RETIRADAS_BY_CLIENTE_ID, LIST_RETIRADAS_BY_BOOKMAKER_ID} from "../types/retiradas";
import axios from "axios";
import { tokenConfig } from "./cuentas";
import { createMessage } from "./mensajes";
import { setErrores, setErroresLoading } from "./errores";
import i18n from '../../translations/i18n';


const RETIRADAS = 'RETIRADAS';

// Add Retirada
export const addRetirada = (retirada) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, RETIRADAS));
    return axios
        .post(`${API_BOOKMAKERS_URL}retiradas/`, retirada, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_RETIRADA,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("ingresos:added_retirada.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': RETIRADAS, 'id': retirada.id}));
        })
};


// Remove Retirada
export const removeRetirada = id => (dispatch, getState) => {
    axios
        .delete(`${API_BOOKMAKERS_URL}retiradas/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_RETIRADA,
                payload: id
            });
            dispatch(createMessage(i18n.t("ingresos:remove_retirada.message")));
        })
        .catch(err => console.log(err))
};


// Update Retirada
export const updateRetirada = (id, Retirada) => (dispatch, getState) => {
    dispatch(setErroresLoading(true, RETIRADAS));
    axios
        .put(`${API_BOOKMAKERS_URL}retiradas/${id}/`, Retirada, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_RETIRADA,
                payload: res.data
            });
            dispatch(setErroresLoading(false));
            dispatch(createMessage(i18n.t("ingresos:updated_retirada.message")));
        })
        .catch(err => {
            dispatch(setErrores(err.response, {'type': RETIRADAS, 'id': id}));
        })
};


// List Retiradas of a cliente
export const listRetiradas = () => (dispatch, getState) => {
    axios
    .get(`${API_BOOKMAKERS_URL}retiradas/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_RETIRADAS,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_RETIRADAS,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listRetiradasByBookmakerId = (bookmakerId) => (dispatch, getState) => {
    axios
    .get(`${API_BOOKMAKERS_URL}${bookmakerId}/retiradas/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_RETIRADAS_BY_BOOKMAKER_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_RETIRADAS_BY_BOOKMAKER_ID,
                payload: []
            });
        }
        console.log(err)
    })
};


export const listRetiradasByClienteId = (clienteId) => (dispatch, getState) => {
    axios
    .get(`${API_CLIENTES_URL}${clienteId}/bookmakers/retiradas/`, tokenConfig(getState))
    .then(res => {
        // eslint-disable-next-line 
        dispatch({
            type: LIST_RETIRADAS_BY_CLIENTE_ID,
            payload: res.data
        });
    })
    .catch(err => {
        if (err.response.status === 403) {
            dispatch({
                type: LIST_RETIRADAS_BY_CLIENTE_ID,
                payload: []
            });
        }
        console.log(err)
    })
};