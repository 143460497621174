import React from "react";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';


class BreadCrumbs extends React.Component {
    render() {
        return (
            <div>              
              <Breadcrumb style={{ marginTop: "10px" }}>
                {this.props.items.map((i, index) => (
                <BreadcrumbItem key={index} active={i.active}>{i.active ? i.name : <a href={i.href}>{i.name}</a>}</BreadcrumbItem>
                ))}
              </Breadcrumb>
            </div>
        );
    }
}


export default BreadCrumbs;
